import { get } from '../base';

const basePath = () => (
  [
    '/smart_planner/availabilities',
  ].join('/')
);

export const findFirstAvailableDate = (duration) => (
  get(`${basePath()}/first-available-date.json?duration=${duration}`)
);

export default {
  findFirstAvailableDate,
};
