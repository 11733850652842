import React from 'react';
import PropTypes from 'prop-types';

const DefaultInput = React.forwardRef(({ size, ...options }, ref) => (
  <input
    ref={ref}
    type="text"
    className={`form-control form-control-${size}`}
    {...options}
  />
));

const InlineInput = React.forwardRef(({ components, column, label, size, ...options }, ref) => {
  const Input = components.Input || DefaultInput;

  return (
    <div className={column}>
      {
        label ? <label>{label}</label> : null
      }
      <Input
        size={size}
        ref={ref}
        {...options}
      />
    </div>
  );
});

InlineInput.propTypes = {
  column: PropTypes.string,
  size: PropTypes.string,
  components: PropTypes.shape({}),
};

InlineInput.defaultProps = {
  column: 'col',
  size: 'lg',
  components: {},
};

export default InlineInput;
