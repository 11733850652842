import React from 'react';
import PropTypes from 'prop-types';

import { invertColor } from '../../../utils/colors';
import Icon from '../../Icon';
import { draftAllocationShape } from '../proptypes';
// import { formatIsoDateTime } from '../../../utils/datetime';

import DatetimeLabel from '../../DatetimeRangeLabel';

const DraftAllocation = ({ draftAllocation, color }) => {
  const {
    duration,
    start_at,
    end_at,
    date,
    moment,
  } = draftAllocation;
  const { description } = moment;
  const start_on = start_at.split('T')[0];
  const end_on = end_at.split('T')[0];
  const style = {};
  let secondaryColor = '#000000';

  if (color) {
    style.backgroundColor = color;
    secondaryColor = invertColor(color, true);
    style.color = secondaryColor;
  }

  const title = description || 'Draft allocation';

  return (
    <div className="DraftAllocation" style={style}>
      <div className="DraftAllocation__content">
        <h6 className="text-truncate" title={title}>
          <Icon
            name="calendar-check"
            family="far"
          />
          &nbsp;
          <strong>
            { title }
          </strong>
        </h6>

        <hr className="my-2" style={{ borderColor: secondaryColor }} />

        <div className="DraftAllocation__datetime small">
          <DatetimeLabel
            start_on={start_on}
            start_at={start_at}
            end_on={end_on}
            end_at={end_at}
            separator=" "
          />
        </div>
      </div>
    </div>
  );
};

DraftAllocation.propTypes = {
  draftAllocation: PropTypes.shape(draftAllocationShape).isRequired,
  color: PropTypes.string,
};

DraftAllocation.defatulProps = {
  color: null,
};

export default DraftAllocation;
