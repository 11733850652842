import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import SelectInput from '../inputs/SelectInput';
import Icon from '../Icon';
import { colorizedLabel } from '../../utils/money';
import handleError from '../../utils/error-handler';
import { success } from '../../utils/notifier';
import { trackParentOperation } from '../../api/operations';
import { operationHeadline } from './commons';

const TrackParentModal = ({
  show,
  onHide,
  onChange,
  operation,
  creditNotes,
}) => {
  const {
    amount,
    operation_reference,
    humanDate,
    counterpart,
    operation_headline,
    credit_note_id,
  } = operation;
  // State
  const initialCreditNode = creditNotes.find(({ operation_id }) => (
    operation_id === credit_note_id
  ));
  const [parentCreditNote, setParentCreditNote] = useState(initialCreditNode);
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const FormattedAmount = colorizedLabel(amount);
  const canSubmit = !isLoading && isDirty;
  const cannotSubmit = !canSubmit;
  const headline = operationHeadline(operation);
  const handleInputChange = (selectedOperation) => {
    if (!isDirty) { setIsDirty(true); }
    setParentCreditNote(selectedOperation);
  };
  const handleSubmit = () => {
    setIsLoading(true);

    const payload = {
      operationId: operation.operation_id,
      parentOperationId: (parentCreditNote || {}).operation_id,
    };

    trackParentOperation(payload)
      .then((response) => response.json())
      .then((data) => {
        success({ message: data.message });
        onChange(payload);
        setIsLoading(false);
      })
      .catch(handleError);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Track parent operation for</strong>
          &nbsp;
          <small>
            &quot;
            <strong>{counterpart}</strong>
            &nbsp;
            {headline}
            &quot;
          </small>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <dl>
          <dt>Operation:</dt>
          <dd>
            <div className="alert alert-secondary">
              <h4 className="float-md-right">{FormattedAmount}</h4>

              <h5>
                N°
                <strong>{operation_reference || '?'}</strong>
                &nbsp;of&nbsp;
                {humanDate}
              </h5>

              <p className="my-0 d-block">
                <strong>{counterpart}</strong>
                &nbsp;
                {operation_headline}
              </p>
            </div>
          </dd>

          <dt>Credit note:</dt>
          <dd>
            <SelectInput
              options={creditNotes}
              valueKey="_id"
              labelKey="label"
              onChange={handleInputChange}
              initialValue={parentCreditNote}
              inputProps={{
                isClearable: true
              }}
            />
          </dd>
        </dl>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="success" onClick={handleSubmit} disabled={cannotSubmit}>
          <Icon name="check" fw />
          &nbsp;
          { isLoading ? 'Loading...' : 'Track' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

TrackParentModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  operation: PropTypes.shape({}).isRequired,
  creditNotes: PropTypes.arrayOf(PropTypes.shape({})),
};

TrackParentModal.defaultProps = {
  show: false,
  creditNotes: [],
};

export default TrackParentModal;
