import PropTypes from 'prop-types';

export const slotShape = PropTypes.shape({
  date: PropTypes.string,
  time_zone_name: PropTypes.string,
});

export const momentShape = PropTypes.shape({
  duration: PropTypes.number,
  position: PropTypes.number,
  priority: PropTypes.number,
});

export const draftAllocationShape = PropTypes.shape({
  slot: slotShape,
  moment: momentShape,
  date: PropTypes.string,
  start_on: PropTypes.string,
  start_at: PropTypes.string,
  min_start_at: PropTypes.string,
  max_start_at: PropTypes.string,
  end_at: PropTypes.string,
  min_end_at: PropTypes.string,
  max_end_at: PropTypes.string,
});

export const calendarEventAttendeeShape = PropTypes.shape({
  id: PropTypes.string,
  display_name: PropTypes.string,
  email: PropTypes.string,
});

export const calendarEventShape = PropTypes.shape({
  id: PropTypes.string,
  account_id: PropTypes.string,
  account_name: PropTypes.string,
  account_provider: PropTypes.string,
  calendar_id: PropTypes.string,
  calendar_name: PropTypes.string,
  user_id: PropTypes.string,
  parent_id: PropTypes.string,
  status: PropTypes.string,
  // "visibility",
  // "provider",
  // "provider_identifier",
  // "provider_parent_identifier",
  // "provider_created_at",
  // "provider_updated_at",
  summary: PropTypes.string,
  description: PropTypes.string,
  start_on: PropTypes.string,
  start_at: PropTypes.string,
  end_on: PropTypes.string,
  end_at: PropTypes.string,
  html_link: PropTypes.string,
  // "i_cal_uid",
  location: PropTypes.string,
  // "reminders",
  // "recurrence",
  // "recurring",
  // "sequence",
  // "transparency",
  // "guests_can_modify",
  // "label",
  priority: PropTypes.number,
});

export const footprintStepShape = PropTypes.shape({
  id: PropTypes.string,
  description: PropTypes.string,
  duration: PropTypes.number,
  priority: PropTypes.number,
});

export const recipeShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
  steps: PropTypes.arrayOf(footprintStepShape),
});

export const fadShape = PropTypes.shape({
  start_on: PropTypes.string,
  end_on: PropTypes.string,
  draft_allocations: PropTypes.arrayOf(draftAllocationShape),
});

export default {
  calendarEventAttendeeShape,
  calendarEventShape,
  recipeShape,
  footprintStepShape,
  fadShape,
  draftAllocationShape,
};
