import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import { debounce } from 'lodash';

import Icon from '../Icon';
// import Spinner from '../Spinner';
import { firstAvailableSlot } from '../../api/smart_planner/available_days';
import { formatIsoDateTime } from '../../utils/datetime';
import { priorityMarks } from './utils';

const initialPriority = 0;

/// // Minutes from 30 to 480
/// const defaultDurations = (new Array(16)).fill(0).map((el, idx) => (((idx + 1.0) * 30.0)));

// Minutes from 30 to 240
// const defaultDurations = (new Array(8)).fill(0).map((el, idx) => (((idx + 1.0) * 30.0)));

// TODO: this should probable be user preferences.
const defaultDurations = [30, 60, 90, 120, 180, 240];

const makeFetchFas = (duration, priority = 0) => (
  firstAvailableSlot(duration, { priority })
    .then((response) => (response.json()))
    .then((response) => ({ ...response, duration }))
);

const FasCaption = ({ fas }) => {
  const { isLoading, start_at } = fas;

  if (isLoading) {
    return (
      <>
        <Icon name="spinner" family="fas" fw spin />
        <span>&nbsp;Loading slot...</span>
      </>
    );
  }

  return (
    <>
      <Icon name="calendar-alt" family="far" fw />
      <span>
        &nbsp;
        {formatIsoDateTime(start_at, { format: 'huge' })}
      </span>
    </>
  );
};
const FasRow = ({ fas }) => {
  const { duration, start_at } = fas;

  return (
    <tr className="row">
      <td className="col-2 pl-0 py-2">
        <span className="d-inline-block pl-5">
          <Icon name="clock" family="far" fw />
          <strong>&nbsp;{duration / 60.0} hours</strong>
        </span>
      </td>

      <td className="col py-2">
        <FasCaption fas={fas} />
      </td>
    </tr>
  );
};

const FasSummaryDatatable = ({ isLoading, fass }) => {
  const valuedFass = Object.values(fass).filter((fas) => (
    fas.slot !== null || fas.isLoading
  ));

  return (
    <table className="table mb-0">
      <tbody>
        {
          valuedFass.map((fas) => (
            <FasRow key={fas.duration} fas={fas} />
          ))
        }
      </tbody>
    </table>
  );
};

const FasSummary = ({ durations }) => {
  const initialState = durations.reduce((data, duration) => ({
    ...data,
    [duration]: {
      slot: null,
      duration,
      isLoading: false,
    },
  }), {});

  const [priority, setPriority] = useState(initialPriority);
  const [isLoading, setIsLoading] = useState(true);
  const [fass, setFass] = useState(initialState);

  const loadUserFas = async (fasPriority = 0) => {
    let willLoadNextFas = true;
    let currDuration;
    let currFas;
    let lastFas;

    for (let index = 0; index < durations.length; index += 1) {
      if (!willLoadNextFas) { break; }

      currDuration = durations[index];
      currFas = fass[currDuration];

      /* eslint-disable no-await-in-loop */
      /* eslint-disable no-loop-func */
      setFass((data) => ({
        ...data,
        [currDuration]: { ...currFas, isLoading: true },
      }));

      if (lastFas && lastFas.available_duration >= currDuration) {
        // avoid another request..
        setFass((data) => ({
          ...data,
          [currDuration]: { ...lastFas, duration: currDuration },
        }));
      } else {
        await makeFetchFas(currDuration, fasPriority).then((value) => {
          if (!value.slot) { willLoadNextFas = false; }

          currFas = { ...currFas, ...value, isLoading: false };
          lastFas = currFas;

          setFass((data) => ({ ...data, [currDuration]: currFas }));
        });
      }
    }
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) { loadUserFas(0); }

    return () => { mounted = false; };
  }, []);

  const debouncedLoadUserFas = useMemo(
    () => debounce(
      (currPriority) => (loadUserFas(currPriority)),
      500,
    ),
    [],
  );

  const handlePriorityChange = (value) => {
    setPriority(value);
    setFass(initialState);
    setIsLoading(true);
    debouncedLoadUserFas(value);
  };

  return (
    <div className="card">
      <div className="row">
        <div className="col-md-6">
          <h4 className="p-5">Your next available slots</h4>
        </div>

        <div className="col-md-6">
          <div className="p-5">
            <h6>
              Priority level: <strong>{priority}</strong>
            </h6>

            <Slider
              defaultValue={initialPriority}
              min={0}
              max={100}
              onChange={(value) => { handlePriorityChange(value); }}
              marks={priorityMarks}
            />
          </div>
        </div>
      </div>

      <FasSummaryDatatable
        isLoading={isLoading}
        fass={fass}
      />
    </div>
  );
};

FasSummary.propTypes = {
  durations: PropTypes.arrayOf(PropTypes.number),
};

FasSummary.defaultProps = {
  durations: defaultDurations,
};

export default FasSummary;
