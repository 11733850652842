import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';

import Icon from '../../Icon';
import { calendarEventShape } from '../proptypes';
import styles from './LabelsPicker.module.scss';

const LabelSelector = ({
  label,
  className,
  selected,
  onClick,
  onEdit,
  onDelete,
  user,
}) => {
  const { id, name, color } = label;
  const handleEdit = () => { onEdit(label); };
  const handleDelete = () => { onDelete(label); };

  const canEdit = (!label.system || user?.admin);
  const width = canEdit ? 244 : '100%';

  return (
    <div className={classnames(className, 'my-1')}>
      {
        canEdit && (
          <div className="float-right">
            <Button variant="light" onClick={handleEdit} size="sm">
              <Icon name="pencil-alt" fw />
            </Button>
            &nbsp;
            <Button variant="outline-danger" onClick={handleDelete} size="sm">
              <Icon name="trash" fw />
            </Button>
          </div>
        )
      }

      <Button
        title={name}
        style={{ backgroundColor: `${color}`, width, borderColor: 'transparent' }}
        className="text-left text-truncate"
        variant="dark"
        onClick={() => { onClick(label); }}
        size="sm"
      >
        {
          selected
            ? (<Icon name="check-square" family="far" fw />)
            : (<Icon name="square" family="far" fw />)
        }

        &nbsp;
        <strong style={{ textShadow: '1px 1px 0px #00000044, 0px 0px 3px #00000044'}}>
          {name}
        </strong>
      </Button>
    </div>
  );
};

LabelSelector.propTypes = {
  onClick: PropTypes.func,
  event: calendarEventShape,
  checked: PropTypes.bool,
};

LabelSelector.defaultProps = {
  onClick: () => {},
  event: {},
  checked: false
};

export default LabelSelector;
