import React from "react"
import Money from '../utils/money.js'
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-basic';
const PlotlyComponent = createPlotlyComponent(Plotly);

class LiquidityAccountChartPlotly extends React.Component {
  constructor(props) {
    super(props);
    this.state = { canvasWidth: 700 };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    // TODO: chart drew into hidden div are rendered small...
    // Find a better way to auto resize the chart when it appears
    this.intervalHandler = setInterval(this.redrawChart.bind(this), 150)
  }

  componentWillUnmount() {
    if (this.intervalHandler) {
      clearInterval(this.intervalHandler)
      this.intervalHandler = undefined
    }
  }

  redrawChart() {
    const { clientWidth } = this.wrapperRef.current;

    if (clientWidth !== this.state.canvasWidth) {
      this.setState({ canvasWidth: this.wrapperRef.current.clientWidth })
    }
  }

  computeData(liquidityData, originalBalance) {
    return liquidityData.map(data => {
      const dailyAmount = Money(data.daily_amount);
      const dailyBalance = Money(data.daily_balance);

      return {
        date: new Date(data["date"]),
        amount: dailyAmount.toUnit(),
        balance: dailyBalance.toUnit()
      }
    })
  }

  unpack(rows, key) {
    return rows.map((row) => {
      return row[key];
    });
  }

  render() {
    const { canvasWidth } = this.state
    let liquidityData = this.props.liquidityData;
    const rows = this.computeData(liquidityData);

    const dateValues = this.unpack(rows, 'date')

    let traceAmount = {
      name: 'Amount',
      type: "scatter",
      fill: 'tonexty',
      xaxis: 'x',
      yaxis: 'y',
      x: dateValues,
      y: this.unpack(rows, 'amount')
    }

    let traceBalance = {
      name: 'Balance',
      type: "scatter",
      fill: 'tonexty',
      xaxis: 'x',
      yaxis: 'y',
      x: dateValues,
      y: this.unpack(rows, 'balance')
    }

    let layout = {
      width: Number(canvasWidth),
      title: 'Liquidity on account',
      xaxis: {
        title: 'Date',
        type: 'date',
        tickmode: 'auto',
        'rangeslider': {
          visible: true,
          autorange: true
        },
        //range: (this.props.scopeYear ? [moment().startOf('year').format(), moment().format()] : []),
        rangeselector: {
          buttons: [
            {
              step: 'all',
              label: 'reset'
            },
            {
              step: 'year',
              stepmode: 'todate',
              count: 1,
              label: 'This Year'
            }
          ]
        }
      },

      yaxis: {
        overlaying: 'y',
        tickprefix: '€',
        tickformat: ',.2r'
      },

      legend: {
        orientation: 'h'
      },

      dragmode: 'zoom'
    };

    let config = {
      // showLink: false,
      // displayModeBar: true
    };

    return (
      <div ref={this.wrapperRef}>
        <PlotlyComponent className="whatever" data={[traceAmount, traceBalance]} layout={layout} config={config} />
      </div>
    );
  }

}

export default LiquidityAccountChartPlotly
