import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BaseInput from './BaseInput';

// Controlled input
const Input = ({ initialValue, onChange, ...props }) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <BaseInput
      {...props}
      value={value}
      onChange={handleChange}
    />
  );
};

Input.propTypes = {
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
};

Input.defaultProps = {
  initialValue: '',
  onChange: () => {},
};

export default Input;
