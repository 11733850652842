import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import Icon from '../../Icon';
import CompleteModal from './CompleteModal';
import { success } from '../../../utils/notifier';
import { visit } from '../../../utils/router';
import { eventShape } from '../utils';

const CompleteButton = ({ event, size, className }) => {
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const hideCompleteModal = () => { setShowCompleteModal(false); };
  const { acl, details_path, human_training_typology } = event;
  const { canComplete } = acl;

  if (!canComplete) { return ''; }

  const handleCompleteButtonClick = () => {
    setShowCompleteModal(true);
  };

  const handleCompleteCompleteation = ({ response }) => {
    visit(details_path).then(() => { success({ message: response.message }); });
  };

  return (
    <div className="cancel-button d-inline-block">
      <CompleteModal
        show={showCompleteModal}
        event={event}
        onHide={hideCompleteModal}
        onComplete={handleCompleteCompleteation}
      />

      <Button
        size={size}
        variant="success"
        title={`Complete ${human_training_typology}`}
        onClick={handleCompleteButtonClick}
        className={className}
      >
        <Icon name="check" fw />
        &nbsp;
        Complete
      </Button>
    </div>
  );
};

CompleteButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  event: eventShape.isRequired,
};

CompleteButton.defaultProps = {
  size: 'md',
  className: '',
};

export default CompleteButton;
