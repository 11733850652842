export const isPresent = (text) => (
  text && text.toString().length > 0
);

export const isBlank = (text) => (!isPresent(text));

export default {
  isPresent,
  isBlank,
};
