import React from 'react';
import classnames from 'classnames';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const BADGE_THEMES = {
  error: 'badge-danger',
  warning: 'badge-warning',
  draft: 'badge-secondary',
  unconfirmed: 'badge-warning',
  confirmed: 'badge-success',
  sold_out: 'badge-success',
  retired: 'badge-warning',
  canceled: 'badge-danger',
  rejected: 'badge-danger',
  running: 'badge-info',
  completed: 'badge-primary',
  past: 'badge-dark',
  opened: 'badge-primary',
  closed: 'badge-dark',
  undefined: 'badge-light',
};

const badgeTheme = (status) => (
  BADGE_THEMES[status] || 'badge-secondary'
);

const EventStatusBadge = ({ status, title, className }) => (
  <OverlayTrigger
    placement="top"
    overlay={(
      <Tooltip>
        {title || status}
      </Tooltip>
    )}
  >
    <span
      title={title}
      className={
        classnames('badge text-uppercase', badgeTheme(status), className)
      }
    >
      {status.toString()}
    </span>
  </OverlayTrigger>
);

export default EventStatusBadge;
