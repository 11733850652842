import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';

// import Entity from './Entity';
import StepsList from './StepsList';
import EmptyState from './EmptyState';
import AddButton from './AddButton';
import Toolbar from './Toolbar';
import AddMomentStepModal from './AddMomentStepModal';
import EditMomentStepModal from './EditMomentStepModal';
import AddOffsetStepModal from './AddOffsetStepModal';
import EditOffsetStepModal from './EditOffsetStepModal';
import uuid from '../../../utils/uuid';
import {
  insertElement,
  updateElementById,
  removeElementById,
} from './arrayUtils';
import { footprintStepShape } from '../proptypes';
import { findOffsetOption, deserializeSteps } from '../utils';

import styles from './styles.module.scss';

const withPosition = (data) => (data.map((datum, position) => ({ ...datum, position })));
const isArrayEqual = function(x, y) {
  return _(x).xorWith(y, _.isEqual).isEmpty();
};

const serializeStep = ({ offset_type, step_type, ...step }) => {
  return {
    ...step,
    step_type,
    offset_type: step_type === 'offset' ? offset_type : null,
  };
}

const serializeSteps = (steps) => (steps.map(serializeStep));

const Footprint = ({
  onChange,
  height,
  className,
  initialSteps,
}) => {
  const deserializedInitialSteps = deserializeSteps(initialSteps || []);
  const [entities, setEntities] = useState(deserializedInitialSteps);
  const updateEntities = (newEntities) => {
    const serializedNewEntities = serializeSteps(newEntities)
    setEntities(serializedNewEntities);
    onChange(withPosition(serializedNewEntities));
  };

  // Required by the search opportunity UI
  useEffect(() => {
    if (!isArrayEqual(entities, deserializedInitialSteps)) {
      updateEntities(deserializedInitialSteps);
    }
  }, [initialSteps]);

  // New
  const [newStepPosition, setNewStepPosition] = useState(0);
  const [showNewMomentStepModal, setShowNewMomentStepModal] = useState(false);
  const hideNewMomentStepModal = () => { setShowNewMomentStepModal(false); };
  const [showNewOffsetStepModal, setShowNewOffsetStepModal] = useState(false);
  const hideNewOffsetStepModal = () => { setShowNewOffsetStepModal(false); };

  // Edit
  const [selectedStepId, setSelectedStepId] = useState(null);
  const [showEditMomentStepModal, setShowEditMomentStepModal] = useState(false);
  const hideEditMomentStepModal = () => { setShowEditMomentStepModal(false); };
  const [showEditOffsetStepModal, setShowEditOffsetStepModal] = useState(false);
  const hideEditOffsetStepModal = () => { setShowEditOffsetStepModal(false); };

  const selectedStep = entities.find(({ id }) => (id === selectedStepId));

  // Handlers

  const handleStepClick = ({ step, step_type }) => {
    const { id } = step;

    setSelectedStepId(id);

    switch (step_type) {
      case 'moment':
        setShowEditOffsetStepModal(false);
        setShowEditMomentStepModal(true);
        break;
      case 'offset':
        setShowEditMomentStepModal(false);
        setShowEditOffsetStepModal(true);
        break;
      default:
        console.error(`Unhandled step type ${step_type}.`);
    }
  };

  const handleStepDeleteClick = (step) => {
    const { id } = step;
    setSelectedStepId(id);

    // Add confirm modal
    const newEntities = removeElementById(id, entities);

    updateEntities(newEntities);
  };

  const handleAddButtonClick = ({ position, step_type }) => {
    setNewStepPosition(position);

    switch (step_type) {
      case 'moment':
        setShowNewOffsetStepModal(false);
        setShowNewMomentStepModal(true);
        break;
      case 'offset':
        setShowNewMomentStepModal(false);
        setShowNewOffsetStepModal(true);
        break;
      default:
        console.error(`Unhandled step type ${step_type}.`);
    }
  };

  const hideModals = () => {
    hideNewMomentStepModal();
    hideNewOffsetStepModal();
    hideEditMomentStepModal();
    hideEditOffsetStepModal();
  };

  const handleCreateStep = (params) => {
    const position = newStepPosition;
    const newEntity = {
      ...params,
      id: uuid.generate(),
    };

    const newEntities = insertElement(position, newEntity, entities);
    updateEntities(newEntities);
    hideModals();
  };

  const handleUpdateStep = (params) => {
    const { id } = params;
    const newEntities = updateElementById(id, params, entities);

    updateEntities(newEntities);
    hideModals();
  };

  return (
    <div
      className={classnames(styles.Footprint, className)}
    >
      <div
        className={classnames(styles.Footprint__content)}
        style={{ height }}
      >
        <Scrollbars>
          <div className="flex-column d-flex justify-content-center">
            {
              entities.length === 0 && (
                <EmptyState onClick={handleAddButtonClick} />
              )
            }

            <StepsList
              steps={entities}
              onClick={handleStepClick}
              onDelete={handleStepDeleteClick}
              onAdd={handleAddButtonClick}
            />
          </div>
        </Scrollbars>
      </div>

      <AddMomentStepModal
        show={showNewMomentStepModal}
        onHide={hideNewMomentStepModal}
        onCreate={handleCreateStep}
      />

      <EditMomentStepModal
        show={showEditMomentStepModal}
        onHide={hideEditMomentStepModal}
        onUpdate={handleUpdateStep}
        step={selectedStep}
      />

      <AddOffsetStepModal
        show={showNewOffsetStepModal}
        onHide={hideNewOffsetStepModal}
        onCreate={handleCreateStep}
      />

      <EditOffsetStepModal
        show={showEditOffsetStepModal}
        onHide={hideEditOffsetStepModal}
        onUpdate={handleUpdateStep}
        step={selectedStep}
      />

      <Toolbar />
    </div>
  );
};

Footprint.propTypes = {
  height: PropTypes.number,
  onChange: PropTypes.func,
  className: PropTypes.string,
  initialSteps: PropTypes.arrayOf(footprintStepShape),
};

Footprint.defaultProps = {
  height: 350,
  onChange: () => {},
  className: null,
  initialSteps: [],
};

export default Footprint;
