import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { DateTime } from 'luxon';

import Icon from '../../Icon';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';
import { cancelPlannedEvent } from '../../../api/trainings/planned_events';
import { eventShape } from '../utils';

const CancelModal = ({
  show,
  onHide,
  onConfirm,
  event,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState('');
  const handleNotesUpdate = (e) => {
    const input = e.currentTarget;
    const { value } = input;

    setNotes(value);
  };

  const plannedEventId = event.id;
  const handleConfirm = () => {
    if (isLoading) { return; }
    setIsLoading(true);

    cancelPlannedEvent({ plannedEventId, notes })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        success({ message: data.message });
        onHide();
        onConfirm({ plannedEventId, response: data });
      })
      .catch(handleError);
  };

  const {
    headline,
    starts_on,
    human_training_typology,
  } = event;
  const startsOn = DateTime.fromISO(starts_on);
  const humanDate = startsOn.toFormat('dd LLL yyyy');

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong className="text-danger">
            Delete
            &nbsp;
            {human_training_typology}
          </strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h4 className="mb-3">
          Do you really want to cancel the
          &nbsp;
          {human_training_typology}
          &nbsp;
          <strong>
            &quot;
            {headline}
            &quot;
          </strong>
          &nbsp;
          on
          &nbsp;
          {humanDate}
          ?
        </h4>

        <Form>
          <Form.Group controlId="cancelForm.notes">
            <Form.Label>Notes about the cancellation:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder={`The ${human_training_typology} was canceled because ...`}
              value={notes}
              onChange={handleNotesUpdate}
            />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="danger" onClick={handleConfirm} disabled={isLoading}>
          <Icon name="trash" fw />
          &nbsp;
          { isLoading ? 'Loading...' : 'Delete' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CancelModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  event: eventShape.isRequired,
  onConfirm: PropTypes.func,
};

CancelModal.defaultProps = {
  show: false,
  onConfirm: () => {},
};

export default CancelModal;
