import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { DateTime } from 'luxon';

import Icon from '../../Icon';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';
import { openPlannedEventRegistrations } from '../../../api/trainings/planned_events';
import { eventShape } from '../utils';

const OpenRegistrationsModal = ({
  show,
  onHide,
  onConfirm,
  event,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const plannedEventId = event.id;
  const handleConfirm = () => {
    if (isLoading) { return; }
    setIsLoading(true);

    openPlannedEventRegistrations({ plannedEventId })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        success({ message: data.message });
        onHide();
        onConfirm({ plannedEventId, response: data });
      })
      .catch(handleError);
  };

  const { headline, starts_on, human_training_typology } = event;
  const startsOn = DateTime.fromISO(starts_on);
  const humanDate = startsOn.toFormat('dd LLL yyyy');

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong className="text-primary">Open {human_training_typology} sales registrations</strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h4 className="mb-3">
          Do you really want to open the {human_training_typology} registrations for
          &nbsp;
          <strong>
            &quot;
            {headline}
            &quot;
          </strong>
          &nbsp;
          on
          &nbsp;
          {humanDate}
          ?
        </h4>
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={handleConfirm} disabled={isLoading}>
          <Icon name="cash-register" fw />
          &nbsp;
          { isLoading ? 'Loading...' : 'Open registrations' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

OpenRegistrationsModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  event: eventShape.isRequired,
  onConfirm: PropTypes.func,
};

OpenRegistrationsModal.defaultProps = {
  show: false,
  onConfirm: () => {},
};

export default OpenRegistrationsModal;
