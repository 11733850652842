import { patch } from '../base';

const basePath = () => (
  '/smart_planner/working_hours'
);

export const setWorkingHours = ({ weeklyWorkingTimes }) => (
  patch(`${basePath()}.json`, {
    working_hours: {
      weekly_working_times: weeklyWorkingTimes,
    },
  })
);

export default {
  setWorkingHours,
};
