import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { addDays, subDays } from 'date-fns';

import {
  searchCalendarEvents,
} from '../../../api/smart_planner/calendar_events';
import {
  userConstraintsOccupations,
} from '../../../api/smart_planner/daily_occupations';
import handleError from '../../../utils/error-handler';
import { fadShape } from '../proptypes';

// initialView
// The initial view when the calendar loads.
// String. the default value is 'dayGridMonth'
// A name of any of the available views, such as 'dayGridWeek', 'timeGridDay', 'listWeek' .

const formattedTitle = ({ title, priority }) => (`${title} [Priority: ${priority}]`);

const CalendarScheduler = ({ fad }) => {
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [constraints, setConstraints] = useState([]);
  const { start_on, min_start_at, max_end_at, draft_allocations } = fad;
  const originDate = new Date(start_on);
  const fadEvents = draft_allocations.reduce((data, allocation, idx) => {
    const { min_start_at, min_end_at, max_end_at, moment } = allocation;
    const { priority, description } = moment;
    const event = {
      title: formattedTitle({ title: description, priority }),
      start: new Date(min_start_at),
      end:   new Date(min_end_at),
      constraint: `group-${idx}`, // defined below
      color: '#257e4a'
    };

    const group = {
      title: `${moment.description} available slot`,
      groupId: `group-${idx}`,
      start: new Date(min_start_at),
      end:   new Date(max_end_at),
      display: 'background'
    };

    data.push(event);
    data.push(group);

    return data;
  }, []);

  useEffect(() => {
    const from = new Date();
    const to = addDays(new Date(max_end_at), 7);

    searchCalendarEvents({ from, to })
      .then((response) => (response.json()))
      .then(({ data }) => {
        const events = data.map(({ attributes }) => (attributes));
        setCalendarEvents(events);
      })
      .catch(handleError);

    userConstraintsOccupations({ from, to })
      .then((response) => (response.json()))
      .then(({ data }) => {
        const occupations = data.map(({ attributes }) => (attributes));
        setConstraints(occupations);
      })
      .catch(handleError);
  }, [fad]);

  const serializedEvents = calendarEvents.map(({ summary, start_at, end_at, start_on, end_on, priority }) => ({
    title: formattedTitle({ title: summary, priority }),
    start: new Date(start_at ? start_at : `${start_on}T00:00`),
    end: new Date(end_at ? end_at : `${end_on}T00:00`),
    selectable: false,
    editable: false,
    // display: 'background'
  }));

  const serializedConstraints = constraints.map(({ start_at, end_at, priority }) => ({
    title: formattedTitle({ title: 'Constraint', priority }),
    start: new Date(start_at),
    end: new Date(end_at),
    selectable: false,
    editable: false,
    display: 'background',
    color: '#000000'
  }));

  const events = [
    ...fadEvents,
    ...serializedEvents,
    ...serializedConstraints,
  ];

  return (
    <div>
      <FullCalendar
        plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
        initialView="timeGridWeek"
        events={events}
        initialDate={originDate}
        selectable
        editable
      />
    </div>
  );
};

CalendarScheduler.propTypes = {
  fad: fadShape.isRequired,
}

export default CalendarScheduler;
