// DEPRECATED
import { post } from '../base';

export const classifiesOperation = ({
  operationClassificationId,
  businessLineId,
}) => (
  post('/financial_analysis/operations_classifications.json', {
    operations_classification: {
      operation_classification_id: operationClassificationId,
      business_line_id: businessLineId,
    },
  })
);

export default {
  classifiesOperation,
};
