import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import InlineCategorization from '../financial_analysis/classification/InlineCategorization';
import styles from './BulkToolbar.module.scss';

const BulkToolbar = ({
  selectedItems,
  categories,
  onCategoryLabelChange,
  onCategoryLabelChanged,
}) => {
  if (selectedItems.length === 0) { return null; }

  const ids = selectedItems.map((item) => (item.operation_id));

  return (
    <div className={classnames('row')}>
      <div className={classnames('col-sm-12', 'text-center')}>
        {
          categories.map((category) => (
            <div
              key={category.id}
              className={classnames(styles.InlineCategorizationWrapper)}
            >
              <div className={classnames(styles.CategoryLabel)}>
                <strong>{category.label}</strong>
              </div>

              <InlineCategorization
                operationId={ids}
                category={category}
                label={null}
                className="h5"
                onChanging={onCategoryLabelChange}
                onChange={onCategoryLabelChanged}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
};

BulkToolbar.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCategoryLabelChange: PropTypes.func.isRequired,
  onCategoryLabelChanged: PropTypes.func.isRequired,
};

BulkToolbar.defaultProps = {
};

export default BulkToolbar;
