import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import EventBus from '../../../packs/event_bus';
import { trainerPrivateClasses } from '../../../api/trainers/trainers';
import sleep from '../../../utils/sleep';
import handleError from '../../../utils/error-handler';

const decoratePrivateClass = (privateClass, attributes = {}) => ({
  ...privateClass,
  ...attributes,
});
const decoratePrivateClasses = (privateClasses, attributes = {}) => (
  privateClasses.map((ca) => (decoratePrivateClass(ca, attributes)))
);

const WithTrainerPrivateClasses = (WrappedComponent) => {
  class TrainerPrivateClassesHOC extends React.Component {
    constructor(props) {
      super(props);

      this.debouncedLoadTrainerPrivateClasses = debounce(
        this.loadTrainerPrivateClasses,
        500,
        { leading: true },
      );

      this.state = {
        privateClasses: [],
        loading: true,
      };
    }

    componentDidMount() {
      const events = [
        'Trainings::PrivateClasses::TrainersSet',
      ];

      this.privateClassesEventsSubscriptionId = EventBus.subscribe(
        events,
        this.handleTrainerPrivateClassesEventsSubscription,
        this,
      );

      this.loadTrainerPrivateClasses();
    }

    componentWillUnmount() {
      EventBus.unsubscribe(this.privateClassesEventsSubscriptionId);
    }

    handleTrainerPrivateClassesEventsSubscription() {
      this.debouncedLoadTrainerPrivateClasses();
    }

    // handleOnDelete({ id }) {
    //   const { privateClasses, certification } = this.state;
    //   const privateClassId = id;
    //   const certificationId = certification.id;
    //   const filteredTrainerPrivateClasses = privateClasses.filter((ca) => (ca.id !== id));

    //   return deletePrivateClass({ certificationId, privateClassId })
    //     .then((response) => response.json())
    //     .then((response) => {
    //       success({ message: response.message });
    //       this.setTrainerPrivateClasses(filteredTrainerPrivateClasses);
    //     })
    //     .catch(handleError);
    // }

    // handleOnUpdate({ privateClass }) {
    //   const { privateClasses } = this.state;
    //   const { id } = privateClass;
    //   const updatedTrainerPrivateClasses = privateClasses.map((ca) => (
    //     ca.id === id ? privateClass : ca
    //   ));

    //   this.setTrainerPrivateClasses(updatedTrainerPrivateClasses);
    // }

    setTrainerPrivateClasses(plainPrivateClasses, attributes = {}) {
      const privateClasses = decoratePrivateClasses(
        plainPrivateClasses,
        attributes,
      );

      this.setState({
        loading: false,
        privateClasses,
      });
    }

    loadTrainerPrivateClasses(wait = 0) {
      const { trainer } = this.props;
      const { id } = trainer;

      return sleep(wait).then(() => {
        trainerPrivateClasses({ trainerId: id })
          .then((response) => (response.json()))
          .then(({ data }) => {
            this.setTrainerPrivateClasses(data.map(({ attributes }) => (attributes)));
          })
          .catch(handleError);
      });
    }

    render() {
      const { trainer } = this.props;
      const { privateClasses, loading } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          loading={loading}
          trainer={trainer}
          privateClasses={privateClasses}
        />
      );
    }
  }

  TrainerPrivateClassesHOC.propTypes = {
    trainer: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  return TrainerPrivateClassesHOC;
};

export default WithTrainerPrivateClasses;
