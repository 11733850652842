import { formatISO } from 'date-fns';
import { get, patch } from '../base';

const basePath = () => (
  [
    '/smart_planner/calendar_events',
  ].join('/')
);

export const searchCalendarEvents = ({ from, to }) => (
  get(`${basePath()}/search.json?from=${formatISO(from)}&to=${formatISO(to)}`)
);

export const updatePriority = (calendarEventId, { priority, mode }) => (
  patch(`${basePath()}/${calendarEventId}/update_priority.json`, {
    calendar_events: { priority, mode },
  })
);

export const fetchCalendarEvent = (calendarEventId) => (
  get(`${basePath()}/${calendarEventId}.json`)
);

export default {
  searchCalendarEvents,
  updatePriority,
  fetchCalendarEvent,
};
