import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import Icon from '../../Icon';
import OpenRegistrationsModal from './OpenRegistrationsModal';
import { success } from '../../../utils/notifier';
import { reload } from '../../../utils/router';
import { eventShape } from '../utils';

const OpenRegistrationsButton = ({ event, size, className }) => {
  const [showOpenRegistrationsModal, setShowOpenRegistrationsModal] = useState(false);
  const hideOpenRegistrationsModal = () => { setShowOpenRegistrationsModal(false); };
  const { acl } = event;
  const { canOpenRegistrations } = acl;

  if (!canOpenRegistrations) { return ''; }

  const handleOpenRegistrationsButtonClick = () => {
    setShowOpenRegistrationsModal(true);
  };

  const handleOpenRegistrationsConfirmation = ({ response }) => {
    reload().then(() => { success({ message: response.message }); });
  };

  return (
    <div className="cancel-button d-inline-block">
      <OpenRegistrationsModal
        show={showOpenRegistrationsModal}
        event={event}
        onHide={hideOpenRegistrationsModal}
        onConfirm={handleOpenRegistrationsConfirmation}
      />

      <Button
        size={size}
        variant="primary"
        title="Open sales registrations"
        onClick={handleOpenRegistrationsButtonClick}
        className={className}
      >
        <Icon name="cash-register" fw />
        &nbsp;
        Open registrations
      </Button>
    </div>
  );
};

OpenRegistrationsButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  event: eventShape.isRequired,
};

OpenRegistrationsButton.defaultProps = {
  size: 'md',
  className: '',
};

export default OpenRegistrationsButton;
