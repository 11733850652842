import React, {
  useState, createContext, useMemo, useEffect,
} from 'react';
// import PropTypes from 'prop-types';
// import { debounce } from 'lodash';
import handleError from '../../../utils/error-handler';
// import { success } from '../../../utils/notifier';
// import EventBus from '../../../packs/event_bus';

import {
  searchMultipleAvailableSlots,
} from '../../../api/smart_planner/multiple_available_slots';

const Context = createContext({});

export const Provider = ({ children, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [slots, setSlots] = useState(null);
  const hasResults = slots !== null;
  const onSearch = (params) => {
    setIsLoading(true);
    setSlots(null);

    searchMultipleAvailableSlots(params)
      .then((response) => (response.json()))
      .then(({ data }) => {
        setSlots(data);
        setIsLoading(false);
      })
      .catch(handleError);
  };

  // The shared state
  const value = useMemo(
    () => ({
      isLoading,
      setIsLoading,
      hasResults,
      onSearch,
      slots,
    }),
    [isLoading],
  );

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  // serializedAccount: PropTypes.shape({
  //   data: PropTypes.shape({
  //     attributes: accountShape,
  //   }),
  // }).isRequired,
};

Provider.defaultProps = {};

export default Context;
