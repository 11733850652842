import React from 'react';
import PropTypes from 'prop-types';

/* Stateless Components */
const TimeResults = ({ results }) => {
  // console.log({ results })

  if (results.length === 0) {
    return null;
  }

  return (
    <div className="results">
      <h6>Selected Time</h6>

      {
        results.map((segment, n) => (
          segment.length
            ? <p key={n}>{segment[0].format('H:mm')} - {segment[1].format('H:mm')}</p>
            : null
        ))
      }
    </div>
  );
};

TimeResults.propTypes = {
  // results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default TimeResults;
