import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';

import TicketPriceUpdateModal from './TicketPriceUpdateModal';
import { formatRubyMoney as fm } from '../../../utils/money';
import { formatIsoDateTime as fdt } from '../../../utils/datetime';
import Icon from '../../Icon';

const ActionsCell = ({ item, showActions, onUpdate }) => {
  const [showPriceModal, setShowPriceModal] = useState(false);
  if (!showActions) { return null; }

  return (
    <td className="text-center">
      <TicketPriceUpdateModal
        show={showPriceModal}
        onHide={() => { setShowPriceModal(false); }}
        onUpdate={onUpdate}
        ticket={item}
      />

      <Button size="sm" variant="warning" onClick={() => { setShowPriceModal(true); }}>
        <Icon name="dollar-sign" fw />
      </Button>
    </td>
  );
};

const TicketRow = ({ item, showActions }) => {
  const {
    attendee_first_name,
    attendee_last_name,
    attendee_email,
    ticket_class_name,
    gross_price,
    purchased_at,
    cancelled,
  } = item;

  const humanPurchasedAt = fdt(purchased_at);

  return (
    <tr className={classnames({ 'table-danger': cancelled })}>
      <td>{attendee_first_name}</td>
      <td>{attendee_last_name}</td>
      <td>{attendee_email}</td>
      <td>{ticket_class_name}</td>
      <td>{fm(gross_price)}</td>
      <td>{humanPurchasedAt}</td>

      <td>
        <span className="label">{ cancelled ? 'CANCELLED' : 'PLACED' }</span>
      </td>

      <ActionsCell
        item={item}
        showActions={showActions}
      />
    </tr>
  );
};

TicketRow.propTypes = {
  showActions: PropTypes.bool,
  item: PropTypes.shape({
    attendee_email: PropTypes.string,
    attendee_first_name: PropTypes.string,
    attendee_last_name: PropTypes.string,
    purchased_at: PropTypes.string,
    ticket_class_name: PropTypes.string,
    gross_price: PropTypes.shape({}),
    cancelled: PropTypes.bool,
  }).isRequired,
};

TicketRow.defaultProps = {
  showActions: false,
};

export default TicketRow;
