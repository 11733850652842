import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import { Provider } from './Context';
import WeeklyScheduleForm from './WeeklyScheduleForm';
// import Datatable from './Datatable';

const SettingsPage = (props) => (
  <Provider {...props}>
    <div className="SettingsPage">
      <Tabs defaultActiveKey="WeeklySchedule" id="smart-planner-settings-page" className="">
        <Tab eventKey="WeeklySchedule" title="Working hours">
          <WeeklyScheduleForm />
        </Tab>

        <Tab eventKey="Other" title="Useless tab">
          <p>Maybe useless tab ...</p>
        </Tab>
      </Tabs>
    </div>
  </Provider>
);

SettingsPage.propTypes = {
};

export default SettingsPage;
