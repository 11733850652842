import React from 'react';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';

import Money from '../../../utils/money';
import Icon from '../../Icon';

import RowWrapper from '../../ListingTable/Row';
import PriceValue from '../../expectations/PriceValue';
import SummaryCaption from './SummaryCaption';

const ReconciliationRow = ({ onCancel, ...props }) => {
  const { item } = props;

  const {
    unreconciledExpectations,
    plannedExpectations,
    newExpectations,
  } = item;

  const handleCancel = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onCancel(item);
  };

  return (
    <RowWrapper
      {...props}
    >
      <td className="">
        <Button className="float-right" variant="outline-danger" onClick={handleCancel} size="sm">
          <Icon name="trash" />
        </Button>

        <SummaryCaption
          unreconciledExpectations={unreconciledExpectations}
          plannedExpectations={plannedExpectations}
          newExpectations={newExpectations}
          compact
        />
      </td>
    </RowWrapper>
  );
};

export default ReconciliationRow;
