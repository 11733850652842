import React from 'react';
// import PropTypes from 'prop-types';

import { Provider } from './Context';
import Datatable from './Datatable';

const UserAccountsPage = (props) => (
  <Provider {...props}>
    <div className="UserAccountsPage">
      <Datatable />
    </div>
  </Provider>
);

UserAccountsPage.propTypes = {
};

export default UserAccountsPage;
