import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { DateTime } from 'luxon';

import { eventShape } from '../../utils';
import PriceInput from '../../../inputs/PriceInput';
import Icon from '../../../Icon';
import handleError from '../../../../utils/error-handler';
import { success } from '../../../../utils/notifier';
import { updateExpectedAverageTickePrice } from '../../../../api/trainings/planned_events_economics';
import { ticketShape } from '../../utils';
import Money from '../../../../utils/money';
import { reload } from '../../../../utils/router';

const ExpectedAverageTicketPriceUpdateModal = ({
  show,
  onHide,
  onUpdate,
  event,
}) => {
  const {
    expected_average_net_price,
    expected_average_gross_price,
  } = event;
  const netPrice = Money(expected_average_net_price || {});
  const grossPrice = Money(expected_average_gross_price || {});

  const initialPriceValues = {
    net: netPrice.toUnit(),
    gross: grossPrice.toUnit(),
    withholdingTax: 0,
    otherWithholdingTax: 0,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [priceValues, setPriceValues] = useState(initialPriceValues);

  const handlePriceChange = (values) => {
    setPriceValues(values);
  };

  const plannedEventId = event.id;
  const handleUpdate = () => {
    if (isLoading) { return; }
    setIsLoading(true);

    const { net, vat, gross } = priceValues;

    updateExpectedAverageTickePrice({ plannedEventId, net, gross, vat })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        onHide();
        onUpdate({ plannedEventId, response: data });
      })
      .catch(handleError);
  };

  const { headline, starts_on, human_training_typology } = event;
  const startsOn = DateTime.fromISO(starts_on);
  const humanDate = startsOn.toFormat('dd LLL yyyy');

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Set expected average ticket price</strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ zIndex: 301 }}>
        <h4 className="mb-3">
          Set the expected averate ticket price for the
          &nbsp;
          {human_training_typology}
          &nbsp;
          <strong>
            &quot;
            {headline}
            &quot;
          </strong>
          &nbsp;
          on
          &nbsp;
          {humanDate}
        </h4>

        <PriceInput
          layout={false}
          showWithholdingFields={false}
          net={netPrice.toUnit()}
          netOptions={{
            label: 'Averate ticket price'
          }}
          vat={0}
          vatOptions={{
            readOnly: true,
            column: 'd-none'
          }}
          gross={grossPrice.toUnit()}
          grossOptions={{
            auto:     true,
            readOnly: true,
            column:   'd-none'
          }}
          onChange={handlePriceChange}
        />
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={handleUpdate} disabled={isLoading}>
          <Icon name="check" fw />
          { isLoading ? 'Loading...' : 'Update' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ExpectedAverageTicketPriceUpdateModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  event: eventShape.isRequired,
};

ExpectedAverageTicketPriceUpdateModal.defaultProps = {
  show: false,
  onUpdate: () => {},
};

export default ExpectedAverageTicketPriceUpdateModal;

