import React, { useState } from 'react';
import { Chart } from 'react-google-charts';
import { startOfDay, differenceInDays, addDays, parse, parseISO, isEqual, isPast, isBefore, addMinutes, subMinutes, formatISO } from 'date-fns';
// import { sortBy } from 'lodash';

import CustomLegend from '../../../charts/CustomLegend';


const columns = [
  { type: 'date',   label: 'Date',                            },  //  0

  { type: 'number', label: 'Revenues',       },  //  1
  { type: 'number', label: 'Gross earnings', },  //  2
  { type: 'number', label: 'Trainer fees',   },  //  3
  { type: 'number', label: 'Avanscoperta\'s earnings',    },  //  4

  { type: 'number', label: 'Costs',             },  //  5
  { type: 'number', label: 'Expected costs',    },  //  6
  { type: 'number', label: 'Tickets',           },  //  7
  { type: 'number', label: 'Expected Tickets',  },  //  8

  { type: 'number', lable: 'Past',           },
  { type: 'number', lable: 'Past',           },
];


const series = [
  { color: '#027bff', type: 'line'},
  { color: '#17a2b8', type: 'line'},
  { color: '#ffc107', type: 'line', lineWidth: 5},
  { color: '#29a745', type: 'line', lineWidth: 5},

  { color: 'red'},
  { color: '#F96F6F'},
  { color: 'blue'},
  { color: '6F6FF9'},

  { color: '#ccc', type: 'steppedArea'},
  { color: '#ccc', type: 'steppedArea'},
]


const RevenuesChart = ({ salesForecast }) => {
  const [legendStates, setLegendStates] = useState(new Array(columns.length - 1).fill(true));

  const activeSeries = series.reduce((newSeries, serie, index) => {
    const isSerieActive = legendStates[index];

    if (isSerieActive) {
      newSeries.push(serie); 
    }

    return newSeries;
  }, []);

  const handleLegendClick = (index) => {
    setLegendStates((items) => {
      const newItems = [...items];
      newItems[index] = !newItems[index];

      return newItems;
    });
  };

  const legendEntries = columns.slice(1, 5).map(({ label }, index) => ({
    label,
    key: index,
    active: legendStates[index],
    color: series[index].color,
  }));

  const activeColumns = columns.reduce((newColumns, column, index) => {
    if (index === 0) { 
      // Always return the date columns
      newColumns.push(column); 

      return newColumns;
    }

    const isColumnActive = legendStates[index - 1];

    if (isColumnActive) {
      newColumns.push(column); 
    }

    return newColumns;
  }, []);

  // const todayMaxValue =  10000
  // const todayMinValue = -0

  const todayMaxValue =  (salesForecast?.forecast.revenues.revenues.at(-1).amount.cents / 100) * 1.2
  const todayMinValue = Math.min(0, (salesForecast?.forecast.revenues.net_earnings.at(0)?.amount.cents / 100 || 0) * 1.2)
  const utcStartOfDayDate = (date) => new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0))

  const fromDate = utcStartOfDayDate(parseISO(salesForecast?.assumptions.sales_start_on))
  const toDate   = utcStartOfDayDate(parseISO(salesForecast?.assumptions.sales_end_on))
  // const numberOfDays = differenceInDays(toDate, fromDate)
  const numberOfDays = salesForecast?.forecast.revenues.revenues.length
  console.log("NUMBER OF DAYS", numberOfDays, salesForecast?.assumptions.sales_start_on, salesForecast?.assumptions.sales_end_on, fromDate, toDate)

  const valuesAtDay = (date, values) => {
    const dateString = utcStartOfDayDate(date).toISOString()
    const result = values.filter(v => v.date == dateString).map(v => v.amount.cents).reduce((a, b) => a + b, 0)

    return result  / 100
  }

  const dailyDynamicData = [...Array(numberOfDays).keys()].map(d => {
    const date = addDays(fromDate, d)

    return [
      date,

      salesForecast?.forecast.revenues.revenues[d]?.amount.cents / 100,
      salesForecast?.forecast.revenues.gross_earnings[d]?.amount.cents / 100,
      salesForecast?.forecast.revenues.trainer_fees[d]?.amount.cents / 100,
      salesForecast?.forecast.revenues.net_earnings[d]?.amount.cents / 100,

      - valuesAtDay(date, salesForecast?.forecast.revenues.costs),            //  registered costs
      - valuesAtDay(date, salesForecast?.forecast.revenues.costs_forecast),   //  expected costs
      valuesAtDay(date, salesForecast?.forecast.revenues.tickets),            //  ticket sold revenues
      valuesAtDay(date, salesForecast?.forecast.revenues.tickets_forecast),   //  expected tickets revenues

      (isPast(date)) ? todayMaxValue: 0,  //  today - positive
      (isPast(date)) ? todayMinValue: 0,  //  today - negative
    ]
  })

  console.log("DAYLY DYNAMIC DATA", dailyDynamicData)
  const activeRows = dailyDynamicData.map((row) => {
    return row.reduce((newRow, value, index) => {
      if (index === 0) { 
        // Always return the date columns
        newRow.push(value); 

        return newRow;
      } 

      const isValueActive = legendStates[index - 1];

      if (isValueActive) {
        newRow.push(value); 
      }

      return newRow;
    }, []);
  });
 
  const options = {
    legend: 'none',
    vAxis: {
      // title: 'Economics',
      style: 'currency',
      format: '\u20AC#',
    },
    isStacked: false,
    series: activeSeries,
    backgroundColor: '#f8f9fa',
    seriesType: 'bars',
    chartArea: {
      width: '80%',
      height: '80%',
    },
  };

  return (
    <div className="RevenuesChart">
      <Chart
        chartType="ComboChart"
        width='100%'
        height='600px'
        rows={activeRows}
        columns={activeColumns}
        options={options}
      />

      <CustomLegend
        items={legendEntries}
        onClick={(key) => { handleLegendClick(key); }}
        className="mt-3"
      />
    </div>
  );
};

// RevenuesChart.defaultProps = {
//   width: '100%',
//   height: '600px',
// };

export default RevenuesChart;
