import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';

import Icon from '../../Icon';
import ConvertIntoOpenEventModal from './ConvertIntoOpenEventModal';
import { success } from '../../../utils/notifier';
import { reload } from '../../../utils/router';
import { eventShape } from '../utils';

const ConvertIntoOpenEventButton = ({
  event,
  size,
  className,
  ButtonComponent,
}) => {
  const [showModal, setShowModal] = useState(false);
  const hideModal = () => { setShowModal(false); };
  const { acl } = event;
  const { canConvertIntoOpenEvent } = acl;

  if (!canConvertIntoOpenEvent) { return ''; }

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleConvertIntoOpenEventConfirmation = ({ response }) => {
    reload().then(() => { success({ message: response.message }); });
  };

  return (
    <>
      <ConvertIntoOpenEventModal
        show={showModal}
        event={event}
        onHide={hideModal}
        onConfirm={handleConvertIntoOpenEventConfirmation}
      />

      <ButtonComponent
        size={size}
        variant="dark"
        title="Convert into Open event"
        onClick={handleButtonClick}
        className={classnames('cancel-button d-inline-block', className)}
      >
        <Icon name="university" fw />
        Covert into an
        &nbsp;
        <strong>Open event</strong>
      </ButtonComponent>
    </>
  );
};

ConvertIntoOpenEventButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  event: eventShape.isRequired,
  // ButtonComponent: PropTypes.node,
};

ConvertIntoOpenEventButton.defaultProps = {
  size: 'md',
  className: '',
  ButtonComponent: Button,
};

export default ConvertIntoOpenEventButton;
