import React from 'react';
import { Chart } from 'react-google-charts';
import moment from 'moment';
import $ from 'jquery';
import _ from 'lodash';

import Money, { moneyToChartValue } from '../../utils/money';
import EventBus from '../../packs/event_bus';
import { get } from '../../api/base';
import sleep from '../../utils/sleep';

const findCategoryLabelId = (categoryId, operationsLabels) => {
  const opLabel = (operationsLabels || []).find(opLabel => (
    opLabel.category_id === categoryId
  ));

  return opLabel && opLabel.category_label_id ? opLabel.category_label_id : 'missing';
};

const computeData = (operations, category) => {
  const { category_id, labels } = category;
  const rows = [];
  const year = operations[0]
    ? moment(operations[0].movement_date).year()
    : moment().year();

  // Returns an object with labelId => row index
  const labelsMapping = labels.reduce((hash, label, index) => {
    hash[label.label_id] = index + 2;

    return hash;
  }, {
    date: 0,
    missing: 1, // Unclassified operations
  });

  // For each month consider the incomes and expenses
  Array(12).fill(null).forEach((_val, monthIndex) => {
    const index = monthIndex * 2;
    const month = monthIndex + 1;
    const monthName = moment(`${year}-${month}-01`, 'YYYY-MM-DD').format('MMMM');

    // Create a empty array with a the amounts for each label
    rows[index] = Array(labels.length + 2).fill(Money());
    rows[index][0] = `${monthName} incomes`; // the first value is the current month

    rows[index + 1] = Array(labels.length + 2).fill(Money());
    rows[index + 1][0] = `${monthName} expenses`; // the first value is the current month
  });

  operations.forEach((datum) => {
    const labelId = findCategoryLabelId(category_id, datum.operation_labels);
    const amount = Money(datum.amount);
    const monthIndex = moment(datum.operation_date).month() * 2;
    const index = labelsMapping[labelId];

    if (_.includes(['outbound_invoice', 'credit_note'], datum.operation_type)) {
      // Incomes
      rows[monthIndex][index] = rows[monthIndex][index].add(amount);
    } else {
      // Expenses
      rows[monthIndex + 1][index] = rows[monthIndex + 1][index].add(amount);
    }
  });

  return rows.map((row) => (
    row.map((value, index) => (
      index === 0 ? value : moneyToChartValue(value)
    ))
  ));
};

const reactiveEvents = [
  'FinancialAnalysis::Classification::PendingOperationClassificationRegistered',
  'FinancialAnalysis::Classification::OperationCategorized',
];

class MonthlyCategoryOperationsBarChart extends React.Component {
  constructor(props) {
    super(props);

    const { initialOperations, category } = props;
    this.state = { operations: initialOperations, category };
  }

  componentDidMount() {
    $(document).on('shown.bs.tab', this.handleTabShown.bind(this));

    this.eventSubscriptionId = EventBus.subscribe(
      reactiveEvents,
      _.debounce(this.handleEventSubscription, 500, { leading: true }),
      this,
    );
  }

  componentWillUnmount() {
    $(document).off('shown.bs.tab', this.handleTabShown);

    EventBus.unsubscribe(this.eventSubscriptionId);
  }

  findLabelId(labelId) {
    const { category } = this.state;

    return category.labels.find((label) => (label.label_id === labelId));
  }

  handleTabShown(event) {
    const $a = $(event.target);
    const { category, operations } = this.state;
    const { category_id } = category;

    if (category_id === $a.data('category-id')) {
      // Force rerender
      sleep(10).then(() => {
        this.setState({ operations: [...operations] });
      });
    }
  }

  handleEventSubscription(event) {
    const { category_label_id, category_id } = event;
    const { category } = this.state;

    if (category.category_id !== category_id) { return; }

    if (this.findLabelId(category_label_id)) {
      this.reloadOperations(200);
    } else {
      this.reloadCategory(50).then(() => {
        this.reloadOperations(200)
      });
    }
  }

  reloadOperations(wait = 0) {
    const { workingYear } = this.props;
    const endpoint = `/financial_analysis/unclassified_operations.json?year=${workingYear}`;

    return sleep(wait).then(() => {
      get(endpoint)
        .then((response) => (response.json()))
        .then(({ data }) => {
          this.setState({ operations: data });
        });
    });
  }

  reloadCategory(wait = 0) {
    const { category } = this.state;
    const { category_id } = category;
    const endpoint = `/financial_analysis/classification/categories/${category_id}.json`;

    return sleep(wait).then(() => {
      get(endpoint)
        .then((response) => (response.json()))
        .then((data) => {
          this.setState({ category: data });
        });
    });
  }

  render() {
    const { operations, category } = this.state;
    const { label, labels } = category;
    const rows = computeData(operations, category);
    const columns = [{}, { type: 'number', label: 'Unspecified' }]
      .concat(labels.map((l) => ({ type: 'number', label: l.name })))

    const series = labels.reduce((hash, label, index) => {
      hash[index + 1] = { color: label.color }; // IN

      return hash;
    }, {
      0: { color: '#dddddd' },
    });

    return (
      <div>
        <Chart
          loader={<div>Loading Chart...</div>}
          chartType="ColumnChart"
          width="100%"
          height="450px"
          rows={rows}
          columns={columns}
          options={{
            title: `Monthly revenues per ${label}`,
            isStacked: true,
            legend: {
              position: 'bottom',
            },
            hAxis: {
              title: 'Time',
              titleTextStyle: { color: '#333' },
            },
            vAxis: {
              style: 'currency',
              format: '\u20AC#',
            },
            series,
            backgroundColor: 'transparent',
            chartArea: {
              width: '90%',
            },
            trendlines: {
              0: { type: 'linear', lineWidth: 5, opacity: 0.3 }
            },
          }}
        />
      </div>
    );
  }
}

export default MonthlyCategoryOperationsBarChart;
