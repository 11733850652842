import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { formatDate } from '../utils/datetime';

const parse = (date) => (
  typeof date === 'string'
    ? Date.parse(date)
    : date
);

export const colorize = (deadline) => {
  const date = parse(deadline);

  if (date <= (Date.now() - 1)) {
    return 'badge badge-danger';
  }

  if(date < (Date.now() + 2)) {
    return 'badge badge-warning';
  }

  return 'badge badge-light';
}

const humanize = (deadline) => {
  const date = new Date(deadline);

  return formatDate(date, { format: 'short' });
};

const ColorizedDeadline = ({ deadline }) => (
  <span className={colorize(deadline)}>
    {humanize(deadline)}
  </span>
);

export default ColorizedDeadline;
