import React, { useContext } from 'react';

import Context from './Context';
import AccountDetails from './AccountDetails';
import CalendarsDatatable from './CalendarsDatatable';

const Layout = () => {
  const {
    account,
    calendars,
  } = useContext(Context);

  return (
    <div className="UserAccountPage">
      <div className="row">
        <div className="col-md-4">
          <AccountDetails
            account={account}
          />
        </div>

        <div className="col-md-8">
          <CalendarsDatatable
            account={account}
            calendars={calendars}
          />
        </div>
      </div>
    </div>
  );
};

export default Layout;
