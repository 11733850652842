import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import WithPrivateClass from './WithPrivateClass';
import Spinner from '../../Spinner';
import MissingCertification from './MissingCertification';
import CertificationSettings from '../certifications/CertificationSettings';
import CertifiedAttendeesPage from '../certifications/CertifiedAttendeesPage';

const CertificationPage = ({
  loading,
  privateClass,
  certification,
  certificatesHost,
}) => {
  if (loading) {
    return (
      <Spinner
        description="Retrieving certification information ..."
      />
    );
  }

  if (!certification) {
    return (
      <MissingCertification
        privateClass={privateClass}
      />
    );
  }

  return (
    <div className={classnames('certification-page')}>
      <CertificationSettings
        certification={certification}
        certificatesHost={certificatesHost}
      />

      <hr />

      <CertifiedAttendeesPage
        certification={certification}
        certificatesHost={certificatesHost}
      />
    </div>
  );
};

CertificationPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  certificatesHost: PropTypes.string,

  privateClass: PropTypes.shape({
    id: PropTypes.string,
  }),

  certification: PropTypes.shape({
    id: PropTypes.string,
  }),
};

CertificationPage.defaultProps = {
  privateClass: null,
  certification: null,
  certificatesHost: null,
};

export default WithPrivateClass(CertificationPage);
