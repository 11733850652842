import PropTypes from 'prop-types';

export const accountShape = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  account_name: PropTypes.string,
  provider: PropTypes.string,
});

export default {
  accountShape,
};
