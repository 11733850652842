import React from 'react';
// import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import EventBus from '../../../packs/event_bus';
import { fetchTrainers } from '../../../api/trainers/trainers';
import sleep from '../../../utils/sleep';
import handleError from '../../../utils/error-handler';

const decorateTrainer = (trainer, attributes = {}) => ({
  ...trainer,
  ...attributes,
});
const decorateTrainers = (trainers, attributes = {}) => (
  trainers.map((ca) => (decorateTrainer(ca, attributes)))
);

const WithTrainers = (WrappedComponent) => {
  class TrainersHOC extends React.Component {
    constructor(props) {
      super(props);

      this.debouncedLoadTrainers = debounce(
        this.loadTrainers,
        500,
        { leading: true },
      );

      this.state = {
        trainers: [],
        loading: true,
      };
    }

    componentDidMount() {
      const events = [
        'Trainings::PublicEdition::TrainerAssigned',
        'Trainings::PublicEdition::TrainerUnassigned',
        'Trainings::PublicEdition::TrainersSet',
      ];

      this.trainersEventsSubscriptionId = EventBus.subscribe(
        events,
        this.handleTrainersEventsSubscription,
        this,
      );

      this.loadTrainers();
    }

    componentWillUnmount() {
      EventBus.unsubscribe(this.trainersEventsSubscriptionId);
    }

    handleTrainersEventsSubscription() {
      this.debouncedLoadTrainers();
    }

    // handleOnDelete({ id }) {
    //   const { trainers, certification } = this.state;
    //   const trainerId = id;
    //   const certificationId = certification.id;
    //   const filteredTrainers = trainers.filter((ca) => (ca.id !== id));

    //   return deleteTrainer({ certificationId, trainerId })
    //     .then((response) => response.json())
    //     .then((response) => {
    //       success({ message: response.message });
    //       this.setTrainers(filteredTrainers);
    //     })
    //     .catch(handleError);
    // }

    // handleOnUpdate({ trainer }) {
    //   const { trainers } = this.state;
    //   const { id } = trainer;
    //   const updatedTrainers = trainers.map((ca) => (
    //     ca.id === id ? trainer : ca
    //   ));

    //   this.setTrainers(updatedTrainers);
    // }

    setTrainers(plainTrainers, attributes = {}) {
      const decoratedTrainers = decorateTrainers(
        plainTrainers,
        attributes,
      );

      this.setState({
        loading: false,
        trainers: decoratedTrainers,
      });
    }

    loadTrainers(wait = 0) {
      return sleep(wait).then(() => {
        fetchTrainers()
          .then((response) => (response.json()))
          .then(({ data }) => {
            this.setTrainers(data.map(({ attributes }) => (attributes)));
          })
          .catch(handleError);
      });
    }

    render() {
      const { trainers, loading } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          loading={loading}
          trainers={trainers}
        />
      );
    }
  }

  TrainersHOC.propTypes = {
  };

  return TrainersHOC;
};

export default WithTrainers;
