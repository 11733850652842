import { post, destroy } from '../base';

export const enableSalesForecasts = ({
  publicTrainingId,
}) => (
  post('/financial_analysis/public-training-sales.json', {
    public_training_sales: {
      public_training_id: publicTrainingId,
    },
  })
);

export const disableSalesForecasts = ({
  publicTrainingId,
}) => (
  destroy(`/financial_analysis/public-training-sales/${publicTrainingId}.json`)
);

export default {
  enableSalesForecasts,
  disableSalesForecasts,
};
