import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Input from '../../RHF/Input';
import ColorInput from '../../RHF/ColorInput';
import { randomColor } from '../../../utils/colors';
import Icon from '../../Icon';
import { calendarEventShape } from '../proptypes';

import styles from './LabelsPicker.module.scss';

const FormFields = ({
  errors,
  control,
  isLoading,
}) => {
  return (
    <div className={classnames(styles.AddLabelModal__formFields)}>
      <ColorInput
        name="color"
        errors={errors}
        control={control}
        label="Color"
        disabled={isLoading}
        required
      />

      <Input
        label="Name"
        placeholder="The label name"
        name="name"
        errors={errors}
        control={control}
        disabled={isLoading}
        size="lg"
        required
      />
    </div>
  );
};

FormFields.propTypes = {
};

FormFields.defaultProps = {
};

export default FormFields;
