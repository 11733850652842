import React from 'react';
import PropTypes from 'prop-types';

import CategoryCharts from './CategoryCharts';

import Spinner from '../Spinner';
import WithOperations from './WithOperations';

const ExpensesCharts = (({
  logic,
  operations,
  categories,
  categoryId,
  loading,
  activeCategoriesLabels,
}) => {
  if (loading) {
    return (
      <Spinner description="Loading revenues charts..." />
    );
  }

  const category = categories.find(({ id }) => (categoryId === id));
  const activeLabels = activeCategoriesLabels[categoryId];

  return (
    <div>
      <CategoryCharts
        category={category}
        operations={operations}
        logic={logic}
        activeLabels={activeLabels}
        active
      />
    </div>
  );
});

ExpensesCharts.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  operations: PropTypes.arrayOf(PropTypes.object).isRequired,
  logic: PropTypes.string,
  loading: PropTypes.bool,
  activeCategoriesLabels: PropTypes.object.isRequired,
  categoryId: PropTypes.string.isRequired,
};

ExpensesCharts.defaultProps = {
  logic: 'positive',
  loading: false,
};

export default WithOperations(ExpensesCharts);
