import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';

const SliderInput = ({
  label,
  errors,
  name,
  control,
  required,
  ...props
}) => (
  <Form.Group controlId={`form-grid-${name}`}>
    {
      label && (
        <Form.Label>
          {label}
          &nbsp;
          {required && (<abbr title="required">*</abbr>)}
        </Form.Label>
      )
    }
    <Controller
      render={({ field }) => (
        <Slider
          tipFormatter={(value) => (value)}
          {...props}
          {...field}
        />
      )}
      control={control}
      name={name}
      rules={{ required }}
    />
    {errors[name] && (
      <Form.Control.Feedback type="invalid">
        {errors[name].message || 'Required'}
      </Form.Control.Feedback>
    )}
  </Form.Group>
);

SliderInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  errors: PropTypes.shape({}),
  control: PropTypes.shape({}).isRequired,
  required: PropTypes.bool,
};

SliderInput.defaultProps = {
  label: 'Slider',
  errors: {},
  required: false,
};

export default SliderInput;
