import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';

import { footprintStepShape } from '../proptypes';
import { convertFromValue } from '../../inputs/TimeDurationInput/utils';
import Icon from '../../Icon';
import styles from './styles.module.scss';

const MomentStepCaption = ({ className, onClick, step, readOnly }) => {
  const { description, priority, duration } = step;
  const humanDuration = convertFromValue(duration, 'm');

  const buttonClassName = classnames(
    className, styles.StepButton, styles.MomentStepButton,
    {
      [styles.MomentStepButton__readOnly]: readOnly,
    }
  );

  return (
    <Button
      className={buttonClassName}
      onClick={() => { onClick({ step }); }}
      title={description}
    >
      <div className="row">
        <div className="col-9">
          <h6 className="mb-0 text-truncate">
            {description}
          </h6>
        </div>

        <div className="col-3">
          <h6 className="mb-0 text-truncate text-right">
            <Icon name="calendar" fw />
          </h6>
        </div>
      </div>

      <hr className="mt-2 mb-1" />

      <div className="row">
        <div className="col">
          <small>
            Priority:
            &nbsp;
            {priority}
          </small>
        </div>

        <div className="col text-right">
          <small>{humanDuration}</small>
        </div>
      </div>
    </Button>
  );
};

MomentStepCaption.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  step: footprintStepShape.isRequired,
  readOnly: PropTypes.bool,
};

MomentStepCaption.defaultProps = {
  className: null,
  onClick: () => {},
  readOnly: false,
};

export default MomentStepCaption;
