import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';

import Icon from '../../Icon';
import CalendarEventDetails from './CalendarEventDetails';

const CalendarEventModal = ({
  show,
  user,
  variant,
  size,
  event,
  onClose,
  onUpdate,
  labels,
  onLabelToggle,
  onLabelCreate,
  onLabelUpdate,
  onLabelDelete,
}) => {
  const { html_link } = event;

  return (
    <Modal show={show} size={size} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong className={classnames({ 'text-danger': variant === 'danger' })}>
            { show && event.summary }
          </strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {
          show && (
            <CalendarEventDetails
              user={user}
              event={event}
              onUpdate={onUpdate}
              labels={labels}
              onLabelToggle={onLabelToggle}
              onLabelCreate={onLabelCreate}
              onLabelUpdate={onLabelUpdate}
              onLabelDelete={onLabelDelete}
            />
          )
        }
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="warning" href={html_link} target="_blank">
          <Icon name="external-link-alt" fw />
          &nbsp;
          Open in Google Calendar
        </Button>

        <Button variant="secondary" onClick={onClose}>
          <Icon name="times" fw />
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CalendarEventModal.propTypes = {
  show: PropTypes.bool,
  variant: PropTypes.string,
  size: PropTypes.string,
  event: PropTypes.shape({
    summary: PropTypes.string,
  }),
  onClose: PropTypes.func,
};

CalendarEventModal.defaultProps = {
  show: false,
  variant: 'primary',
  size: 'lg',
  event: {},
  onClose: () => {},
};

export default CalendarEventModal;
