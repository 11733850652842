import React from 'react';
import PropTypes from 'prop-types';

import { localize } from './l10n';

const Localize = ({ label }) => {
  const text = localize(label);

  return (
    <>
      {text}
    </>
  );
};

Localize.propTypes = {
  label: PropTypes.string.isRequired,
};

export default Localize;
