import $ from 'jquery';

export const buttonsCtas = 'button[data-js-toggle-class], a[data-js-toggle-class]';
export const inputsCtas = 'select[data-js-toggle-class], input[data-js-toggle-class]';
export const defaultTargets = '[data-js-togglable-class]';

export const toggleItem = (item) => {
  const $item = $(item);
  const originalClass = $item.attr('class');
  const toggleClass = $item.data('js-togglable-class');

  $item.attr('class', toggleClass);
  $item.data('js-togglable-class', originalClass);
};

const handleClick = (e) => {
  const $el = $(e.currentTarget);
  const stopPropagation = $el.data('js-toggle-class-propagation') || true;
  const targets = $el.data('js-toggle-class') === true
    ? defaultTargets
    : $el.data('js-toggle-class');

  if (stopPropagation === true) { e.preventDefault(); }

  $(targets).each((i, item) => {
    toggleItem(item);
  });
};

// Do not stop propagation here
const handleChange = (e) => {
  const $el = $(e.currentTarget);

  const targets = $el.data('js-toggle-class') === true
    ? defaultTargets
    : $el.data('js-toggle-class');

  $(targets).each((i, item) => {
    toggleItem(item);
  });
};

export const initialize = () => {
  $(inputsCtas).off('change', handleChange);
  $(inputsCtas).on('change', handleChange);
  $(buttonsCtas).off('click', handleClick);
  $(buttonsCtas).on('click', handleClick);
};

export const destroy = () => {
  $(inputsCtas).off('change', handleChange);
  $(buttonsCtas).off('click', handleClick);
};

export default initialize;
