import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import moment from 'moment';

import DateInput from '../inputs/DateInput';
import Money from '../../utils/money';
import handleError from '../../utils/error-handler';
import { stopRecurringExpectation } from '../../api/recurringExpectations';
import { visit } from '../../utils/router';

const formatDate = (date) => {
  if (!date) { return ''; }

  return moment(date).format('D/M/Y');
};

const StopRecurringExpectation = ({
  expectationId,
  headline,
  show,
  direction,
  expectedAmount,
  onHide,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [willStopOn, setWillStopOn] = React.useState(new Date());
  const handleUpdate = (value) => {
    setWillStopOn(value);
  };
  const isSubmitDisabled = () => (isLoading || !willStopOn);
  const expectationsLabel = direction === 'out'
    ? 'expenses'
    : 'incomes';
  const humanAmount = Money(expectedAmount).toFormat();
  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    stopRecurringExpectation({ expectationId, willStopOn: formatDate(willStopOn) })
      .then((response) => { visit(response.redirect_to); })
      .catch(handleError);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {`Stop recurring ${expectationsLabel}`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h6>
          Stop future {expectationsLabel} of <strong>{humanAmount}</strong> for <em>{headline}</em>.
        </h6>

        <br />

        <DateInput
          label={`Stop ${expectationsLabel} from date`}
          onChange={(date) => { handleUpdate(date); }}
          defaultValue={formatDate(willStopOn)}
        />

      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="danger" disabled={isSubmitDisabled()} onClick={(e) => { handleSubmit(e); }}>
          {
            isLoading
              ? 'Loading...'
              : `Stop ${expectationsLabel}`
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

StopRecurringExpectation.propTypes = {
  expectationId: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  direction: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
};

StopRecurringExpectation.defaultProps = {
  show: false,
  direction: 'out',
};

export default StopRecurringExpectation;
