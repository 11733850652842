import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ListingTable from '../../ListingTable';
import Row from './SettingsPage/CategoryRow';
import { eventShape, categoryShape } from '../utils';
import Spinner from '../../Spinner';

const SettingsPage = ({ event, classificationCategories, classificationRules, loading }) => {
  if (loading) {
    return (
      <div>
        <Spinner
          description="Loading public training classification rules..."
        />
      </div>
    );
  }

  const categoryLabelsMap = classificationCategories.reduce((accumulator, category) => ({
    ...accumulator,
    [category.id]: category.labels,
  }), {});

  const findCategoryLabel = ({ categoryId, labelId }) => (
    categoryLabelsMap[categoryId].find((label) => (label.id === labelId))
  );
  const enrichRuleCategoryLabels = (rule) => {
    const {
      classification_category_id,
      classification_category_labels,
    } = rule;

    return classification_category_labels.map(({ category_label_id, repartition }) => ({
      ...findCategoryLabel({
        categoryId: classification_category_id,
        labelId: category_label_id,
      }),
      repartition,
    }));
  };
  // const initialCategoryLabels = {};
  const initialCategoryLabels = classificationRules.reduce((accumulator, rule) => ({
    ...accumulator,
    [rule.classification_category_id]: enrichRuleCategoryLabels(rule),
  }), {});

  const [categoryLabels, setCategoryLabels] = useState({ ...initialCategoryLabels });

  const handleChange = ({ categoryId, labels }) => {
    setCategoryLabels((currentLabels) => ({
      ...currentLabels,
      [categoryId]: labels,
    }));
  };

  const headers = ['Financial category', 'Classification label', 'Category description'];
  const sortableColumns = {};
  const rows = classificationCategories.map((category) => ({
    id: category.id,
    category,
    labels: categoryLabels[category.id] || [],
  }));

  return (
    <div className={classnames('settings-page')}>
      <h4 className="mb-3">
        Public training settings
        <br />
        <small>Setup the predefined financial analysis classification rules</small>
      </h4>

      <ListingTable
        items={rows}
        components={{ Row }}
        headers={headers}
        sortableColumns={sortableColumns}
        rowProps={{ onChange: handleChange, event }}
        hideSearch
      />
    </div>
  );
};

SettingsPage.propTypes = {
  event: eventShape.isRequired,
  classificationCategories: PropTypes.arrayOf(categoryShape).isRequired,
  classificationRules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool,
};

SettingsPage.defaultProps = {
  loading: false,
};

export default SettingsPage;
