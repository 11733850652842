import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { parseISO, formatDistanceToNow } from 'date-fns';

import Icon from './Icon';
import { syncOperations, syncStatus } from '../api/operations';
import handleError from '../utils/error-handler';
import { success } from '../utils/notifier';

const SyncOperationsButton = ({ caption, className, placement }) => {
  const [status, setStatus] = useState(null);
  const classNames = classnames(className);
  const handleClick = () => {
    syncOperations()
      .then((response) => response.json())
      .then((data) => { success({ message: data.message }); })
      .catch(handleError);

    setStatus({
      startedAt: new Date(),
      completedAt: null,
    });
  };

  const updateSyncStatus = () => {
    syncStatus()
      .then((response) => response.json())
      .then((data) => {
        setStatus({
          startedAt: data.started_at ? parseISO(data.started_at) : null,
          completedAt: data.completed_at ? parseISO(data.completed_at) : null,
          expired: data.expired,
        });
      })
      .catch(handleError);
  };

  // On mount
  useEffect(() => {
    let mounted = true;

    if (mounted) { updateSyncStatus(); }

    return () => { mounted = false; };
  }, []);

  const isSynching = status && status.startedAt && !status.completedAt && !status.expired;
  const disabled = status === null || isSynching;
  let subtitle;

  if (isSynching) {
    subtitle = `Sync started ${formatDistanceToNow(status.startedAt)}`;
  } else if (status && status.expired) {
    subtitle = `The sync started ${formatDistanceToNow(status.startedAt)} is expired`;
  } else if (status && status.completedAt) {
    subtitle = `Last sync completed ${formatDistanceToNow(status.completedAt)}`;
  } else {
    subtitle = 'Sync never started';
  }

  return (
    <OverlayTrigger
      key={placement}
      placement={placement}
      overlay={(
        <Tooltip id={`tooltip-${placement}`}>
          Sync operations from FattureInCloud.
          <br />
          {subtitle}
        </Tooltip>
      )}
    >
      <Button
        className={classNames}
        onClick={handleClick}
        variant="warning"
        disabled={disabled}
      >
        <Icon name="sync" spin={isSynching} fw />
        &nbsp;
        {caption}
      </Button>
    </OverlayTrigger>
  );
};

SyncOperationsButton.propTypes = {
  className: PropTypes.string,
  caption: PropTypes.string,
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};

SyncOperationsButton.defaultProps = {
  className: '',
  caption: 'Synchronize',
  placement: 'bottom',
};

export default SyncOperationsButton;
