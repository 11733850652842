import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ColorizedDeadline from './ColorizedDeadline';
import StopButton from './expectations/StopRecurringButton';
import ExtendButton from './expectations/ExtendRecurringButton';
import Icon from './Icon';
import { generateUrl } from './expectations/commons';

import PriceValue from './expectations/PriceValue';

const iconFor = (expectationType, direction) => {
  let iconString = 'fa fa-lg';
  if (expectationType === 'single-shot' && direction === 'out') iconString += ' fa-arrow-down';
  if (expectationType === 'single-shot' && direction === 'in') iconString += ' fa-arrow-up';
  if (expectationType === 'recurring') iconString += ' fa-redo';

  return iconString;
};

class ExpectationsListRow extends React.Component {
  renderRegisterCompleteCta() {
    const { id, acl, direction } = this.props;
    if (!acl.canRegisterComplete) { return ''; }

    return (
      <>
        &nbsp;
        <a
          href={generateUrl({ id, direction, action: 'register_complete' })}
          className="btn btn-outline-success btn-sm"
          data-toggle="tooltip"
          data-placement="top"
          title="Mark as fulfilled"
          data-original-title="Mark as fulfilled"
        >
          <Icon name="check" fw />
        </a>
      </>
    );
  }

  renderEditCta() {
    const { id, acl, direction } = this.props;
    if (!acl.canEdit) { return ''; }

    return (
      <>
        &nbsp;
        <a
          href={generateUrl({ id, direction, action: 'edit' })}
          className="btn btn-outline-primary btn-sm"
          data-toggle="tooltip"
          data-placement="top"
          title="Edit expectation"
          data-original-title="Edit expectation"
        >
          <Icon name="pencil-alt" fw />
        </a>
      </>
    );
  }

  renderDeleteCta() {
    const { id, acl, direction } = this.props;
    if (!acl.canDelete) { return ''; }

    return (
      <>
        &nbsp;
        <a
          href={generateUrl({ id, direction })}
          className="btn btn-outline-danger btn-sm"
          data-toggle="tooltip"
          data-placement="top"
          data-confirm="Are you sure?"
          data-method="delete"
          title="Delete expectation"
          data-original-title="Delete expectation"
        >
          <Icon name="trash" fw />
        </a>
      </>
    );
  }

  renderDeleteRecurringCta() {
    const { id, acl } = this.props;
    if (!acl.canDeleteRecurring) { return ''; }

    return (
      <>
        &nbsp;
        <a
          href={generateUrl({ id, recurring: true })}
          className="btn btn-outline-dark btn-sm"
          data-toggle="tooltip"
          data-placement="top"
          data-confirm="Are you sure?"
          data-method="delete"
          title="Delete recurring expectation"
          data-original-title="Delete recurring expectation"
        >
          <Icon name="trash" fw />
        </a>
      </>
    );
  }

  renderRenewCta() {
    const { id, acl, direction } = this.props;
    if (!acl.canRenew) { return ''; }

    return (
      <>
        &nbsp;
        <a
          href={generateUrl({ id, direction, action: 'renew' })}
          className="btn btn-outline-warning btn-sm"
          data-toggle="tooltip"
          data-placement="top"
          title="Renew fulfilled expectation"
          data-original-title="Renew fulfilled expectation"
        >
          <Icon name="redo" fw />
        </a>
      </>
    );
  }

  renderStopReurringCta() {
    const {
      id, direction, acl, headline
    } = this.props;

    const expectedAmount = this.props.expected_amount || this.props.expectedAmount;

    return (
      <>
        &nbsp;
        <StopButton
          id={id}
          direction={direction}
          acl={acl}
          headline={headline}
          expectedAmount={expectedAmount}
          className="btn btn-outline-danger btn-sm"
          label=""
        />
      </>
    );
  }

  renderExtendReurringCta() {
    const {
      id, direction, acl, headline,
    } = this.props;

    const expectedAmount = this.props.expected_amount || this.props.expectedAmount;

    return (
      <>
        &nbsp;
        <ExtendButton
          id={id}
          direction={direction}
          acl={acl}
          headline={headline}
          expectedAmount={expectedAmount}
          className="btn btn-outline-success btn-sm"
          label=""
        />
      </>
    );
  }

  render() {
    const {
      id,
      headline,
      counterpart,
      direction,
      item,
      acl,
    } = this.props;

    const hasACL = Object.keys(acl || {}).length > 0;

    // const signAdjustedAmount = item.sign_adjusted_amount || item.signAdjustedAmount;
    const expectedOnDate = item.expected_on_date || item.expectedOnDate;
    const expectationType = item.expectation_type || item.expectationType;

    return (
      <tr key={id} className="row">
        <td className="text-right col-lg-3 align-items-center d-flex justify-content-end">
          <div>
            <h5 className="mb-0">
              <ColorizedDeadline deadline={expectedOnDate} />
            </h5>
            <PriceValue expectation={item} />
          </div>
        </td>

        <td className="col-lg-1 text-center  align-items-center d-flex">
          <i className={iconFor(expectationType, direction)} />
        </td>

        <td className={classnames({ 'col-lg-5': hasACL, 'col-md-8': !hasACL })}>
          <div>
            <strong>{counterpart}</strong>
            <br />
            <a href={generateUrl(id, direction)} title={headline}>{headline}</a>
          </div>
        </td>

        {
          hasACL && (
            <td className="col-lg-3 text-center align-items-center d-flex justify-content-end">
              {this.renderExtendReurringCta()}
              {this.renderStopReurringCta()}
              {this.renderRegisterCompleteCta()}
              {this.renderEditCta()}
              {this.renderRenewCta()}
              {this.renderDeleteCta()}
              {this.renderDeleteRecurringCta()}
            </td>
          )
        }
      </tr>
    );
  }
}

ExpectationsListRow.propTypes = {
  id: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  acl: PropTypes.shape({}),
  item: PropTypes.shape({}).isRequired,
};

ExpectationsListRow.defaultProps = {
  acl: {},
};

export default ExpectationsListRow;
