import { patch } from '../base';

export const overridesTicketPrice = ({
  ticketId,
  net,
  gross,
  tax,
}) => (
  patch(`/trainings/tickets/${ticketId}/overrides-ticket-price.json`, {
    ticket: { net, gross, tax },
  })
);

export default {
  overridesTicketPrice,
};
