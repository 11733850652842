import React, { useState } from 'react';
import parse from 'html-react-parser';
import classnames from 'classnames';
import _ from 'lodash';

import CalendarEventDatetimeLabel from '../CalendarEventDatetimeLabel';
import CalendarEventLocationMap from './CalendarEventLocationMap';
import CalendarEventAttendee from './CalendarEventAttendee';
import LabelsPicker from '../LabelsPicker';
import PriorityInplaceInput from '../PriorityInplaceInput';
import { calendarEventShape } from '../proptypes';
import ProviderIcon from '../ProviderIcon';
import Icon from '../../Icon';

import styles from './CalendarEventDetails.module.scss';

// const eventKeys = [
//   "id",
//   "account_id",
//   "calendar_id",
//   "user_id",
//   "parent_id",
//   "status",
//   "visibility",
//   "provider",
//   "provider_identifier",
//   "provider_parent_identifier",
//   "provider_created_at",
//   "provider_updated_at",
//   "summary",
//   "description",
//   "start_on",
//   "start_at",
//   "end_on",
//   "end_at",
//   "html_link",
//   "i_cal_uid",
//   "location",
//   "reminders",
//   "recurrence",
//   "recurring",
//   "sequence",
//   "transparency",
//   "guests_can_modify",
//   "label"
// ];

const CalendarEventDetails = ({
  user,
  event,
  labels,
  onUpdate,
  onLabelToggle,
  onLabelCreate,
  onLabelUpdate,
  onLabelDelete,
}) => {
  const [hideContent, setHideContent] = useState(false);
  const {
    location,
    description,
    account_name,
    account_provider,
    account_provider_identifier,
    calendar_name,
    calendar_provider_identifier,
    location_latitude,
    location_longitude,
    provider_identifier,
    calendar_time_zone,
    attendees,
    priority,
    html_link,
  } = event;

  const parsedDescription = parse(description || '<div />');

  const handlePriorityClick = () => {
    setHideContent(true);
  };

  const handlePriorityUpdate = (eventId, attributes) => {
    setHideContent(false);

    onUpdate(eventId, {
      ...event,
      ...attributes,
    });
  };

  const accountName = _.uniq([
    account_name,
    account_provider_identifier,
  ]).join(' / ');

  const calendarName = _.uniq([
    calendar_name,
    calendar_provider_identifier,
  ]).join(' / ');

  return (
    <div
      className={classnames(
        'CalendarEventDetails',
        { 'd-none': hideContent },
      )}
    >
      <div className="row">
        <div className="col-sm-6">
          <dl>
            <dt>
              <Icon name="fingerprint" family="fas" fw />
              &nbsp;
              PROVIDER ID:
            </dt>
            <dd className="text-truncate" title={provider_identifier}>
              {provider_identifier}
            </dd>

            <dt>
              <Icon name="clock" family="far" fw />
              &nbsp;
              Dates:
            </dt>
            <dd>
              <CalendarEventDatetimeLabel event={event} />
            </dd>

            <dt>
              <Icon name="globe-europe" family="fas" fw />
              &nbsp;
              Timezone:
            </dt>
            <dd>
              {calendar_time_zone}
            </dd>

            <dt>
              <ProviderIcon provider={account_provider} fw />
              &nbsp;
              Account:
            </dt>
            <dd>
              {accountName}
            </dd>

            <dt>
              <Icon name="calendar-alt" family="far" fw />
              &nbsp;
              Calendar:
            </dt>
            <dd>
              {calendarName}
            </dd>

            <dt>
              <Icon name="weight" family="fas" fw />
              &nbsp;
              Priority:
            </dt>
            <dd>
              <PriorityInplaceInput
                event={event}
                onChange={handlePriorityUpdate}
                onClick={handlePriorityClick}
                onHide={() => { setHideContent(false); }}
              />
            </dd>
          </dl>
        </div>

        <div className="col-sm-6">
          <dl>
            <dt>
              <Icon name="tags" fw />
              &nbsp;
              Labels:
            </dt>
            <dd>
              <LabelsPicker
                user={user}
                event={event}
                labels={labels}
                onToggle={onLabelToggle}
                onCreate={onLabelCreate}
                onUpdate={onLabelUpdate}
                onDelete={onLabelDelete}
              />
            </dd>
          </dl>

          {
            attendees.length > 0 && (
              <dl>
                <dt>
                  <Icon name="users" fw />
                  &nbsp;
                  Attendees:
                </dt>
                <dd>
                  {
                    attendees.map((attendee) => (
                      <CalendarEventAttendee
                        attendee={attendee}
                        key={attendee.id}
                      />
                    ))
                  }
                </dd>
              </dl>
            )
          }
        </div>
      </div>

      <dl>
        {
          description && (
            <>
              <dt>
                <Icon name="file-alt" family="far" fw />
                &nbsp;
                Notes:
              </dt>
              <dd>
                <blockquote className={styles.blockquote}>
                  {parsedDescription}
                </blockquote>
              </dd>
            </>
          )
        }

        {
          location_latitude && location_longitude && (
            <>
              <dt>
                <Icon name="map-marker-alt" family="fas" fw />
                &nbsp;
                Location:
              </dt>
              <dd>
                <p>{location}</p>
                <CalendarEventLocationMap
                  event={event}
                  className={styles.map}
                />
              </dd>
            </>
          )
        }
      </dl>
    </div>
  );
};

CalendarEventDetails.propTypes = {
  event: calendarEventShape,
};

CalendarEventDetails.defaultProps = {
  event: {},
};

export default CalendarEventDetails;
