import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import Icon from '../Icon';
import DeleteModal from './DeleteModal';
import { success } from '../../utils/notifier';
import { visit } from '../../utils/router';
import { indexPath } from '../../routes/trainers/trainers';

const DeleteButton = ({ size, className, trainer }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const hideDeleteModal = () => { setShowDeleteModal(false); };
  const handleDeleteButtonClick = () => { setShowDeleteModal(true); };
  const handleDelete = ({ response }) => {
    visit(indexPath()).then(() => { success({ message: response.message }); });
  };

  return (
    <div className="cancel-button d-inline-block">
      <DeleteModal
        show={showDeleteModal}
        onHide={hideDeleteModal}
        onDelete={handleDelete}
        trainer={trainer}
        displaySuccessMessage={false}
      />

      <Button
        size={size}
        variant="danger"
        title="Delete event"
        onClick={handleDeleteButtonClick}
        className={className}
      >
        <Icon name="trash" fw />
        &nbsp;
        Delete
      </Button>
    </div>
  );
};

DeleteButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  trainer: PropTypes.shape({}).isRequired,
};

DeleteButton.defaultProps = {
  size: 'md',
  className: '',
};

export default DeleteButton;
