import React from 'react';
import PropTypes from 'prop-types';
import {
  unclassifiedLabel,
} from '../../../OperationsPage/commons';
import SelectInput from '../../../inputs/SelectInput';

const buildOption = (label) => ({
  ...label,
  // icon: 'tag',
  label: label.name,
});

const SelectLabelInput = React.forwardRef((props, ref) => {
  const {
    onChange, onBlur, labels, categoryLabels, menuIsOpen, includeBlank,
  } = props;
  const isMulti = categoryLabels.length > 1;
  const ids = isMulti
    ? categoryLabels.map((cl) => cl.id)
    : (categoryLabels[0] || {}).id;
  const category_id = labels[0] ? labels[0].category_id : null;
  const options = (labels || []).map(buildOption);
  const optionsWithBlank = [...options];

  if (includeBlank) {
    optionsWithBlank.push(buildOption({
      ...unclassifiedLabel,
      category_id,
    }));
  }

  return (
    <SelectInput
      options={optionsWithBlank}
      valueKey="_id"
      labelKey="label"
      initialValue={ids}
      onChange={onChange}
      layout={false}
      type="creatable"
      inputProps={{
        isMulti,
        defaultMenuIsOpen: menuIsOpen,
        onBlur,
        autoFocus: true,
        styles: {
          option: (provided, _state) => ({
            ...provided,
            whiteSpace: 'nowrap',
            // borderBottom: '1px dotted pink',
            // color: state.isSelected ? 'red' : 'blue',
            paddingRight: 30,
            textAlign: 'left',
          }),
          menu: (provided, state) => ({
            ...provided,
            width: state.selectProps.width,
            minWidth: '100%',
          }),
        },
      }}
    />
  );
});

SelectLabelInput.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  categoryLabels: PropTypes.arrayOf(PropTypes.shape({})),
  menuIsOpen: PropTypes.bool,
  includeBlank: PropTypes.bool,
};

SelectLabelInput.defaultProps = {
  menuIsOpen: false,
  onBlur: () => {},
  categoryLabels: [],
  includeBlank: true,
};

export default SelectLabelInput;
