import React from 'react';

import ExpectedExpensesEditButton from './ExpectedExpensesEditButton';
import ColorizedDeadline from '../../../ColorizedDeadline';
import PriceValue from '../../../expectations/PriceValue';
import Icon from '../../../Icon';

const ExpectedExpensesListRow = ({ expense }) => {
  const { headline, date, counterpart } = expense;

  return (
    <tr className="row">
      <td className="text-right col-lg-3">
        <PriceValue expectation={expense} />
      </td>

      <td className="col-lg-6">
        <div className="text-truncate" style={{ maxWidth: 150 }}>
          <h6 className="mb-0">
            {headline}
            <br />
            <small>{counterpart}</small>
          </h6>
        </div>
      </td>

      <td className="col-lg-3 text-center">
        <h5>
          <ColorizedDeadline 
            deadline={date}
          />
        </h5>
      </td>
    </tr>
  );
};

const ExpectedExpensesList = ({ expenses }) => (
  <table className="table table-striped table-sm mt-2">
    <tbody>
      {
        expenses.map((expense) => (
          <ExpectedExpensesListRow 
            key={expense.id}
            expense={expense}
          />
        ))
      }
    </tbody>
  </table>
);

const ExpectedExpensesElement = ({ 
  event, 
  countries, 
  accounts,
  currencies,
  expectedExpenses,
}) => (
  <>
    <dt>
      <ExpectedExpensesEditButton  
        event={event}
        className="float-right px-1 py-0"
        countries={countries}
        accounts={accounts}
        currencies={currencies}
      />

      <Icon name="wallet" fw />
      &nbsp;
      Planned expenses
    </dt>
    <dd className="">
      {
        expectedExpenses.length === 0 && (
          <table className="table table-striped table-sm mt-2">
            <tbody>
              <tr>
                <td>No expected expenses</td>
              </tr>
            </tbody>
          </table>
        )
      }
      {
        expectedExpenses.length > 0 && (
          <ExpectedExpensesList expenses={expectedExpenses} />
        )
      }
    </dd>
  </>
);

export default ExpectedExpensesElement;
