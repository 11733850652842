import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import Icon from '../../Icon';
import CalendarDatatable from './CalendarDatatable';
import RunningDatatable from './RunningDatatable';
import ArchiveDatatable from './ArchiveDatatable';

const Title = ({ icon, label }) => (
  <span>
    <Icon name={icon} fw />
    &nbsp;
    {label}
  </span>
)

const AsideView = () => (
  <aside>
    <Tabs defaultActiveKey="calendar" id="expectations-aside-view">
      <Tab eventKey="calendar" title={<Title icon="calendar" label="Calendar" />}>
        <CalendarDatatable />
      </Tab>

      <Tab eventKey="running" title={<Title icon="list" label="List" />}>
        <RunningDatatable />
      </Tab>

      <Tab eventKey="archive" title={<Title icon="archive" label="Archive" />}>
        <ArchiveDatatable />
      </Tab>
    </Tabs>
  </aside>
);

AsideView.propTypes = {
};

AsideView.defaultProps = {
};

export default AsideView;
