import { useContext } from 'react';
import _ from 'lodash';

import Context from './Context';

export const localize = (label) => {
  const { i18n } = useContext(Context);
  const text = _.get(i18n, label);

  return text;
};

export default {
  localize,
};
