import { patch, httpDelete } from '../base';

export const updateCertifiedAttendee = ({ certificationId, certifiedAttendee }) => {
  const { id } = certifiedAttendee;

  // return patch(`/trainings/certified_attendees/${id}.json`, {
  return patch(`/trainings/certification/${certificationId}/certified_attendees/${id}.json`, {
    certified_attendee: certifiedAttendee,
  });
};

export const deleteCertifiedAttendee = ({ certificationId, certifiedAttendeeId }) => (
  httpDelete(`/trainings/certification/${certificationId}/certified_attendees/${certifiedAttendeeId}.json`)
);

export default {
  updateCertifiedAttendee,
  deleteCertifiedAttendee,
};
