import React from 'react';
import _ from 'lodash';

import EventBus from '../../packs/event_bus';
import { expenses as fetchExpenses } from '../../api/billing/expenses';

import sleep from '../../utils/sleep';

const decorateExpense = (expense) => ({
  ...expense,
});

const decorateExpenses = (expenses) => expenses.map(decorateExpense);

const WithExpenses = (WrappedComponent) => {
  class HOC extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        expenses: [],
        loading: true,
      };
    }

    componentDidMount() {
      this.expenseEventsSubscriptionId = EventBus.subscribe(
        [
          'Billing::Expenses::ExpenseRegistered',
          'Billing::Expenses::ExpenseUpdated',
          'Billing::PassiveCreditNotes::PassiveCreditNoteRegistered',
          'Billing::PassiveCreditNotes::PassiveCreditNoteUpdated',
          'Billing::PassiveCreditNotes::PassiveCreditNoteDiscarded',
        ],
        _.debounce(
          this.handleExpensesEventsSubscription,
          500,
          { leading: true },
        ),
        this,
      );

      this.reloadExpenses();
    }

    // componentWillUnmount() {
    //   EventBus.unsubscribe(this.expensesEventsSubscriptionId);
    // }

    handleOnDelete({ expenseId }) {
      const { expenses } = this.state;
      const filteredExpenses = expenses.filter((pe) => (
        pe.id !== expenseId
      ));

      this.setExpenses(filteredExpenses);
    }

    handleOnUpdate({ expense }) {
      const { expenses } = this.state;
      const { id } = expense;
      const updatedExpenses = expenses.map((pe) => (
        pe.id === id ? expense : pe
      ));

      this.setExpenses(updatedExpenses);
    }

    handleExpensesEventsSubscription() {
      this.reloadExpenses(100);
    }

    setExpenses(plainExpenses) {
      const expenses = decorateExpenses(plainExpenses);

      this.setState({
        loading: false,
        expenses,
      });
    }

    reloadExpenses(wait = 0) {
      return sleep(wait).then(() => {
        fetchExpenses().then((data) => {
          this.setExpenses(data);
        });
      });
    }

    render() {
      const {
        expenses,
        loading,
      } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          loading={loading}
          expenses={expenses}
          onDelete={(args) => { this.handleOnDelete(args); }}
          onUpdate={(args) => { this.handleOnUpdate(args); }}
        />
      );
    }
  }

  return HOC;
};

export default WithExpenses;
