import { post, httpDelete } from '../base';

const basePath = (calendarEventId) => (
  `/smart_planner/calendar_events/${calendarEventId}/label`
);

export const assignLabel = (calendarEventId, { label }) => (
  post(`${basePath(calendarEventId)}.json`, {
    calendar_event_labels: { label_id: label.id },
  })
);

export const removeLabel = (calendarEventId, { label }) => (
  httpDelete(`${basePath(calendarEventId)}.json`, {
    calendar_event_labels: { label_id: label.id },
  })
);

export default {
  assignLabel,
  removeLabel,
};
