import {
  addMinutes,
  compareAsc,
  endOfDay,
  isBefore,
  min,
} from 'date-fns';
import _ from 'lodash';
// import { DateRange, MapCellInfoToDateRange } from '../types';
import cellToDate from './cellToDate';

// export type RecurringTimeRange = DateRange[];

const createMapCellInfoToRecurringTimeRange = ({
  fromY: toMin,
  fromX: toDay,
  originDate,
}) => ({
  startX,
  startY,
  endX,
  spanY,
}) => {
  const result = _.range(startX, endX + 1)
    .map((i) => {
      const startDate = cellToDate({
        startX: i,
        startY,
        toMin,
        toDay,
        originDate,
      });

      // const val1 = addMinutes(startDate, toMin(spanY));
      // const val2 = endOfDay(startDate);
      // const res1 = min([val1, val2]);
      // const res2 = _.min([val1, val2]);
      // console.log('BAR', { res1, res2, val1, val2 });

      const endDate = min([
        addMinutes(startDate, toMin(spanY)),
        endOfDay(startDate),
      ]);

      const range = isBefore(startDate, endDate)
        ? [startDate, endDate]
        : [endDate, startDate];

      return range;
    })
    .sort((range1, range2) => compareAsc(range1[0], range2[0]));

  return result;
};

export default createMapCellInfoToRecurringTimeRange;
