import React from 'react';

import classnames from 'classnames';
import Button from 'react-bootstrap/Button';

import { formatIsoDateTime } from '../../../utils/datetime';
import Money from '../../../utils/money';
import Icon from '../../Icon';

import RowWrapper from '../../ListingTable/Row';
import PriceValue from '../../expectations/PriceValue';
import SummaryCaption from './SummaryCaption';

const serialize = (e) => ({
  ...e,
  id: e._id,
  expected_on_date: e.date,
  
});

const HistoryRow = (props) => {
  const { item } = props;
  const { 
    reconciliation_user, 
    reconciled_at,
    planned_expectations,
    unreconciled_expectations,
    new_expectations,
  } = item;

  const plannedExpectations = planned_expectations.map(serialize);
  const unreconciledExpectations = unreconciled_expectations.map(serialize);
  const newExpectations = new_expectations.map(serialize);

  return (
    <RowWrapper {...props}>
      <td className="">
        <Icon name="calendar" fw />
        &nbsp;
        {formatIsoDateTime(reconciled_at)}
      </td>

      <td className="">
        <Icon name="user" fw />
        &nbsp;
        {reconciliation_user?.full_name}
      </td>

      <td className="">
        <SummaryCaption 
          unreconciledExpectations={unreconciledExpectations}
          plannedExpectations={plannedExpectations}
          newExpectations={newExpectations}
          compact
        />
      </td>
    </RowWrapper>
  );
};

export default HistoryRow;

