import React from 'react';
import PropTypes from 'prop-types';

const Label = ({ value, ...options }) => {
  if (value.length === 0) { return ''; }

  return (
    <label {...options}>
      {value}
    </label>
  );
};

Label.propTypes = {
  value: PropTypes.string,
};

Label.defaultProps = {
  value: '',
};

export default Label;
