import React from 'react';
import PropTypes from 'prop-types';
// import { debounce } from 'lodash';

const SearchBar = ({ onFilter }) => {
  const handleChange = (event) => {
    const text = event.target.value;

    onFilter({ text });
  };
  // const debouncedHandleChange = debounce(handleChange, 200);
  // const debouncedHandleChange = handleChange;

  return (
    <div className="row mb-3 mt-3 justify-content-center">
      <div className="col-12 col-md-10 align-self-center">
        <div className="input-group">
          <input
            type="text"
            placeholder="Search..."
            className="input-sm form-control"
            onChange={handleChange}
          />
          <span className="input-group-append">
            <button type="button" className="btn btn-sm btn-primary"> Search</button>
          </span>
        </div>
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  onFilter: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {

};

export default SearchBar;
