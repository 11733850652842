import React from 'react';

// import ExpectedExpensesEditButton from './ExpectedExpensesEditButton';
import ColorizedDeadline from '../../../ColorizedDeadline';
import PriceValue from '../../../expectations/PriceValue';
import Icon from '../../../Icon';

const RegisteredCostsListRow = ({ registeredCost }) => {
  const { headline, date, counterpart } = registeredCost;

  return (
    <tr className="row">
      <td className="text-right col-lg-3">
        <PriceValue expectation={registeredCost} />
      </td>

      <td className="col-lg-6">
        <div className="text-truncate" style={{ maxWidth: 150 }}>
          <h6 className="mb-0">
            {headline}
            <br />
            <small>{counterpart}</small>
          </h6>
        </div>
      </td>

      <td className="col-lg-3 text-center">
        <h5>
          <ColorizedDeadline 
            deadline={date}
          />
        </h5>
      </td>
    </tr>
  );
};

const RegisteredCostsList = ({ registeredCosts }) => (
  <table className="table table-striped table-sm mt-2">
    <tbody>
      {
        registeredCosts.map((cost) => (
          <RegisteredCostsListRow 
            key={cost.id}
            registeredCost={cost}
          />
        ))
      }
    </tbody>
  </table>
);

const RegisteredCostsElement = ({ 
  event, 
  countries, 
  accounts,
  currencies,
  registeredCosts,
}) => (
  <>
    <dt>
      <Icon name="euro-sign" fw />
      &nbsp;
      Registered costs
    </dt>
    <dd className="">
      {
        registeredCosts.length === 0 && (
          <table className="table table-striped table-sm mt-2">
            <tbody>
              <tr>
                <td>No registered costs</td>
              </tr>
            </tbody>
          </table>
        )
      }
      {
        registeredCosts.length > 0 && (
          <RegisteredCostsList registeredCosts={registeredCosts} />
        )
      }
    </dd>
  </>
);

export default RegisteredCostsElement;
