import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import BaseInput from '../../../../inputs/BaseInput';
import SelectLabelInput from '../SelectLabelInput';
import { isValidRepartition, decimal } from '../utils';
import Icon from '../../../../Icon';

const EntityFields = ({
  entity,
  labels,
  onChange,
  onRemove,
  validRepartitions,
  style,
}) => {
  const { entityId, category_id } = entity;
  const currentRepartition = entity.repartition;
  const availableLabels = [entity, ...labels];

  // State
  const [currentLabel, setCurrentLabel] = useState(entity);

  // Handlers
  const triggerChange = ({ label = currentLabel, repartition = currentRepartition }) => {
    const currentEntity = {
      ...label,
      repartition,
      entityId,
      category_id,
    };

    onChange(currentEntity);
  };

  const handleLabelChange = (inputLabel) => {
    setCurrentLabel(inputLabel);
    triggerChange({ label: inputLabel });
  };

  const handleRepartitionChange = (event) => {
    const { value } = event.target;
    const inputRepartition = decimal(value || 0);
    const repartition = decimal(inputRepartition / 100.0, 4);

    triggerChange({ repartition });
  };

  const handleRemove = () => {
    onRemove(entityId);
  };

  const inputRepartition = decimal(currentRepartition * 100.0);
  const validRepartition = isValidRepartition(inputRepartition);
  const repartitionClassName = classnames(
    'form-control form-control-lg',
    { 'is-invalid': !validRepartitions || !validRepartition },
  );
  // Rendering
  return (
    <Row className="my-0" style={style || {}}>
      <Col md={7}>
        <SelectLabelInput
          labels={availableLabels}
          onChange={handleLabelChange}
          categoryLabels={[entity]}
          includeBlank={false}
        />
      </Col>

      <Col md={3}>
        <BaseInput
          value={inputRepartition}
          type="number"
          onChange={handleRepartitionChange}
          min="0.1"
          max="100.0"
          className={repartitionClassName}
          step="1"
        />
      </Col>

      <Col md={2}>
        <Button variant="danger" size="lg" onClick={handleRemove}>
          <Icon name="trash" fw title="Remove" />
        </Button>
      </Col>
    </Row>
  );
};

EntityFields.propTypes = {
  entity: PropTypes.shape({
    entityId: PropTypes.string,
    category_id: PropTypes.string,
    repartition: PropTypes.number,
  }).isRequired,
  labels: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  validRepartitions: PropTypes.bool.isRequired,
};

export default EntityFields;
