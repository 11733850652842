// NOTE: DEPRECATED feature
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import SelectInput from '../inputs/SelectInput';
import Icon from '../Icon';
import handleError from '../../utils/error-handler';
import blApi from '../../api/financialAnalysis/businessLines';
import { classifiesOperation } from '../../api/financialAnalysis/classification';
import { success } from '../../utils/notifier';
import styles from './styles/OperationClassificationRow.module.scss';

let businessLinesCache;

const EditLabel = ({ businessLine, onClick }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <a href="#edit" onClick={handleClick} className={styles.EditLabel}>
      {
        businessLine
          ? businessLine.name
          : 'Uncategorized'
      }
      &nbsp;
      <Icon name="pencil-alt" fw className={styles.EditLabelIcon}/>
    </a>
  )
}

const BusinessLinesSelect = ({ onChange, businessLines, businessLine }) => {
  const id = businessLine ? businessLine.id : null

  return (
    <SelectInput
      options={businessLines}
      valueKey="_id"
      labelKey="name"
      initialValue={id}
      onChange={onChange}
      layout={false}
    />
  )
}

const BusinessLineInlineClassification = ({
  operationClassificationId,
  businessLine,
}) => {
  // State primitives
  const [isEditing, setIsEditing] = useState(false);
  const [businessLines, setBusinessLines] = useState([]);
  const [currentBusinessLine, setCurrentBusinessLine] = useState('initial');

  if (currentBusinessLine === 'initial') {
    setCurrentBusinessLine(businessLine);
  }

  const submitClassification = (businessLine) => {
    const businessLineId = businessLine ? businessLine.id : null;

    classifiesOperation({ operationClassificationId, businessLineId })
      .then((response) => response.json())
      .then((data) => { success({ message: data.message }); })
      .catch(handleError);
  };

  const loadBusinessLines = () => {
    if (businessLines.length > 0) { return; }

    if (!businessLinesCache) {
      blApi.businessLines()
        .then((response) => response.json())
        .then((data) => {
        businessLinesCache = data;
        setBusinessLines(data);
      }).catch(handleError);
    } else {
      setBusinessLines(businessLinesCache);
    }
  };

  const handleEditClick = () => {
    loadBusinessLines();
    setIsEditing(true);
  }

  const handleBusinessLineChange = (businessLine) => {
    setCurrentBusinessLine(businessLine);
    submitClassification(businessLine);
    setIsEditing(false);
  }

  return (
    <div className="BusinessLineInlineClassification">
      {
        isEditing
          ? <BusinessLinesSelect businessLines={businessLines}
                                 onChange={handleBusinessLineChange}
                                 businessLine={currentBusinessLine} />
          : <EditLabel onClick={handleEditClick}
                       businessLine={currentBusinessLine} />
      }
    </div>
  )
};

BusinessLineInlineClassification.propTypes = {
  operationClassificationId: PropTypes.string.isRequired
};

BusinessLineInlineClassification.defaultProps = {
  businessLine: null,
};

export default BusinessLineInlineClassification;
