import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Label from './Label';

const BaseInput = ({
  column,
  label,
  type,
  invalid,
  ...options
}) => (
  <div className={classnames(column, { 'form-group-invalid': invalid })}>
    {
      label ? (<Label value={label} />) : null
    }
    <input
      type={type}
      className={
        classnames('form-control form-control-lg', { 'is-invalid': invalid })
      }
      {...options}
    />
  </div>
);

BaseInput.propTypes = {
  column: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  invalid: PropTypes.bool,
};

BaseInput.defaultProps = {
  column: 'form-group',
  type: 'text',
  label: '',
  invalid: false,
};

export default BaseInput;
