import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import WithTrainerPrivateClasses from './WithTrainerPrivateClasses';
import Spinner from '../../Spinner';
import ListingTable from '../../ListingTable';
import Row from './PrivateClassRow';

const PrivateClassesPage = ({ trainer, loading, privateClasses }) => {
  const { full_name } = trainer;

  if (loading) {
    return (
      <div>
        <Spinner description={`Loading ${full_name} private classes...`} />
      </div>
    );
  }

  const headers = [
    'Date',
    'Status',
    'Customer',
    'Headline',
    'Trainers',
    'Venue',
    'Sold tickets',
  ];

  const sortableColumns = {
    Date: 'starts_on',
    Headline: 'headline',
    Trainers: 'trainers_name',
    Customer: 'customer',
    Status: 'status',
    Venue: 'city',
    'Sold tickets': 'tickets_sold',
  };

  return (
    <div className={classnames('trainer-public-events-page')}>
      <ListingTable
        items={privateClasses}
        filterKeys={[
          'headline', 'customer', 'city',
        ]}
        headers={headers}
        sortableColumns={sortableColumns}
        headerRowProps={{ className: 'text-nowrap' }}
        components={{ Row }}
      />
    </div>
  );
};

PrivateClassesPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  trainer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    full_name: PropTypes.string,
    email: PropTypes.string,
    public_page_url: PropTypes.string,
    picture_url: PropTypes.string,
  }).isRequired,
};

PrivateClassesPage.defaultProps = {
};

export default WithTrainerPrivateClasses(PrivateClassesPage);
