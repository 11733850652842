import React, { useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';

import TextInput from '../../RHF/TextInput';
import Localize from './Localize';
import Icon from '../../Icon';

import Context from './Context';
import SelectedWorkingTimes from './SelectedWorkingTimes';
import CTPInput from '../../RHF/CircularTimespanPickerInput';
import {
  weekDays,
  serializeSegments,
  interval,
  boundaryHour,
} from './utils';

// NOTE: prefillDays is currently deprecated
const WeeklyScheduleForm = () => {
  const {
    isLoading,
    updateWeeklySchedule,
    initialPrefillDays,
    initialWeeklySegments,
  } = useContext(Context);
  const {
    handleSubmit, control, watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      prefillDays: initialPrefillDays,
      ...initialWeeklySegments,
    },
  });

  const weeklySegments = {
    mon: watch('mon'),
    tue: watch('tue'),
    wed: watch('wed'),
    thu: watch('thu'),
    fri: watch('fri'),
    sat: watch('sat'),
    sun: watch('sun'),
  };

  const onSubmit = ({ prefillDays, ...rawSegments }) => {
    const parsedWWT = Object.keys(rawSegments).reduce((data, day) => ({
      ...data,
      [day]: serializeSegments(rawSegments[day], interval),
    }), {});

    updateWeeklySchedule({ prefillDays, weeklyWorkingTimes: parsedWWT });
  };

  // const handleDayChange = ({ dayName, segments, wwt }) => {
  //   const workingTimes = serializeSegments(segments, interval);

  //   const currentWWT = Object.keys(wwt).reduce((data, key) => {
  //     data[key] = [...weeklyWorkingTimes[key]];
  //     return data;
  //   }, {});

  //   const newWWT = ({
  //     ...currentWWT,
  //     [dayName]: [...workingTimes],
  //   });

  //   setWeeklyWorkingTimes(newWWT);
  // };

  /// const prefillDaysInput = (
  ///   <TextInput
  ///     name="prefillDays"
  ///     label="Calendar prefill days"
  ///     errors={errors}
  ///     control={control}
  ///     placeholder="e.g. 365"
  ///     required
  ///   />
  /// );

  return (
    <div className="WeeklyScheduleForm">
      <Form onSubmit={handleSubmit(onSubmit)} id="weekly-schedule-form">
        <div className="row">
          <div className="col-md-9">
            <h5>Weekly schedule</h5>

            <hr />

            <div className="row">
              {
                weekDays.map((dayName) => (
                  <div className="col-sm-6 col-md-6 col-xl-3" key={dayName}>
                    <h6>
                      <Localize label={`dayNames.${dayName}`} />
                    </h6>

                    <hr className="mb-0" />

                    <CTPInput
                      initialSegments={initialWeeklySegments[dayName]}
                      innerRadius={35}
                      outerRadius={85}
                      boundaryHour={boundaryHour}
                      interval={interval}
                      totalHours={12}
                      showResults={false}
                      name={dayName}
                      label={null}
                      errors={errors}
                      control={control}
                    />
                  </div>
                ))
              }
            </div>
          </div>

          <div className="col-md-3">
            <h5>Selected slots:</h5>

            <SelectedWorkingTimes
              weeklySegments={weeklySegments}
              interval={interval}
            />

            <hr />

            <Button
              type="submit"
              variant="success"
              size="lg"
              disabled={isLoading}
            >
              {
                isLoading
                  ? <span><Icon name="spinner" fw spin /> Loading...</span>
                  : <span><Icon name="check" fw /> Save changes</span>
              }
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

WeeklyScheduleForm.propTypes = {
};

export default WeeklyScheduleForm;
