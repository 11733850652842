import { patch } from '../base';

export const enablePrivateClassCertification = ({ privateClassId }) => (
  patch(`/trainings/private_classes/${privateClassId}/enable_certification.json`)
);

export const updateTrainers = ({ privateClassId, trainers }) => (
  patch(`/trainings/private_classes/${privateClassId}/update_trainers.json`, {
    private_class: { trainers },
  })
);

export default {
  enablePrivateClassCertification,
  updateTrainers,
};
