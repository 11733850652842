import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';

import Input from '../../RHF/Input';
import ColorInput from '../../RHF/ColorInput';
import { randomColor } from '../../../utils/colors';
import Icon from '../../Icon';
import { calendarEventShape } from '../proptypes';
import { updateLabel } from '../../../api/smart_planner/labels';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';

import FormFields from './FormFields';
import styles from './LabelsPicker.module.scss';

const AddLabelModalEditForm = ({
  onBack,
  onUpdate,
  onDelete,
  className,
  label,
  show,
  formId,
}) => {
  if (!show) { return null; }

  const [isLoading, setIsLoading] = useState(false);
  const { id, name, color } = label;
  const { handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: { color, name },
  });

  const enhancedOnSubmit = ({ name, color }) => {
    setIsLoading(true);

    updateLabel(id, { name, color })
      .then((response) => (response.json()))
      .then(({ message }) => {
        success({ message });
        setIsLoading(false);
        onBack();
        onUpdate({ ...label, name, color });
      })
      .catch(handleError);
  };

  return (
    <div
      className={classnames(
        styles.AddLabelModalForm, className,
        { 'd-none': !show },
      )}
    >
      <Form onSubmit={handleSubmit(enhancedOnSubmit)} id={formId}>
        <div className={styles.AddLabelModal__body}>
          <FormFields
            errors={errors}
            control={control}
            disabled={isLoading}
          />
        </div>

        <div className={styles.AddLabelModal__footer}>
          <Button
            type="submit"
            variant="primary"
            className="float-right"
            disabled={isLoading}
          >
            {
              isLoading
                ? (<Icon name="spinner" spin fw />)
                : (<Icon name="check" fw />)
            }
            &nbsp;
            Update
          </Button>

          <Button
            variant="secondary"
            onClick={() => { onBack(); }}
            disabled={isLoading}
          >
            <Icon name="angle-left" fw />
            &nbsp;
            Back
          </Button>
        </div>
      </Form>
    </div>
  );
};

AddLabelModalEditForm.propTypes = {
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  label: PropTypes.shape({}).isRequired,
  formId: PropTypes.string,
};

AddLabelModalEditForm.defaultProps = {
  formId: 'edit-label-form',
  onUpdate: () => {},
  onDelete: () => {},
};

export default AddLabelModalEditForm;
