// Setup Marker.io
import markerSDK from '@marker.io/browser';

let markerWidget;

export const initialize = async () => {
  markerWidget = await markerSDK.loadWidget({
    destination: '616005465698f5304a5f2970',
  });

  markerWidget.show();
};

export const destroy = async () => {
  if (markerWidget) {
    markerWidget.hide();
    markerWidget.unload();
  }
};

export default {
  initialize,
  destroy,
};
