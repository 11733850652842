import React from 'react';

import { updateRecipe } from '../../../api/smart_planner/recipes';
import handleError from '../../../utils/error-handler';
import { visit } from '../../../utils/router';
import { success } from '../../../utils/notifier';
import Form from './Form';
import Icon from '../../Icon';
import { recipeShape } from '../proptypes';
import { findOffsetOption } from '../utils';

const Edit = ({ recipe }) => {
  const decoratedRecipe = recipe;
  decoratedRecipe.steps = decoratedRecipe.steps.map(({ offset_type, ...step }) => ({
    ...step,
    offset_type, //: findOffsetOption(offset_type),
  }));

  const handleUpdate = (params) => {
    updateRecipe({
      ...params,
      recipeId: recipe.id,
    }).then((response) => response.json())
      .then(({ redirect_to, message }) => {
        visit(redirect_to).then(() => { success({ message }); });
      })
      .catch(handleError);
  };

  return (
    <div className="card">
      <div className="card-header bg-info">
        <h1 className="text-center">
          <Icon name="shapes" />
          &nbsp;
          Edit recipe
        </h1>
      </div>

      <div className="card-body">
        <div className="col-12 col-lg-10 mx-auto mt-4 mb-4">
          <Form
            onSubmit={handleUpdate}
            recipe={decoratedRecipe}
          />
        </div>
      </div>
    </div>
  );
};

Edit.propTypes = {
  recipe: recipeShape.isRequired,
};

export default Edit;
