import React from 'react';
import PropTypes from 'prop-types';

import MonthHeader from './MonthHeader';
import WeekHeader from './WeekHeader';

const Header = ({ weekStartedOn }) => (
  <>
    <MonthHeader
      month={weekStartedOn}
      dateFormat="MMMM yyyy"
    />

    <WeekHeader
      weekStartedOn={weekStartedOn}
    />
  </>
);

Header.propTypes = {
  weekStartedOn: PropTypes.instanceOf(Date).isRequired,
};

export default Header;
