import React, { useContext } from 'react';
import classnames from 'classnames';

import Context from './Context';
import SearchForm from './SearchForm';
import Results from './Results';

const formId = 'search-fad-form';

const Body = ({ className }) => {
  const {
    recipes,
    users,
    currentUserId,
    initialRecipe,
    isLoading,
    onSearch,
    initialSteps,
    currentFAD,
    currentFADIndex,
    onFormChange,
    isFormDirty,
    canSearch,
    opportunitySteps,
    hideRecipeInput,
    hideDesigner,
    showStepsList,
    showNewOpportunityCTA,
    showEditOpportunityCTA,
    preload,
    onPrevFad,
    onNextFad,
  } = useContext(Context);

  const columnsClassName = classnames({
    'col-lg-6': !hideDesigner,
    'col-lg-12': hideDesigner,
  });

  const height = hideDesigner ? 400 : 621;

  return (
    <div className={className}>
      <div className="row">
        <div className={columnsClassName}>
          <SearchForm
            onSearch={onSearch}
            recipes={recipes}
            users={users}
            currentUserId={currentUserId}
            initialRecipe={initialRecipe}
            isLoading={isLoading}
            formId={formId}
            onChange={onFormChange}
            canSearch={canSearch}
            hideRecipeInput={hideRecipeInput}
            hideDesigner={hideDesigner}
            showStepsList={showStepsList}
            showNewOpportunityCTA={showNewOpportunityCTA}
            showEditOpportunityCTA={showEditOpportunityCTA}
            preload={preload}
          />
        </div>

        <div className={columnsClassName}>
          <Results
            formId={formId}
            currentFAD={currentFAD}
            currentFADIndex={currentFADIndex}
            isLoading={isLoading}
            canSearch={canSearch}
            hideDesigner={hideDesigner}
            height={height}
            onPrevFad={onPrevFad}
            onNextFad={onNextFad}
          />
        </div>
      </div>
    </div>
  );
};

Body.propTypes = {};

export default Body;
