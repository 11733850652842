import React from 'react';
import PropTypes from 'prop-types';
import ExtendModal from './ExtendRecurringModal';
import Icon from '../Icon';
import { generateUrl } from './commons';

class ExtendRecurringButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayModal: false,
    };
  }

  handleClick(e) {
    e.preventDefault();

    this.setState({ displayModal: true });
  }

  render() {
    const {
      id,
      direction,
      acl,
      headline,
      expectedAmount,
      className,
      label,
      title,
    } = this.props;

    if (!acl.canExtendRecurring) { return ''; }

    const { displayModal } = this.state;
    const url = generateUrl({ id, direction, action: 'extend-recurring' });

    return (
      <span>
        <a
          href={url}
          className={className}
          data-toggle="tooltip"
          data-placement="top"
          title={title}
          data-original-title={title}
          onClick={(e) => { this.handleClick(e); }}
        >
          <Icon name="external-link-square-alt" fw /> {label}
        </a>

        <ExtendModal
          expectationId={id}
          headline={headline}
          show={displayModal}
          onHide={() => { this.setState({ displayModal: false }); }}
          direction={direction}
          expectedAmount={expectedAmount}
        />
      </span>
    );
  }
}

ExtendRecurringButton.propTypes = {
  id: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  expectedAmount: PropTypes.object,
  acl: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
};

ExtendRecurringButton.defaultProps = {
  acl: {},
  className: 'btn btn-success',
  label: 'Extend',
  title: 'Extend recurring expectation'
};

export default ExtendRecurringButton;
