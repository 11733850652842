import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import { accountShape } from './utils';
import Row from '../../ListingTable/Row';
import Icon from '../../Icon';

import { showPath } from '../../../routes/smart_planner/accounts';

const TimesIcon = () => (<Icon name="times" fw />);
const LoadingIcon = () => (<Icon name="spinner" family="fas" spin fw />);
const ActionsCell = ({ item, onDelete, isDeleting }) => {
  const { id } = item;
  const href = showPath(id);
  const handleDelete = (event) => {
    event.preventDefault();

    onDelete(item);
  };

  return (
    <td className="h5">
      <div className="btn-group">
        <Button size="sm" variant="secondary" href={href} title="Details" disabled={isDeleting}>
          <Icon name="tools" fw />
        </Button>

        <Button size="sm" variant="danger" title="Delete" onClick={handleDelete} disabled={isDeleting}>
          {
            isDeleting
              ? <LoadingIcon />
              : <TimesIcon />
          }
        </Button>
      </div>
    </td>
  );
};

ActionsCell.propTypes = {
  item: accountShape.isRequired,
};

const AccountRow = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { item, onDelete } = props;
  const {
    id,
    label,
    description,
    provider,
  } = item;

  const href = isDeleting ? '#deleted' : showPath(id);
  const handleDelete = (account) => {
    setIsDeleting(true);
    onDelete(account);
  };

  return (
    <Row {...props}>
      <td className="h6">
        <a href={href} disabled={isDeleting}>
          <Icon name="google" family="fab" fw />
          &nbsp;
          {label}
        </a>
      </td>

      <td className="text-uppercase">
        {provider}
      </td>

      <td className="text-truncate">
        {description}
        ...
      </td>

      <ActionsCell
        item={item}
        onDelete={handleDelete}
        isDeleting={isDeleting}
      />
    </Row>
  );
};

AccountRow.propTypes = {
  item: accountShape.isRequired,
  onDelete: PropTypes.func
};

AccountRow.defaultProps = {
  onDelete: () => {},
};

export default AccountRow;
