import { addDays, addMinutes } from 'date-fns';

const cellToDate = ({
  startX,
  startY,
  toMin,
  originDate,
}) => addMinutes(addDays(originDate, startX), toMin(startY));

export default cellToDate;
