// Behavior required from the components/inputs/SelectInput

import $ from 'jquery';

const terminationFieldsDataName = 'data-js-expectation-form-termination-fields';

export const destroy = (props, state) => {
  // NOOP
};

export const onChange = (props, { selectedOption }) => {
  $(`[${terminationFieldsDataName}]`).addClass('d-none');

  if (selectedOption) {
    const value = selectedOption.value ? selectedOption.value : selectedOption;
    $(`[${terminationFieldsDataName}="${value}"]`).removeClass('d-none');
  }
};

export const initialize = (props, state) => {
  onChange(props, state);
};
