import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  format,
  addDays,
} from 'date-fns';

import styles from './WeekViewCalendar.module.scss';

const WeekHeader = ({ weekStartedOn }) => {
  const dateFormat = 'EEE';
  const days = [];

  for (let i = 0; i < 7; i += 1) {
    const weekDay = (
      <div className={classnames(styles.col, styles.colCenter)} key={i}>
        <strong>
          {format(addDays(weekStartedOn, i), dateFormat)}
        </strong>
      </div>
    );

    days.push(weekDay);
  }

  return (
    <div className={classnames(styles.weekDaysRow, styles.days)}>
      {days}

      <div className={classnames(styles.colLg, styles.colCenter)}>
        <strong>DETAILS</strong>
      </div>
    </div>
  );
};

WeekHeader.propTypes = {
  weekStartedOn: PropTypes.instanceOf(Date).isRequired,
};

export default WeekHeader;
