import React from 'react';
import PropTypes from 'prop-types';
import ClassifyDocumentModal from './ClassifyDocumentModal';
import Icon from '../Icon';
import { generateUrl } from './commons';

class ClassifyDocumentButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayModal: false,
    };
  }

  handleClick(e) {
    e.preventDefault();

    this.setState({ displayModal: true });
  }

  render() {
    const {
      documentId,
      progressiveNumber,
      businessLineId,
      issuedOn,
      headline,
      acl,
      className,
      caption
    } = this.props;

    // if (!acl.canClassifyDocument) { return ''; }

    const { displayModal } = this.state;
    const url = generateUrl({ documentId, action: 'classfy' });
    const title = `Classify document Nº ${progressiveNumber}`;

    return (
      <span>
        <a
          href={url}
          className={className}
          data-toggle="tooltip"
          data-placement="top"
          title={title}
          data-original-title={title}
          onClick={(e) => { this.handleClick(e); }}
        >
          <Icon name="tag" />
        </a>

        <ClassifyDocumentModal
          documentId={documentId}
          progressiveNumber={progressiveNumber}
          businessLineId={businessLineId}
          headline={headline}
          issuedOn={issuedOn}
          show={displayModal}
          onHide={() => { this.setState({ displayModal: false }); }}
        />
      </span>
    );
  }
}

ClassifyDocumentButton.propTypes = {
  documentId: PropTypes.string.isRequired,
  businessLineId: PropTypes.string,
  className: PropTypes.string,
  caption: PropTypes.string,
  progressiveNumber: PropTypes.string.isRequired,
  acl: PropTypes.object,
};

ClassifyDocumentButton.defaultProps = {
  acl: {},
  className: 'btn btn-warning',
  caption: 'Classify',
  businessLineId: null,
};

export default ClassifyDocumentButton;
