import React from 'react';
import PropTypes from 'prop-types';
import ColorPicker from 'react-input-color';

export const defaultColor = '#2b7bb9';

class ColorInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value || props.initialValue };
  }

  handleChange(color) {
    const { onChange } = this.props;
    const value = color.hex;

    this.setState({ value });
    onChange(value);
  }

  render() {
    const { value } = this.state;
    const { name } = this.props;

    return (
      <div className="ColorInput">
        <input name={name} value={value} type="hidden" />
        <ColorPicker
          className="ColorInput__picker"
          initialValue={value}
          onChange={(color) => { this.handleChange(color); }}
        />
        <span className="ColorInput__value">
          {value}
        </span>
      </div>
    );
  }
}

ColorInput.propTypes = {
  initialValue: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

ColorInput.defaultProps = {
  initialValue: defaultColor,
  name: 'color',
  onChange: () => {},
};

export default ColorInput;
