import React, {
  useState, createContext, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import {
  fetchUserAccounts,
  deleteUserAccount,
} from '../../../api/smart_planner/user_accounts';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';
import EventBus from '../../../packs/event_bus';

const Context = createContext({});

const events = [
  'SmartPlanner::Accounts::GoogleAccoutAdded',
  'SmartPlanner::Accounts::AccountCalendarsSet',
  'SmartPlanner::Accounts::AccountDeleted',
];

export const Provider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [userAccounts, setUserAccounts] = useState([]);
  const loadUserAccounts = () => {
    fetchUserAccounts()
      .then((response) => (response.json()))
      .then(({ data }) => {
        const entries = data.map(({ attributes }) => (attributes));

        setUserAccounts(entries);
        setLoading(false);
      })
      .catch(handleError);
  };

  const handleAccountDeletion = (account) => {
    deleteUserAccount(account.id)
      .then((response) => (response.json()))
      .then((data) => { success({ message: data.message }); })
      .catch(handleError);
  };

  // On mount
  useEffect(() => {
    let mounted = true;
    if (mounted) { loadUserAccounts(); }

    return () => { mounted = false; };
  }, []);

  // Event BUS subscription
  useEffect(() => {
    const handleEvent = () => { loadUserAccounts(); };

    const eventBusSubscription = EventBus.subscribe(
      events,
      debounce(handleEvent, 500, { leading: true }),
      this,
    );

    return () => { EventBus.unsubscribe(eventBusSubscription); };
  });

  // The shared state
  const value = useMemo(
    () => ({
      loading,
      userAccounts,
      deleteAccount: handleAccountDeletion,
    }),
    [loading, userAccounts],
  );

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Provider.defaultProps = {};

export default Context;
