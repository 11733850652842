import React, {
  useState,
  createContext,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  getWeek,
  getYear,
  addWeeks,
  startOfWeek,
  endOfWeek,
  parseISO,
} from 'date-fns';

import {
  fetchCalendarEvent,
  searchCalendarEvents,
} from '../../../api/smart_planner/calendar_events';

import { assignLabel, removeLabel } from '../../../api/smart_planner/calendar_event_labels';
import { fetchLabels } from '../../../api/smart_planner/labels';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';
import EventBus from '../../../packs/event_bus';
import sleep from '../../../utils/sleep';

const Context = createContext({});

// const events = [
//   'SmartPlanner::Accounts::GoogleAccoutAdded',
//   'SmartPlanner::Accounts::AccountCalendarsSet',
// ];

const calendarEventUpdateEvents = [
  'SmartPlanner::Events::EventAddedFromProvider',
  'SmartPlanner::Events::EventUpdatedFromProvider',
];

const calendarEventUpdatedPriorityEvents = [
  'SmartPlanner::Events::EventPrioritySet',
];

const calendarEventCancelEvents = [
  'SmartPlanner::Events::EventCancelled',
  'SmartPlanner::Events::EventCancelledFromProvider',
  'SmartPlanner::Events::InconsistentChildEventCancelled',
];

export const initialViewMode = 'compact';
export const initialSortingMode = 'duration';
export const initialPriorityFilter = 0;
export const initialWeeksNumber = 10;
export const weekStartsOn = 0; // Start weeks on sunday

const weeksDatumIdFromDate = (date) => {
  const weekEndedOn = endOfWeek(date, { weekStartsOn });
  const endYear = getYear(weekEndedOn);
  const year = endYear;
  const weekNumber = getWeek(date);

  return [
    year.toString(),
    weekNumber.toString().padStart(2, 0),
  ].join('');
};

const buildInitialWeeksDatum = (date) => {
  const weekStartedOn = startOfWeek(date, { weekStartsOn });
  const weekEndedOn = endOfWeek(date, { weekStartsOn });
  const startYear = getYear(weekStartedOn);
  const endYear = getYear(weekEndedOn);
  const year = endYear;
  const weekNumber = getWeek(date);
  const id = weeksDatumIdFromDate(date);

  return {
    id,
    isLoading: true,
    events: [],
    year,
    startYear,
    endYear,
    weekNumber,
    weekStartedOn,
    weekEndedOn,
  };
};

const buildInitialWeeksData = (fromDate) => {
  const initialWeeksData = {};
  let startWeek = startOfWeek(fromDate, { weekStartsOn: 0 });
  let datum;

  for (let i = 0; i < initialWeeksNumber; i += 1) {
    datum = buildInitialWeeksDatum(startWeek);
    initialWeeksData[datum.id] = datum;

    startWeek = addWeeks(startWeek, 1);
  }

  return initialWeeksData;
};

export const Provider = ({ children, ...props }) => {
  const { i18n, googleMapsApiKey, user } = props;
  const fromDate = new Date();
  const initialWeeksData = buildInitialWeeksData(fromDate);
  const [weeksData, setWeeksData] = useState(initialWeeksData);
  const [viewMode, setViewMode] = useState(initialViewMode);
  const [sortingMode, setSortingMode] = useState(initialSortingMode);
  const [priorityFilter, setPriorityFilter] = useState(initialPriorityFilter);
  const [labels, setLabels] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const flattenEvents = _.uniqBy(
    Object.values(weeksData).flatMap(({ events }) => (events)),
    ({ id }) => (id),
  );
  const flattenEventIds = flattenEvents.map(({ id }) => (id));
  // const weekIds = Object.keys(weeksData);

  const findEvent = (id) => (flattenEvents.find((event) => (id === event.id)));
  const selectedEvent = selectedEventId
    ? flattenEvents.find(({ id }) => (selectedEventId === id))
    : null;

  const setSelectedEvent = (event) => {
    setSelectedEventId(event ? event.id : null);
  };
  const setWeeksDatum = (weekDatum) => {
    setWeeksData((state) => ({
      ...state,
      [weekDatum.id]: {
        ...weekDatum,
      },
    }));
  };
  const updateWeekDatum = (weekDatumId, callback) => {
    setWeeksData((state) => {
      const oldWeekDatum = state[weekDatumId];

      return {
        ...state,
        [weekDatumId]: callback(oldWeekDatum),
      };
    });
  };

  const loadWeekEvents = (weekDatum) => {
    const { weekStartedOn, weekEndedOn } = weekDatum;

    searchCalendarEvents({ from: weekStartedOn, to: weekEndedOn })
      .then((response) => (response.json()))
      .then(({ data }) => {
        const events = data.map(({ attributes }) => (attributes));
        setWeeksDatum({ ...weekDatum, isLoading: false, events });
      })
      .catch(handleError);
  };

  const updateEventWithAttributes = (id, updatedEventAttributes) => {
    const oldEvent = findEvent(id) || {};
    const updatedEvent = { ...oldEvent, ...updatedEventAttributes };
    const { start_on, end_on } = updatedEvent;
    const startOn = parseISO(start_on);
    const endOn = parseISO(end_on);
    const startWeekId = parseInt(weeksDatumIdFromDate(startOn), 10);
    const endWeekId = parseInt(weeksDatumIdFromDate(endOn), 10);

    for (let currWeekId = startWeekId; currWeekId <= endWeekId; currWeekId += 1) {
      // NOTE: a multiweeks calendar event could be updated and we still not
      // have a preloaded week
      // if (_.includes(weekIds, currWeekId)) {
      updateWeekDatum(currWeekId, (oldWeekDatum) => {
        const { events } = oldWeekDatum;
        const updatedEvents = events.map((origEvent) => {
          if (origEvent.id === id) { return updatedEvent; }

          return origEvent;
        });
        const updatedWeekDatum = {
          ...oldWeekDatum,
          events: updatedEvents,
        };

        return updatedWeekDatum;
      });
      // }
    }
  };

  const reloadCalendarEvent = (eventId) => {
    fetchCalendarEvent(eventId)
      .then((response) => (response.json()))
      .then(({ data }) => {
        const { id, attributes } = data;
        updateEventWithAttributes(id, attributes);
      })
      .catch(handleError);
  };

  // On mount
  useEffect(() => {
    let mounted = true;

    Object.values(initialWeeksData).forEach((weekDatum, idx) => {
      sleep(idx * 300).then(() => {
        if (mounted) {
          loadWeekEvents(weekDatum);
        }
      });
    });

    if (mounted) {
      fetchLabels()
        .then((response) => (response.json()))
        .then(({ data }) => {
          const remoteLabels = data.map(({ attributes }) => (attributes));
          setLabels(remoteLabels);
        })
        .catch(handleError);
    }

    return () => { mounted = false; };
  }, []);

  // On weeksData change
  useEffect(() => {
    const handleUpdateCalendarEvent = (event) => {
      const { smart_planner_event_id } = event;

      if (_.includes(flattenEventIds, smart_planner_event_id)) {
        // Wait for projectors..
        sleep(500).then(() => {
          reloadCalendarEvent(smart_planner_event_id);
        });
      }
    };

    const handleDeleteCalendarEvent = (event) => {
      const { smart_planner_event_id } = event;

      Object.keys(weeksData).forEach((key) => {
        const datum = weeksData[key];
        const eventIds = datum.events.map(({ id }) => (id));

        if (_.includes(eventIds, smart_planner_event_id)) {
          const newDatumEvents = datum.events.filter(({ id }) => (id !== smart_planner_event_id));

          setWeeksData((data) => ({
            ...data,
            [key]: {
              ...datum,
              events: newDatumEvents,
            },
          }));
        }
      });
    };

    const handleUpdatedPriorityCalendarEvent = (event) => {
      const { smart_planner_event_id, priority } = event;

      if (_.includes(flattenEventIds, smart_planner_event_id)) {
        updateEventWithAttributes(smart_planner_event_id, { priority });
        handleUpdateCalendarEvent(event);
      }
    };

    const updateEventsBusSubscription = EventBus.subscribe(
      calendarEventUpdateEvents,
      handleUpdateCalendarEvent,
      this,
    );

    const deleteEventsBusSubscription = EventBus.subscribe(
      calendarEventCancelEvents,
      handleDeleteCalendarEvent,
      this,
    );

    const updatedPriorityEventsBusSubscription = EventBus.subscribe(
      calendarEventUpdatedPriorityEvents,
      handleUpdatedPriorityCalendarEvent,
      this,
    );

    return () => {
      EventBus.unsubscribe(updateEventsBusSubscription);
      EventBus.unsubscribe(deleteEventsBusSubscription);
      EventBus.unsubscribe(updatedPriorityEventsBusSubscription);
    };
  }, [weeksData]);

  const loadNextCalendarWeek = () => {
    const lastKey = _.max(Object.keys(weeksData));
    const lastWeekDatum = weeksData[lastKey];
    const lastWeekStartedOn = lastWeekDatum.weekStartedOn;
    const nextWeekStartedOn = addWeeks(lastWeekStartedOn, 1);
    const nextWeekDatum = buildInitialWeeksDatum(nextWeekStartedOn);

    setWeeksDatum(nextWeekDatum);
    loadWeekEvents(nextWeekDatum);
  };

  const resetSelectedEvent = () => { setSelectedEvent(null); };

  const sortEvents = (events) => {
    switch (sortingMode) {
      case 'date':
        // The events should be sorted by default by datetime
        return events;
      case 'duration':
        return events.sort((a, b) => (b.duration - a.duration));
      case 'priority':
        return events.sort((a, b) => (b.priority - a.priority));
      default:
        console.error(`Unhandled sorting mode ${sortingMode}!`);
        return events;
    }
  };

  const eventPrioryFilter = (event) => (event.priority >= priorityFilter);
  const filterEvent = (event) => eventPrioryFilter(event);
  const filterEvents = (events) => (events.filter(filterEvent));
  const handleLabelCreate = (newLabel) => {
    setLabels((currLabels) => ([newLabel, ...currLabels]));
  };
  const handleLabelUpdate = (updatedLabel) => {
    setLabels((currLabels) => (
      currLabels.map((label) => (
        label.id === updatedLabel.id
          ? updatedLabel
          : label
      ))
    ));
  };
  const handleLabelDelete = (deletedLabel) => {
    setLabels((currLabels) => (
      currLabels.filter((label) => (label.id !== deletedLabel.id))
    ));
  };
  const handleLabelToggle = ({ label, event }) => {
    let label_ids;
    let api;

    if (_.includes(event.label_ids, label.id)) {
      label_ids = event.label_ids.filter((id) => (id !== label.id));
      api = removeLabel;
    } else {
      label_ids = [...event.label_ids, label.id];
      api = assignLabel;
    }

    updateEventWithAttributes(event.id, { label_ids });
    api(event.id, { label })
      .then((response) => (response.json()))
      .then(({ message }) => { success({ message }); })
      .catch(handleError);
  };

  const context = {
    i18n,
    user,
    googleMapsApiKey,
    loadNextCalendarWeek,
    fromDate,
    weeksData: Object.values(weeksData),
    selectedEvent,
    setSelectedEvent,
    resetSelectedEvent,
    setViewMode,
    viewMode,
    setSortingMode,
    sortingMode,
    sortEvents,
    priorityFilter,
    setPriorityFilter,
    filterEvent,
    filterEvents,
    onEventUpdate: updateEventWithAttributes,
    labels,
    onLabelToggle: handleLabelToggle,
    onLabelCreate: handleLabelCreate,
    onLabelUpdate: handleLabelUpdate,
    onLabelDelete: handleLabelDelete,
  };

  // The shared state
  const value = useMemo(
    () => (context),
    [
      weeksData,
      selectedEvent,
      viewMode,
      sortingMode,
      priorityFilter,
      labels,
    ],
  );

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  googleMapsApiKey: PropTypes.string,
  i18n: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Provider.defaultProps = {
  googleMapsApiKey: '',
};

export default Context;
