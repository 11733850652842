import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Icon from '../../Icon';
import { calendarEventShape } from '../proptypes';
import AddLabelModal from './AddLabelModal';

import styles from './LabelsPicker.module.scss';

const AddLabel = ({
  className,
  title,
  onClick,
  onLabelCreate,
  onLabelUpdate,
  onLabelDelete,
  onLabelToggle,
  labels,
  event,
  user,
}) => {
  const [showModal, setShowModal] = useState(false);
  const handleAddClick = () => { setShowModal((val) => (!val)); };
  const handleCloseModal = () => { setShowModal(false); };

  return (
    <div className={styles.AddLabel}>
      <OverlayTrigger
        placement="bottom"
        overlay={(
          <Tooltip>
            {title}
          </Tooltip>
        )}
      >
        <a
          title={title}
          className={classnames(className, styles.Label)}
          onClick={handleAddClick}
        >
          <Icon name="plus" className={styles.LabelIcon} fw />
        </a>
      </OverlayTrigger>

      <AddLabelModal
        show={showModal}
        onClose={handleCloseModal}
        onLabelDelete={onLabelDelete}
        onLabelCreate={onLabelCreate}
        onLabelUpdate={onLabelUpdate}
        onLabelToggle={onLabelToggle}
        labels={labels}
        event={event}
        user={user}
      />
    </div>
  );
};

AddLabel.propTypes = {
  onClick: PropTypes.func,
  onCreate: PropTypes.func,
  title: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.shape({})),
};

AddLabel.defaultProps = {
  onClick: () => {},
  onCreate: () => {},
  title: 'Add label',
  labels: [],
};

export default AddLabel;
