import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SelectableColumn from './SelectableColumn';

const Row = ({
  className,
  children,
  selectable,
  selected,
  id,
  onSelection,
  onRowClick,
  item,
  trProps,
}) => (
  <tr
    {...trProps}
    className={classnames('listing-table-row', className)}
    onClick={() => { onRowClick(item); }}
  >
    <SelectableColumn
      selectable={selectable}
      selected={selected}
      item={id}
      id={id}
      onChange={(i) => { onSelection(i); }}
    />
    {children}
  </tr>
);

Row.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.shape({}).isRequired,
  trProps: PropTypes.shape({}),
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  onSelection: PropTypes.func,
  onRowClick: PropTypes.func,
};

Row.defaultProps = {
  className: '',
  selectable: false,
  selected: false,
  onSelection: () => {},
  onRowClick: () => {},
  trProps: {},
};

export default Row;
