import { get, httpDelete, fetchPaginatedResources } from '../base';

const basePath = '/trainers/trainers';

export const fetchTrainers = () => (
  get(`${basePath}.json`)
);

export const searchTrainers = ({ query, limit = 50 }) => (
  get(`${basePath}.json?q=${query}&limit=${limit}`)
);

export const deleteTrainer = ({ trainerId }) => (
  httpDelete(`${basePath}/${trainerId}.json`)
);

export const trainerPublicEvents = ({ trainerId }) => (
  get(`${basePath}/${trainerId}/public_events.json`)
);

export const trainerPrivateClasses = ({ trainerId }) => (
  get(`${basePath}/${trainerId}/private_classes.json`)
);

export const paginatedTrainerPlacedTickets = ({ page, trainerId, ...options }) => (
  get(`${basePath}/${trainerId}/placed_tickets.json?page=${page || 1}&per_page=${options.perPage}`)
);

export const trainerPlacedTickets = ({ trainerId, ...options }) => {
  const fetchApi = (args = {}) => (paginatedTrainerPlacedTickets({
    ...args,
    trainerId,
    perPage: 200,
  }));

  return fetchPaginatedResources({ ...options, fetchApi });
};

export default {
  fetchTrainers,
  searchTrainers,
  deleteTrainer,
  trainerPublicEvents,
  trainerPrivateClasses,
  paginatedTrainerPlacedTickets,
  trainerPlacedTickets,
};
