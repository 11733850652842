import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import Input from '../../RHF/Input';
import ColorInput from '../../RHF/ColorInput';
import Icon from '../../Icon';
import { randomColor } from '../../../utils/colors';
import { recipeShape } from '../proptypes';
import Footprint from '../Footprint';

const serializeStep = ({ offset_type, step_type, ...attrs }) => ({
  ...attrs,
  offset_type: step_type === 'offset' ? offset_type.value : null,
  step_type,
});

const RecipeForm = ({
  recipe,
  formId,
  onSubmit,
  showSubmit,
}) => {
  const {
    name,
    description,
    color,
  } = recipe;
  const initialSteps = recipe.steps;
  const [currentSteps, setCurrentSteps] = useState(initialSteps);
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      name,
      description,
      color: color || `#${randomColor()}`,
    },
  });

  const enhancedOnSubmit = (params) => {
    setIsLoading(true);
    const formParams = {
      ...params,
      steps: currentSteps.map(serializeStep),
    };

    onSubmit(formParams);
  };

  const handleFootprintChange = (steps) => {
    setCurrentSteps(steps);
  };

  return (
    <div className="RecipeForm">
      <Form onSubmit={handleSubmit(enhancedOnSubmit)} id={formId}>
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-8">
                <Input
                  label="Name"
                  placeholder="The Recipe name"
                  name="name"
                  errors={errors}
                  control={control}
                  size="lg"
                  required
                />
              </div>

              <div className="col-4">
                <ColorInput
                  name="color"
                  errors={errors}
                  control={control}
                  label="Color identifier"
                />
              </div>
            </div>

            <Input
              label="Description"
              placeholder="The Recipe description..."
              name="description"
              errors={errors}
              control={control}
              size="lg"
              as="textarea"
              rows={8}
            />
          </div>

          <div className="col-md-6">
            <Footprint
              height={360}
              onChange={handleFootprintChange}
              initialSteps={initialSteps}
            />
          </div>
        </div>
        {
          showSubmit && (
            <>
              <hr />

              <div className="text-center mt-3">
                <Button
                  type="submit"
                  form={formId}
                  variant="success"
                  disabled={isLoading}
                  size="lg"
                >
                  <Icon name="check" fw />
                  &nbsp;
                  { isLoading ? 'Loading...' : 'Save Recipe' }
                </Button>
              </div>
            </>
          )
        }
      </Form>
    </div>
  );
};

RecipeForm.propTypes = {
  recipe: recipeShape,
  formId: PropTypes.string,
  onSubmit: PropTypes.func,
  showSubmit: PropTypes.bool,
};

RecipeForm.defaultProps = {
  recipe: {},
  formId: 'RecipeForm',
  onSubmit: () => {},
  showSubmit: true,
};

export default RecipeForm;
