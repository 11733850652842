import React from 'react';
import PropTypes from 'prop-types';
import Money from '../../utils/money';
import Cable from '../../channels/FinancialAnalysis';
import { accountTransactions } from '../../api/bankingAccounts';

class ReactiveValue extends React.Component {
  constructor(props) {
    super(props);

    const zeroParams = { cents: 0, currencyIso: 'EUR' };

    this.state = {
      value: props.initialValue || zeroParams,
    };
  }

  componentDidMount() {
    this.cableSubscriptionId = Cable.subscribe(
      'account_mirrors_view:updated',
      this.handleAccountUpdate,
      this,
    );
  }

  componentWillUnmount() {
    Cable.unsubscribe(this.cableSubscriptionId);
  }

  handleAccountUpdate({ id }) {
    const { accountId } = this.props;
    if (id !== accountId) {
      return;
    }

    accountTransactions({ id })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ value: response.banking_account.current_balance });
      });
  }

  render() {
    const { value } = this.state;
    const formattedValue = Money(value).toFormat();

    return (
      <span>
        {formattedValue}
      </span>
    );
  }
}

ReactiveValue.propTypes = {
  accountId: PropTypes.string.isRequired,
  initialValue: PropTypes.object,
};

ReactiveValue.defaultProps = {
  initialValue: { cents: 0, currencyIso: 'EUR' },
};

export default ReactiveValue;
