import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Segment = (props) => {
  const {
    item, segmentArcFn, minutesArcFn, label, fill,
    value, handleClick, isActive,
  } = props;

  const content = label === 60
    ? null
    : (
      <text
        className="minuteLabel"
        transform={`translate(${minutesArcFn.centroid(item)})`}
        dy=".35em"
      >
        {label}
      </text>
    )

  return (
    <g
      className={classnames('segment', { active: isActive })}
      onClick={() => { handleClick(value); }}
      onMouseDown={() => { handleClick(value, true); }}
    >
      <path
        d={segmentArcFn(item)}
        fill={fill}
        onMouseLeave={() => { handleClick(value, true); }}
        onDragLeave={() => { handleClick(value, true); }}
        onMouseDown={() => { handleClick(value, true); }}
      />

      {content}
    </g>
  );
};

export default Segment;
