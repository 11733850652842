import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Slider from 'rc-slider';
import { debounce } from 'lodash';

import { priorityMarks } from '../utils';
import Icon from '../../Icon';

import 'rc-slider/assets/index.css';

const CalendarToolbar = ({
  initialPriority,
  onPriorityChange,
  initialSortingMode,
  onSortingModeChange,
  initialViewMode,
  onViewModeChange,
}) => {
  const [viewMode, setViewMode] = useState(initialViewMode);
  const handleViewModeChange = (value) => {
    onViewModeChange(value);
    setViewMode(value);
  };
  const [sortingMode, setSortingMode] = useState(initialSortingMode);
  const handleSortingModeChange = (value) => {
    onSortingModeChange(value);
    setSortingMode(value);
  };
  const handlePriorityChange = useMemo(() => debounce(onPriorityChange, 500), []);

  return (
    <div className="card CalendarToolbar">
      <div className="card-body">
        <div className="row">
          <div className="col-md-4">
            <ToggleButtonGroup type="radio" name="view" value={viewMode} onChange={handleViewModeChange}>
              <ToggleButton value="compact" variant="light">
                <Icon name="calendar-week" fw />
                &nbsp;
                Compact view
              </ToggleButton>

              <ToggleButton value="list" variant="light">
                <Icon name="calendar" fw />
                &nbsp;
                List view
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div className="col-md-4 text-center">
            <ToggleButtonGroup type="radio" name="duplicates" value="all" onChange={console.log}>
              <ToggleButton value="all" variant="light">
                <Icon name="map-marker-alt" fw />
                &nbsp;
                Show all events
              </ToggleButton>

              <ToggleButton value="hide" variant="light" disabled>
                <Icon name="list" fw />
                &nbsp;
                Hide Duplicates
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div className="col-md-4 text-right">
            Sort by:
            &nbsp;
            <ToggleButtonGroup type="radio" name="sort" value={sortingMode} onChange={handleSortingModeChange}>
              <ToggleButton value="priority" variant="light">
                <Icon name="list" fw />
                &nbsp;
                Priority
              </ToggleButton>

              <ToggleButton value="date" variant="light">
                <Icon name="map-marker-alt" fw />
                &nbsp;
                Date
              </ToggleButton>

              <ToggleButton value="duration" variant="light">
                <Icon name="globe-africa" fw />
                &nbsp;
                Duration
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div className="col-md-10 offset-md-1 my-4">
            <Slider
              defaultValue={initialPriority}
              min={0}
              max={100}
              onChange={(value) => { handlePriorityChange(value); }}
              marks={priorityMarks}
            />
          </div>
        </div>

      </div>
    </div>
  );
};

CalendarToolbar.propTypes = {
  initialSortingMode: PropTypes.string,
  initialPriority: PropTypes.number,
  onPriorityChange: PropTypes.func,
  onSortingModeChange: PropTypes.func,
  initialViewMode: PropTypes.string,
  onViewModeChange: PropTypes.func,
};

CalendarToolbar.defaultProps = {
  initialSortingMode: 'priority',
  onSortingModeChange: () => {},
  initialPriority: 0,
  onPriorityChange: () => {},
  initialViewMode: 'compact',
  onViewModeChange: () => {},
};

export default CalendarToolbar;
