import { withFormat } from '../utils';

const basePath = '/users';

export const indexPath = () => (basePath);

export const newPath = () => (`${basePath}/new`);

export const showPath = (id, options = {}) => (withFormat(`${basePath}/${id}`, options));

export const editPath = (id, options = {}) => (withFormat(`${basePath}/${id}/edit`, options));

export const deletePath = showPath;

export default {
  indexPath,
  newPath,
  showPath,
  editPath,
  deletePath,
};
