import React from 'react';
import Label from './LabelBadge';

import { unclassifiedLabel } from '../../../OperationsPage/commons';

export const unspecifiedColor = unclassifiedLabel.color;
export const unspecifiedName = unclassifiedLabel.name;

const UnspecifiedLabel = ({ repartition = 1 }) => (
  <Label
    color={unspecifiedColor}
    name={unspecifiedName}
    repartition={repartition}
  />
);

export default UnspecifiedLabel;
