import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';

import { calendarEventShape } from '../proptypes';
import Label from './Label';
import AddLabel from './AddLabel';
import handleError from '../../../utils/error-handler';

import styles from './LabelsPicker.module.scss';

const LabelsPicker = ({
  user,
  event,
  labels,
  onCreate,
  onUpdate,
  onDelete,
  onToggle,
}) => {
  // NOTE: label_ids could include also deleted labels;
  const { label_ids } = event;
  const currentLabels = _.compact(
    label_ids.map((labelId) => (labels.find(({ id }) => (labelId === id))))
  );
  const systemLabels = currentLabels.filter(({ system }) => (system === true));
  const userLabels = currentLabels.filter(({ system }) => (system === false));
  const handleLabelCreate = (newLabel) => {
    onCreate(newLabel);
  };
  const handleLabelUpdate = (label) => {
    onUpdate(label);
  };
  const handleLabelDelete = (label) => {
    onDelete(label);
  };
  const handleLabelToggle = (label) => {
    onToggle(label);
  };

  return (
    <div className={classnames(styles.LabelsPicker)}>
      <AddLabel
        user={user}
        event={event}
        labels={labels}
        onLabelCreate={handleLabelCreate}
        onLabelUpdate={handleLabelUpdate}
        onLabelDelete={handleLabelDelete}
        onLabelToggle={handleLabelToggle}
      />

      <>
        {
          systemLabels.map((label) => (
            <Label
              key={label.id}
              label={label}
            />
          ))
        }
      </>
      <>
        {
          userLabels.map((label) => (
            <Label
              key={label.id}
              label={label}
            />
          ))
        }
      </>
    </div>
  );
};

LabelsPicker.propTypes = {
  event: calendarEventShape,
  onCreate: PropTypes.func,
};

LabelsPicker.defaultProps = {
  event: {},
  onCreate: () => {},
};

export default LabelsPicker;
