import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// import Icon from '../../Icon';
import Spinner from '../../Spinner';
import WithTrainers from './WithTrainers';
import ListingTable from '../../ListingTable';
import Row from './TrainerProfileRow';

const filterKeys = ['first_name', 'last_name', 'full_name', 'email'];
const headers = ['Image', 'Name', 'Email', 'Planned events', 'Actions'];
const sortableColumns = {
  Name: 'full_name',
  Email: 'email',
};

const TrainersPage = ({ trainers, loading }) => {
  if (loading) {
    return (
      <div className={classnames('trainers-page', 'card')}>
        <div className="card-body">
          <Spinner description="Loading trainers list..." />
        </div>
      </div>
    );
  }

  return (
    <div className={classnames('trainers-page', 'card')}>
      <div className="card-body">
        <ListingTable
          items={trainers}
          filterKeys={filterKeys}
          headers={headers}
          sortableColumns={sortableColumns}
          headerRowProps={{ className: 'text-nowrap' }}
          components={{ Row }}
        />
      </div>
    </div>
  );
};

TrainersPage.propTypes = {
  loading: PropTypes.bool,
  trainers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      public_page_url: PropTypes.string,
      picture_url: PropTypes.string,
    }),
  ),
};

TrainersPage.defaultProps = {
  loading: false,
  trainers: [],
};

export default WithTrainers(TrainersPage);
