import { patch } from '../base';

export const updateExpectedTicketSales = ({ plannedEventId, expectedTicketSales }) => (
  patch(`/trainings/planned_events/${plannedEventId}/economics/expected_ticket_sales.json`, {
    planned_event: { expected_ticket_sales: expectedTicketSales },
  })
);

export const updateExpectedAverageTickePrice = ({
  plannedEventId,
  net,
  gross,
  vat,
}) => (
  patch(`/trainings/planned_events/${plannedEventId}/economics/expected_average_ticket_price.json`, {
    planned_event: { net, gross, vat },
  })
);

export const updateExpectedCostPerAttendee = ({
  plannedEventId,
  net,
  gross,
  vat,
}) => (
  patch(`/trainings/planned_events/${plannedEventId}/economics/expected_cost_per_attendee.json`, {
    planned_event: { net, gross, vat },
  })
);

export const updateExpectedTrainerMinimumFee = ({
  plannedEventId,
  net,
  gross,
  vat,
}) => (
  patch(`/trainings/planned_events/${plannedEventId}/economics/expected_trainer_minimum_fee.json`, {
    planned_event: { net, gross, vat },
  })
);

export const updateExpectedTrainerRevenuePercentage = ({
  plannedEventId,
  revenuePercentage,
}) => (
  patch(`/trainings/planned_events/${plannedEventId}/economics/expected_trainer_revenue_percentage.json`, {
    planned_event: { revenue_percentage: revenuePercentage },
  })
);

export const updateExpectedExpenses = ({
  plannedEventId,
  expenses,
}) => (
  patch(`/trainings/planned_events/${plannedEventId}/economics/expected_expenses.json`, {
    planned_event: { expenses },
  })
);

export default {
  updateExpectedTicketSales,
  updateExpectedAverageTickePrice,
  updateExpectedCostPerAttendee,
  updateExpectedTrainerMinimumFee,
  updateExpectedTrainerRevenuePercentage,
  updateExpectedExpenses,
};
