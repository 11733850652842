import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import BarChart from './CompensatedBarChart';
import PieChart from './PieChart';
import CustomLegend from '../charts/CustomLegend';
import { unclassifiedLabel } from './commons';

const CategoryCharts = (props) => {
  const {
    category,
    operations,
    logic,
    active,
    activeLabels,
    filteredLabels,
    onChange,
  } = props;

  if (!active) {
    return (<div>Disabled Category...</div>);
  }

  const positiveLabelSorter = (a, b) => (
    (activeLabels[b.key] || {}).amount - (activeLabels[a.key] || {}).amount
  );
  const negativeLabelSorter = (b, a) => (
    (activeLabels[b.key] || {}).amount - (activeLabels[a.key] || {}).amount
  );
  const labelSorter = logic === 'negative'
    ? negativeLabelSorter
    : positiveLabelSorter;
  const initialLegendLabels = [unclassifiedLabel]
    .concat(category.labels.map((label) => ({
      ...label,
      active: _.includes(filteredLabels, label.id),
      label: label.name,
      tooltip: label.name,
      key: label.id,
    })))
    .sort(labelSorter)
    .filter(({ key }) => (
      (activeLabels[key] || {}).active === true
    ));
  const [legendLabels, setLegendLabels] = useState(initialLegendLabels);
  const handleClick = (id) => {
    const updatedLabels = legendLabels.map((label) => ({
      ...label,
      active: label.id === id ? !label.active : label.active,
    }));
    setLegendLabels(updatedLabels);

    const activeLabelIds = updatedLabels.filter(({ active }) => (active))
                                        .map(({ id }) => (id));

    onChange(activeLabelIds);
  };

  // Run on every re-render (can we limit the dependencies for this useEffect?)
  useEffect(() => {
    const initialIds = initialLegendLabels.map(({ id }) => (id));
    const currentIds = legendLabels.map(({ id }) => (id));
    const currentStates = legendLabels.reduce((accumulator, { id, active }) => {
      accumulator[id] = active;

      return accumulator;
    }, {});

    if (!_.isEqual(initialIds, currentIds)) {
      const nextLegendLabels = initialLegendLabels.map((label) => ({
        ...label,
        active: typeof currentStates[label.id] === 'undefined' ? true : currentStates[label.id],
      }));

      setLegendLabels(nextLegendLabels);
    }
  });

  const labels = legendLabels.filter((l) => (l.active === true));

  return (
    <div className="CategoryCharts">
      <div className="row">
        <div className="col-md-9">
          <BarChart
            operations={operations}
            category={category}
            labels={labels}
            reactive={false}
          />
        </div>

        <div className="col-md-3">
          <PieChart
            operations={operations}
            category={category}
            labels={labels}
            logic={logic}
            reactive={false}
          />
        </div>
      </div>

      <CustomLegend
        items={legendLabels}
        onClick={handleClick}
      />
    </div>
  );
};

CategoryCharts.propTypes = {
  category: PropTypes.shape({}).isRequired,
  operations: PropTypes.arrayOf(PropTypes.shape({})),
  logic: PropTypes.string,
  active: PropTypes.bool,
  activeLabels: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func,
};

CategoryCharts.defaultProps = {
  logic: 'positive',
  active: true,
  operations: [],
  onChange: () => {},
};

export default CategoryCharts;
