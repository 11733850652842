import {
  getDay,
  isSameDay,
  setDay,
} from 'date-fns';
// import { CellInfo, DateRange, MapCellInfoToDateRange } from '../types';
import createMapCellInfoToContiguousDateRange from './createMapCellInfoToContiguousDateRange';

const constrainToOneDay = ([start, end]) => {
  if (!isSameDay(end, start)) {
    return [start, setDay(end, getDay(start))];
  }

  return [start, end];
};

const createMapCellInfoToSingleDayRange = (options) => {
  const mapToRange = createMapCellInfoToContiguousDateRange(options);

  return (info) => (
    [constrainToOneDay(mapToRange(info)[0])]
  );
};

export default createMapCellInfoToSingleDayRange;
