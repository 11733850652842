import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import Icon from '../../Icon';

const LandingPageButton = ({ event, className, size }) => {
  const { external_url } = event;
  const disabled = (!external_url || external_url.length === 0);

  return (
    <Button
      size={size}
      variant="info"
      title="Open external landing page"
      href={external_url}
      target="_blank"
      className={className}
      disabled={disabled}
    >
      <Icon name="safari" family="fab" fw />
      &nbsp;
      Landing page
    </Button>
  );
};

LandingPageButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  event: PropTypes.shape({
    external_url: PropTypes.string,
  }).isRequired,
};

LandingPageButton.defaultProps = {
  size: 'md',
  className: '',
};

export default LandingPageButton;
