import React from "react"
import Money from '../utils/money.js'
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-basic';
const PlotlyComponent = createPlotlyComponent(Plotly);

class LiquidityAccountChartPlotly extends React.Component {

    computeData(expectationsData, originalBalance) {
        let balance = originalBalance
        let rows = expectationsData.map(expectation => {
            const dailyAmount = Money(expectation.expected_amount);
            const currentBalance = balance.add(dailyAmount)
            const row = [
                new Date(expectation["expected_on_date"]),
                this.moneyToChartValue(dailyAmount),
                this.moneyToChartValue(currentBalance)
            ]
            //balance.add(dailyAmount)
            balance = currentBalance
            return row
        })

        rows.unshift([new Date(), 0, this.moneyToChartValue(balance)])
        return rows
    }

  unpack(rows, key) {
    return rows.map((row) => {
      return row[key];
    });
  }

  render() {
    let liquidityData = this.props.liquidityData;
    const rows = this.computeData(liquidityData);

    const dateValues = this.unpack(rows, 'date')

    let traceAmount = {
      name: 'Amount',
      type: "scatter",
      fill: 'tonexty',
      xaxis: 'x',
      yaxis: 'y',
      x: dateValues,
      y: this.unpack(rows, 'amount')
    }

    let traceBalance = {
      name: 'Balance',
      type: "scatter",
      fill: 'tonexty',
      xaxis: 'x',
      yaxis: 'y',
      x: dateValues,
      y: this.unpack(rows, 'balance')
    }

    let layout = {
      title: 'Liquidity on account',
      xaxis: {
        title: 'Date',
        type: 'date',
        tickmode: 'auto',
        'rangeslider': {
          visible: true,
          autorange: true
        },
        //range: (this.props.scopeYear ? [moment().startOf('year').format(), moment().format()] : []),
        rangeselector: {
          buttons: [
            {
              step: 'all',
              label: 'reset'
            },
            {
              step: 'year',
              stepmode: 'todate',
              count: 1,
              label: 'This Year'
            }
          ]
        }
      },

      yaxis: {
        overlaying: 'y',
        tickprefix: '€',
        tickformat: ',.2r'
      },

      legend: {
        orientation: 'h'
      },

      dragmode: 'zoom'
    };

    let config = {
      // showLink: false,
      // displayModeBar: true
    };

    return (
      <PlotlyComponent className="whatever" data={[traceAmount, traceBalance]} layout={layout} config={config} />
    );
  }

}

export default LiquidityAccountChartPlotly
