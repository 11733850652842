import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import _ from 'lodash';

import Icon from '../../Icon';
import Row from '../../ListingTable/Row';
import UpdateModal from './CertifiedAttendeeUpdateModal';
import ConfirmModal from '../../ConfirmModal';
import { verifyPath } from '../../../routes/trainings/certifications';

const DownloadButton = ({
  item, size, variant, format,
  host,
}) => {
  const {
    isGeneratingPdf, pdf_filename, verification_code,
  } = item;

  if (isGeneratingPdf) {
    return (
      <Button variant={variant} size={size} className="disabled" disabled>
        <Icon name="spinner" spin fw />
      </Button>
    );
  }

  const icon = format === 'pdf'
    ? <Icon name="file-pdf" family="fas" fw />
    : <Icon name="id-badge" family="far" fw />;

  if (!pdf_filename || pdf_filename.length === 0) {
    return (
      <Button variant={variant} size={size} className="disabled" disabled>
        {icon}
      </Button>
    );
  }

  const url = verifyPath(verification_code, { format, host });

  return (
    <Button variant={variant} size={size} href={url} target="_blank">
      {icon}
    </Button>
  );
};

DownloadButton.propTypes = {
  host: PropTypes.string,
  format: PropTypes.oneOf(['pdf', 'html']),
  variant: PropTypes.string,
  size: PropTypes.string,
  item: PropTypes.shape({
    id: PropTypes.string,
    verification_code: PropTypes.string,
    certification_id: PropTypes.string,
    certificate_id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    pdf_filename: PropTypes.string,
    email: PropTypes.string,
    isGeneratingPdf: PropTypes.bool,
  }).isRequired,
};

DownloadButton.defaultProps = {
  size: 'sm',
  variant: 'primary',
  format: 'pdf',
  host: null,
};

const CertifiedAttendeeRow = ((props) => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const hideUpdateModal = () => { setShowUpdateModal(false); };
  const handleUpdateButtonClick = () => { setShowUpdateModal(true); };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const hideDeleteModal = () => { setShowDeleteModal(false); };
  const handleDeleteButtonClick = () => { setShowDeleteModal(true); };

  const {
    item, onUpdate, onDelete, certification,
    certificatesHost,
  } = props;
  const {
    first_name, last_name, email
  } = item;
  const fullName = _.compact([first_name, last_name]).join(' ');

  const deleteMessage = (
    <h5 className="mb-3">
      Do you really want to remove the certified attendee
      &nbsp;
      <strong>
        &quot;
        {fullName}
        &quot;
      </strong>
      ?
    </h5>
  );

  const handleDelete = () => { onDelete(item); };

  return (
    <Row {...props}>
      <td>{first_name}</td>
      <td>{last_name}</td>
      <td>{email}</td>
      <td>
        <UpdateModal
          certifiedAttendee={item}
          show={showUpdateModal}
          onHide={hideUpdateModal}
          onUpdate={onUpdate}
          certification={certification}
        />

        <ConfirmModal
          variant="danger"
          title="Remove the selected attendee"
          description={deleteMessage}
          show={showDeleteModal}
          onHide={hideDeleteModal}
          onConfirm={handleDelete}
        />

        <div className="btn-group">
          <Button variant="light" size="sm" onClick={handleUpdateButtonClick}>
            <Icon name="pencil-alt" fw />
          </Button>

          <DownloadButton
            item={item}
            host={certificatesHost}
            format="html"
            variant="info"
          />

          <DownloadButton
            item={item}
            host={certificatesHost}
            format="pdf"
          />

          <Button variant="danger" size="sm" onClick={handleDeleteButtonClick}>
            <Icon name="trash" fw />
          </Button>
        </div>
      </td>
    </Row>
  );
});

CertifiedAttendeeRow.propTypes = {
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  certification: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  item: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

CertifiedAttendeeRow.defaultProps = {
  onUpdate: () => {},
  onDelete: () => {},
};

export default CertifiedAttendeeRow;
