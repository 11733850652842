import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SelectableColumn from './SelectableColumn';
import Icon from '../Icon';

const SortLink = ({
  children,
  onSort,
  column,
  sortKey,
  current,
  direction,
}) => {
  const sortable = !!sortKey;
  if (!sortable) { return children; }
  const isActive = current === column;

  const className = classnames(
    'ListingTable__sortableHeader',
    'text-info',
    direction,
    { active: isActive },
  );

  const content = [children];
  let icon = null;

  if (isActive && direction === 'asc') {
    icon = <Icon name="sort-up" fw key="ascIcon" />;
  }

  if (isActive && direction === 'desc') {
    icon = <Icon name="sort-down" fw key="descIcon" />;
  }

  return (
    <a className={className} onClick={() => { onSort({ column, sortKey }); }}>
      {content} {icon}
    </a>
  );
};

const Cell = ({
  children,
  className,
  onSort,
  sortableColumns,
  sortColumn,
  sortDirection,
  ...props
}) => {
  const sortKey = sortableColumns[children];

  return (
    <th {...(props || {})} className={classnames(className)}>
      <SortLink
        onSort={onSort}
        column={children}
        sortKey={sortKey}
        current={sortColumn}
        direction={sortDirection}
      >
        {children}
      </SortLink>
    </th>
  );
};

const Header = ({
  className,
  columns,
  rowProps,
  colsProps,
  selectable,
  onSelection,
  selected,
  sortableColumns,
  onSort,
}) => {
  const [sortColumn, updateSortColumn] = useState(null);
  const [sortDirection, updateSortDirection] = useState('asc');
  const handleSorting = ({ column, sortKey }) => {
    let direction = 'asc';

    // Initial state
    if (sortColumn !== column) {
      updateSortColumn(column);
      updateSortDirection(direction);
    } else {
      direction = sortDirection === 'asc' ? 'desc' : 'asc';
      updateSortDirection(direction);
    }

    onSort({ column, direction, sortKey });
  };

  return (
    <thead className={classnames('listing-table-header', className)}>
      <tr {...rowProps}>
        <SelectableColumn
          selectable={selectable}
          item="all"
          onChange={onSelection}
          selected={selected}
          header
        />

        {
          columns.map((text, idx) => (
            <Cell
              key={text}
              sortableColumns={sortableColumns}
              onSort={handleSorting}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              {...(colsProps[idx] || {})}
            >
              {text}
            </Cell>
          ))
        }
      </tr>
    </thead>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  rowProps: PropTypes.object,
  colsProps: PropTypes.arrayOf(PropTypes.object),
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  onSelection: PropTypes.func,
  stickyHeader: PropTypes.bool,
  sortable: PropTypes.bool,
  onSort: PropTypes.func,
};

Header.defaultProps = {
  className: '',
  rowProps: {},
  colsProps: [],
  selectable: false,
  selected: false,
  onSelection: () => {},
  stickyHeader: false,
  sortable: false,
  onSort: () => {},
};

export default Header;
