import React from 'react';
import PropTypes from 'prop-types';

import ListingTable from '../ListingTable';

import Spinner from '../Spinner';
import WithIssuedInvoices from './WithIssuedInvoices';
import Chart from '../MonthlyBillingBarChart';

const IssuedInvoicesPage = (({
  loading,
  issuedInvoices,
  // onUpdate,
  // onDelete,
}) => {
  if (loading) {
    return (
      <div>
        <Spinner
          description="Loading issued invoice documents..."
        />
      </div>
    );
  }

  return (
    <div className="mt-3 card">
      <div className="card-body pt-5">
        <Chart
          billingData={issuedInvoices}
        />

        <hr className="my-5" />

        <ListingTable
          billingData={issuedInvoices}
          items={issuedInvoices}
          hideSearch={false}
          responsive={false}
          rowComponent="BillingDocumentRow"
          filterKeys={['headline', 'customer', 'progressive_number']}
          headers={['Headline', 'Customer', 'Document type', 'Amount', 'Status', 'Actions']}
        />
      </div>
    </div>
  );
});

IssuedInvoicesPage.propTypes = {
  loading: PropTypes.bool,
  // onUpdate: PropTypes.func.isRequired,
  // onDelete: PropTypes.func.isRequired,
};

IssuedInvoicesPage.defaultProps = {
  loading: false,
};

export default WithIssuedInvoices(IssuedInvoicesPage);
