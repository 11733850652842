import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import TrainersUpdateModal from './TrainersUpdateModal';
import { visit } from '../../../utils/router';
import Icon from '../../Icon';
import { success } from '../../../utils/notifier';

const TrainersInplaceInput = ({ event, className }) => {
  const [showModal, setShowModal] = useState(false);
  const hideModal = () => { setShowModal(false); };
  const { trainers, details_path } = event;
  const trainersLabel = trainers && trainers.length >= 1
    ? trainers.map(({ full_name }) => (full_name)).join(', ')
    : 'Missing trainers';

  const handleClick = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleTrainersUpdate = ({ response }) => {
    visit(details_path).then(() => { success({ message: response.message }); });
  };

  return (
    <div className={classnames('TrainersInplaceInput', className)}>
      <TrainersUpdateModal
        show={showModal}
        event={event}
        onHide={hideModal}
        onUpdate={handleTrainersUpdate}
      />

      <a href="#trainers" onClick={handleClick}>
        <span className="editable">{trainersLabel}</span>
        &nbsp;
        <Icon name="pencil-alt" fw />
      </a>
    </div>
  );
};

TrainersInplaceInput.propTypes = {
  event: PropTypes.shape({
    headline: PropTypes.string,
    description: PropTypes.string,
    details_path: PropTypes.string,
    trainers_name: PropTypes.string,
    venue: PropTypes.string,
    status: PropTypes.string,
    starts_on: PropTypes.string,
    ends_on: PropTypes.string,
    tickets_sold: PropTypes.number,
    capacity: PropTypes.number,
    external_url: PropTypes.string,
    acl: PropTypes.shape({}),
    trainers: PropTypes.arrayOf(PropTypes.shape({
      full_name: PropTypes.string
    })),
  }).isRequired,
};

TrainersInplaceInput.defaultProps = {
};

export default TrainersInplaceInput;
