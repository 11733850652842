import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

const Checkbox = ({
  label,
  type,
  errors,
  name,
  hint,
  // defaultValue,
  hasError,
  onChange,
  ...props
}) => (
  <Form.Group controlId={`form-grid-${name}`}>
    <Form.Check
      {...props}
      type={type}
      isInvalid={!!hasError}
      onChange={(e) => { onChange(e.target.checked); }}
      label={label}
    />
    { hint && (
      <small
        className={
          classnames(
            'form-text', {
              'text-muted': !hasError,
              'text-danger': !!hasError,
            },
          )
        }
      >
        {hint}
      </small>
    )}
  </Form.Group>
);

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  hint: PropTypes.string,
  // defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  label: 'Checkbox',
  type: 'checkbox',
  name: 'checkbox',
  // defaultValue: false,
  hint: null,
  hasError: false,
  onChange: () => {},
};

export default Checkbox;
