import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../Icon';
import BlankState from '../../BlankState';
import { enablePrivateClassCertification } from '../../../api/trainings/private_classes';
import { success } from '../../../utils/notifier';

const MissingCertification = ({ privateClass }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { headline, id } = privateClass;
  const description = `Do you want to enable the certification for the "${headline}" private class?`;
  const buttonLabel = (
    <span>
      <Icon name="check" fw />
      &nbsp;
      Enable certification
    </span>
  );

  const handleConfirmButtonClick = () => {
    setIsLoading(true);
    enablePrivateClassCertification({ privateClassId: id })
      .then((response) => response.json())
      .then((data) => { success({ message: data.message }); });
  };

  return (
    <div>
      <BlankState
        title="The certification has not yet been enabled"
        description={description}
        buttonLabel={buttonLabel}
        onClick={handleConfirmButtonClick}
        icon="calendar-alt"
        isLoading={isLoading}
        buttonProps={{
          variant: 'success',
        }}
      />
    </div>
  );
};

MissingCertification.propTypes = {
  privateClass: PropTypes.shape({
    id: PropTypes.string,
    headline: PropTypes.string,
    details_path: PropTypes.string,
  }).isRequired,
};

MissingCertification.defaultProps = {
};

export default MissingCertification;
