import React from 'react';
import Button from 'react-bootstrap/Button';

import Icon from '../../Icon';

const SearchButton = ({
  formId,
  isLoading,
  size,
  className,
  disabled,
}) => (
  <Button
    type="submit"
    form={formId}
    variant="success"
    disabled={isLoading || disabled}
    className={className}
    size={size}
  >
    &nbsp;
    { isLoading && (
      <>
        <Icon name="spinner" spin fw />
        &nbsp;
        Loading...
      </>
    )}
    { !isLoading && (
      <>
        <Icon name="search" fw />
        &nbsp;
        Search FAD
      </>
    )}
  </Button>
);

SearchButton.defaultProps = {
  disabled: false,
  isLoading: false,
}

export default SearchButton;
