import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { DateTime } from 'luxon';

import { eventShape } from '../../utils';
import SliderInput from '../../../inputs/SliderInput';
import Icon from '../../../Icon';
import handleError from '../../../../utils/error-handler';
import { success } from '../../../../utils/notifier';
import { updateExpectedTrainerRevenuePercentage } from '../../../../api/trainings/planned_events_economics';
import { ticketShape } from '../../utils';
import Money from '../../../../utils/money';
import { reload } from '../../../../utils/router';

const ExpectedTrainerRevenuePercentageUpdateModal = ({
  show,
  onHide,
  onUpdate,
  event,
}) => {
  const initialRevenuePercentage = event.expected_trainer_revenue_percentage;
  const [isLoading, setIsLoading] = useState(false);
  const [revenuePercentage, setRevenuePercentage] = useState(initialRevenuePercentage);

  const handleRevenuePercentageChange = (value) => {
    setRevenuePercentage(value);
  };

  const plannedEventId = event.id;
  const handleUpdate = () => {
    if (isLoading) { return; }
    setIsLoading(true);

    updateExpectedTrainerRevenuePercentage({ plannedEventId, revenuePercentage })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        onHide();
        onUpdate({ plannedEventId, response: data });
      })
      .catch(handleError);
  };

  const { headline, starts_on, human_training_typology } = event;
  const startsOn = DateTime.fromISO(starts_on);
  const humanDate = startsOn.toFormat('dd LLL yyyy');

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Set expected trainer revenue percentage</strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ zIndex: 301 }}>
        <h4 className="mb-3">
          Set the expected trainer revenue percentage for the
          &nbsp;
          {human_training_typology}
          &nbsp;
          <strong>
            &quot;
            {headline}
            &quot;
          </strong>
          &nbsp;
          on
          &nbsp;
          {humanDate}
        </h4>

        <h5>
          Revenue percentage: 
          &nbsp;
          <strong>
            {revenuePercentage}
          </strong>
          <Icon name="percent" fw />
        </h5>

        <div className="p-2">
          <SliderInput 
            initialValue={initialRevenuePercentage}
            onChange={handleRevenuePercentageChange}
            className="mb-3"
            deboundeTimer={10}
          />
        </div>
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={handleUpdate} disabled={isLoading}>
          <Icon name="check" fw />
          { isLoading ? 'Loading...' : 'Update' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ExpectedTrainerRevenuePercentageUpdateModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  event: eventShape.isRequired,
};

ExpectedTrainerRevenuePercentageUpdateModal.defaultProps = {
  show: false,
  onUpdate: () => {},
};

export default ExpectedTrainerRevenuePercentageUpdateModal;

