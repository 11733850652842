import React from 'react';
// import PropTypes from 'prop-types';
import _, { debounce, remove } from 'lodash';
import moment from 'moment';

import EventBus from '../../packs/event_bus';
import { get } from '../../api/base';
import { operations as fetchOperations } from '../../api/financialAnalysis/classification/operations';
import sleep from '../../utils/sleep';
import { unclassifiedId, operationHeadline } from './commons';

// Used into the DataTable sort by operations logics
// NOTE: We could keep only one label per category here
const operationCategoryLabelsNames = (operation) => (
  operation.category_labels.reduce((acc, label) => {
    const { category_id, name } = label;
    acc[category_id] = name;

    return acc;
  }, {})
);

const operationLabel = (operation) => {
  const { counterpart, operation_date } = operation;
  const prefix = `[${moment(operation_date).format('D/M/Y')}] ${counterpart}`;
  const headline = operationHeadline(operation);

  return [prefix, headline].join(' // ');
};

const decorateOperation = (operation) => ({
  ...operation,
  category_labels_names: operationCategoryLabelsNames(operation),
  label: operationLabel(operation),
  humanDate: moment(operation.operation_date).format('D/M/Y'),
  date: moment(operation.operation_date).toDate(),
});

const decorateOperations = (operations) => operations.map(decorateOperation);

const getActiveCategoriesLabels = ({ operations, categories }) => {
  const data = categories.reduce((accumulator, category) => {
    const { id, labels } = category;

    accumulator[id] = accumulator[id] || {
      [unclassifiedId]: { active: true, amount: 0.0 },
    };

    labels.forEach((l) => {
      accumulator[id][l.id] = {
        active: false,
        amount: 0.0,
      };
    });

    return accumulator;
  }, {});

  operations.forEach(({ category_labels, amount }) => {
    const op_amount = amount ? amount.cents : 0.0;

    categories.forEach(({ category_id }) => {
      data[category_id][unclassifiedId].amount += op_amount;
    });

    (category_labels || []).forEach((cl) => {
      const { category_id, label_id } = cl;

      if (data[category_id] && data[category_id][label_id]) {
        data[category_id][label_id].active = true;
        data[category_id][label_id].amount += op_amount;
        data[category_id][unclassifiedId].amount -= op_amount;
      }
    });
  });

  return data;
};

const updateOperationCategoryLabels = ({ operation, categoryId, labels }) => {
  const { category_labels } = operation;

  // Delete the current category selection
  remove(category_labels, { category_id: categoryId });

  _.compact(labels).forEach((label) => {
    if (label.label_id !== unclassifiedId) {
      category_labels.push({ ...label });
    }
  });

  return decorateOperation({
    ...operation,
    category_labels: [...category_labels], // force mutation
  });
};

const WithOperations = (WrappedComponent) => {
  class HOC extends React.Component {
    constructor(props) {
      super(props);

      const { categories } = props;
      const activeCategoryLabelsFilters = categories.reduce((acc, category) => {
        acc[category.id] = category.labels.map(({ id }) => (id));
        return acc;
      }, {});

      this.state = {
        operations: [],
        categories,
        loading: true,
        updating: false,
        activeCategoryId: props.activeCategoryId || categories[0].id,
        activeOperationIds: [], // Really used? O.o
        activeCategoriesLabels: {},
        activeCategoryLabelsFilters,
      };
    }

    componentDidMount() {
      this.operationEventsSubscriptionId = EventBus.subscribe(
        [
          'FinancialAnalysis::Classification::OperationRegistered',
          'FinancialAnalysis::Classification::OperationUpdated',
          'FinancialAnalysis::Classification::OperationArchived',
        ],
        debounce(this.handleOperationsEventsSubscription, 500, { leading: true }),
        this,
      );

      this.reloadOperations(0);
    }

    componentWillUnmount() {
      EventBus.unsubscribe(this.operationsEventsSubscriptionId);
    }

    handleCategoryChange(activeCategoryId) {
      this.setState({ activeCategoryId });
    }

    handleOnCategoryLabelsChange({ labels, operationId, categoryId }) {
      this.setState({ updating: true });

      const operationIds = Array.isArray(operationId) ? operationId : [operationId];
      const { operations, categories } = this.state;
      const updatedCategories = categories.map((category) => {
        const { id } = category;

        // Do not change other categories
        if (id !== categoryId || _.compact(labels).lenght === 0) { return category; }

        // Assign category selected labels
        _.compact(labels).forEach((label) => {
          const foundLabel = category.labels.find(({ label_id }) => (
            label_id === label.label_id
          ));

          // Assign the label when not present
          if (!foundLabel) {
            category.labels.push(label);
          }
        });

        return category;
      });

      const updatedOperations = operations.map((operation) => {
        const { id } = operation;

        if (!_.includes(operationIds, id)) { return operation; }

        return updateOperationCategoryLabels({
          operation,
          categoryId,
          labels,
        });
      });

      const activeCategoriesLabels = getActiveCategoriesLabels({
        categories: updatedCategories,
        operations: updatedOperations,
      });

      this.setState({
        categories: updatedCategories,
        operations: updatedOperations,
        activeCategoriesLabels,
      });
    }

    handleOnCategoryLabelsChanged() {
      this.setState({ updating: false });
    }

    handleOnDelete({ operationId }) {
      const { operations } = this.state;
      const filteredOperations = operations.filter((op) => (op.operation_id !== operationId));

      this.setOperations(filteredOperations);
    }

    handleOnUpdate({ operation }) {
      const { operations } = this.state;
      const { operation_id } = operation;
      const updatedOperations = operations.map((op) => (
        op.operation_id === operation_id
        ? { ...operation, _uiUpdatedAt: new Date() }
          : op
      ));

      this.setOperations(updatedOperations);
    }

    handleOperationsEventsSubscription() {
      this.reloadOperations(100);
    }

    handleCategoryLabelsFiltersToggle(categoryId, activeLabelIds) {
      this.setActiveCategoryLabelsFilter(categoryId, activeLabelIds);
    };

    setActiveCategoryLabelsFilter(categoryId, labelIds) {
      this.setState((state) => ({
        activeCategoryLabelsFilters: {
          ...state.activeCategoryLabelsFilters,
          [categoryId]: labelIds,
        }
      }));
    }

    setOperations(plainOperations) {
      const { categories } = this.state;
      const operations = decorateOperations(
        _.uniq(plainOperations, 'id')
      );
      const activeCategoriesLabels = getActiveCategoriesLabels({
        operations,
        categories,
      });

      this.setState({
        loading: false,
        operations,
        activeCategoriesLabels,
      });
    }

    categoryLabelIds() {
      const { categories } = this.state;

      let ids = [];

      categories.forEach((category) => {
        ids = ids.concat(
          category.labels.map(({ label_id }) => (label_id)),
        );
      });

      return ids;
    }

    hasCategoryLabel(labelId) {
      return _.includes(this.categoryLabelIds(), labelId);
    }

    findCategoryLabel({ categoryId, categoryLabelId }) {
      const { categories } = this.state;
      const category = categories.find(({ id }) => (id === categoryId));

      if (!category) { return false; }

      const label = category.labels.find(({ label_id }) => (
        label_id === categoryLabelId
      ));

      return label || false;
    }

    reloadOperations(wait = 0, options = {}) {
      const { typology } = this.props;

      return sleep(wait).then(() => {
        fetchOperations({ ...options, typology })
          .then((data) => { this.setOperations(data); });
      });
    }

    reloadCategories(wait = 0) {
      const { categoriesEndpoint } = this.props;
      const { operations } = this.state;

      return sleep(wait).then(() => {
        get(categoriesEndpoint)
          .then((response) => (response.json()))
          .then((data) => {
            const activeCategoriesLabels = getActiveCategoriesLabels({
              operations,
              categories: data,
            });

            this.setState({
              categories: data,
              activeCategoriesLabels,
            });
          });
      });
    }

    render() {
      const {
        operations,
        categories,
        loading,
        updating,
        activeOperationIds,
        activeCategoriesLabels,
        activeCategoryLabelsFilters, // The enabled labels into the CustomLegend
        activeCategoryId,
      } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          loading={loading}
          isUpdating={updating}
          categories={categories}
          operations={operations}
          activeCategoryId={activeCategoryId}
          activeOperationIds={activeOperationIds}
          activeCategoriesLabels={activeCategoriesLabels}
          activeCategoryLabelsFilters={activeCategoryLabelsFilters}
          onDelete={(args) => { this.handleOnDelete(args); }}
          onUpdate={(args) => { this.handleOnUpdate(args); }}
          onCategoryChange={(categoryId) => { this.handleCategoryChange(categoryId); }}
          onCategoryLabelChange={(args) => { this.handleOnCategoryLabelsChange(args); }}
          onCategoryLabelChanged={(args) => { this.handleOnCategoryLabelsChanged(args); }}
          onCategoryLabelsFiltersToggle={(cId, lIds) => { this.handleCategoryLabelsFiltersToggle(cId, lIds); }}
        />
      );
    }
  }

  return HOC;
};

export default WithOperations;
