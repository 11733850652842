import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';

import Icon from '../../Icon';
import styles from './styles.module.scss';

const DeleteButton = ({ className, onClick }) => (
  <Button
    className={classnames(styles.DeleteButton, className)}
    variant="danger"
    onClick={() => { onClick(); }}
  >
    <Icon name="trash" fw />
  </Button>
);

DeleteButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

DeleteButton.defaultProps = {
  className: null,
  onClick: () => {},
};

export default DeleteButton;
