import DefaultEventRootComponent from './components/DefaultEventRootComponent';
import TimeGridScheduler from './components/TimeGridScheduler';
import { SchedulerContext } from './context';
import useMousetrap from './hooks/useMousetrap';
import classes from './styles/styles.module.scss';
import getTextForDateRange, {
  getFormattedComponentsForDateRange,
} from './utils/getTextForDateRange';

export default {
  TimeGridScheduler,
  classes,
  DefaultEventRootComponent,
  useMousetrap,
  SchedulerContext,
  getFormattedComponentsForDateRange,
  getFormattedTimeRangeComponents: getFormattedComponentsForDateRange,
  getTextForDateRange,
};
