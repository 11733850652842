import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { debounce } from 'lodash';

import EventBus from '../../packs/event_bus';
import Icon from '../Icon';
import {
  syncAccountsCalendars,
  accountsCalendarsSyncStatus,
} from '../../api/smart_planner/account_calendars';
import handleError from '../../utils/error-handler';
import { success } from '../../utils/notifier';

const events = [
  'SmartPlanner::Calendars::PaginatedCalendarSyncCompleted',
  'SmartPlanner::Calendars::CalendarSyncCompleted',
];

const SyncCalendarsButton = ({ className, caption }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSync, setIsSync] = useState(false);
  const handleSync = () => {
    if (isSync || isDisabled) { return; }
    setIsSync(true);

    syncAccountsCalendars()
      .then((response) => (response.json()))
      .then((data) => { success({ message: data.message }); })
      .catch(handleError);
  };
  const updateSyncStatus = () => {
    accountsCalendarsSyncStatus()
      .then((response) => (response.json()))
      .then(({ synching }) => {
        setIsDisabled(false);
        setIsSync(synching);
      })
      .catch(handleError);
  };

  // Did mount
  useEffect(() => {
    let mounted = true;
    let eventBusSubscription;
    const handleEvent = () => { updateSyncStatus(); };

    if (mounted) {
      updateSyncStatus();

      eventBusSubscription = EventBus.subscribe(
        events,
        debounce(handleEvent, 250),
        this,
      );
    }

    return () => {
      mounted = false;
      EventBus.unsubscribe(eventBusSubscription);
    };
  }, []);

  return (
    <Button
      variant="warning"
      className={className}
      disabled={isSync || isDisabled}
      onClick={() => (handleSync())}
    >
      <Icon name="sync-alt" spin={isSync} fw />
      &nbsp;
      {caption}
    </Button>
  );
};

SyncCalendarsButton.propTypes = {
  className: PropTypes.string,
  caption: PropTypes.string,
};

SyncCalendarsButton.defaultProps = {
  className: '',
  caption: 'Sync calendars',
};

export default SyncCalendarsButton;
