import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { calendarEventShape } from '../proptypes';
import styles from './LabelsPicker.module.scss';

const Label = ({ label, className }) => {
  const { id, name, color } = label;
  const handleClick = () => {
    alert(name);
  }

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={(
        <Tooltip>
          {name}
        </Tooltip>
      )}
    >
      <a
        title={name}
        className={classnames(className, styles.Label)}
        style={{ backgroundColor: color }}
        onClick={handleClick}
      />
    </OverlayTrigger>
  );
};

Label.propTypes = {
  onClick: PropTypes.func,
  event: calendarEventShape,
};

Label.defaultProps = {
  onClick: () => {},
  event: {},
};

export default Label;
