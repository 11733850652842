import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DateTime } from 'luxon';
import { ThemeProvider } from 'styled-components';
import { Datepicker } from '@datepicker-react/styled';

import WithPrivateClass from './WithPrivateClass';
import Spinner from '../../Spinner';
import Icon from '../../Icon';
import { formatIsoDate } from '../../../utils/datetime';
import TrainersInplaceInput from './TrainersInplaceInput';

const InfoPage = ({ loading, privateClass }) => {
  if (loading) {
    return (
      <Spinner
        description="Retrieving private class information ..."
      />
    );
  }

  const {
    headline,
    description,
    starts_on,
    ends_on,
    city,
  } = privateClass;

  const startsOnDT = DateTime.fromISO(starts_on);
  const endsOnDT = DateTime.fromISO(ends_on);

  const startsOn = startsOnDT.toJSDate();
  const endsOn = endsOnDT.toJSDate();

  const humanStartsOn = formatIsoDate(starts_on);
  const humanEndsOn = formatIsoDate(ends_on);

  return (
    <div className={classnames('info-page')}>
      <div className="row">
        <div className="col-md-6">
          <dl>
            <dt>Headline</dt>
            <dd>
              <h3>{headline}</h3>
              <p>{description}</p>
            </dd>

            <dt>Trainers</dt>
            <dd>
              <TrainersInplaceInput
                privateClass={privateClass}
              />
            </dd>

            <dt>Dates</dt>
            <dd>
              <Icon name="calendar" family="far" fw />
              &nbsp;
              {humanStartsOn} - {humanEndsOn}
            </dd>

            <dt>City</dt>
            <dd>{ city || 'Online' }</dd>
          </dl>

          <p>
            <em>Add more info here...</em>
          </p>
        </div>

        <div className="col-md-6">
          <ThemeProvider
            theme={{
              breakpoints: ['32em', '48em', '64em'],
              reactDatepicker: {
                daySize: [36, 40],
                fontFamily: 'system-ui, -apple-system',
                colors: {
                  accessibility: '#D80249',
                  selectedDay: '#f7518b',
                  selectedDayHover: '#F75D95',
                  primaryColor: '#d8366f',
                  normalDayHover: 'transparent',
                },
              },
            }}
          >
            <div className="float-right">
              <Datepicker
                showResetDates={false}
                showSelectedDates={false}
                showClose={false}
                initialVisibleMonth={startsOn}
                minBookingDate={startsOn}
                maxBookingDate={endsOn}
                numberOfMonths={1}
              />
            </div>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

InfoPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  privateClass: PropTypes.shape({
    headline: PropTypes.string,
    description: PropTypes.string,
    trainers_name: PropTypes.string,
    city: PropTypes.string,
    status: PropTypes.string,
    starts_on: PropTypes.string,
    ends_on: PropTypes.string,
  }),
};

InfoPage.defaultProps = {
  privateClass: null,
};

export default WithPrivateClass(InfoPage);
