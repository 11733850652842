import React from 'react';
// import { EventRootProps } from '../types';

const DefaultEventRootComponent = React.memo(
  React.forwardRef(function DefaultEventRootComponent(
    {
      isActive,
      handleDelete,
      cellIndex,
      rangeIndex,
      classes,
      disabled,
      ...props
    },
    ref,
  ) {
    return <div ref={ref} aria-disabled={disabled} {...props} />;
  }),
);

export default DefaultEventRootComponent;
