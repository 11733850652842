import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import EventsDefaultPriorityInplaceInput from './EventsDefaultPriorityInplaceInput';
import { accountShape } from './utils';
import Row from '../../ListingTable/Row';
import Icon from '../../Icon';
import { formatIsoDate } from '../../../utils/datetime';

const PrimaryIcon = ({ primary }) => {
  if (primary) {
    return (<Icon name="check" className="text-success" fw />);
  }

  return (<Icon name="times" className="text-danger" fw />);
};

const ToggleButton = ({
  label, variant, onClick, isLoading, icon, disabled,
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    if (disabled) { return; }
    onClick();
  };

  if (isLoading) {
    return (
      <Button
        size="sm"
        variant={variant}
        href="#toggle"
        title={label}
        disabled
      >
        <Icon name="spinner" family="fas" fw spin />
        &nbsp;
        Loading...
      </Button>
    );
  }

  return (
    <Button
      size="sm"
      variant={variant}
      href="#toggle"
      title={label}
      onClick={handleClick}
      disabled={disabled}
    >
      <Icon name={icon} fw />
      &nbsp;
      {label}
    </Button>
  );
};

const EnableButton = ({ item, onClick, disabled }) => {
  const { enabled, isLoading } = item;

  if (enabled) { return null; }

  const handleClick = () => { onClick(item); };

  return (
    <ToggleButton
      variant="success"
      label="Enable"
      onClick={handleClick}
      isLoading={isLoading}
      icon="check"
      disabled={disabled}
    />
  );
};

const DisableButton = ({ item, onClick, disabled }) => {
  const { enabled, isLoading } = item;

  if (!enabled) { return null; }

  const handleClick = () => { onClick(item); };

  return (
    <ToggleButton
      variant="danger"
      label="Disable"
      onClick={handleClick}
      isLoading={isLoading}
      icon="times"
      disabled={disabled}
    />
  );
};

EnableButton.propTypes = {
  item: accountShape.isRequired,
  onClick: PropTypes.func.isRequired,
};

const CalendarRow = ({
  item,
  onEnable,
  onDisable,
  isSynchronizingCalendars,
  ...props
}) => {
  const {
    label,
    time_zone,
    primary,
    description,
    provider_identifier,
    is_synching,
    sync_completed_at,
    events_default_priority,
  } = item;

  const syncUpdatedAtLabel = sync_completed_at
    ? formatIsoDate(sync_completed_at)
    : '-';

  const syncUpdatedAtMarkup = is_synching
    ? (<Icon name="sync-alt" fw spin />)
    : (<span>{syncUpdatedAtLabel}</span>);

  return (
    <Row {...props} item={item}>
      <td>
        <strong>{ label }</strong>
        <br />
        <small>
          { label === provider_identifier ? '' : provider_identifier }
        </small>
      </td>

      <td className="text-truncate" title={description}>
        { description || '-' }
      </td>

      <td>
        { time_zone }
      </td>

      <td className="">
        <PrimaryIcon primary={primary} />
      </td>

      <td>
        <EventsDefaultPriorityInplaceInput
          calendar={item}
        />
      </td>

      <td>
        { syncUpdatedAtMarkup }
      </td>

      <td className="h5 text-center">
        <div className="btn-group">
          <EnableButton
            item={item}
            onClick={onEnable}
            disabled={isSynchronizingCalendars}
          />

          <DisableButton
            item={item}
            onClick={onDisable}
            disabled={isSynchronizingCalendars}
          />
        </div>
      </td>
    </Row>
  );
};

CalendarRow.propTypes = {
  item: accountShape.isRequired,
  onEnable: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
};

CalendarRow.defaultProps = {
};

export default CalendarRow;
