import React from 'react';

import EventBus from '../../../packs/event_bus';
import { get } from '../../../api/base';
import sleep from '../../../utils/sleep';
import { showPath as privateClassPath } from '../../../routes/trainings/private_classes';

const decoratePrivateClass = (privateClass) => ({
  ...privateClass,
});

const reactiveEvents = [
  'Trainings::Certifications::PrivateClassCertificationEnabled',
];

const WithPrivateClass = (WrappedComponent) => {
  class HOC extends React.Component {
    constructor(props) {
      super(props);

      const { privateClassId } = props;

      this.state = {
        privateClassId,
        loading: true,
      };
    }

    componentDidMount() {
      this.privateClassEventsSubscriptionId = EventBus.subscribe(
        reactiveEvents,
        this.handlePrivateClassSubscription,
        this,
      );

      this.loadPrivateClass();
    }

    componentWillUnmount() {
      EventBus.unsubscribe(this.privateClassEventsSubscriptionId);
    }

    handlePrivateClassSubscription() {
      this.loadPrivateClass(1000);
    }

    loadPrivateClass(wait = 0) {
      const { privateClassId } = this.state;
      const path = privateClassPath(privateClassId, { format: 'json' });

      return sleep(wait).then(() => {
        get(path)
          .then((response) => (response.json()))
          .then(({ private_class, certification }) => {
            this.setState({
              loading: false,
              privateClass: decoratePrivateClass(private_class),
              certification,
            });
          });
      });
    }

    render() {
      const {
        privateClass,
        loading,
        certification,
      } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          loading={loading}
          privateClass={privateClass}
          certification={certification}
        />
      );
    }
  }

  return HOC;
};

export default WithPrivateClass;
