import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ListingTable from './ListingTable';
import EventBus from '../../packs/event_bus';
import { get } from '../../api/base';
import sleep from '../../utils/sleep';

class Reactive extends React.Component {
  constructor(props) {
    super(props);
    const { items } = props;

    this.state = { items };
  }

  componentDidMount() {
    const { events, items } = this.props;

    this.eventSubscriptionId = EventBus.subscribe(
      events,
      _.debounce(this.reloadItems, 500, { leading: true }),
      this,
    );

    if (!items || items.length === 0) {
      this.reloadItems();
    }
  }

  componentWillUnmount() {
    EventBus.unsubscribe(this.eventSubscriptionId);
  }

  reloadItems(wait = 100) {
    const { endpoint } = this.props;

    sleep(wait).then(() => {
      get(endpoint)
        .then((response) => (response.json()))
        .then(({ data }) => {
          this.setState({ items: data });
        });
    });
  }

  render() {
    const {
      events,
      endpoint,
      wait,
      ...listingTableProps
    } = this.props;

    const { items } = this.state;

    return (
      <ListingTable
        {...listingTableProps}
        items={items}
      />
    );
  }
}

Reactive.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  events: PropTypes.arrayOf(PropTypes.string),
  endpoint: PropTypes.string.isRequired,
  wait: PropTypes.number,
};

Reactive.defaultProps = {
  items: [],
  events: [],
  wait: 200,
};

export default Reactive;
