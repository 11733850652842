import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import $ from 'jquery';
import _ from 'lodash';

import Money, { moneyToChartValue } from '../../utils/money';
import EventBus from '../../packs/event_bus';
import { get } from '../../api/base';
import sleep from '../../utils/sleep';
import { findOperationCategoryLabels } from './commons';

const labelsMapper = (labels) => (labels.reduce((hash, label, index) => {
  hash[label.label_id] = index + 1;

  return hash;
}, {
  legend: 0,
}));

const computeData = (operations, labels, category, { logic, labelsMapping }) => {
  const { category_id } = category;
  const rows = Array(labels.length + 1).fill(null);

  rows[0] = ['Label', 'Amount'];
  // rows[1] = ['Unspecified', Money({ cents: 0 })];

  // Assign the name for each label
  labels.forEach(({ label_id, name }) => {
    const index = labelsMapping[label_id];
    rows[index] = [name, Money()];
  });

  if (rows.length === 1) {
    rows[1] = ['Unspecified', Money({ cents: 0 })];
  }

  operations.forEach((datum) => {
    const { amount, category_labels } = datum;
    const categoryLabels = findOperationCategoryLabels(category_id, category_labels);
    const rawAmount = { ...amount };

    if (logic === 'negative') {
      rawAmount.cents *= -1;
    }

    categoryLabels.forEach((label) => {
      const { label_id, repartition } = label;

      const labelAmount = { ...rawAmount };
      labelAmount.cents *= repartition;

      const repartedAmount = Money(labelAmount);
      const index = labelsMapping[label_id]; // || 1;

      if (index) {
        rows[index][1] = rows[index][1].add(repartedAmount);
      }
    });
  });

  return _.compact(rows).map((row, rx) => {
    // Header row
    if (rx === 0) { return row; }

    return row.map((value, cx) => {
      // Month name
      if (cx === 0) { return value; }

      // Do not consider negative values
      if (value.isNegative()) {
        return moneyToChartValue(Money({ cents: 0 }));
      }

      return moneyToChartValue(value);
    });
  });
};

const reactiveEvents = [
  'FinancialAnalysis::Classification::PendingOperationClassificationRegistered',
  'FinancialAnalysis::Classification::OperationCategorized',
];

class PieChart extends React.Component {
  constructor(props) {
    super(props);

    const { operations, category, labels } = props;

    this.state = {
      operations,
      category,
      labelsMapping: labelsMapper(labels)
    };
  }

  componentDidMount() {
    $(document).on('shown.bs.tab', this.handleTabShown.bind(this));
    const { reactive } = this.props;
    if (!reactive) { return; }

    const { operations } = this.props;

    this.eventSubscriptionId = EventBus.subscribe(
      reactiveEvents,
      _.debounce(this.handleEventSubscription, 500, { leading: true }),
      this,
    );

    if (!operations || operations.length === 0) {
      this.reloadOperations();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { category, labels } = nextProps;

    this.setState({
      category,
      labelsMapping: labelsMapper(labels)
    });
  }

  componentWillUnmount() {
    $(document).off('shown.bs.tab', this.handleTabShown);

    if (this.eventSubscriptionId) {
      EventBus.unsubscribe(this.eventSubscriptionId);
      this.eventSubscriptionId = undefined;
    }
  }

  getOperations() {
    const { reactive } = this.props;

    return reactive
      ? this.state.operations
      : this.props.operations;
  }

  getCategory() {
    const { category } = this.state;

    return category;
  }

  reloadOperations(wait = 0) {
    const { workingYear, endpoint } = this.props;

    return sleep(wait).then(() => {
      get(endpoint)
        .then((response) => (response.json()))
        .then(({ data }) => {
          this.setState({ operations: data });
        });
    });
  }

  reloadCategory(wait = 0) {
    const category = this.getCategory();
    const { category_id } = category;
    const endpoint = `/financial_analysis/classification/categories/${category_id}.json`;

    return sleep(wait).then(() => {
      get(endpoint)
        .then((response) => (response.json()))
        .then((data) => {
          this.setState({ category: data });
        });
    });
  }

  labelsIndex(labelId) {
    const { labelsMapping } = this.state;

    return labelsMapping[labelId];
  }

  handleTabShown(event) {
    const $a = $(event.target);
    const category = this.getCategory();
    const operations = this.getOperations();
    const { category_id } = category;

    if (category_id === $a.data('category-id')) {
      // Force rerender
      sleep(10).then(() => {
        this.setState({ operations: [...operations] });
      });
    }
  }

  handleEventSubscription(event) {
    const { category_label_id, category_id } = event;
    const category = this.getCategory();

    if (category.category_id !== category_id) { return; }

    if (this.findLabelId(category_label_id)) {
      this.reloadOperations(200);
    } else {
      this.reloadCategory(50).then(() => {
        this.reloadOperations(200);
      });
    }
  }

  findLabelId(labelId) {
    const { labels } = this.props;

    return labels.find((label) => (label.label_id === labelId));
  }

  render() {
    const { labelsMapping } = this.state;
    const { logic, labels } = this.props;
    const category = this.getCategory();
    const { label } = category;
    const operations = this.getOperations();
    const data = computeData(operations, labels, category, { logic, labelsMapping });
    const slices = labels.reduce((hash, { label_id, color }) => {
      const index = this.labelsIndex(label_id);
      hash[index - 1] = { color };

      return hash;
    }, {});

    const title = logic === 'negative'
      ? `Yearly expenses per ${label}`
      : `Yearly revenues per ${label}`;

    return (
      <div>
        <Chart
          loader={<div>Loading Chart...</div>}
          chartType="PieChart"
          width="100%"
          height="450px"
          data={data}
          options={{
            title,
            pieHole: 0.4,
            legend: 'none',
            backgroundColor: 'transparent',
            is3D: false,
            slices,
          }}
        />
      </div>
    );
  }
}

PieChart.propTypes = {
  logic: PropTypes.string,
  operations: PropTypes.arrayOf(PropTypes.object),
  reactive: PropTypes.bool,
  workingYear: PropTypes.number,
  labels: PropTypes.arrayOf(PropTypes.object),
};

PieChart.defaultProps = {
  logic: 'positive',
  operations: [],
  reactive: true,
  labels: [],
};

export default PieChart;

