import React, { useContext } from 'react';

import CalendarToolbar from './CalendarToolbar';
import CalendarEventModal from '../CalendarEventModal';
import WeekViewCalendar from '../../WeekViewCalendar';
import Context from './Context';
import Icon from '../../Icon';

const Calendar = () => {
  const {
    weeksData,
    loadNextCalendarWeek,
    setSelectedEvent,
    selectedEvent,
    resetSelectedEvent,
    setSortingMode,
    sortingMode,
    sortEvents,
    priorityFilter,
    setPriorityFilter,
    filterEvent,
    viewMode,
    setViewMode,
    onEventUpdate,
    labels,
    onLabelToggle,
    onLabelCreate,
    onLabelUpdate,
    onLabelDelete,
    user,
  } = useContext(Context);

  const handleLoadMore = () => {
    loadNextCalendarWeek();
  };

  const handleEventFocus = (event) => {
    setSelectedEvent(event);
  };

  const handleDayFocus = (day) => {
    // console.log('handleDayFocus', { day });
    // setSelectedDay(day);
  };

  const setFilter = (value) => {
    setPriorityFilter(value);
  };
  const showSelectedEvent = !!selectedEvent;

  return (
    <div className="Calendar">
      <CalendarToolbar
        initialViewMode={viewMode}
        onViewModeChange={setViewMode}
        initialPriority={priorityFilter}
        onPriorityChange={setFilter}
        initialSortingMode={sortingMode}
        onSortingModeChange={setSortingMode}
      />

      <div className="CalendarEvents card">
        {
          selectedEvent && (
            <CalendarEventModal
              user={user}
              event={selectedEvent}
              show={showSelectedEvent}
              onClose={resetSelectedEvent}
              onUpdate={onEventUpdate}
              labels={labels}
              onLabelToggle={(label) => { onLabelToggle({ label, event: selectedEvent }); }}
              onLabelCreate={onLabelCreate}
              onLabelUpdate={onLabelUpdate}
              onLabelDelete={onLabelDelete}
            />
          )
        }

        <WeekViewCalendar
          weeksData={weeksData}
          onDayFocus={handleDayFocus}
          onEventFocus={handleEventFocus}
          sortEvents={sortEvents}
          filterEvent={filterEvent}
          viewMode={viewMode}
          labels={labels}
        />

        <div className="card-body text-center">
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => { handleLoadMore(); }}
          >
            <Icon name="calendar-alt" fw />
            &nbsp;
            Load next week..
          </button>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
