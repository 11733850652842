import { get } from './base';

export const accountsTransactions = () => (
  get('/financial_analysis/accounts.json')
);

export const accountTransactions = ({ id }) => (
  get(`/financial_analysis/accounts/${id}.json`)
);

export default {
  accountsTransactions,
};
