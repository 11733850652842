export const camelizeOperationProps = (operation) => {
  const {
    id,
    state,
    operation_headline,
    counterpart,
    amount,
    operation_date,
    operation_notes,
    operation_reference,
    operation_country,
    operation_document_url,
    operation_type,
    credit_note_id,
    human_operation_type,
    category_labels,
    categories,
    typology,
  } = operation;

  return {
    id,
    state,
    operationHeadline: operation_headline,
    counterpart,
    amount,
    categoryLabels: category_labels,
    categories,
    typology,
    operationType: operation_type,
    humanOperationType: human_operation_type,
    operationDate: operation_date,
    operationReference: operation_reference,
    operationDocumentUrl: operation_document_url,
    operationNotes: operation_notes,
    operationCountry: operation_country,
    creditNoteId: credit_note_id,
  };
};

// TODO: check me
export const generateUrl = (id = null, action = null) => {
  const baseUrl = '/billing/issued_invoices';
  const urlChunks = [baseUrl];

  if (id) { urlChunks.push(id); }
  if (action) { urlChunks.push(action); }

  return urlChunks.join('/');
};

export default {
  camelizeOperationProps,
  generateUrl,
}
