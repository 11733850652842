import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import Icon from '../../Icon';
import CancelModal from './CancelModal';
import { success } from '../../../utils/notifier';
import { visit } from '../../../utils/router';
import { indexPath } from '../../../routes/trainings/planned_events';
import { eventShape } from '../utils';

const CancelButton = ({ event, size, className }) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const hideCancelModal = () => { setShowCancelModal(false); };
  const { acl, training_typology, human_training_typology } = event;
  const { canCancel } = acl;

  if (!canCancel) { return ''; }

  const handleCancelButtonClick = () => {
    setShowCancelModal(true);
  };

  const handleCancelConfirmation = ({ response }) => {
    visit(indexPath(training_typology)).then(() => { success({ message: response.message }); });
  };

  return (
    <div className="delete-button d-inline-block">
      <CancelModal
        show={showCancelModal}
        event={event}
        onHide={hideCancelModal}
        onConfirm={handleCancelConfirmation}
      />

      <Button
        size={size}
        variant="danger"
        title={`Delete ${human_training_typology}`}
        onClick={handleCancelButtonClick}
        className={className}
      >
        <Icon name="trash" fw />
        &nbsp;
        Delete
      </Button>
    </div>
  );
};

CancelButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  event: eventShape.isRequired,
};

CancelButton.defaultProps = {
  size: 'md',
  className: '',
};

export default CancelButton;
