import React from 'react';
import _ from 'lodash';

import EventBus from '../../packs/event_bus';
import { issuedInvoices as fetchIssuedInvoices } from '../../api/billing/issued_invoices';

import sleep from '../../utils/sleep';

const decorateIssuedInvoice = (issuedInvoice) => ({
  ...issuedInvoice,
});

const decorateIssuedInvoices = (issuedInvoices) => issuedInvoices.map(decorateIssuedInvoice);

const WithIssuedInvoices = (WrappedComponent) => {
  class HOC extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        issuedInvoices: [],
        loading: true,
      };
    }

    componentDidMount() {
      this.issuedInvoiceEventsSubscriptionId = EventBus.subscribe(
        [
          'Billing::IssuedInvoices::IssuedInvoiceRegistered',
          'Billing::IssuedInvoices::IssuedInvoiceUpdated',
          'Billing::CreditNotes::CreditNoteRegistered',
          'Billing::CreditNotes::CreditNoteUpdated',
        ],
        _.debounce(
          this.handleIssuedInvoicesEventsSubscription,
          500,
          { leading: true },
        ),
        this,
      );

      this.reloadIssuedInvoices();
    }

    // componentWillUnmount() {
    //   EventBus.unsubscribe(this.issuedInvoicesEventsSubscriptionId);
    // }

    handleOnDelete({ issuedInvoiceId }) {
      const { issuedInvoices } = this.state;
      const filteredIssuedInvoices = issuedInvoices.filter((pe) => (
        pe.id !== issuedInvoiceId
      ));

      this.setIssuedInvoices(filteredIssuedInvoices);
    }

    handleOnUpdate({ issuedInvoice }) {
      const { issuedInvoices } = this.state;
      const { id } = issuedInvoice;
      const updatedIssuedInvoices = issuedInvoices.map((pe) => (
        pe.id === id ? issuedInvoice : pe
      ));

      this.setIssuedInvoices(updatedIssuedInvoices);
    }

    handleIssuedInvoicesEventsSubscription() {
      this.reloadIssuedInvoices(100);
    }

    setIssuedInvoices(plainIssuedInvoices) {
      const issuedInvoices = decorateIssuedInvoices(plainIssuedInvoices);

      this.setState({
        loading: false,
        issuedInvoices,
      });
    }

    reloadIssuedInvoices(wait = 0) {
      return sleep(wait).then(() => {
        fetchIssuedInvoices().then((data) => {
          this.setIssuedInvoices(data);
        });
      });
    }

    render() {
      const {
        issuedInvoices,
        loading,
      } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          loading={loading}
          issuedInvoices={issuedInvoices}
          onDelete={(args) => { this.handleOnDelete(args); }}
          onUpdate={(args) => { this.handleOnUpdate(args); }}
        />
      );
    }
  }

  return HOC;
};

export default WithIssuedInvoices;
