import React from 'react';
import PropTypes from 'prop-types';

import ListingTable from './ListingTable';
import { get } from '../../api/base';
import EventBus from '../../packs/event_bus';
import sleep from '../../utils/sleep';

// NOTE: Extends Reactive ListingTable?
class Infinite extends React.Component {
  constructor(props) {
    super(props);

    this.state = { items: [], meta: false, loading: false };
  }

  componentDidMount() {
    const { events, items } = this.props;

    this.eventSubscriptionId = EventBus.subscribe(
      events,
      _.debounce(this.resetAndReloadItems, 500, { leading: true }),
      this,
    );

    this.loadNextPage();
  }

  resetAndReloadItems(wait = 100) {
    sleep(wait).then(() => {
      this.setState({
        items: [],
        meta: false,
        loading: false
      });

      this.loadNextPage();
    });
  }

  getNextEndpoint() {
    const { initialEndpoint } = this.props;
    const { meta } = this.state;

    return meta ? meta.next_url : initialEndpoint;
  }

  loadNextPage() {
    if (!this.hasMorePages()) { return; }

    const { loading } = this.state;
    if (loading) { return; }

    this.setState({ loading: true });

    const nextEndpoint = this.getNextEndpoint();

    get(nextEndpoint)
      .then((response) => (response.json()))
      .then((response) => {
        const { data, meta } = response;
        // JSON API
        const newItems = data.map(({ attributes }) => (attributes));

        this.setState((prevState) => ({
          items: [...prevState.items, ...newItems],
          loading: false,
          meta,
        }));
      });
  }

  hasMorePages() {
    const { meta } = this.state;
    if (!meta) { return true; }

    const { page, pages } = meta;
    return page < pages;
  }

  render() {
    const { items, loading } = this.state;
    const infiniteProps = {
      loadMore: () => { this.loadNextPage(); },
      hasMore: this.hasMorePages(),
      loading,
    };

    return (
      <ListingTable
        {...this.props}
        items={items}
        infiniteProps={infiniteProps}
        infinite
      />
    );
  }
}

Infinite.propTypes = {
  initialEndpoint: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(PropTypes.string),
  wait: PropTypes.number,
};

Infinite.defaultProps = {
  events: [],
  wait: 200,
};

export default Infinite;
