export const insertElement = (index, item, array) => [
  ...array.slice(0, index),
  item,
  ...array.slice(index),
];

export const updateElementById = (id, params, array) => (
  array.map((item) => (item.id === id ? params : item))
);

export const removeElementById = (id, array) => (
  array.filter((item) => (item.id !== id))
);

export default {
  insertElement,
  updateElementById,
  removeElementById,
};
