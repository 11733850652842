import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import EventBus from '../../../packs/event_bus';
import { trainerPublicEvents } from '../../../api/trainers/trainers';
import sleep from '../../../utils/sleep';
import handleError from '../../../utils/error-handler';

const decoratePublicEvent = (publicEvent, attributes = {}) => ({
  ...publicEvent,
  ...attributes,
});
const decoratePublicEvents = (publicEvents, attributes = {}) => (
  publicEvents.map((ca) => (decoratePublicEvent(ca, attributes)))
);

const WithTrainerPublicEvents = (WrappedComponent) => {
  class TrainerPublicEventsHOC extends React.Component {
    constructor(props) {
      super(props);

      this.debouncedLoadTrainerPublicEvents = debounce(
        this.loadTrainerPublicEvents,
        500,
        { leading: true },
      );

      this.state = {
        publicEvents: [],
        loading: true,
      };
    }

    componentDidMount() {
      const events = [
        'Trainings::PublicEdition::TrainerAssigned',
        'Trainings::PublicEdition::TrainerUnassigned',
        'Trainings::PublicEdition::TrainersSet',
      ];

      this.publicEventsEventsSubscriptionId = EventBus.subscribe(
        events,
        this.handleTrainerPublicEventsEventsSubscription,
        this,
      );

      this.loadTrainerPublicEvents();
    }

    componentWillUnmount() {
      EventBus.unsubscribe(this.publicEventsEventsSubscriptionId);
    }

    handleTrainerPublicEventsEventsSubscription() {
      this.debouncedLoadTrainerPublicEvents();
    }

    // handleOnDelete({ id }) {
    //   const { publicEvents, certification } = this.state;
    //   const publicEventId = id;
    //   const certificationId = certification.id;
    //   const filteredTrainerPublicEvents = publicEvents.filter((ca) => (ca.id !== id));

    //   return deletePublicEvent({ certificationId, publicEventId })
    //     .then((response) => response.json())
    //     .then((response) => {
    //       success({ message: response.message });
    //       this.setTrainerPublicEvents(filteredTrainerPublicEvents);
    //     })
    //     .catch(handleError);
    // }

    // handleOnUpdate({ publicEvent }) {
    //   const { publicEvents } = this.state;
    //   const { id } = publicEvent;
    //   const updatedTrainerPublicEvents = publicEvents.map((ca) => (
    //     ca.id === id ? publicEvent : ca
    //   ));

    //   this.setTrainerPublicEvents(updatedTrainerPublicEvents);
    // }

    setTrainerPublicEvents(plainPublicEvents, attributes = {}) {
      const publicEvents = decoratePublicEvents(
        plainPublicEvents,
        attributes,
      );

      this.setState({
        loading: false,
        publicEvents,
      });
    }

    loadTrainerPublicEvents(wait = 0) {
      const { trainer } = this.props;
      const { id } = trainer;

      return sleep(wait).then(() => {
        trainerPublicEvents({ trainerId: id })
          .then((response) => (response.json()))
          .then(({ data }) => {
            this.setTrainerPublicEvents(data.map(({ attributes }) => (attributes)));
          })
          .catch(handleError);
      });
    }

    render() {
      const { trainer } = this.props;
      const { publicEvents, loading } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          loading={loading}
          trainer={trainer}
          publicEvents={publicEvents}
        />
      );
    }
  }

  TrainerPublicEventsHOC.propTypes = {
    trainer: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  return TrainerPublicEventsHOC;
};

export default WithTrainerPublicEvents;
