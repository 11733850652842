import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import classnames from 'classnames';

import Spinner from '../../Spinner';
import Context from './Context';

import ListingTable from '../../ListingTable';
import Row from './Row';

import BlankState from '../../BlankState';

import { newPath } from '../../../routes/smart_planner/recipes';

const filterKeys = ['account_name', 'provider'];
const headers = ['Color', 'Name', 'Description', 'Actions'];
const sortableColumns = {
  Color: 'color',
  Name: 'name',
  Description: 'description',
};

const Datatable = () => {
  const {
    loading,
    recipes,
    deleteRecipe,
  } = useContext(Context);

  const onDelete = (recipe) => {
    deleteRecipe(recipe);
  };

  if (loading) {
    return (
      <div className={classnames('Datatable', 'card')}>
        <div className="card-body">
          <Spinner description="Loading your recipes..." />
        </div>
      </div>
    );
  }

  if (recipes.length === 0) {
    return (
      <div className={classnames('Datatable', 'card')}>
        <BlankState
          buttonProps={{ href: newPath() }}
          buttonLabel="Add recipe"
          description="No recipes configured"
          iconFamily="fas"
          icon="shapes"
        />
      </div>
    );
  }

  return (
    <div className="Datatable">
      <div className={classnames('Datatable', 'card')}>
        <div className="card-body">
          <ListingTable
            items={recipes}
            filterKeys={filterKeys}
            headers={headers}
            sortableColumns={sortableColumns}
            headerRowProps={{ className: 'text-nowrap' }}
            components={{ Row }}
            rowProps={{ onDelete }}
          />
        </div>
      </div>
    </div>
  );
};

export default Datatable;
