import React, {
  useState,
  createContext,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
// import { debounce } from 'lodash';

import { reconcileExpectations } from '../../../api/forecast/reconciliations';
import { deleteExpectation } from '../../../api/forecast/expectations';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';
// import EventBus from '../../../packs/event_bus';
import UUID from '../../../utils/uuid';
import sleep from '../../../utils/sleep';

const Context = createContext({});

export const Provider = ({
  children,
  currentUserId,
  countries,
  currencies,
  accounts,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [unreconciledExpectations, setUnreconciledExpectations] = useState([]);
  const [plannedExpectations, setPlannedExpectations] = useState([]);
  const [newExpectations, setNewExpectations] = useState([]);
  const [processedReconciliations, setProcessedReconciliations] = useState([]);
  const [reconciliations, setReconciliations] = useState([]);

  const resetSelection = (view) => {
    const resetCurrentViewExpectations = (items) => (
      items.filter(({ direction }) => (
        view === 'invoices'
          ? direction === 'out' // keep out items
          : direction === 'in'  // keep in  items
      ))
    );

    setUnreconciledExpectations(resetCurrentViewExpectations);
    setPlannedExpectations(resetCurrentViewExpectations);
    setNewExpectations(resetCurrentViewExpectations);
  };

  const handleUnreconciledExpectationClick = (item) => {
    setUnreconciledExpectations((expectations) => {
      const expectationIds = expectations.map(({ id }) => (id));

      if (expectationIds.includes(item.id)) {
        return expectations.filter(({ id }) => (id !== item.id));
      } else {
        return [...expectations, item];
      }
    });
  };

  const handlePlannedExpectationClick = (item) => {
    setPlannedExpectations((expectations) => {
      const expectationIds = expectations.map(({ id }) => (id));

      if (expectationIds.includes(item.id)) {
        return expectations.filter(({ id }) => (id !== item.id));
      } else {
        return [...expectations, item];
      }
    });
  };

  const handleNewExpectation = (newExpectation) => {
    setNewExpectations((expectations) => ([
      ...expectations,
      {
        ...newExpectation,
        id: UUID.generate(),
      },
    ]));
  };

  const handleReconciliation = ({ 
    view, 
    unreconciledExpectations, 
    plannedExpectations,
    newExpectations,
  }) => {
    resetSelection(view);

    setReconciliations((entries) => ([
      ...entries,
      {
        id: UUID.generate(),
        view,
        unreconciledExpectations,
        plannedExpectations,
        newExpectations,
      },
    ]))
  };

  const handleSubmit = ({ view, reconciliations }) => {
    setIsLoading(true);

    const promises = reconciliations.map(({ id, plannedExpectations, unreconciledExpectations, newExpectations }) => (
      reconcileExpectations({
        id,
        plannedExpectationIds: plannedExpectations.map(({ id }) => (id)),
        unreconciledExpectationIds: unreconciledExpectations.map(({ id }) => (id)),
        newExpectations,
        costCenter: plannedExpectations[0]?.cost_center || 'AVANSCOPERTA',
      })
    ));

    Promise.all(promises).then(() => {
      sleep(500).then(() => {
        resetSelection(view);
        setProcessedReconciliations((prevReconciliations) => ([
          ...prevReconciliations,
          ...reconciliations,
        ]));
        setReconciliations([]);
        setIsLoading(false);
        const message = 'Reconciliations successfully completed';
        success({ message });
      });
    });
  };

  const handleReconciliationCancellation = (item) => {
    setReconciliations((currReconciliations) => (
      currReconciliations.filter((r) => (r.id !== item.id))
    ));
  };

  const handleExpectationDelete = (item) => {
    deleteExpectation(item.id)
      .then((response) => (response.json()))
      .then((data) => {
        success({ message: data.message });
      })
      .catch(handleError);
  };

  const contextState = {
    isLoading,
    setIsLoading,
    currentUserId,
    countries,
    currencies,
    accounts,
    onUnreconciledExpectationClick: handleUnreconciledExpectationClick,
    onPlannedExpectationClick: handlePlannedExpectationClick,
    unreconciledExpectations,
    plannedExpectations,
    processedReconciliations,
    reconciliations,
    onReconcile: handleReconciliation,
    onSubmit: handleSubmit,
    onReconciliationCancellation: handleReconciliationCancellation,
    onNewExpectation: handleNewExpectation,
    newExpectations,
    onExpectationDelete: handleExpectationDelete,
  };

  const expirationValues = [
    unreconciledExpectations,
    plannedExpectations,
    reconciliations,
    newExpectations,
    isLoading,
  ];

  // The shared state
  const value = useMemo(() => (contextState), expirationValues);

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
};

Provider.defaultProps = {
};

export default Context;

