import React from 'react';
import PropTypes from 'prop-types';

import Icon from './Icon';

const DashboardCounterWidget = (({
  wip,
  icon,
  counter,
  link,
  label,
  description,
}) => {
  const wipClass = wip === true ? 'wip' : '';

  return (
    <div className={`${wipClass} card-condensed`}>
      <a href={link} className="card-header">
        <Icon name={icon} className="card-icon" size="5x" />

        <div>
          <div className="text-value-big text-right font-weight-bold">
            {counter || '-'}
          </div>
          <div className="text-muted text-right text-uppercase font-weight-bold small">
            {label}
          </div>
          <div className="text-muted text-right small">
            &nbsp;
            {description}
          </div>
        </div>
      </a>
    </div>
  );
});

DashboardCounterWidget.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  counter: PropTypes.number,
  icon: PropTypes.string,
  wip: PropTypes.bool,
};

DashboardCounterWidget.defaultProps = {
  label: 'Counter Widget',
  description: '',
  link: '/',
  counter: 100,
  icon: 'info',
  wip: false,
};

export default DashboardCounterWidget;
