import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import classnames from 'classnames';

import { eventLabels } from './utils';
import styles from './WeekViewCalendar.module.scss';

const LabelsCloud = ({ event, labels }) => {
  const { label_ids } = event;
  const realLabels = eventLabels({ event, labels });

  return (
    <div className={styles.LabelsCloud}>
      {
        realLabels.map(({ id, name, color }) => (
          <OverlayTrigger
            key={id}
            placement="bottom"
            overlay={(
              <Tooltip>
                {name}
              </Tooltip>
            )}
          >
            <span
              className={classnames(styles.LabelsCloud__item, 'badge')}
              style={{ backgroundColor: color }}
              title={name}
            >
              {name}
            </span>
          </OverlayTrigger>
        ))
      }
    </div>
  );
};

export default LabelsCloud;
