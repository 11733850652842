import React from 'react';
import classnames from 'classnames';

import InfiniteListingTable from '../../ListingTable/Infinite';
import Icon from '../../Icon';
import Row from './HistoryRow';

const events = [
  'Forecast::Reconciliations::Events::ExpectationsReconciled',
];

const loadUrl = '/financial_analysis/reconciliations.json';

const wrapperStyles = {
  minHeight: '400px',
  height: 'calc(100vh - 460px)',
  overflowX: 'hidden',
  overflowY: 'auto',
};

const History = ({ }) => {

  return (
    <div>
      <InfiniteListingTable
        idColumn="id"
        events={events}
        initialEndpoint={loadUrl}
        components={{ Row }}
        wrapperStyles={wrapperStyles}
        headers={['Reconciled at', 'User', 'Summary']}
        showSearch={false}
      />
    </div>
  );
};

export default History;
