import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import { DateTime } from 'luxon';
import moment from 'moment';
import _ from 'lodash';


import Icon from '../../Icon';
import Row from '../../ListingTable/Row';
import ConfirmModal from './ConfirmModal';
import CancelModal from './CancelModal';
import EventStatusBadge from './EventStatusBadge';
import { eventShape } from '../utils';

const HeadlineCell = ({ detailsPath, headline }) => (
  <td className="h5">
    <a href={detailsPath} title={headline} className="d-block text-truncate" style={{ maxWidth: 300 }}>
      { headline }
    </a>
  </td>
);

const DateCell = ({ date, small, className }) => {
  if (!date) {
    return (
      <td className=""> - </td>
    );
  }

  return (
    <td className={classnames('text-nowrap', className, { h5: !small, h6: small })}>
      <Icon name="calendar" family="far" fw />
      &nbsp;
      {moment(date).format('D/M/Y')}
    </td>
  );
};

const ConfirmationDateCell = ({ startsOn, confirmationDate, trainingStatus }) => {
  const confirmationDateDT = DateTime.fromISO(confirmationDate);

  const diff = confirmationDate
    ? DateTime.fromISO(confirmationDate).diff(DateTime.now(), 'days').days
    : 100;

  if (!confirmationDate) {
    return (
      <td className="h6"> - </td>
    );
  }

  const toBeConfirmed = _.includes([
   'draft',
   'unconfirmed',
  ], trainingStatus);

  // const isDanger =
  return (
    <DateCell
      className={classnames({
        'text-danger font-weight-bold': toBeConfirmed && diff < 0,
        'text-warning font-weight-bold': toBeConfirmed && diff <= 7 && diff >= 0,
      })}
      date={confirmationDate}
      small
    />
  );
};

const TrainersCell = ({ trainersName }) => {
  if (!trainersName) {
    return (
      <td className="h6">
        -
      </td>
    );
  }

  return (
    <td className="h6 text-nowrap">
      <Icon name="user-alt" fw />
      &nbsp;
      { trainersName }
    </td>
  );
};

const VenueCell = ({ venue }) => {
  if (!venue) {
    return (
      <td className="h6">
        <Icon name="globe-africa" fw />
        &nbsp;
        Online
      </td>
    );
  }

  return (
    <td className="h6">
      <Icon name="map-marker" fw />
      &nbsp;
      { venue }
    </td>
  );
};

const StatusCell = ({ status, title }) => (
  <td className="text-center">
    <EventStatusBadge
      title={title}
      status={status}
      className="text-uppercase w-100"
    />
  </td>
);

const TicketsProgressBar = ({ ticketsSold, capacity }) => {
  const ticketProgress = ticketsSold
    ? (ticketsSold / capacity) * 100.0
    : 0;

  const label = ticketProgress >= 100 ? 'SOLD OUT' : '';

  return (
    <div className="progress progress-mini">
      <div
        style={{ width: `${ticketProgress}%` }}
        className={
          classnames('progress-bar', {
            'bg-success': ticketProgress >= 100,
            'bg-warning': ticketProgress < 50,
          })
        }
      >
        { label }
      </div>
    </div>
  );
};

const TicketsCell = ({ ticketsSold, capacity }) => (
  <td className="h6 text-nowrap">
    <div className="small text-center pb-1">
      Tickets Sold:
      &nbsp;
      <strong>{ticketsSold} of {capacity}</strong>
    </div>

    <TicketsProgressBar ticketsSold={ticketsSold} capacity={capacity} />
  </td>
);

// TODO: Use ACL
const CertificationButton = ({ status, href }) => {
  if (status === 'unconfirmed') { return ''; }

  return (
    <a href={href} className="btn btn-sm btn-light" title="Certificates">
      <Icon name="stamp" fw />
    </a>
  );
};

const CancelButton = ({ item, onClick }) => {
  const { human_training_typology, acl } = item;
  const { canCancel } = acl;

  if (!canCancel) { return ''; }

  return (
    <Button
      size="sm"
      variant="danger"
      title={`Delete ${human_training_typology}`}
      onClick={onClick}
    >
      <Icon name="trash" fw />
    </Button>
  );
};

const ConfirmButton = ({ item, onClick }) => {
  const { human_training_typology, acl } = item;
  const { canConfirm } = acl;

  if (!canConfirm) { return ''; }

  return (
    <Button
      size="sm"
      variant="success"
      title={`Confirm ${human_training_typology}`}
      onClick={onClick}
    >
      <Icon name="check" fw />
    </Button>
  );
};

const EventRow = (props) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const hideCancelModal = () => { setShowCancelModal(false); };
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const hideConfirmModal = () => { setShowConfirmModal(false); };
  const { item, onDelete, onUpdate } = props;
  const {
    headline,
    details_path,
    trainers_name,
    venue,
    composite_status,
    training_status,
    sales_registrations_status,
    starts_on,
    tickets_sold,
    initial_capacity,
    confirmation_date,
  } = item;
  const showPath = (tab) => (`${details_path}?view=${tab}`);
  const { status } = composite_status;
  const hasForecast = item['enabled_forecast?'];

  const handleCancelButtonClick = () => { setShowCancelModal(true); };
  const handleConfirmButtonClick = () => { setShowConfirmModal(true); };
  const handleConfirmation = () => {
    onUpdate({
      plannedEvent: {
        ...item,
        status: 'confirmed',
        _status: 'confirmed',
      },
    });
  };

  return (
    <Row {...props}>
      <DateCell
        date={starts_on}
      />

      <HeadlineCell
        headline={headline}
        detailsPath={details_path}
      />

      <TrainersCell
        trainersName={trainers_name}
      />

      <VenueCell
        venue={venue}
      />

      <StatusCell
        title={composite_status.message}
        status={status}
      />

      <StatusCell
        title={`Registrations sales ${sales_registrations_status}`}
        status={sales_registrations_status}
      />

      <td className="text-center">
        <a href={showPath('forecast')}>
          {
            hasForecast 
              ? (<span className=" w-100 text-nowrap badge badge-success"><Icon name="check" fw />RUNNING</span>)
              : (<span className=" w-100 text-nowrap badge badge-danger"><Icon name="times" fw />NONE</span>)
          }
        </a>
      </td>

      <TicketsCell
        capacity={initial_capacity}
        ticketsSold={tickets_sold}
      />

      <td className="h5 text-center">
        <div className="btn-group">
          <CancelModal
            show={showCancelModal}
            event={item}
            onHide={hideCancelModal}
            onConfirm={onDelete}
          />

          <ConfirmModal
            show={showConfirmModal}
            event={item}
            onHide={hideConfirmModal}
            onConfirm={handleConfirmation}
          />

          <Button size="sm" variant="info" href={details_path} title="Details">
            <Icon name="info" fw />
          </Button>

          <ConfirmButton
            item={item}
            onClick={handleConfirmButtonClick}
          />

          <CancelButton
            item={item}
            onClick={handleCancelButtonClick}
          />
        </div>
      </td>
    </Row>
  );
};

EventRow.propTypes = {
  item: eventShape.isRequired,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

EventRow.defaultProps = {
  onUpdate: () => {},
  onDelete: () => {},
};

export default EventRow;
