import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';

import DeleteStepModal from './DeleteStepModal';
import DeleteButton from './DeleteButton';
import MomentStepCaption from './MomentStepCaption';
import OffsetStepCaption from './OffsetStepCaption';
import { convertFromValue } from '../../inputs/TimeDurationInput/utils';
import { footprintStepShape } from '../proptypes';
import styles from './styles.module.scss';

const Step = ({
  step,
  position,
  onClick,
  onDelete,
  className,
  layout,
  readOnly,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const hideDeleteModal = () => { setShowDeleteModal(false); };
  const {
    description,
    duration,
    priority,
  } = step;

  const humanDuration = convertFromValue(duration, 'm');
  const handleDeleteClick = () => { setShowDeleteModal(true); };
  const handleDeleteConfirm = () => { onDelete(step); };
  const { step_type } = step;

  const isVertical = layout === 'vertical';
  const isHorizontal = layout === 'horizontal';

  return (
    <div
      className={classnames(styles.Step, className, {
        'd-flex': isVertical ,
        'd-inline-block': isHorizontal,
        'mr-2 mb-2': isHorizontal,
      })}
    >
      {
        !readOnly && (
          <>
            <DeleteStepModal
              show={showDeleteModal}
              onHide={hideDeleteModal}
              onDelete={handleDeleteConfirm}
              step={step}
            />
            <DeleteButton
              onClick={handleDeleteClick}
            />
          </>
        )
      }

      {
        step_type === 'offset' && (
          <OffsetStepCaption
            onClick={() => { onClick({ step, position, step_type }); }}
            readOnly={readOnly}
            position={position}
            step={step}
          />
        )
      }

      {
        step_type === 'moment' && (
          <MomentStepCaption
            onClick={() => { onClick({ step, position, step_type }); }}
            readOnly={readOnly}
            position={position}
            step={step}
          />
        )
      }
    </div>
  );
};

Step.propTypes = {
  position: PropTypes.number.isRequired,
  className: PropTypes.string,
  step: footprintStepShape.isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  layout: PropTypes.string,
  readOnly: PropTypes.bool,
};

Step.defaultProps = {
  className: null,
  onClick: () => {},
  onDelete: () => {},
  layout: 'vertical',
  readOnly: false
};

export default Step;
