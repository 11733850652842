import React from 'react';
import PropTypes from 'prop-types';

import { Provider } from './Context';
import Body from './Body';

const RiconciliationPage = (props) => (
  <Provider {...props}>
    <div className="RiconciliationPage">
      <Body />
    </div>
  </Provider>
);

RiconciliationPage.propTypes = {
  currentUserId: PropTypes.string.isRequired,
};

RiconciliationPage.defaultProps = {
};

export default RiconciliationPage;

