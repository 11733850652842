import React from "react"

class BusyPersonRow extends React.Component {
    render() {
        const id = this.props._id;

        return (
            <tr key={id}>
                <td style={{ width: '20%' }}>
                    {this.props.first_name} <strong>{this.props.last_name}</strong>
                </td>
                <td>
                    <a href={"/smart_planner/busy_persons/" + id}><big>{this.props.email}</big></a>
                </td>

                <td className="table-actions" style={{ width: '10%' }}>
                    <div className="btn-group">
                        <a href={`/smart_planner/busy_persons/${id}`} className="btn btn-light"><i className="fa fa-eye"></i> View </a>
                        <a href={`/smart_planner/busy_persons/${id}/edit`} className="btn btn-light"><i className="fa fa-pencil"></i> Edit </a>
                    </div>
                </td>
            </tr>
        )
    }
}

export default BusyPersonRow
