import PropTypes from 'prop-types';

export const trainerShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  full_name: PropTypes.string,
  email: PropTypes.string,
  public_page_url: PropTypes.string,
  picture_url: PropTypes.string,
});

export const attendeeShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
});

export const ticketShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
});

export default {
  trainerShape,
  attendeeShape,
  ticketShape,
};
