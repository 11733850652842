import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';
import _ from 'lodash';

import Icon from '../../Icon';
import Spinner from '../../Spinner';
import WithCertifiedAttendees from './WithCertifiedAttendees';
import DataTable from './CertifiedAttendeesDataTable';
import AddAttendeesModal from './AddAttendeesModal';

const CertifiedAttendeesPage = (({
  loading,
  certification,
  certifiedAttendees,
  onGenerateCertificates,
  onUpdate,
  onDelete,
  certificatesHost,
}) => {
  if (loading) {
    return (<Spinner description="Loading certified attendees" />);
  }

  const [showAddAttendeesModal, setShowAddAttendeesModal] = useState(false);
  const hideAddAttendeesModal = () => { setShowAddAttendeesModal(false); };
  const disabled = certifiedAttendees.find(({ isGeneratingPdf }) => (isGeneratingPdf));
  const handleAddAttendeesButtonClick = () => {
    setShowAddAttendeesModal(true);
  };

  const { id } = certification;
  const downloadPath = `/trainings/certification/${id}/certified_attendees/bulk_download`;
  const hasPdfs = certifiedAttendees.map(({ has_pdf }) => (has_pdf));
  const isDownloadDisabled = _.includes(hasPdfs, false);

  return (
    <div>
      {
        showAddAttendeesModal && (
          <AddAttendeesModal
            certification={certification}
            show={showAddAttendeesModal}
            onHide={hideAddAttendeesModal}
          />
        )
      }

      <div className="row my-2">
        <div className="col-md-6">
          <h4 className="mt-2">Certified attendees</h4>
        </div>

        <div className="col-md-6 text-right">
          <Button
            variant="secondary"
            className={classnames('mr-3')}
            onClick={handleAddAttendeesButtonClick}
          >
            <Icon name="plus" fw />
            Add attendees
          </Button>

          <Button
            variant="warning"
            disabled={disabled}
            className={classnames('mr-3', { disabled })}
            onClick={onGenerateCertificates}
          >
            <Icon name="file-pdf" fw />
            Generate PDFs
          </Button>

          <Button
            variant="primary"
            disabled={isDownloadDisabled}
            className={classnames({ disabled: isDownloadDisabled })}
            href={downloadPath}
            target="_blank"
          >
            <Icon name="save" fw />
            Bulk download
          </Button>
        </div>
      </div>

      <DataTable
        certifiedAttendees={certifiedAttendees}
        certification={certification}
        onUpdate={onUpdate}
        onDelete={onDelete}
        certificatesHost={certificatesHost}
      />
    </div>
  );
});

CertifiedAttendeesPage.propTypes = {
  certification: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  certifiedAttendees: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    isGeneratingPdf: PropTypes.bool,
    pdf_url: PropTypes.string,
  })).isRequired,
  loading: PropTypes.bool,
  onGenerateCertificates: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

CertifiedAttendeesPage.defaultProps = {
  loading: false,
};

export default WithCertifiedAttendees(CertifiedAttendeesPage);
