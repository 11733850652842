import { post } from './base';

export const stopRecurringExpectation = ({ expectationId, willStopOn }) => (
  post(
    `/financial_analysis/recurring_expectations/${expectationId}/stop.json`,
    { stop_recurring: { will_stop_on: willStopOn } },
  )
);

export const extendRecurringExpectation = ({ expectationId, params }) => (
  post(
    `/financial_analysis/recurring_expectations/${expectationId}/extend.json`,
    { extend_recurring: params },
  )
);

export default {
  stopRecurringExpectation,
  extendRecurringExpectation,
};
