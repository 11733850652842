import React from 'react';
import ProviderIcon from '../ProviderIcon';

const AccountDetails = ({ account }) => {
  const {
    account_name, description, label, provider,
  } = account;

  return (
    <div className="card">
      <div className="card-body">
        <h3>
          <ProviderIcon provider={provider} className="float-right" />
          Account details:
        </h3>

        <hr />

        <div className="">
          <dt className="">
            Account name:
          </dt>

          <dd className="">
            { account_name }
          </dd>

          <dt className="">
            Provider:
          </dt>
          <dd className="text-uppercase">
            { provider }
          </dd>

          <dt className="">
            Last sync:
          </dt>
          <dd className="text-uppercase">
            Show the last sync datetime...
          </dd>

          <dt className="">
            Description:
          </dt>
          <dd className="">
            { description || '...' }
          </dd>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
