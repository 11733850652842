import React, { useState } from 'react';
import en from 'date-fns/locale/en-US';
import 'resize-observer-polyfill/dist/ResizeObserver.global';
import RWS from '../../react-week-scheduler';
import classes from '../../react-week-scheduler/styles/styles.module.scss';

// const rangeStrings = [
//   ['2019-03-04 00:15', '2019-03-04 01:45'],
//   ['2019-03-05 09:00', '2019-03-05 10:30'],
//   ['2019-03-06 22:00', '2019-03-06 22:30'],
//   ['2019-03-07 01:30', '2019-03-07 03:00'],
//   ['2019-03-07 05:30', '2019-03-07 10:00'],
//   ['2019-03-08 12:30', '2019-03-08 01:30'],
//   ['2019-03-09 22:00', '2019-03-09 23:59'],
// ];

// const defaultSchedule = rangeStrings.map(range =>
//   range.map(dateString => new Date(dateString)),
// );

const WeekScheduler = ({ fad }) => {
  const { start_on, draft_allocations } = fad;
  const originDate = new Date(start_on);
  const initialSchedule = draft_allocations.map(({ min_start_at, min_end_at }) => ([
    new Date(min_start_at),
    new Date(min_end_at),
  ]));
  const [schedule, setSchedule] = useState(initialSchedule);

  return (
    <div>
      <RWS.SchedulerContext.Provider value={{ locale: en }}>
        <RWS.TimeGridScheduler
          classes={classes}
          originDate={originDate}
          schedule={schedule}
          onChange={setSchedule}
        />
      </RWS.SchedulerContext.Provider>
    </div>
  );
};

export default WeekScheduler;
