import React, { useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';

import Context from './Context';
import Icon from '../../Icon';
import SelectInput from '../../RHF/SelectInput';
import SliderInput from '../../RHF/SliderInput';
import DateInput from '../../RHF/DateInput';
import Input from '../../RHF/Input';
import {
  priorityMarks,
  startTimeHours,
  repeatEveryOptions,
} from '../utils';

const formDomId = 'multiple-available-slots-form';

const startTimeOptions = startTimeHours.map((hour) => ({
  value: hour,
  label: hour,
}));

const normalizeParams = ({ repeatEveryUnit, ...params }) => ({
  ...params,
  repeatEveryUnit: repeatEveryUnit.value,
});

const SearchForm = () => {
  const {
    isLoading,
    onSearch,
  } = useContext(Context);

  const { handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      from: new Date(),
      repeatEvery: 1,
      repeatEveryUnit: repeatEveryOptions[0],
      priority: 0,
    },
  });

  const onSubmit = (params) => {
    const normalizedParams = normalizeParams(params);
    onSearch(normalizedParams);
  };

  return (
    <div className="SearchMultipleAvailableSlotsPage__form">
      <Form onSubmit={handleSubmit(onSubmit)} id={formDomId}>
        <h5>Fill out the form fields to start a new search</h5>

        <hr />

        <div className="row">
          <div className="col">
            <DateInput
              label="From date"
              placeholder="From date"
              name="from"
              errors={errors}
              control={control}
              required
            />
          </div>

          <div className="col">
            <Input
              label="Slot minimum duration (hours)"
              placeholder="4 hours"
              name="duration"
              errors={errors}
              control={control}
              type="number"
              min="1"
              max="24"
              size="lg"
              required
            />
          </div>

          <div className="col">
            <Input
              label="Number of slots"
              placeholder="5 slots"
              name="quantity"
              errors={errors}
              control={control}
              type="number"
              min="1"
              max="100"
              size="lg"
              required
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Input
              label="Repeat every"
              placeholder="The slots recurrence"
              name="repeatEvery"
              errors={errors}
              control={control}
              type="number"
              min="1"
              max="100"
              size="lg"
              required
            />
          </div>

          <div className="col">
            <SelectInput
              label="Recurrence unit"
              placeholder="Slots recurrence"
              name="repeatEveryUnit"
              errors={errors}
              control={control}
              options={repeatEveryOptions}
              className="Select-lg"
              required
            />
          </div>

          <div className="col">
            <SelectInput
              label="Minimum start time"
              placeholder="HH:MM:SS"
              name="minStartTime"
              errors={errors}
              control={control}
              options={startTimeOptions}
              className="Select-lg"
            />
          </div>

          <div className="col">
            <SelectInput
              label="Maximum start time"
              placeholder="HH:MM:SS"
              name="maxStartTime"
              errors={errors}
              control={control}
              options={startTimeOptions}
              className="Select-lg"
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <SliderInput
              label="Priority level"
              name="priority"
              errors={errors}
              control={control}
              min={0}
              max={100}
              marks={priorityMarks}
              required
            />
          </div>
        </div>

        <div className="text-center my-5">
          <Button
            type="submit"
            form={formDomId}
            variant="success"
            disabled={isLoading}
            size="lg"
          >
            <Icon name="search" fw />
            &nbsp;
            { isLoading ? 'Loading slots...' : 'Search available slots' }
          </Button>
        </div>
      </Form>
    </div>
  );
};

SearchForm.propTypes = {
};

export default SearchForm;
