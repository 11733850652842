import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import SelectInput from '../inputs/SelectInput';
import handleError from '../../utils/error-handler';
import blApi from '../../api/financialAnalysis/businessLines';
import { classifyDocument } from '../../api/billing/classifications';
import { success } from '../../utils/notifier';

let businessLinesCache;

const ClassifyDocumentExpectationModal = ({
  documentId,
  progressiveNumber,
  headline,
  issuedOn,
  businessLineId,
  show,
  onHide,
}) => {
  // State primitives
  const [isLoading, setIsLoading] = useState(false);
  const [currentBusinessLineId, setCurrentBusinessLineId] = useState('initial');
  // TODO: Useless remove me and use the cache
  const [businessLines, setBusinessLines] = useState([]);

  if (businessLineId && currentBusinessLineId === 'initial') {
    setCurrentBusinessLineId(businessLineId);
  }

  const currentBusinessLine = () => (
    businessLines.find((bl) => (bl._id === currentBusinessLineId))
  );

  // Validations
  const isSubmitEnabled = () => (!isLoading && currentBusinessLine());
  const isSubmitDisabled = () => (!isSubmitEnabled());

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    classifyDocument({ documentId, businessLineId: currentBusinessLineId })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        onHide();
        success({ message: data.message });
      })
      .catch(handleError);
  };

  useEffect(() => {
    if (!businessLinesCache) {
      blApi.businessLines().then((response) => response.json()).then((data) => {
        businessLinesCache = data;
        setBusinessLines(data);
      }).catch(handleError);
    } else {
      setBusinessLines(businessLinesCache);
    }
  });

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {`Classify document Nº ${progressiveNumber} of ${issuedOn}`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h6>
          {headline}
        </h6>

        <br />

        <div className="row">
          <div className="col">
            <SelectInput
              label="Business line"
              options={businessLines}
              valueKey="_id"
              labelKey="name"
              initialValue={businessLineId}
              onChange={(bl) => { setCurrentBusinessLineId(bl ? bl._id : null); }}
            />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="success" disabled={isSubmitDisabled()} onClick={(e) => { handleSubmit(e); }}>
          {
            isLoading
              ? 'Loading...'
              : 'Classify'
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ClassifyDocumentExpectationModal.propTypes = {
  documentId: PropTypes.string.isRequired,
  businessLineId: PropTypes.string,
  issuedOn: PropTypes.string.isRequired,
  progressiveNumber: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
};

ClassifyDocumentExpectationModal.defaultProps = {
  show: false,
  businessLineId: null,
};

export default ClassifyDocumentExpectationModal;
