import PropTypes from 'prop-types';

export const accountShape = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  account_name: PropTypes.string,
  provider: PropTypes.string,
});

export const calendarShape = PropTypes.shape({
  id: PropTypes.string,
  account_id: PropTypes.string,
  calendar_name: PropTypes.string,
  events_default_priority: PropTypes.number,
  provider: PropTypes.string,
});

export default {
  accountShape,
  calendarShape,
};
