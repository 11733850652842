import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';

import Input from '../../RHF/Input';
import SliderInput from '../../RHF/SliderInput';
import TimeDurationInput from '../../RHF/TimeDurationInput';
import { priorityMarks } from '../utils';

const MomentStepForm = ({
  formId,
  onSubmit,
  defaultValues,
  className,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const enhancedOnSubmit = (params) => {
    onSubmit({
      ...params,
      step_type: 'moment',
    });
  };

  const customSubmitHandler = (event) => {
    // Avoid submit event propagation in the parent form component
    event.stopPropagation();
    handleSubmit(enhancedOnSubmit)(event);
  };

  return (
    <div className={classnames('StepTypeForm', className)}>
      <Form onSubmit={customSubmitHandler} id={formId}>
        <div className="row">
          <div className="col-8">
            <Input
              label="Description"
              placeholder="Workshop.."
              name="description"
              errors={errors}
              control={control}
              size="lg"
              required
            />
          </div>

          <div className="col-4">
            <TimeDurationInput
              label="Duration"
              placeholder="120 minutes"
              name="duration"
              errors={errors}
              control={control}
              size="lg"
              hint="i.e. 2h 30m"
              customValidation={({ value }) => (value > 0)}
              required
            />
          </div>
        </div>

        <div className="row">
          <div className="col pb-3">
            <SliderInput
              label="Priority level"
              name="priority"
              errors={errors}
              control={control}
              min={0}
              max={100}
              marks={priorityMarks}
              required
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

MomentStepForm.propTypes = {
  formId: PropTypes.string,
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.shape({}),
  className: PropTypes.string,
};

MomentStepForm.defaultProps = {
  formId: 'moment-step-form',
  onSubmit: () => {},
  defaultValues: { priority: 50, duration: 60 },
  className: null,
};

export default MomentStepForm;
