const basePath = '/financial_analysis/classification/categories';

export const indexPath = () => (basePath);

export const newPath = () => (`${basePath}/new`);

export const showPath = (id) => (`${basePath}/${id}`);

export const editPath = (id) => (`${basePath}/${id}/edit`);

export const deletePath = showPath;

export default {
  indexPath,
  newPath,
  showPath,
  editPath,
  deletePath,
};
