import { get, patch } from '../base';

const basePath = (accountId) => (
  [
    '/smart_planner/account',
    accountId,
    'calendars',
  ].join('/')
);

export const fetchAccountCalendars = (accountId) => (
  get(`${basePath(accountId)}.json`)
);

export const syncAccountsCalendars = () => (
  patch('/smart_planner/accounts/synchronize.json')
);

export const accountsCalendarsSyncStatus = () => (
  get('/smart_planner/accounts/synchronize-status.json')
);

export const syncAccountCalendars = (accountId) => (
  patch(`/smart_planner/account/${accountId}/calendars/synchronize.json`)
);

export const enableCalendar = (calendarId) => (
  patch(`/smart_planner/calendars/${calendarId}/enable.json`)
);

export const disableCalendar = (calendarId) => (
  patch(`/smart_planner/calendars/${calendarId}/disable.json`)
);

export const setEventsDefaultPriority = (calendarId, priority) => (
  patch(`/smart_planner/calendars/${calendarId}/events_default_priority.json`, {
    priority,
  })
);

export default {
  syncAccountCalendars,
  syncAccountsCalendars,
  accountsCalendarsSyncStatus,
  fetchAccountCalendars,
  setEventsDefaultPriority,
  enableCalendar,
  disableCalendar,
};
