import $ from 'jquery';

const form = '[data-js-expectation-form]';
const recurringFlag = '[data-js-expectation-form-recurring-flag]';
const recurringFields = '[data-js-expectation-form-recurring-fields]';

const canRun = () => ($(form).length === 1);
const handleFlagChange = () => {
  const $input = $(recurringFlag);

  if ($input.prop('checked')) {
    $(recurringFields).removeClass('d-none');
  } else {
    $(recurringFields).addClass('d-none');
  }
};

export const destroy = () => {
  if (!canRun()) { return; }

  $(recurringFlag).off('change', handleFlagChange);
};

export const initialize = () => {
  if (!canRun()) { return; }

  destroy();

  handleFlagChange();
  $(recurringFlag).on('change', handleFlagChange);
};
