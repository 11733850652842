import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import Icon from '../../Icon';
import CloseRegistrationsModal from './CloseRegistrationsModal';
import { success } from '../../../utils/notifier';
import { reload } from '../../../utils/router';
import { eventShape } from '../utils';

const CloseRegistrationsButton = ({ event, size, className }) => {
  const [showCloseRegistrationsModal, setShowCloseRegistrationsModal] = useState(false);
  const hideCloseRegistrationsModal = () => { setShowCloseRegistrationsModal(false); };
  const { acl } = event;
  const { canCloseRegistrations } = acl;

  if (!canCloseRegistrations) { return ''; }

  const handleCloseRegistrationsButtonClick = () => {
    setShowCloseRegistrationsModal(true);
  };

  const handleCloseRegistrationsConfirmation = ({ response }) => {
    reload().then(() => { success({ message: response.message }); });
  };

  return (
    <div className="cancel-button d-inline-block">
      <CloseRegistrationsModal
        show={showCloseRegistrationsModal}
        event={event}
        onHide={hideCloseRegistrationsModal}
        onConfirm={handleCloseRegistrationsConfirmation}
      />

      <Button
        size={size}
        variant="dark"
        title="Close training registrations"
        onClick={handleCloseRegistrationsButtonClick}
        className={className}
      >
        <Icon name="cash-register" fw />
        &nbsp;
        Close registrations
      </Button>
    </div>
  );
};

CloseRegistrationsButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  event: eventShape.isRequired,
};

CloseRegistrationsButton.defaultProps = {
  size: 'md',
  className: '',
};

export default CloseRegistrationsButton;
