import React, { useContext } from 'react';
import Alert from 'react-bootstrap/Alert';

import Context from './Context';
import ResultsDatatable from './ResultsDatatable';
import Icon from '../../Icon';

const EmptyState = () => (
  <div className="SearchMultipleAvailableSlotsPage__results">
    <Alert variant="secondary" className="text-center py-4 my-5">
      <Icon name="search" fw />
      &nbsp;
      Start a new search to view the available slots
    </Alert>
  </div>
);

const LoadingState = () => (
  <div className="SearchMultipleAvailableSlotsPage__results">
    <Alert variant="secondary" className="text-center py-4 my-5">
      <Icon name="spinner" spin fw />
      &nbsp;
      Loading available slots...
    </Alert>
  </div>
);

const Results = () => {
  const {
    isLoading,
    hasResults,
    slots,
  } = useContext(Context);

  if (isLoading) {
    return (<LoadingState />);
  }

  if (!hasResults) {
    return (<EmptyState />);
  }

  return (
    <div className="SearchMultipleAvailableSlotsPage__results">
      <ResultsDatatable slots={slots} />
    </div>
  );
};

Results.propTypes = {};

export default Results;
