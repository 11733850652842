import { formatISO } from 'date-fns';
import { get } from '../base';

const basePath = () => (
  [
    '/smart_planner/occupations',
  ].join('/')
);

export const userConstraintsOccupations = ({ from, to }) => (
  get(`${basePath()}/user_constraints_occupations.json?from=${formatISO(from)}&to=${formatISO(to)}`)
);

export default {
  userConstraintsOccupations,
};
