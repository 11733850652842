import React from 'react';
import PropTypes from 'prop-types';

import { Provider } from './Context';
import Body from './Body';

const SearchOpportunitiesPage = (props) => (
  <Provider {...props}>
    <div className="SearchOpportunitiesPage card">
      <div className="card-body">
        <h5>Fill out the form fields to start a new opportunity search</h5>

        <hr />

        <Body />
      </div>
    </div>
  </Provider>
);

SearchOpportunitiesPage.propTypes = {
  showStepsList: PropTypes.bool,
  hideDesigner: PropTypes.bool,
  showNewOpportunityCTA: PropTypes.bool,
  showEditOpportunityCTA: PropTypes.bool,
  currentUserId: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

SearchOpportunitiesPage.defaultProps = {
  showStepsList: true,
  hideDesigner: true,
  showNewOpportunityCTA: true,
  showEditOpportunityCTA: true,
};

export default SearchOpportunitiesPage;
