import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

import DayCellEvent from './DayCellEvent';

const EventsCompactView = ({ events, labels, onEventFocus }) => {
  if (events.length === 0) { return <span />; }

  return (
    <Scrollbars>
      <DayCellEvent
        event={events[0]}
        labels={labels}
        onClick={onEventFocus}
        multiline
        highlight
      />
    </Scrollbars>
  );
};

EventsCompactView.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onEventFocus: PropTypes.func,
  // selected: PropTypes.bool,
};

EventsCompactView.defaultProps = {
  onEventFocus: () => {},
  // selected: false,
};

export default EventsCompactView;
