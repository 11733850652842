import React from 'react';
import { Chart } from 'react-google-charts';

const days = [
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
  'sun',
];

const columns = [
  { type: 'string', id: 'Name' },
  { type: 'string', id: 'Label' },
  { type: 'date',   id: 'Start' },
  { type: 'date',   id: 'End' },
];

const UsersWorkingHoursTimeline = ({ usersWorkingHours, i18n }) => {
  const workingHours = [];
  const abscissa = [];

  // for each user
  usersWorkingHours.forEach(({ user, working_hours }) => {
    const fullName = user.data.attributes.full_name;

    days.forEach((day, idx) => {
      const dayWorkingHours = working_hours[day] || [];

      dayWorkingHours.forEach(({ from, to }) => {
        workingHours.push([
          fullName,
          '',
          new Date(0, 0, idx, from.hour, from.minute, from.second),
          new Date(0, 0, idx, to.hour, to.minute, to.second),
        ]);
      });
    });
  });

  days.forEach((day, idx) => {
    abscissa.push([
      'Week days',
      i18n.dayNames[day],
      new Date(0, 0, idx, 0, 0, 0),
      new Date(0, 0, idx, 24, 0, 0),
    ]);
  });

  const data = [columns, ...workingHours, ...abscissa];
  // const data = [columns, ...abscissa];
  const width = 220 * abscissa.length; // 500 for each day (now we have 30 days by default)
  const height = 50 * usersWorkingHours.length + 100;

  return (
    <div style={{ overflowX: 'scroll', padding: '5px 0px 1px' }}>
      <div className="">
        <Chart
          chartType="Timeline"
          height={`${height}px`}
          width="100%"
          data={data}
        />
      </div>
    </div>
  );
};

export default UsersWorkingHoursTimeline;
