import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

import Icon from '../../Icon';
import TextInput from '../../RHF/TextInput';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';
import { addCertifiedAttendees } from '../../../api/trainings/certifications';
import UUID from '../../../utils/uuid';

const inputName = (uuid, name) => (`attendees[${uuid}][${name}]`);

const AttendeeFields = ({
  errors,
  control,
  attendee,
  onDelete,
  onPaste,
}) => {
  const parseAttendeeLine = (line) => {
    const chunks = line.toString().split('\t');
    const firstName = chunks[0].toString().trim();
    const lastName = chunks[1].toString().trim();
    const email = chunks[2].toString().trim();

    if (firstName.length === 0 && lastName.length === 0) { return null; }

    return {
      firstName,
      lastName,
      email,
    };
  };
  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData('Text');

    if (_.includes(pastedText, '\t')) {
      e.preventDefault();

      const lines = pastedText.split('\n');
      const attendees = _.compact(lines.map(parseAttendeeLine));

      onPaste(attendees);
    }
  };
  const {
    uuid,
    firstName,
    lastName,
    email,
  } = attendee;
  const handleDelete = () => { onDelete(uuid); };

  return (
    <div className="row">
      <div className="col-md-3">
        <TextInput
          name={inputName(uuid, 'first_name')}
          label="First name"
          errors={errors}
          control={control}
          placeholder="e.g. John"
          defaultValue={firstName}
          onPaste={handlePaste}
          required
        />
      </div>

      <div className="col-md-3">
        <TextInput
          name={inputName(uuid, 'last_name')}
          label="Last name"
          errors={errors}
          control={control}
          placeholder="e.g. Doe"
          defaultValue={lastName}
          onPaste={handlePaste}
          required
        />
      </div>

      <div className="col-md-5">
        <TextInput
          name={inputName(uuid, 'email')}
          label="Email"
          type="email"
          errors={errors}
          control={control}
          placeholder="e.g. john.email@example.co"
          defaultValue={email}
          onPaste={handlePaste}
        />
      </div>

      <div className="col-md-1">
        <label>&nbsp;</label>
        <br />
        <Button variant="danger" className="float-right" onClick={handleDelete}>
          <Icon name="times" size="md" fw />
        </Button>
      </div>
    </div>
  );
};

const buildAttendee = (attrs = {}) => {
  const firstName = attrs.firstName || '';
  const lastName = attrs.lastName || '';
  const email = attrs.email || '';

  return {
    uuid: UUID.generate(),
    firstName,
    lastName,
    email,
  };
};

const isPresent = (text) => (text.toString().trim().length > 0);

const AddAttendeesModal = (({
  show,
  onHide,
  certification,
}) => {
  const { id, headline } = certification;
  const [attendees, setAttendees] = useState([buildAttendee()]);
  const [isLoading, setIsLoading] = useState(false);
  const isSubmitDisabled = isLoading || attendees.length === 0;
  const onSubmit = (params) => {
    if (isSubmitDisabled) { return; }
    setIsLoading(true);

    const certificationId = id;
    const certifiedAttendees = Object
      .values(params.attendees)
      .filter(({ first_name, last_name, email }) => (
        isPresent(first_name) || isPresent(last_name) || isPresent(email)
      ));

    addCertifiedAttendees({ certificationId, certifiedAttendees })
      .then((response) => response.json())
      .then((response) => {
        success({ message: response.message });
        setIsLoading(false);
        onHide();
      })
      .catch(handleError);
  };

  const formDomId = 'add-certified-attendees-form';
  // const attendeeDefaultValues = ({
  //   uuid, firstName, lastName, email,
  // }) => ({
  //   [inputName(uuid, 'first_name')]: firstName || '',
  //   [inputName(uuid, 'last_name')]: lastName || '',
  //   [inputName(uuid, 'email')]: email || '',
  // });
  // const defaultValues = attendees.reduce((values, attendee) => ({
  //   ...values,
  //   ...attendeeDefaultValues(attendee),
  // }), {});
  const { handleSubmit, control, formState: { errors } } = useForm();
  const handleAddAttendee = () => { setAttendees([...attendees, buildAttendee()]); };
  const handleRemoveAttendee = (removedUuid) => {
    const newAttendees = attendees.filter(({ uuid }) => (uuid !== removedUuid));
    setAttendees(newAttendees);
  };
  const valuedAttendee = ({ firstName, lastName, email }) => (
    isPresent(firstName) || isPresent(lastName) || isPresent(email)
  );
  const handlePastedAttendees = (newAttendees) => {
    const futureAttendees = ([
      ...attendees,
      ...newAttendees.map(buildAttendee),
    ]).filter(valuedAttendee);

    setAttendees(futureAttendees);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>
            Add attendees to
            &nbsp;
            <small>{headline}</small>
          </strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Alert variant="info">
          <Icon name="info-circle" fw />
          &nbsp;
          Use copy/paste from a spreadsheet to bulk import the attendees.
        </Alert>

        <Form onSubmit={handleSubmit(onSubmit)} id={formDomId}>
          {
            attendees.map((attendee) => (
              <AttendeeFields
                onPaste={handlePastedAttendees}
                onDelete={handleRemoveAttendee}
                control={control}
                errors={errors}
                attendee={attendee}
                key={attendee.uuid}
              />
            ))
          }
        </Form>
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button
          variant="secondary"
          onClick={onHide}
          disabled={isLoading}
        >
          Close
        </Button>
        <Button
          variant="info"
          onClick={handleAddAttendee}
          disabled={isLoading}
        >
          <Icon name="plus" fw />
          Add attendee
        </Button>
        <Button
          type="submit"
          form={formDomId}
          variant="success"
          disabled={isSubmitDisabled}
        >
          <Icon name="check" fw />
          &nbsp;
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

AddAttendeesModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  // onUpdate: PropTypes.func,
  certification: PropTypes.shape({
    id: PropTypes.string,
    headline: PropTypes.string,
  }).isRequired,
};

AddAttendeesModal.defaultProps = {
  show: false,
  // onUpdate: () => {},
};

export default AddAttendeesModal;
