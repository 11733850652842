import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import RoundedButton from './RoundedButton';
import styles from './styles.module.scss';

const Toolbar = ({ className }) => (
  <div className={classnames(className, styles.Footprint__toolbar)}>
    <RoundedButton
      iconName="sort"
      iconFamily="fas"
      title="Sort steps"
    />

    <RoundedButton
      iconName="check-square"
      iconFamily="far"
      title="Select steps"
    />

    <RoundedButton
      iconName="clone"
      iconFamily="fas"
      title="Clone steps"
      disabled
    />

    <RoundedButton
      iconName="trash"
      iconFamily="fas"
      title="Delete steps"
      variant="outline-danger"
      disabled
    />
  </div>
);

Toolbar.propTypes = {
  className: PropTypes.string,
};

Toolbar.defaultProps = {
  className: null,
};

export default Toolbar;
