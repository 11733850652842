import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import Alert from 'react-bootstrap/Alert';
import TextInput from '../../RHF/TextInput';

import Icon from '../../Icon';

import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';
import { updateCertifiedAttendee } from '../../../api/trainings/certifiedAttendees';

const CertifiedAttendeeUpdateModal = (({
  show,
  onHide,
  certifiedAttendee,
  onUpdate,
  certification,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  // const handleConfirm = () => {
  // };

  const {
    id,
    first_name,
    last_name,
    email,
    pdf_url,
  } = certifiedAttendee;
  const { handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      first_name,
      last_name,
      email,
    },
  });

  const onSubmit = (params) => {
    if (isLoading) { return; }
    setIsLoading(true);
    const isGeneratingPdf = !!(pdf_url && pdf_url.length > 0);
    const certificationId = certification.id;
    const updatedCertifiedAttendee = {
      ...certifiedAttendee,
      ...params,
      isGeneratingPdf,
      id,
    };

    updateCertifiedAttendee({ certificationId, certifiedAttendee: updatedCertifiedAttendee })
      .then((response) => response.json())
      .then((response) => {
        success({ message: response.message });
        setIsLoading(false);
        onHide();
        onUpdate({ certifiedAttendee: updatedCertifiedAttendee });
      })
      .catch(handleError);
  };

  const formDomId = `certified-attendee-form-${id}`;


  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Update certified attendee</strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Alert variant="warning">
          You should regenerate the PDF after each change.
        </Alert>

        <Form onSubmit={handleSubmit(onSubmit)} id={formDomId}>
          <div className="row">
            <div className="col-md-6">
              <TextInput
                name="first_name"
                label="First name"
                errors={errors}
                control={control}
                placeholder="e.g. John"
                required
              />
            </div>

            <div className="col-md-6">
              <TextInput
                name="last_name"
                label="Last name"
                errors={errors}
                control={control}
                placeholder="e.g. Doe"
                required
              />
            </div>
          </div>

          <TextInput
            name="email"
            label="Email"
            errors={errors}
            control={control}
            placeholder="e.g. john.email@example.co"
          />
        </Form>
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button
          type="submit"
          form={formDomId}
          variant="success"
          disabled={isLoading}
        >
          <Icon name="check" fw />
          { isLoading ? 'Loading...' : 'Update' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

CertifiedAttendeeUpdateModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  certification: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  certifiedAttendee: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    pdf_url: PropTypes.string,
  }).isRequired,
};

CertifiedAttendeeUpdateModal.defaultProps = {
  show: false,
  onUpdate: () => {},
};

export default CertifiedAttendeeUpdateModal;
