import PropTypes from 'prop-types';

export const categoryShape = PropTypes.shape({
  id: PropTypes.string,
  color: PropTypes.string,
  label_id: PropTypes.string,
  name: PropTypes.string,
  notes: PropTypes.string,
});

export const ticketShape = PropTypes.shape({
  id: PropTypes.string,
  attendee_first_name: PropTypes.string,
  attendee_last_name: PropTypes.string,
});

export const eventShape = PropTypes.shape({
  id: PropTypes.string,
  headline: PropTypes.string,
  description: PropTypes.string,
  details_path: PropTypes.string,
  trainers_name: PropTypes.string,
  venue: PropTypes.string,
  status: PropTypes.string,
  confirmation_date: PropTypes.string,
  starts_on: PropTypes.string,
  ends_on: PropTypes.string,
  tickets_sold: PropTypes.number,
  capacity: PropTypes.number,
  initial_capacity: PropTypes.number,
  external_url: PropTypes.string,
  acl: PropTypes.shape({
    canCancel: PropTypes.bool,
    canOpenRegistrations: PropTypes.bool,
    canConfirm: PropTypes.bool,
    canConvertIntoOpenEvent: PropTypes.bool,
    canConvertIntoTraining: PropTypes.bool,
  }),
  sales_registrations_status: PropTypes.string,
  training_status: PropTypes.string,
  composite_status: PropTypes.shape({
    status: PropTypes.string,
    message: PropTypes.string,
  }),
  trainers: PropTypes.arrayOf(PropTypes.shape({
    full_name: PropTypes.string
  })),
});

export default {
  eventShape,
  categoryShape,
  ticketShape,
};
