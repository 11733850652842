import React from 'react';
import PropTypes from 'prop-types';

import { Provider } from './Context';
import Body from './Body';

const SearchRecipeOpportunitiesPage = ({ recipe, ...props }) => (
  <Provider {...props} recipes={[recipe]} initialRecipe={recipe}>
    <Body className="" />
  </Provider>
);

SearchRecipeOpportunitiesPage.propTypes = {
  showStepsList: PropTypes.bool,
  hideDesigner: PropTypes.bool,
  showNewOpportunityCTA: PropTypes.bool,
  showEditOpportunityCTA: PropTypes.bool,
};

SearchRecipeOpportunitiesPage.defaultProps = {
  showStepsList: false,
  hideDesigner: false,
  showNewOpportunityCTA: false,
  showEditOpportunityCTA: false,
};

export default SearchRecipeOpportunitiesPage;
