import React from 'react';
import Button from 'react-bootstrap/Button';
import Icon from '../Icon';

const EditButton = ({
  onClick, title = '', icon = 'info', theme = 'info',
}) => (
  <Button
    rel="noopener noreferrer"
    className={`btn btn-sm btn-${theme}`}
    onClick={onClick}
    title={title}
  >
    <Icon name={icon} fw />
  </Button>
);

export default EditButton;
