import React from 'react';
import PropTypes from 'prop-types';

import ConfirmModal from '../../ConfirmModal';
import { recipeShape } from '../proptypes';

const DeleteModal = ({
  recipe, show, onHide, onDelete,
}) => {
  const { id, name } = recipe;
  const deleteMessage = (
    <h5 className="my-3">
      Do you really want to delete the Recipe
      &nbsp;
      <strong>
        &quot;
        {name}
        &quot;
      </strong>
      ?
    </h5>
  );
  const handleDelete = () => (
    onDelete({ id })
  );

  return (
    <ConfirmModal
      variant="danger"
      title="Delete the selected Recipe"
      description={deleteMessage}
      show={show}
      onHide={onHide}
      onConfirm={handleDelete}
    />
  );
};

DeleteModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onDelete: PropTypes.func,
  recipe: recipeShape.isRequired,
};

DeleteModal.defaultProps = {
  show: false,
  onHide: () => {},
  onDelete: () => {},
};

export default DeleteModal;
