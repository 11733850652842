import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import WithTrainerPlacedTickets from './WithTrainerPlacedTickets';
import Spinner from '../../Spinner';
import ListingTable from '../../ListingTable';
import Row from './PlacedTicketRow';
import { trainerShape, ticketShape } from '../proptypes';

const PlacedTicketsPage = ({ trainer, loading, tickets }) => {
  const { full_name } = trainer;

  if (loading) {
    return (
      <div>
        <Spinner
          description={`Loading ${full_name} placed tickets...`}
        />
      </div>
    );
  }

  const headers = [
    'Training date',
    'Training',
    'Sold at',
    'First name',
    'Last name',
    'Email',
    'Gross price',
    'Net price',
  ];

  const sortableColumns = {
    'First name': 'attendee_first_name',
    'Last name': 'attendee_last_name',
    'Email': 'attendee_email',
    'Sold at': 'purchased_at',
    'Training date': 'training.starts_on',
    'Training': 'training.headline',
    'Gross price': 'gross_price.cents',
    'Net price': 'base_price.cents',
  };

  return (
    <div className={classnames('trainer-placed-tickets-page')}>
      <ListingTable
        items={tickets}
        filterKeys={['headline', 'customer', 'city']}
        headers={headers}
        sortableColumns={sortableColumns}
        headerRowProps={{ className: 'text-nowrap' }}
        components={{ Row }}
      />
    </div>
  );
};

PlacedTicketsPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  trainer: trainerShape.isRequired,
  tickets: PropTypes.arrayOf(ticketShape),
};

PlacedTicketsPage.defaultProps = {
  loading: true,
  tickets: [],
};

export default WithTrainerPlacedTickets(PlacedTicketsPage);
