import React from 'react';
import PropTypes from 'prop-types';
import {
  parseISO,
  isSameDay,
  add,
  formatDistance,
} from 'date-fns';

import {
  formatIsoDate,
  formatIsoDateTime,
  formatIsoTime,
} from '../utils/datetime';
// import Icon from '../../Icon';

const DatetimeRangeLabel = ({
  start_on,
  start_at,
  end_on,
  end_at,
  compact,
  className,
  separator,
}) => {
  const isFullDay = !start_at || !end_at;
  const formattedDistance = formatDistance(
    parseISO(start_at || start_on),
    parseISO(end_at || end_on),
  );

  const formatD = (d) => (formatIsoDate(d, {
    format: compact ? 'medCompact' : 'default',
  }));

  const formatDT = (dt) => (formatIsoDateTime(dt, {
    format: compact ? 'medCompact' : 'default',
  }));

  const formatT = (t) => (formatIsoTime(t, { format: 'short' }));

  let title;

  // FULL DAY //////////////////////////////////////////////////////////////////

  if (isFullDay) {
    const startOn = parseISO(start_on);
    const expectedFullDayEndOn = add(startOn, { days: 1 });
    const endOn = parseISO(end_on);
    const isDifferentDay = !isSameDay(expectedFullDayEndOn, endOn);
    title = formatD(start_on);

    if (isDifferentDay) { title = `${title}${separator}${formatD(end_on)}`; }

    title = `${title} (${formattedDistance})`;

    return (
      <span className={className} title={title}>
        <strong>{formatD(start_on)}</strong>

        {
          isDifferentDay && (
          <>
            {separator}
            <strong>{formatD(end_on)}</strong>
          </>
          )
        }

        {
          !compact && (
            <>
              &nbsp;
              <em>
                (
                {formattedDistance}
                )
              </em>
            </>
          )
        }
      </span>
    );
  }

  // NOT FULL DAY //////////////////////////////////////////////////////////////

  const startAt = parseISO(start_at);
  const endAt = parseISO(end_at);

  if (isSameDay(startAt, endAt)) {
    title = `${formatDT(start_at)}${separator}${formatT(end_at)} (${formattedDistance})`;

    return (
      <span className={className} title={title}>
        <strong>{formatDT(start_at)}</strong>
        {separator}
        <strong>{formatT(end_at)}</strong>

        {
          !compact && (
            <>
              <em>
                (
                {formattedDistance}
                )
              </em>
            </>
          )
        }
      </span>
    );
  }

  title = `${formatDT(start_at)}${separator}${formatDT(end_at)} (${formattedDistance})`;

  return (
    <span className={className} title={title}>
      <strong>{formatDT(start_at)}</strong>
      {separator}
      <strong>{formatDT(end_at)}</strong>
      {
        !compact && (
          <>
            <em>
              (
              {formattedDistance}
              )
            </em>
          </>
        )
      }
    </span>
  );
};

DatetimeRangeLabel.propTypes = {
  start_on: PropTypes.string.isRequired,
  start_at: PropTypes.string,
  end_on: PropTypes.string.isRequired,
  end_at: PropTypes.string,
  compact: PropTypes.bool,
  className: PropTypes.string,
  separator: PropTypes.string,
};

DatetimeRangeLabel.defaultProps = {
  start_at: null,
  end_at: null,
  compact: false,
  className: '',
  separator: ' - ',
};

export default DatetimeRangeLabel;
