import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DateTime } from 'luxon';

import ExpectedCostPerAttendeeUpdateModal from './ExpectedCostPerAttendeeUpdateModal';
import { eventShape } from '../../utils';
import { reload } from '../../../../utils/router';
import { formatRubyMoney } from '../../../../utils/money';
import Icon from '../../../Icon';
import { success } from '../../../../utils/notifier';

const ExpectedCostPerAttendeeInplaceInput = ({ event, className }) => {
  const [showModal, setShowModal] = useState(false);
  const hideModal = () => { setShowModal(false); };
  const { expected_cost_per_attendee_gross_amount, details_path } = event;
  const handleExpectedCostPerAttendeeUpdate = ({ response }) => {
    reload().then(() => { success({ message: response.message }); });
  };
  const handleClick = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <div className={classnames('ExpectedCostPerAttendeeInplaceInput', className)}>
      <ExpectedCostPerAttendeeUpdateModal
        show={showModal}
        event={event}
        onHide={hideModal}
        onUpdate={handleExpectedCostPerAttendeeUpdate}
      />

      <a href="#cost-per-attendee" onClick={handleClick}>
        <span className="editable">{formatRubyMoney(expected_cost_per_attendee_gross_amount || {})}</span>
        &nbsp;
        <Icon name="pencil-alt" fw />
      </a>
    </div>
  );
};

ExpectedCostPerAttendeeInplaceInput.propTypes = {
  event: eventShape.isRequired,
};

ExpectedCostPerAttendeeInplaceInput.defaultProps = {};

export default ExpectedCostPerAttendeeInplaceInput;
