import React from 'react';
import { Chart } from 'react-google-charts';
import moment from 'moment';
import Money, { moneyToChartValue } from '../utils/money';

const computeData = (billingData) => {
  if (!billingData || billingData.length === 0) { return []; }

  const rows = [];
  const year = moment(billingData[0].issued_on).year() || moment().year();

  Array(12).fill(null).forEach((_val, index) => {
    const month = index + 1;
    const date = moment(`${year}-${month}-01`, 'YYYY-MM-DD').format('MMMM')

    rows[index] = [
      date,    // Month
      Money(), // Revenues
      Money(), // Cost
    ];
  });

  billingData.forEach((billingDocument) => {
    const inAmount = Money(billingDocument.in_amount);
    const outAmount = Money(billingDocument.out_amount);
    const month = moment(billingDocument.issued_on).month();

    rows[month][1] = rows[month][1].add(inAmount);
    rows[month][2] = rows[month][2].add(outAmount);
  });

  return rows.map((row) => (
    [
      row[0],
      moneyToChartValue(row[1]),
      moneyToChartValue(row[2]),
    ]
  ));
};

const MonthlyBillingBarChart = (props) => {
  const { billingData } = props;
  const rows = computeData(billingData);
  const columns = [
    {
    },
    {
      type: 'number',
      label: 'Revenues',
    },
    {
      type: 'number',
      label: 'Cost',
    },
  ];

  return (
    <Chart
      loader={<div>Loading Chart...</div>}
      chartType="Bar"
      width="100%"
      height="380px"
      rows={rows}
      columns={columns}
      options={{
        title: 'Monthly Bills',
        legend: {
          position: 'bottom',
        },
        hAxis: {
          title: 'Time',
          titleTextStyle: { color: '#333' },
        },
        vAxis: {
          title: 'Liquidity',
          style: 'currency',
          format: '\u20AC#',
        },
        backgroundColor: 'red',
        seriesType: 'bars',
        series: {
          0: { color: 'blue', isStacked: false },
        },
      }}
    />
  );
};

export default MonthlyBillingBarChart;
