import React from 'react';
import PropTypes from 'prop-types';
// import classnames from 'classnames';

import ListingTable from '../../ListingTable/Infinite';
import Row from '../../TransactionsListRow';

const TransactionsDatatable = ({
  account,
  onUpdate,
  onDelete,
  hideSearch,
}) => {
  const headers = [
    'Date',
    'Amount',
    'Counterpart',
    '',
  ];
  const endpoint = `/treasury/account/${account.id}/transactions.json`;

  return (
    <ListingTable
      initialEndpoint={endpoint}
      filterKeys={[
        'counterpart', 'notes',
      ]}
      headers={headers}
      headerRowProps={{ className: 'row' }}
      headerColsProps={[
        { className: 'col-lg-3' },
        { className: 'col-lg-2' },
        { className: 'col-lg-6' },
        { className: 'col-lg-1' },
      ]}
      rowProps={{
        onUpdate,
        onDelete,
      }}
      components={{ Row }}
      hideSearch={hideSearch}
      selectable={false}
      responsive={false}
    />
  );
};

TransactionsDatatable.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  hideSearch: PropTypes.bool,
};

TransactionsDatatable.defaultProps = {
  onUpdate: () => {},
  onDelete: () => {},
  hideSearch: false,
};

export default TransactionsDatatable;
