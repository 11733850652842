import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { DateTime } from 'luxon';
import AsyncSelect from 'react-select/async';

import Icon from '../../Icon';
import { searchTrainers } from '../../../api/trainers/trainers';
import handleError from '../../../utils/error-handler';
import { updateTrainers } from '../../../api/trainings/private_classes';

const decorateTrainer = (trainer) => ({
  ...trainer,
  value: trainer.id,
  label: trainer.full_name,
});

const decorateTrainers = (trainers) => trainers.map(decorateTrainer);

const TrainersUpdateModal = ({
  show,
  onHide,
  onUpdate,
  privateClass,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [trainers, setTrainers] = useState(decorateTrainers(privateClass.trainers || []));
  const handleTrainersChange = (values) => {
    setTrainers(decorateTrainers(values));
  };

  const privateClassId = privateClass.id;
  const handleUpdate = () => {
    if (isLoading) { return; }
    setIsLoading(true);

    updateTrainers({ privateClassId, trainers })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        onHide();
        onUpdate({ privateClassId, response: data });
      })
      .catch(handleError);
  };

  const loadTrainers = (query, callback) => {
    searchTrainers({ query })
      .then((response) => response.json())
      .then(({ data }) => (data.map(({ attributes }) => (attributes))))
      .then((data) => {
        const serializedTrainers = data.map(decorateTrainer);
        callback(serializedTrainers);
      });
  };

  const { headline, starts_on } = privateClass;
  const startsOn = DateTime.fromISO(starts_on);
  const humanDate = startsOn.toFormat('dd LLL yyyy');

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Update private class trainers</strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ zIndex: 301 }}>
        <h4 className="mb-3">
          Choose the trainers for the public training privateClass
          &nbsp;
          <strong>
            &quot;
            {headline}
            &quot;
          </strong>
          &nbsp;
          on
          &nbsp;
          {humanDate}
        </h4>

        <Form>
          <Form.Group controlId="cancelFormTrainers">
            <Form.Label>Trainers:</Form.Label>
            <AsyncSelect
              cacheOptions
              loadOptions={loadTrainers}
              defaultValue={trainers}
              onChange={handleTrainersChange}
              defaultOptions
              isMulti
            />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={handleUpdate} disabled={isLoading}>
          <Icon name="check" fw />
          { isLoading ? 'Loading...' : 'Update' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

TrainersUpdateModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  privateClass: PropTypes.shape({
    id: PropTypes.string,
    headline: PropTypes.string,
    starts_on: PropTypes.string,
  }).isRequired,
  onUpdate: PropTypes.func,
};

TrainersUpdateModal.defaultProps = {
  show: false,
  onUpdate: () => {},
};

export default TrainersUpdateModal;
