import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import PriceInput from '../../inputs/PriceInput';
import Icon from '../../Icon';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';
import { overridesTicketPrice } from '../../../api/trainings/tickets';
import { ticketShape } from '../utils';
import Money from '../../../utils/money';
import { reload } from '../../../utils/router';

const TicketPriceUpdateModal = ({
  show,
  onHide,
  onUpdate,
  ticket,
}) => {
  const {
    base_price,
    ticketing_service_fee,
    gross_price,
    attendee_first_name,
    attendee_last_name,
  } = ticket;
  const netPrice = Money(base_price);
  const serviceFeePrice = Money(ticketing_service_fee);
  const totalNetPrice = netPrice.add(serviceFeePrice);
  const grossPrice = Money(gross_price);

  const initialPriceValues = {
    net: totalNetPrice.toUnit(),
    gross: grossPrice.toUnit(),
    withholdingTax: 0,
    otherWithholdingTax: 0,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [priceValues, setPriceValues] = useState(initialPriceValues);

  const handlePriceChange = (values) => {
    setPriceValues(values);
  };

  const ticketId = ticket.id;
  const handleUpdate = () => {
    if (isLoading) { return; }
    setIsLoading(true);

    const { net, vat, gross } = priceValues;

    overridesTicketPrice ({ ticketId, net, tax: vat, gross })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        onHide();
        reload().then(() => {
          success({ message: data.message });
          onUpdate({ ticketId, response: data });
        });
      })
      .catch(handleError);
  };

  // const { headline, starts_on, human_training_typology } = event;
  // const startsOn = DateTime.fromISO(starts_on);
  // const humanDate = startsOn.toFormat('dd LLL yyyy');

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Update ticket price</strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ zIndex: 301 }}>
        <h4 className="mb-3">
          Update ticket price for
          &nbsp;
          <strong>
            &quot;
            {attendee_first_name}
            &nbsp;
            {attendee_last_name}
            &quot;
          </strong>
        </h4>

        <PriceInput
          layout={false}
          showWithholdingFields={false}
          net={totalNetPrice.toUnit()}
          gross={grossPrice.toUnit()}
          grossOptions={{
            auto: true,
            readOnly: true
          }}
          onChange={handlePriceChange}
        />
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={handleUpdate} disabled={isLoading}>
          <Icon name="check" fw />
          { isLoading ? 'Loading...' : 'Update' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

TicketPriceUpdateModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  ticket: ticketShape,
  onUpdate: PropTypes.func,
};

TicketPriceUpdateModal.defaultProps = {
  show: false,
  onUpdate: () => {},
};

export default TicketPriceUpdateModal;
