import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import { recipeShape } from '../proptypes';
import Row from '../../ListingTable/Row';
import Icon from '../../Icon';
import DeleteModal from './DeleteModal';

import { showPath, editPath } from '../../../routes/smart_planner/recipes';

const TimesIcon = () => (<Icon name="trash" fw />);
const LoadingIcon = () => (<Icon name="spinner" family="fas" spin fw />);
const ActionsCell = ({ item, onDelete, isDeleting }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const hideDeleteModal = () => { setShowDeleteModal(false); };
  const { id } = item;
  const handleDeleteButtonClick = (event) => {
    event.preventDefault();
    setShowDeleteModal(true);
  };

  return (
    <td className="h5">
      <DeleteModal
        show={showDeleteModal}
        onHide={hideDeleteModal}
        onDelete={onDelete}
        recipe={item}
      />

      <div className="btn-group">
        <Button
          variant="primary"
          href={editPath(id)}
          title="Edit activity type"
          disabled={isDeleting}
          className="text-nowrap"
        >
          Edit
          &nbsp;
          <Icon name="edit" fw />
        </Button>

        <Button
          variant="danger"
          title="Delete"
          onClick={handleDeleteButtonClick}
          disabled={isDeleting}
          className="text-nowrap"
        >
          Delete
          &nbsp;
          {
            isDeleting
              ? <LoadingIcon />
              : <TimesIcon />
          }
        </Button>
      </div>
    </td>
  );
};

ActionsCell.propTypes = {
  item: recipeShape.isRequired,
};

const RecipeRow = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { item, onDelete } = props;
  const {
    id,
    name,
    description,
    color,
  } = item;

  const handleDelete = () => {
    setIsDeleting(true);
    onDelete(item);
  };

  return (
    <Row {...props}>
      <td className="text-end">
        <div
          className="ColorMarker ml-1"
          style={{ backgroundColor: color }}
          title={color}
        />
      </td>

      <td className="h5 w-25">
        <a href={showPath(id)} disabled={isDeleting}>
          <strong>{name}</strong>
        </a>
      </td>

      <td className="text-truncate w-50">
        {description || '-'}
      </td>

      <ActionsCell
        item={item}
        onDelete={handleDelete}
        isDeleting={isDeleting}
      />
    </Row>
  );
};

RecipeRow.propTypes = {
  item: recipeShape.isRequired,
  onDelete: PropTypes.func,
};

RecipeRow.defaultProps = {
  onDelete: () => {},
};

export default RecipeRow;
