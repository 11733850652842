import { post, get, patch } from '../../base';

const basePath = '/financial_analysis/classification';

export const categorize = ({
  operationId,
  categoryId,
  categoryLabelId,
  repartition = 1,
  ...args
}) => (
  post(`${basePath}/categorizes.json`, {
    categorize: {
      operation_id: operationId,
      category_id: categoryId,
      category_label_id: categoryLabelId || '',
      notes: args.notes,
      repartition,
    },
  })
);

export const bulkCategorize = ({
  operationId,
  categoryId,
  labels,
  ...args
}) => {
  const labelsParams = labels.reduce((acc, { label_id, repartition }) => {
    acc[label_id] = { id: label_id, repartition };
    return acc;
  }, {});

  return post(`${basePath}/categorizes/bulk.json`, {
    categorize: {
      operation_id: operationId,
      category_id: categoryId,
      category_labels: labelsParams,
      notes: args.notes,
    },
  });
};

export const createCategoryLabel = ({
  categoryId,
  categoryLabelId,
  name,
  ...args
}) => (
  post(`${basePath}/categories/${categoryId}/labels.json`, {
    category_label: {
      ...args,
      label_id: categoryLabelId,
      name,
    },
  })
);

export const categories = () => (
  get(`${basePath}/categories.json`)
);

export const categoryLabels = ({ categoryId }) => (
  get(`${basePath}/categories/${categoryId}/labels.json`)
);

export const createCategory = ({
  label,
  type,
  notes,
  labels,
}) => (
  post(`${basePath}/categories.json`, {
    category: {
      label,
      type,
      notes,
      labels_attributes: labels,
    },
  })
);

export const updateCategory = ({
  label,
  type,
  notes,
  labels,
  categoryId,
}) => (
  patch(`${basePath}/categories/${categoryId}.json`, {
    category: {
      label,
      type,
      notes,
      labels_attributes: labels,
    },
  })
);

export default {
  categories,
  categorize,
  bulkCategorize,
  categoryLabels,
  createCategoryLabel,
  createCategory,
  updateCategory,
};
