import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { DateTime } from 'luxon';

import Input from '../../../inputs/Input';
import Icon from '../../../Icon';
import handleError from '../../../../utils/error-handler';
import { updateExpectedTicketSales } from '../../../../api/trainings/planned_events_economics';
import { eventShape } from '../../utils';

const ExpectedTicketSalesUpdateModal = ({
  show,
  onHide,
  onUpdate,
  event,
  label,
}) => {
  const { expected_ticket_sales } = event;
  const [isLoading, setIsLoading] = useState(false);
  const [expectedTicketSales, setExpectedTicketSales] = useState(expected_ticket_sales);
  const handleExpectedTicketSalesChange = (value) => {
    setExpectedTicketSales(value);
  };

  const plannedEventId = event.id;
  const handleUpdate = () => {
    if (isLoading) { return; }
    setIsLoading(true);

    updateExpectedTicketSales({ plannedEventId, expectedTicketSales })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        onHide();
        onUpdate({ plannedEventId, response: data, value: expectedTicketSales });
      })
      .catch(handleError);
  };

  const { headline, starts_on, human_training_typology } = event;
  const startsOn = DateTime.fromISO(starts_on);
  const humanDate = startsOn.toFormat('dd LLL yyyy');

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>
            Set the total number of saleable tickets expected
          </strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ zIndex: 301 }}>
        <h4 className="mb-3">
          Set the total number of saleable tickets expected for the
          &nbsp;
          {human_training_typology}
          &nbsp;
          <strong>
            &quot;
            {headline}
            &quot;
          </strong>
          &nbsp;
          on
          &nbsp;
          {humanDate}
          <br />
          <small>(since the start of sales)</small>
        </h4>

        <Input
          label={label}
          onChange={handleExpectedTicketSalesChange}
          initialValue={expected_ticket_sales}
          type="number"
        />
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={handleUpdate} disabled={isLoading}>
          <Icon name="check" fw />
          { isLoading ? 'Loading...' : 'Save' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ExpectedTicketSalesUpdateModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  event: eventShape.isRequired,
  onUpdate: PropTypes.func,
  label: PropTypes.string,
};

ExpectedTicketSalesUpdateModal.defaultProps = {
  show: false,
  onUpdate: () => {},
  label: 'Expected tickets number since the start of sales'
};

export default ExpectedTicketSalesUpdateModal;
