import { post } from '../base';

const basePath = () => (
  '/smart_planner/available-slots'
);

export const searchMultipleAvailableSlots = ({
  from,
  repeatEvery,
  repeatEveryUnit,
  duration,
  quantity,
  minStartTime,
  maxStartTime,
  priority = 0,
}) => (
  post(`${basePath()}.json`, {
    q: {
      from,
      repeat_every: repeatEvery,
      repeat_every_unit: repeatEveryUnit,
      duration,
      quantity,
      min_start_time: minStartTime,
      max_start_time: maxStartTime,
      priority,
    },
  })
);

export default {
  searchMultipleAvailableSlots,
};
