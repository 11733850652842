import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Tabs, Tab } from 'react-bootstrap';

import Icon from '../../Icon';

import InfoPage from './InfoPage';
import PublicEventsPage from './PublicEventsPage';
import PrivateClassesPage from './PrivateClassesPage';
import PlacedTicketsPage from './PlacedTicketsPage';

const TabTitle = ({ title, icon, iconFamily = 'fas' }) => (
  <span>
    <Icon name={icon} family={iconFamily} fw />
    &nbsp;
    {title}
  </span>
);

const TrainerPage = ({ trainer }) => {
  const [key, setKey] = useState('info');

  return (
    <div className={classnames('trainer-page')}>
      <Tabs
        activeKey={key}
        id="trainer-page"
        onSelect={setKey}
      >
        <Tab
          eventKey="info"
          title={<TabTitle title="Information" icon="info" />}
        >
          <InfoPage trainer={trainer} />
        </Tab>

        <Tab
          eventKey="public-events"
          title={<TabTitle title="Public events" icon="ticket-alt" />}
        >
          <PublicEventsPage trainer={trainer} />
        </Tab>

        <Tab
          eventKey="private-classes"
          title={<TabTitle title="Private classes" icon="chalkboard-teacher" />}
        >
          <PrivateClassesPage trainer={trainer} />
        </Tab>

        <Tab
          eventKey="placed-tickets"
          title={<TabTitle title="Tickets" icon="ticket-alt" iconFamily="fas" />}
        >
          <PlacedTicketsPage trainer={trainer} />
        </Tab>

        <Tab
          eventKey="finance"
          title={<TabTitle title="Finance" icon="money-bill-alt" iconFamily="far" />}
        >
          <p>Work in progress...</p>
        </Tab>

        <Tab
          eventKey="media"
          title={<TabTitle title="Media" icon="photo-video" />}
        >
          <p>Work in progress...</p>
        </Tab>

        <Tab
          eventKey="other"
          title={<TabTitle title="Other" icon="question" />}
        >
          <p>Work in progress...</p>
        </Tab>
      </Tabs>
    </div>
  );
};

TrainerPage.propTypes = {
  trainer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    public_page_url: PropTypes.string,
    picture_url: PropTypes.string,
  }).isRequired,
};

TrainerPage.defaultProps = {
};

export default TrainerPage;
