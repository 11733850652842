import React from 'react';
import Button from 'react-bootstrap/Button';
import Icon from '../Icon';

const DownloadButton = ({ url = null }) => {
  if (!url) {
    return (
      <Button rel="noopener noreferrer" target="_blank" className="btn btn-sm btn-light disabled">
        <Icon name="download" fw />
      </Button>
    );
  }

  return (
    <Button
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      className="btn btn-sm btn-light"
    >
      <Icon name="download" fw />
    </Button>
  );
};

export default DownloadButton;
