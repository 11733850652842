import { get, httpDelete } from '../base';

const basePath = '/users';

export const fetchUsers = () => (
  get(`${basePath}.json`)
);

export const deleteUser = (userId) => (
  httpDelete(`${basePath}/${userId}.json`)
);

export default {
  fetchUsers,
  deleteUser,
};
