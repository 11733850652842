import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import LabelsCloud from './LabelsCloud';
import CalendarEventDatetimeLabel from '../smart_planner/CalendarEventDatetimeLabel';
import styles from './WeekViewCalendar.module.scss';
import { formatIsoTime } from '../../utils/datetime';

const formatT = (t) => (formatIsoTime(t, { format: 'short' }));
const timeLabel = (event) => {
  const { is_full_day, start_at, end_at } = event;

  if (is_full_day) { return 'All day long'; }

  const startTime = formatT(start_at);
  const endTime = formatT(end_at);

  return `${startTime} - ${endTime}`
};

const HighlightedContent = ({ event, labels, multiline }) => {
  const { summary, priority } = event;

  return (
    <div className={classnames('mt-2', styles.DayCellEvent__highlightedContent)}>
      <div className="text-truncate pb-1">
        <span className={classnames(styles.priorityBadge, 'float-right')}>
          {priority}
        </span>

        <CalendarEventDatetimeLabel
          event={event}
          compact
        />
      </div>

      <h6
        className={classnames(
          styles.DayCellEvent__headline, 'my-1',
          { 'text-truncate': !multiline },
        )}
      >
        {summary}
      </h6>

      <LabelsCloud
        event={event}
        labels={labels}
      />
    </div>
  );
};

const DefaultContent = ({ event, labels, multiline }) => {
  const { summary, priority } = event;
  const time = timeLabel(event);

  return (
    <div className="">
      <h6 className="text-truncate mb-0">
        <small className="d-block text-end mb-1 text-truncate">
          <span className={classnames(styles.priorityBadge, 'float-right')}>{priority}</span>
          <CalendarEventDatetimeLabel
            event={event}
            compact
          />
        </small>
        {summary}
      </h6>
      <LabelsCloud
        event={event}
        labels={labels}
      />
    </div>
  );
};

const DayCellEvent = ({
  event,
  labels,
  highlight,
  onClick,
  multiline,
}) => {
  const { summary, priority } = event;
  const handleClick = (clickEvent) => {
    clickEvent.stopPropagation();
    onClick(event);
  };

  const ContentComponent = highlight ? HighlightedContent : DefaultContent;

  return (
    <div
      className={classnames(
        styles.DayCellEvent,
        'p-1 m-1 badge d-block text-left',
        {
          'badge-primary': !highlight,
          'badge-warning': highlight,
          [styles.highlightDayCellEvent]: highlight,
          [styles.multilineDayCellEvent]: multiline,
        },
      )}
      onClick={handleClick}
      onKeyPress={handleClick}
      title={summary}
      role="button"
      aria-pressed="false"
      tabIndex={0}
    >
      <ContentComponent
        event={event}
        labels={labels}
        multiline={multiline}
      />
    </div>
  );
};

DayCellEvent.propTypes = {
  onClick: PropTypes.func,
  event: PropTypes.shape({
    summary: PropTypes.string,
  }).isRequired,
  highlight: PropTypes.bool,
  multiline: PropTypes.bool,
};

DayCellEvent.defaultProps = {
  onClick: () => {},
  highlight: false,
  multiline: false,
};

export default DayCellEvent;
