import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { DateTime } from 'luxon';

import Input from '../../inputs/Input';
import Icon from '../../Icon';
import handleError from '../../../utils/error-handler';
import { setEventsDefaultPriority } from '../../../api/smart_planner/account_calendars';
import { calendarShape } from './utils';

const EventsDefaultPriorityUpdateModal = ({
  show,
  onHide,
  onUpdate,
  calendar,
  label,
}) => {
  const { events_default_priority, calendar_name } = calendar;
  const [isLoading, setIsLoading] = useState(false);
  const [priority, setPriority] = useState(events_default_priority || 50);
  const handleEventsDefaultPriorityChange = (value) => {
    setPriority(value);
  };

  const plannedEventId = calendar.id;
  const handleUpdate = () => {
    if (isLoading) { return; }
    setIsLoading(true);

    setEventsDefaultPriority(plannedEventId, priority)
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        onHide();
        onUpdate({ plannedEventId, response: data, priority });
      })
      .catch(handleError);
  };

  // const { headline, starts_on, human_training_typology } = calendar;
  // const startsOn = DateTime.fromISO(starts_on);
  // const humanDate = startsOn.toFormat('dd LLL yyyy');

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Update calendar events default priority</strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ zIndex: 301 }}>
        <h4 className="mb-3">
          Choose the priority for the calendar
          &nbsp;
          <strong>
            &quot;
            {calendar_name}
            &quot;
          </strong>
        </h4>

        <Input
          label={label}
          onChange={handleEventsDefaultPriorityChange}
          initialValue={priority}
          type="number"
          min={0}
          max={100}
        />
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={handleUpdate} disabled={isLoading}>
          <Icon name="check" fw />
          { isLoading ? 'Loading...' : 'Update' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

EventsDefaultPriorityUpdateModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  calendar: calendarShape.isRequired,
  onUpdate: PropTypes.func,
  label: PropTypes.string,
};

EventsDefaultPriorityUpdateModal.defaultProps = {
  show: false,
  onUpdate: () => {},
  label: 'Calendar events default priority'
};

export default EventsDefaultPriorityUpdateModal;

