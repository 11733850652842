import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'
import classnames from 'classnames';

import Icon from '../../Icon';
import Row from '../../ListingTable/Row';

import { showPath } from '../../../routes/trainings/private_classes';

const statusBadgeClass = (status) => {
  switch (status) {
    case 'draft':
      return 'badge-secondary';
    case 'unconfirmed':
      return 'badge-warning';
    case 'confirmed':
      return 'badge-success';
    case 'sold_out':
      return 'badge-success';
    case 'canceled':
      return 'badge-dark';
    case 'running':
      return 'badge-info';
    case 'completed':
      return 'badge-success';
    case 'past':
      return 'badge-light';
    default:
      return 'badge-secondary';
  }
};

const DateCell = ({ startsOn }) => (
  <td className="h5">
    <Icon name="calendar" family="far" fw />
    &nbsp;
    {moment(startsOn).format('D/M/Y')}
  </td>
);

const HeadlineCell = ({ detailsPath, headline }) => (
  <td className="h5">
    <a href={detailsPath}>
      { headline }
    </a>
  </td>
);

const VenueCell = ({ venue }) => {
  if (!venue) {
    return (
      <td className="h6">
        <Icon name="globe-africa" fw />
        &nbsp;
        Online
      </td>
    );
  }

  return (
    <td className="h6">
      <Icon name="map-marker" fw />
      &nbsp;
      { venue }
    </td>
  );
};

const TrainersCell = ({ trainersName }) => {
  if (!trainersName) {
    return (
      <td className="h6">
        -
      </td>
    );
  }

  return (
    <td className="h6">
      <Icon name="user-alt" fw />
      &nbsp;
      { trainersName }
    </td>
  );
};

const TicketsProgressBar = ({ ticketsSold, capacity }) => {
  const ticketProgress = ticketsSold
    ? (ticketsSold / capacity) * 100.0
    : 0;

  const label = ticketProgress >= 100 ? 'SOLD OUT' : '';

  return (
    <div className="progress progress-mini">
      <div
        style={{ width: `${ticketProgress}%` }}
        className={
          classnames('progress-bar', {
            'bg-success': ticketProgress >= 100,
            'bg-warning': ticketProgress < 50,
          })
        }
      >
        { label }
      </div>
    </div>
  );
};

const TicketsCell = ({ ticketsSold, capacity }) => (
  <td className="h6">
    <div className="small">
      Tickets Sold:
      &nbsp;
      <strong>
        {ticketsSold || 0} of {capacity || 0}
      </strong>
    </div>

    <TicketsProgressBar ticketsSold={ticketsSold} capacity={capacity} />
  </td>
);

const PrivateClassRow = (props) => {
  const { item } = props;
  const {
    id,
    headline,
    starts_on,
    // ends_on,
    trainers_name,
    city,
    customer,
    tickets_sold,
    initial_capacity,
    status,
  } = item;

  return (
    <Row {...props}>
      <DateCell
        startsOn={starts_on}
      />

      <td>
        <h4>
          <span className={`badge ${statusBadgeClass(status)}`}>{status}</span>
        </h4>
      </td>

      <td>
        <strong>
          <Icon name="building" fw />
          &nbsp;
          {customer}
        </strong>
      </td>

      <HeadlineCell
        headline={headline}
        detailsPath={showPath(id)}
      />

      <TrainersCell
        trainersName={trainers_name}
      />

      <VenueCell venue={city} />

      <TicketsCell
        capacity={initial_capacity}
        ticketsSold={tickets_sold}
      />
    </Row>
  );
};

PrivateClassRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    headline: PropTypes.string,
    trainers_name: PropTypes.string,
    city: PropTypes.string,
    status: PropTypes.string,
    customer: PropTypes.string,
    starts_on: PropTypes.string,
    tickets_sold: PropTypes.number,
    initial_capacity: PropTypes.number,
    // acl: PropTypes.shape({
    //   canConfirm: PropTypes.bool,
    //   canCancel: PropTypes.bool,
    // }),
  }).isRequired,
  // onUpdate: PropTypes.func,
  // onDelete: PropTypes.func,
};

export default PrivateClassRow;
