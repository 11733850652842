import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import { debounce } from 'lodash';
import classnames from 'classnames';

import 'rc-slider/assets/index.css';

const SliderInput = ({ 
  onChange,
  initialValue,
  debounceTimer,
  className,
  min,
  max,
  marks,
}) => {
  const handleChange = useMemo(() => debounce(onChange, debounceTimer), []);

  const half = max / 2;

  return (
    <div className={classnames('SliderInput', className)}>
      <Slider
        min={min}
        max={max}
        marks={marks || { [min]: min, [half]: half, [max]: max }}
        onChange={(value) => { handleChange(value); }}
        defaultValue={initialValue}
      />
    </div>
  );
};

SliderInput.propTypes = {
  initialValue: PropTypes.number,
  onChange: PropTypes.func,
  debounceTimer: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
};

SliderInput.defaultProps = {
  debounceTimer: 50,
  min: 0,
  max: 100,
  onChange: () => {},
  initialValue: 0,
}

export default SliderInput;

