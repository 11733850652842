import React, {
  useState, createContext, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';
import EventBus from '../../../packs/event_bus';

import {
  syncAccountCalendars,
  fetchAccountCalendars,
  enableCalendar,
  disableCalendar,
} from '../../../api/smart_planner/account_calendars';

import { accountShape } from './utils';

const Context = createContext({});
const decorateCalendar = (calendar) => ({
  isLoading: false,
  ...calendar,
});
const decorateCalendars = (calendars) => (calendars.map(decorateCalendar));
const events = [
  'SmartPlanner::Calendars::PaginatedCalendarSyncCompleted',
  'SmartPlanner::Calendars::CalendarSyncCompleted',
];

export const Provider = ({ children, serializedAccount, ...props }) => {
  const account = serializedAccount.data.attributes;
  const [calendars, setCalendars] = useState([]);
  const [loadingCalendars, setLoadingCalendars] = useState(true);
  // const [isSynchronizingCalendars, setSynchronizingCalendars] = useState(false);
  const setSynchronizingCalendars = () => {
    setCalendars((calendarsData) => (calendarsData.map((calendar) => ({
      ...calendar,
      is_synching: calendar.enabled,
    }))));
  };
  const loadCalendars = () => {
    setLoadingCalendars(true);

    fetchAccountCalendars(account.id)
      .then((response) => (response.json()))
      .then(({ data }) => {
        const entries = decorateCalendars(data.map(({ attributes }) => (attributes)));

        setCalendars(entries);
        setLoadingCalendars(false);
      })
      .catch(handleError);
  };
  const updateCalendar = (calendarId, attributes = {}) => {
    const nextCalendars = calendars.map((calendar) => (
      calendar.id === calendarId
        ? ({ ...calendar, ...attributes })
        : calendar
    ));

    setCalendars(nextCalendars);
  };

  // Did mount
  useEffect(() => {
    let mounted = true;
    let eventBusSubscription;
    const handleEvent = () => { loadCalendars(); };

    if (mounted) {
      loadCalendars();

      eventBusSubscription = EventBus.subscribe(
        events,
        debounce(handleEvent, 250),
        this,
      );
    }

    return () => {
      mounted = false;
      EventBus.unsubscribe(eventBusSubscription);
    };
  }, []);

  const handleEnableCalendar = (calendar) => {
    updateCalendar(calendar.id, { isLoading: true });

    enableCalendar(calendar.id)
      .then((response) => (response.json()))
      .then((data) => {
        updateCalendar(calendar.id, { isLoading: false, enabled: true });
        success({ message: data.message });
      })
      .catch(handleError);
  };

  const handleDisableCalendar = (calendar) => {
    updateCalendar(calendar.id, { isLoading: true });

    disableCalendar(calendar.id)
      .then((response) => (response.json()))
      .then((data) => {
        updateCalendar(calendar.id, { isLoading: false, enabled: false });
        success({ message: data.message });
      })
      .catch(handleError);
  };

  const handleSyncAccountCalendars = () => {
    setSynchronizingCalendars();

    syncAccountCalendars(account.id)
      .then((response) => (response.json()))
      .then((data) => { success({ message: data.message }); })
      .catch(handleError);
  };

  const isSynchronizingCalendars = calendars.some(({ enabled, is_synching }) => (enabled && is_synching));

  // The shared state
  const value = useMemo(
    () => ({
      enableCalendar: handleEnableCalendar,
      disableCalendar: handleDisableCalendar,
      syncAccountCalendars: handleSyncAccountCalendars,
      isSynchronizingCalendars,
      loadingCalendars,
      calendars,
      account,
    }),
    [loadingCalendars, calendars, isSynchronizingCalendars],
  );

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  serializedAccount: PropTypes.shape({
    data: PropTypes.shape({
      attributes: accountShape,
    }),
  }).isRequired,
};

Provider.defaultProps = {
};

export default Context;
