import _ from 'lodash';

export const eventLabels = ({ event, labels }) => {
  const { label_ids } = event;

  return _.compact(label_ids.map((id) => (
    labels.find((label) => (label.id === id))
  )));
};

export default {
  eventLabels,
};
