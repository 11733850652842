import { Controller } from '@hotwired/stimulus';
import { parseISO, sub, isAfter } from 'date-fns';
import { reload } from '../utils/router';

export default class extends Controller {
  static targets = ['timestamp'];

  // connect() {
  //   console.log("Hello, Stimulus!", this.element)
  // }

  checkTimestamp() {
    if (!this.isValidTimestamp()) {
      reload();
    }
  }

  timestamp () {
    return parseISO(this.timestampTarget.value);
  }

  // Do not consider a valid form after 4 hours
  isValidTimestamp() {
    const threshold = sub(new Date(), { hours: 40 });

    return isAfter(this.timestamp(), threshold);
  }
}
