import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { DateTime } from 'luxon';
import AsyncSelect from 'react-select/async';

import Select from 'react-select';
import Icon from '../../Icon';
import handleError from '../../../utils/error-handler';
import { updateLanguage } from '../../../api/trainings/planned_events';
import { eventShape } from '../utils';

const options = [
  { value: 'IT', label: 'Italian' },
  { value: 'EN', label: 'English' },
  { value: 'DE', label: 'German' },
];

const LanguageUpdateModal = ({
  show,
  onHide,
  onUpdate,
  event,
  label,
}) => {
  const initialLanguage = options.find(({ value }) => (value === event.language));
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState(initialLanguage || null);
  const handleLanguageChange = (value) => {
    setLanguage(value);
  };

  const plannedEventId = event.id;
  const handleUpdate = () => {
    if (isLoading) { return; }
    setIsLoading(true);

    updateLanguage({ plannedEventId, language: language.value })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        onHide();
        onUpdate({ plannedEventId, response: data });
      })
      .catch(handleError);
  };

  const { headline, starts_on, human_training_typology } = event;
  const startsOn = DateTime.fromISO(starts_on);
  const humanDate = startsOn.toFormat('dd LLL yyyy');

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Update event language</strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ zIndex: 301 }}>
        <h4 className="mb-3">
          Choose the language for the
          &nbsp;
          {human_training_typology}
          &nbsp;
          <strong>
            &quot;
            {headline}
            &quot;
          </strong>
          &nbsp;
          on
          &nbsp;
          {humanDate}
        </h4>

        <Select
          name="language"
          valueKey="value"
          labelKey="label"
          value={language}
          options={options}
          className="Select-lg Select"
          onChange={handleLanguageChange}
          defaultOptions
        />
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={handleUpdate} disabled={isLoading}>
          <Icon name="check" fw />
          { isLoading ? 'Loading...' : 'Update' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

LanguageUpdateModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  event: eventShape.isRequired,
  onUpdate: PropTypes.func,
  label: PropTypes.string,
};

LanguageUpdateModal.defaultProps = {
  show: false,
  onUpdate: () => {},
  label: 'Language'
};

export default LanguageUpdateModal;
