export const baseUrl = ({ direction, recurring }) => {
  if (recurring) {
    return '/financial_analysis/recurring_expectations';
  }

  return direction === 'in'
    ? '/financial_analysis/income_expectations'
    : '/financial_analysis/expense_expectations';
};

export const generateUrl = ({
  id, action, direction, recurring,
}) => {
  const urlChunks = [baseUrl({ direction, recurring })];

  if (id) { urlChunks.push(id); }
  if (action) { urlChunks.push(action); }

  return urlChunks.join('/');
};

export default {
  baseUrl,
  generateUrl,
};
