import React from "react"
import { Chart } from "react-google-charts";
import Money from '../utils/money.js'

class MonthlyBillingGrowthChart extends React.Component {

    computeData(billingData) {
        const today = new Date();
        let rows = billingData.map(billingDocument => {
            const netAmount = Money(billingData.net_amount);
            const rollingAmount = Money(0,'EUR')
            const row = [
                billingData.issued_on,
                this.moneyToChartValue(netAmount)
            ];
            return row
        });

        return rows;
    }

    moneyToChartValue(money) {
        return { v: money.toUnit(), f: money.toFormat() }
    }

    render() {

        let billingData = this.props.billingData;
        const rows = this.computeData(billingData);
        const columns = [
            {
                type: "date",
                label: "Date"
            },
            {
                type: "number",
                label: "billed amount"
            }
        ];

        return (
            <Chart
                chartType="BarChart"
                width={"100%"}
                height={"480px"}
                rows={rows}
                columns={columns}
                options={{
                    //title: "Liquidity forecast",
                    legend: {
                        position: 'bottom'
                    },
                    hAxis: {
                        //title: 'Time',
                        titleTextStyle: { color: '#333' },
                    },
                    vAxis: {
                        //title: 'Liquidity',
                        style: 'currency',
                        format: '\u20AC#'
                    },
                    backgroundColor: '#f8f9fa',
                    seriesType: 'bars',
                    series: {
                        0: { color: 'blue', isStacked: false }
                    }
                }}
            />
        );

    }
}

export default MonthlyBillingGrowthChart



