import React, { Component, PropTypes } from 'react'
//import MessagesRoll from '../../components/common/messages/MessagesRoll'
//import MessageInputBox from '../../components/common/messages/MessageInputBox'
import uuidv4 from 'uuid/v4'
// import InfiniteScroll from 'react-infinite-scroller';
// import EventBus from '../packs/event_bus';

class MessageInputBox extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     value: '',
  //     prompt: 'Type your message here'
  //   };

  //   this.handleChange = this.handleChange.bind(this);
  //   this.handleSubmit = this.handleSubmit.bind(this);
  // }

  // handleChange(event) {
  //   this.setState({
  //     value: event.target.value
  //   });
  // }

  // handleSubmit(event) {
  //   event.preventDefault();

  //   this.props.submitCallback({
  //     username: 'GUEST',
  //     message: this.state.value
  //   })
  // }

  render() {
    return (
      <div>
        Refactor me if really needed...
      </div>
    );

    // return (
    //   <div className="chat-form">
    //             <form
    //   onSubmit={this.handleSubmit}>
    //                 <div className="form-group">
    //                     <textarea className="form-control text-small"
    //   value={this.state.value}
    //   onChange={this.handleChange}
    //   placeholder="Type your message..."></textarea>
    //                 </div>
    //                 <div className="text-right">
    //                     <button type="submit" className="btn btn-sm btn-primary m-t-n-xs">
    //                         <strong>Send message</strong>
    //                     </button>
    //                 </div>
    //             </form>
    //         </div>
    //   );
  }
}

class MessagesRoll extends React.Component {
  render() {
    const { messages } = this.props;
    return (
      <div>
        Refactor me if really needed...
      </div>
    );

    // return (
    //   <InfiniteScroll
    //     className={`infinite-scroller ${this.props.className}`}
    //     pageStart={0}
    //     loadMore={this.props.loadMoreChatMessages}
    //     hasMore={this.props.hasMoreMessages}
    //     isReverse={true}
    //     initialLoad={false}
    //     loader={<div className="loader">Loading ...</div>}
    //   >
    //     {
    //       messages.map((message) => (
    //         <Message key={message._id} message={message} />
    //       ))
    //     }
    //   </InfiniteScroll>
    // )
  }
}

const Message = (props) => (
  <div className="chat-element">
    <div className="media-body">
      <small className="pull-right text-navy"></small>
      <strong>{props.message.username}</strong>

      <p className="m-b-xs">
        {props.message.message}
      </p>

      <small className="text-muted">{(new Date(props.message.sent_at)).toString()}</small>
    </div>
  </div>
);

class Chat extends Component {
  state = {
    messages: []
  };

  // componentDidMount() {
  //   this.eventSubscriptionId = EventBus.subscribe("Chat::ChatMessagePosted", this.onReceived, this)

  //   fetch(`/chats/${this.props.topicName}/messages.json`, {credentials: 'same-origin'})
  //     .then(response => response.ok ? response.json() : [])
  //     .then(json => this.setState({
  //       messages: json
  //     }))
  // }

  // componentWillUnmount() {
  //   EventBus.unsubscribe(this.eventSubscriptionId)
  // }

  // onReceived(event) {
  //   const {message, username, sent_at} = event
  //   this.setState({
  //     messages: [
  //       ...this.state.messages,
  //       {
  //         message,
  //         username,
  //         sent_at
  //       }
  //     ]
  //   })
  // }

  // handleChatMessageSubmit = message => {
  //   const {dispatch, chatId} = this.props
  //   message['id'] = uuidv4();
  //   //dispatch(sendChatMessage(chatId, message))
  //   //

  //   fetch(`/chats/${this.props.topicName}/messages.json`, {
  //     credentials: 'same-origin',
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json, text/plain, */*',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       message: {
  //         id: message.id,
  //         message: message.message
  //       }
  //     })
  //   })
  // }

  // loadMoreChatMessages = (page) => {
  //   console.debug("load more: ", page)
  // }

  render() {
    return (
      <div>
        Refactor me if really needed...
      </div>
    );

    // return (
    //   <div className="chat chat-sidebar">
    //     <MessagesRoll
    //       className="chat-messages-container"
    //       messages={this.state.messages}
    //       loadMoreChatMessages={this.loadMoreChatMessages}
    //       hasMoreMessages={true}
    //     />

    //     <div className="chat-form-container m-t-sm">
    //       <MessageInputBox submitCallback={this.handleChatMessageSubmit} />
    //     </div>
    //   </div>
    // )
  }
}

export default Chat

