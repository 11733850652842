import React, { useEffect, useState, useMemo } from 'react';
// import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import { DateTime } from 'luxon';
import Slider from 'rc-slider';
import _, { debounce } from 'lodash';

import { userAvailableSlots } from '../../../api/smart_planner/available_days';
import Spinner from '../../Spinner';
import { priorityMarks } from '../utils';

import 'rc-slider/assets/index.css';

const parseISOTime = (time) => (DateTime.fromISO(time).toJSDate());

const columns = [
  { type: 'string', id: 'Name' },
  { type: 'string', id: 'Label' },
  { type: 'date', id: 'Start' },
  { type: 'date', id: 'End' },
];

const initialPriority = 0;

const fetchUserSlots = (user, { priority }) => (
  userAvailableSlots({ userId: user.id, priority })
    .then((response) => (response.json()))
    .then((response) => ({ ...response, user }))
);

const fetchUsersSlots = (users, { priority }) => (
  Promise.all(users.map((user) => (fetchUserSlots(user, { priority }))))
);

// TODO:
// consider the implementation of a custom legend:
// See here for more details:
// https://stackoverflow.com/questions/35987014/how-to-write-your-own-custom-legends-for-google-line-chart-google-line-chart-le/36064828#36064828
const TimelineChart = ({
  rows, columns, isLoading, users,
}) => {
  if (isLoading) {
    return (
      <div className="">
        <Spinner title="Loading users avaialable slots Timeline..." />
      </div>
    );
  }

  let currDate = DateTime.fromJSDate(_.minBy(rows, (data) => (data[2]))[2]).startOf('day');
  const maxDate = DateTime.fromJSDate(_.maxBy(rows, (data) => (data[3]))[3]).endOf('day');
  const abscissa = [];

  while (currDate <= maxDate) {
    abscissa.push([
      'Days',
      currDate.toLocaleString(DateTime.DATE_HUGE),
      currDate.startOf('day').toJSDate(),
      currDate.endOf('day').toJSDate(),
    ]);

    currDate = currDate.plus({ days: 1 });
  }

  if (rows.length === 0) {
    return (
      <div className="">
        <h5 className="text-center my-5">No available slots from users...</h5>
      </div>
    );
  }

  const data = [columns, ...rows, ...abscissa];
  const width = 220 * abscissa.length; // 500 for each day (now we have 30 days by default)
  const height = 50 * users.length + 100;

  return (
    <div style={{ overflowX: 'scroll', padding: '5px 0px 1px' }}>
      <div className="px-5">
        <Chart
          chartType="Timeline"
          height={`${height}px`}
          width={`${width}px`}
          data={data}
        />
      </div>
    </div>
  );
};

const UsersAvailableDays = ({ users }) => {
  const initialState = users.reduce((data, user) => ({
    ...data,
    [user.id]: { slots: null, user },
  }), {});

  const [priority, setPriority] = useState(initialPriority);
  const [isLoading, setIsLoading] = useState(true);
  const [usersSlots, setUsersSlots] = useState(initialState);

  const loadUsersSlots = (currentPriority) => {
    setIsLoading(true);

    fetchUsersSlots(users, { priority: currentPriority }).then((values) => {
      setUsersSlots(values.reduce((data, response) => ({
        ...data,
        [response.user_id]: response,
      }), {}));

      setIsLoading(false);
    });
  };

  const debouncedLoadUsersSlots = useMemo(
    () => debounce(
      (currUsers, currPriority) => (loadUsersSlots(currUsers, currPriority)),
      500,
    ),
    [],
  );

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      loadUsersSlots();
    }

    return () => { mounted = false; };
  }, []);

  const handlePriorityChange = (value) => {
    setPriority(value);
    setIsLoading(true);
    debouncedLoadUsersSlots(value);
  };

  const rows = [];

  Object.values(usersSlots).forEach(({ user, data }) => {
    const slots = data || [];
    const fullName = user.full_name;

    slots.forEach((slot) => {
      const startAt = parseISOTime(slot.start_at);
      const endAt = parseISOTime(slot.end_at);

      rows.push([
        fullName,
        '',
        startAt,
        endAt
      ])
    });
  });

  return (
    <div className="UsersAvailableDays card">
      <div className="row">
        <div className="col-md-6">
          <h4 className="p-5">Next available slots</h4>
        </div>

        <div className="col-md-6">
          <div className="p-5">
            <h6>
              Priority level: <strong>{priority}</strong>
            </h6>

            <Slider
              defaultValue={initialPriority}
              min={0}
              max={100}
              onChange={(value) => { handlePriorityChange(value) }}
              marks={priorityMarks}
            />
          </div>
        </div>

        <div className="col-md-12">
          <TimelineChart
            isLoading={isLoading}
            columns={columns}
            users={users}
            rows={rows}
          />
        </div>
      </div>
    </div>
  );
};

UsersAvailableDays.propTypes = {
};

export default UsersAvailableDays;

