import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Frame = ({ children, className, height }) => (
  <div className="SearchFirstAvailableSlotsPage__results mt-3 mt-lg-0 bg-light">
    <div
      className={classnames(className)}
      style={{ minHeight: height }}
    >
      {children}
    </div>
  </div>
);

Frame.propTypes = {
  height: PropTypes.number,
};

Frame.defaultProps = {
  height: 620,
};

export default Frame;
