import React from 'react';
import Button from 'react-bootstrap/Button';

import Icon from '../Icon';

const AddAccountButton = () => (
  <Button href="/smart_planner/google_calendars/new">
    <Icon name="plus" fw />
    &nbsp;
    Add google account
  </Button>
);

export default AddAccountButton;
