import React, {
  useState, createContext, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import {
  fetchRecipes,
  deleteRecipe,
} from '../../../api/smart_planner/recipes';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';
import EventBus from '../../../packs/event_bus';

const Context = createContext({});

const events = [
  'SmartPlanner::Recipes::Events::RecipeDefined',
  'SmartPlanner::Recipes::Events::RecipeUpdated',
  'SmartPlanner::Recipes::Events::RecipeDeleted',
];

export const Provider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [recipes, setRecipes] = useState([]);
  const loadRecipes = () => {
    fetchRecipes()
      .then((response) => (response.json()))
      .then(({ data }) => {
        const entries = data.map(({ attributes }) => (attributes));

        setRecipes(entries);
        setLoading(false);
      })
      .catch(handleError);
  };

  const handleDeletion = (account) => {
    deleteRecipe(account.id)
      .then((response) => (response.json()))
      .then((data) => { success({ message: data.message }); })
      .catch(handleError);
  };

  // On mount
  useEffect(() => {
    let mounted = true;
    if (mounted) { loadRecipes(); }

    return () => { mounted = false; };
  }, []);

  // Event BUS subscription
  useEffect(() => {
    const handleEvent = () => { loadRecipes(); };

    const eventBusSubscription = EventBus.subscribe(
      events,
      debounce(handleEvent, 300, { leading: true }),
      this,
    );

    return () => { EventBus.unsubscribe(eventBusSubscription); };
  });

  // The shared state
  const value = useMemo(
    () => ({
      loading,
      recipes,
      deleteRecipe: handleDeletion,
    }),
    [loading, recipes],
  );

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Provider.defaultProps = {};

export default Context;
