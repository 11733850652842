import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';

import Icon from '../../Icon';
import styles from './styles.module.scss';

const RoundedButton = ({
  className,
  onClick,
  iconName,
  iconFamily,
  variant,
  title,
  disabled,
}) => (
  <Button
    className={classnames(styles.RoundedButton, className)}
    variant={variant}
    onClick={onClick}
    title={title}
    disabled={disabled}
  >
    <Icon
      name={iconName}
      family={iconFamily}
      fw
    />
  </Button>
);

RoundedButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  iconName: PropTypes.string,
  iconFamily: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
};

RoundedButton.defaultProps = {
  className: null,
  onClick: () => {},
  variant: 'secondary',
  iconName: 'plus',
  iconFamily: 'far',
  title: null,
  disabled: false,
};

export default RoundedButton;
