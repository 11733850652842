import React, { useState } from 'react';

import Icon from '../../Icon';
import BlankState from '../../BlankState';
import { success } from '../../../utils/notifier';
import { visit } from '../../../utils/router';
import sleep from '../../../utils/sleep';
import EnableCertificationModal from './EnableCertificationModal';
import { eventShape } from '../utils';

const ConfirmedMissingCertification = ({ event }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const hideModal = () => { setShowModal(false); };
  const {
    acl,
    details_path,
    headline,
    human_training_typology,
  } = event;
  const { canConfirm } = acl;
  const description = `Do you want to enable the certification for the "${headline}" course?`;
  const buttonLabel = (
    <span>
      <Icon name="check" fw />
      &nbsp;
      Enable certification
    </span>
  );

  const handleConfirmButtonClick = () => {
    setShowModal(true);
  };

  const handleConfirmConfirmation = ({ response }) => {
    setIsLoading(true);

    sleep(1000).then(() => {
      visit(details_path)
        .then(() => { success({ message: response.message }); });
    });
  };

  return (
    <div>
      <EnableCertificationModal
        show={showModal}
        event={event}
        onHide={hideModal}
        onConfirm={handleConfirmConfirmation}
      />

      <BlankState
        title={`Certification for the current ${human_training_typology} has not yet been activated`}
        description={description}
        buttonLabel={buttonLabel}
        onClick={handleConfirmButtonClick}
        iconFamily="fas"
        icon="stamp"
        showButton={canConfirm}
        isLoading={isLoading}
      />
    </div>
  );
};

ConfirmedMissingCertification.propTypes = {
  event: eventShape.isRequired,
};

ConfirmedMissingCertification.defaultProps = {
};

export default ConfirmedMissingCertification;
