import React from 'react';
import classnames from 'classnames';

import styles from './WeekViewCalendar.module.scss';

const Footer = ({ onClick }) => (
  <div className={classnames(styles.header, styles.row, 'flex-middle')}>
    <div className="container-fluid">
      <div className="row">
        <div className="col" />

        <div className="col text-center">
          <button
            type="button"
            className={classnames('btn btn-primary')}
            onClick={() => onClick('prev')}
          >
            LOAD MORE
          </button>
        </div>

        <div className="col text-right" />
      </div>
    </div>
  </div>
);

export default Footer;
