import React from 'react';
import _ from 'lodash';

import EventBus from '../../../packs/event_bus';
import { get } from '../../../api/base';
import sleep from '../../../utils/sleep';
import { showPath as plannedEventPath } from '../../../routes/trainings/planned_events';

const decoratePlannedEvent = (plannedEvent) => ({
  ...plannedEvent,
});

const reactiveEvents = [
  'Trainings::Tickets::TicketSaleRegistered',
  'Trainings::PublicEditionEconomics::Events::ExpectedTicketSalesSet',
  'Trainings::PublicEditionEconomics::Events::ExpectedAverageTicketPriceSet',
  'Forecast::PublicTrainingSales::Events::SalesForecastsCompleted',
];

const WithPlannedEvent = (WrappedComponent) => {
  class HOC extends React.Component {
    // static propTypes = {
    //   plannedEvent: PropTypes.shape({}),
    // };

    constructor(props) {
      super(props);

      const { plannedEvent } = props;

      this.state = {
        plannedEvent,
        tickets: [],
        classificationCategories: [],
        classificationRules: [],
        forecastExpectations: [],
        salesForecast: null,
        loading: true,
      };
    }

    componentDidMount() {
      this.plannedEventEventsSubscriptionId = EventBus.subscribe(
        reactiveEvents,
        _.debounce(
          this.handlePlannedEventSubscription,
          500,
          { leading: true },
        ),
        this,
      );

      this.loadPlannedEvent();
    }

    componentWillUnmount() {
      EventBus.unsubscribe(this.plannedEventEventsSubscriptionId);
    }

    // handleOnDelete({ plannedEventId }) {
    //   const { plannedEvents } = this.state;
    //   const filteredPlannedEvents = plannedEvents.filter((pe) => (
    //     pe.id !== plannedEventId
    //   ));

    //   this.setPlannedEvents(filteredPlannedEvents);
    // }

    // handleOnUpdate({ plannedEvent }) {
    //   const { plannedEvents } = this.state;
    //   const { id } = plannedEvent;
    //   const updatedPlannedEvents = plannedEvents.map((pe) => (
    //     pe.id === id ? plannedEvent : pe
    //   ));

    //   this.setPlannedEvents(updatedPlannedEvents);
    // }

    handlePlannedEventSubscription() {
      this.loadPlannedEvent(500);
    }

    loadPlannedEvent(wait = 0) {
      const { plannedEvent } = this.state;
      const { id } = plannedEvent;
      const path = plannedEventPath(id, { format: 'json' });

      return sleep(wait).then(() => {
        get(path)
          .then((response) => (response.json()))
          .then(({
            planned_event,
            tickets,
            certification,
            classification_categories,
            classification_rules,
            forecast_expectations,
            sales_forecast,
          }) => {
            this.setState({
              loading: false,
              plannedEvent: decoratePlannedEvent(planned_event),
              tickets,
              certification,
              classificationCategories: classification_categories,
              classificationRules: classification_rules,
              forecastExpectations: forecast_expectations,
              salesForecast: sales_forecast,
            });
          });
      });
    }

    render() {
      const {
        plannedEvent,
        loading,
        certification,
        tickets,
        classificationCategories,
        classificationRules,
        forecastExpectations,
        salesForecast,
      } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          loading={loading}
          plannedEvent={plannedEvent}
          certification={certification}
          tickets={tickets}
          classificationCategories={classificationCategories}
          classificationRules={classificationRules}
          forecastExpectations={forecastExpectations}
          salesForecast={salesForecast}
        />
      );
    }
  }

  return HOC;
};

export default WithPlannedEvent;
