import React from 'react';
import PropTypes from 'prop-types';

import ListingTable from '../../ListingTable';
import { itemMatchSearchTerm } from '../../ListingTable/utils';
import SearchBar from './TicketsSearchBar';
import TicketRow from './TicketRow';
import { eventShape } from '../utils';

const searchTickets = ({ items, term }) => {
  if (term.length === 0) { return items; }

  return items.filter((item) => (
    itemMatchSearchTerm({ item, filterKeys, term })
  ));
};

const handleOnSearch = ({ items, filters, filterKeys }) => {
  const { text, ticketsState } = filters;
  const term = (text || '').toLowerCase();
  const placedTickets = items.filter(({ cancelled }) => (!cancelled));
  const cancelledTickets = items.filter(({ cancelled }) => (cancelled));
  const filteredItems = ticketsState === 'cancelled'
    ? searchTickets({ items: cancelledTickets, term })
    : searchTickets({ items: placedTickets, term });

  return filteredItems;
};

const TicketsDataTable = ({
  event,
  tickets,
  hideSearch,
  showActions,
}) => {
  const headers = [
    'First name',
    'Last name',
    'Email',
    'Ticket class',
    'Ticket price',
    'Purchases at',
    'Status',
  ];

  const headerColsProps = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ];

  if (showActions) {
    headers.push('Actions');
    headerColsProps.push({ className: 'text-center' });
  }

  const sortableColumns = {
    'First name': 'attendee_first_name',
    'Last name': 'attendee_last_name',
    Email: 'attendee_email',
    'Ticket class': 'ticket_class_name',
    'Ticket price': (t) => (t.gross_price.cents),
    'Purchases at': 'purchased_at',
    Status: 'cancelled',
  };
  const filterKeys = [
    'attendee_first_name',
    'attendee_last_name',
    'attendee_email',
  ];
  const placedTickets = tickets.filter(({ cancelled }) => (!cancelled));
  const cancelledTickets = tickets.filter(({ cancelled }) => (cancelled));

  return (
    <ListingTable
      items={tickets}
      components={{ Row: TicketRow, SearchBar }}
      headers={headers}
      sortableColumns={sortableColumns}
      filterKeys={filterKeys}
      hideSearch={hideSearch}
      searchBarProps={{ cancelledTickets, placedTickets }}
      rowProps={{ showActions }}
      onSearch={handleOnSearch}
      headerColsProps={headerColsProps}
    />
  );
};

TicketsDataTable.propTypes = {
  event: eventShape.isRequired,
  hideSearch: PropTypes.bool,
  showActions: PropTypes.bool,
  onUpdate: PropTypes.func,
};

TicketsDataTable.defaultProps = {
  hideSearch: false,
  showActions: false,
  onUpdate: () => {},
};

export default TicketsDataTable;
