import uuidv4 from 'uuid/v4';
import Consumer from './Consumer';

// TODO: create a Base abstract class
class FinancialAnalysis {
  constructor() {
    this.handlers = [];
    this.connect();
  }

  connect() {
    Consumer.subscriptions.create({
      channel: 'FinancialAnalysisChannel',
    }, {
      connected: () => {},
      received: (message) => {
        this.publish(message);
      },
    });
  }

  subscribe(key, handler, context, staticId) {
    const id = staticId || uuidv4();

    if (typeof context === 'undefined') {
      context = handler;
    }

    this.handlers.push({ id, key, handler: handler.bind(context) });
    return id;
  }

  unsubscribe(id) {
    this.handlers = this.handlers.filter((handler) => handler.id !== id);
  }

  publish(payload) {
    for (let i = 0; i < this.handlers.length; i += 1) {
      if (this.handlers[i].key === payload.key) {
        this.handlers[i].handler.call(null, payload);
      }
    }
  }
}

export default new FinancialAnalysis();
