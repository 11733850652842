import React from "react"
import PropTypes from "prop-types"
import Moment from 'moment'

class LearnerRow extends React.Component {

    generate_url(id = null, action = null) {
        let url = '/learners/learners/'
        if(id) url = url + id + '/';
        if(action) url = url + action;
        return url
    }

  render() {
        const id = this.props._id;
        const first_name = this.props.first_name;
        const last_name = this.props.last_name;
        const full_name = this.props.full_name;

        return (
            <tr key={id}>
                <td>
                    <a href={this.generate_url(id)}><big>{full_name}</big></a>
                </td>
                <td>
                    <p>Accomplishments: </p>
                </td>

            </tr>
        )
    }
}

export default LearnerRow
