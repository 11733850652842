import React from 'react';
// import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import EventBus from '../../../packs/event_bus';
import { get } from '../../../api/base';
import sleep from '../../../utils/sleep';
import { certifiedAttendeesPath } from '../../../routes/trainings/certifications';
import { generateCertificates } from '../../../api/trainings/certifications';
import { deleteCertifiedAttendee } from '../../../api/trainings/certifiedAttendees';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';

const decorateCertifiedAttendee = (certifiedAttendee, attributes = {}) => ({
  isGeneratingPdf: certifiedAttendee.isGeneratingPdf || false,
  ...certifiedAttendee,
  ...attributes,
});
const decorateCertifiedAttendees = (certifiedAttendees, attributes = {}) => (
  certifiedAttendees.map((ca) => (decorateCertifiedAttendee(ca, attributes)))
);

const WithCertifiedAttendees = (WrappedComponent) => {
  class CertifiedAttendeesHOC extends React.Component {
    constructor(props) {
      super(props);

      const { certification } = props;

      this.debouncedLoadCertifiedAttendees = debounce(
        this.loadCertifiedAttendees,
        500,
        { leading: true },
      );

      this.state = {
        certifiedAttendees: [],
        certification,
        loading: true,
      };
    }

    componentDidMount() {
      const events = [
        'Trainings::Certificates::PdfAssetAssigned',
        'Trainings::Certifications::CertifiedAttendeeAdded',
        'Trainings::Certifications::PublicEditionCertifiedAttendeeAdded',
        'Trainings::Certifications::CertifiedAttendeeUpdatedFromExternalSystem',
      ];

      this.certifiedAttendeesEventsSubscriptionId = EventBus.subscribe(
        events,
        this.handleCertifiedAttendeesEventsSubscription,
        this,
      );

      this.loadCertifiedAttendees();
    }

    componentWillUnmount() {
      EventBus.unsubscribe(this.certifiedAttendeesEventsSubscriptionId);
    }

    setCertifiedAttendees(plainCertifiedAttendees, attributes = {}) {
      const certifiedAttendees = decorateCertifiedAttendees(
        plainCertifiedAttendees,
        attributes,
      );

      this.setState({
        loading: false,
        certifiedAttendees,
      });
    }

    handlePdfAssetAssignedEvent(event) {
      const { certified_attendee, asset } = event;
      const { id } = certified_attendee;
      const pdf_filename = asset.file_name;

      const { certifiedAttendees } = this.state;
      const updatedCertifiedAttendees = certifiedAttendees.map((ca) => {
        if (ca.id === id) {
          return {
            ...ca,
            isGeneratingPdf: false,
            has_pdf: true,
            pdf_filename,
          };
        }

        return ca;
      });

      this.setCertifiedAttendees(updatedCertifiedAttendees);
    }

    handleCertifiedAttendeesEventsSubscription({ event_name, ...event }) {
      switch (event_name) {
        case 'Trainings::Certificates::PdfAssetAssigned':
          this.handlePdfAssetAssignedEvent(event);
          break;
        default:
          this.debouncedLoadCertifiedAttendees();
      }
    }

    handleOnGenerateCertificates() {
      const { certifiedAttendees, certification } = this.state;
      this.setCertifiedAttendees(certifiedAttendees, { isGeneratingPdf: true });
      generateCertificates({ certificationId: certification.id })
        .then((response) => response.json())
        .then((data) => { success({ message: data.message }); })
        .catch(handleError);
    }

    handleOnDelete({ id }) {
      const { certifiedAttendees, certification } = this.state;
      const certifiedAttendeeId = id;
      const certificationId = certification.id;
      const filteredCertifiedAttendees = certifiedAttendees.filter((ca) => (ca.id !== id));

      return deleteCertifiedAttendee({ certificationId, certifiedAttendeeId })
        .then((response) => response.json())
        .then((response) => {
          success({ message: response.message });
          this.setCertifiedAttendees(filteredCertifiedAttendees);
        })
        .catch(handleError);
    }

    handleOnUpdate({ certifiedAttendee }) {
      const { certifiedAttendees } = this.state;
      const { id } = certifiedAttendee;
      const updatedCertifiedAttendees = certifiedAttendees.map((ca) => (
        ca.id === id ? certifiedAttendee : ca
      ));

      this.setCertifiedAttendees(updatedCertifiedAttendees);
    }

    loadCertifiedAttendees(wait = 0) {
      const { certification } = this.state;
      const { id } = certification;
      const endpoint = certifiedAttendeesPath(id, { format: 'json' });

      return sleep(wait).then(() => {
        get(endpoint)
          .then((response) => (response.json()))
          .then(({ data }) => { this.setCertifiedAttendees(data); });
      });
    }

    render() {
      const {
        certification,
        certifiedAttendees,
        loading,
      } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          loading={loading}
          certification={certification}
          certifiedAttendees={certifiedAttendees}
          onGenerateCertificates={() => { this.handleOnGenerateCertificates(); }}
          onDelete={(args) => (this.handleOnDelete(args))}
          onUpdate={(args) => (this.handleOnUpdate(args))}
        />
      );
    }
  }

  return CertifiedAttendeesHOC;
};

export default WithCertifiedAttendees;
