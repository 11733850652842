import React from 'react';
// import PropTypes from 'prop-types';

import { Provider } from './Context';
import Layout from './Layout';

const UserAccountPage = (props) => (
  <Provider {...props}>
    <Layout />
  </Provider>
);

UserAccountPage.propTypes = {
};

export default UserAccountPage;
