import React from 'react';
import PropTypes from 'prop-types';
// import classnames from 'classnames';

import Icon from '../../../Icon';
import { categoryShape, eventShape } from '../../utils';
import InlineCategorization from '../../../financial_analysis/classification/InlineCategorization';
import {
  createPublicTrainingRule,
  resetPublicTrainingCategoryRules,
} from '../../../../api/financialAnalysis/rules';

const CategoryRow = ({
  category, labels, onChange, event,
}) => {
  const {
    label,
    notes,
    expense,
    revenue,
  } = category;

  const handleCategorizeLabel = ({ categoryId, categoryLabelId, repartition }) => {
    if (categoryLabelId) {
      return createPublicTrainingRule({
        publicTrainingId: event.id,
        categoryId,
        categoryLabels: [{ id: categoryLabelId, repartition }],
      });
    }

    // else
    return resetPublicTrainingCategoryRules({
      publicTrainingId: event.id,
      categoryId,
    });
  };

  const handleCategorizeLabels = ({ categoryId, labels }) => {
    const categoryLabels = labels.map(({ id, repartition }) => ({ id, repartition }));

    return createPublicTrainingRule({
      publicTrainingId: event.id,
      categoryId,
      categoryLabels,
    });
  };

  return (
    <tr>
      <td>
        <strong className="h5">{label}</strong>
        <br />
        {
          revenue && (
            <span className="badge badge-success mr-2">
              <Icon name="sign-in-alt" fw />
              &nbsp;
              Revenue
            </span>
          )
        }
        {
          expense && (
            <span className="badge badge-danger mr-2">
              <Icon name="sign-out-alt" fw />
              &nbsp;
              Expense
            </span>
          )
        }
      </td>

      <td className="h5 align-middle">
        <InlineCategorization
          category={category}
          categorizeLabelPromise={handleCategorizeLabel}
          categorizeLabelsPromise={handleCategorizeLabels}
          onChanging={onChange}
          values={labels}
        />
      </td>

      <td className="align-middle">
        {notes || '-'}
      </td>

      <td />
    </tr>
  );
};

CategoryRow.propTypes = {
  category: categoryShape.isRequired,
  labels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  event: eventShape.isRequired,
};

export default CategoryRow;
