import React from 'react';
import PropTypes from 'prop-types';

import ListingTable from '../../ListingTable';
import Row from './CertifiedAttendeeRow';

const CertifiedAttendeesDataTable = (({
  onUpdate,
  onDelete,
  certification,
  certifiedAttendees,
  certificatesHost,
}) => {
  const headers = [
    'First name',
    'Last name',
    'Email',
    'Actions',
  ];
  const sortableColumns = {
    'First name': 'first_name',
    'Last name': 'last_name',
    Email: 'email',
  };

  return (
    <ListingTable
      items={certifiedAttendees}
      components={{ Row }}
      headers={headers}
      sortableColumns={sortableColumns}
      rowProps={{
        onUpdate,
        onDelete,
        certification,
        certificatesHost,
      }}
      hideSearch
    />
  );
});

CertifiedAttendeesDataTable.propTypes = {
  certification: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  certifiedAttendees: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })).isRequired,
};

CertifiedAttendeesDataTable.defaultProps = {
};

export default CertifiedAttendeesDataTable;
