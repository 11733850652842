import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import Icon from '../../Icon';
import { success } from '../../../utils/notifier';
import handleError from '../../../utils/error-handler';
import { synchronizePlannedEvent } from '../../../api/trainings/planned_events';
import sleep from '../../../utils/sleep';

const SyncButton = ({ event, size, className }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { acl } = event;
  const { canSynchronize } = acl;

  if (!canSynchronize) { return ''; }

  const handleButtonClick = () => {
    setIsLoading(true);
    const { id } = event;

    sleep(20000).then(() => { setIsLoading(false); });

    synchronizePlannedEvent({ plannedEventId: id })
      .then((response) => response.json())
      .then((data) => {
        success({ message: data.message });
      })
      .catch(handleError);
  };

  return (
    <div className="sync-button d-inline-block">
      <Button
        size={size}
        variant="warning"
        title="Sync order from eventbrite"
        onClick={handleButtonClick}
        className={className}
        disabled={isLoading}
      >
        <Icon name="sync" spin={isLoading} fw />
        &nbsp;
        Sync event
      </Button>
    </div>
  );
};

SyncButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  event: PropTypes.shape({
    id: PropTypes.string,
    headline: PropTypes.string,
    details_path: PropTypes.string,
    trainers_name: PropTypes.string,
    venue: PropTypes.string,
    status: PropTypes.string,
    starts_on: PropTypes.string,
    tickets_sold: PropTypes.number,
    capacity: PropTypes.number,
    acl: PropTypes.shape({
      canCancel: PropTypes.bool,
      canSynchronize: PropTypes.bool,
    }),
  }).isRequired,
};

SyncButton.defaultProps = {
  size: 'md',
  className: '',
};

export default SyncButton;
