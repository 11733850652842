const isPresent = (text) => (text && text.length > 0);

export const withFormat = (path, { format }) => {
  if (isPresent(format)) { return `${path}.${format}`; }

  return path;
};

export const withHost = (path, { host, protocol = 'https' }) => {
  if (host) { return `${protocol}://${host}${path}`; }

  return path;
};

export const urlFor = (path, options = {}) => withHost(withFormat(path, options), options);

export default {
  withFormat,
  withHost,
  urlFor,
};
