import React from 'react';
import Icon from './Icon';
import Money, { colorize } from '../utils/money';
import { deletePath } from '../routes/treasury/transactions';

class TransactionsListRow extends React.Component {
  renderDeleteCta() {
    const { id, acl } = this.props.item;
    if (!acl.canDelete) { return ''; }

    return (
      <a
        href={deletePath(id)}
        className="btn btn-ghost-dark btn-sm"
        data-toggle="tooltip"
        data-placement="top"
        data-confirm="Are you sure?"
        data-method="delete"
        title="Delete transaction"
        data-original-title="Delete transaction"
      >
        <Icon name="trash" fw />
      </a>
    );
  }

  render() {
    const { item } = this.props;
    const {
      id,
      entry_type,
      date,
      operation_amount,
      formatted_operation_amount,
      formatted_resulting_balance,
      counterpart,
      notes,
    } = item;

    const isTransaction = entry_type === 'transaction';

    return (
      <tr key={id} className="row">
        <td className="col-lg-3">
          { date }
          <br />
          <small className="badge badge-light">
            {entry_type}
          </small>
        </td>

        <td className="col-lg-2 text-right">
          <strong className={colorize(Money(operation_amount))}>
            { formatted_operation_amount }
          </strong>
          {
            isTransaction && (
              <small className="d-block">{ formatted_resulting_balance }</small>
            )
          }
        </td>

        <td className="col-lg-6">
          <div style={{ maxWidth: '250px' }}>
            <div className="text-truncate" title={counterpart}>
              <strong>{counterpart}</strong>
            </div>

            <div className="text-truncate" title={notes}>
              {notes}
            </div>
          </div>
        </td>

        <td className="col-lg-1 text-center">{ this.renderDeleteCta() }</td>
      </tr>
    );
  }
}

export default TransactionsListRow;
