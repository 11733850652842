import React from 'react';
import PropTypes from 'prop-types';
import InlineCategorization from '../financial_analysis/classification/InlineCategorization';

const findCategoryLabels = (category, categoryLabels) => (
  categoryLabels.filter((label) => (
    label.category_id === category.category_id
  ))
);

const CategoryColumn = ({
  operationId,
  operation,
  category,
  categoryLabels,
  operationHeadline,
  onCategoryLabelChange,
  onCategoryLabelChanged,
}) => {
  const labels = findCategoryLabels(category, categoryLabels);

  return (
    <td className="h5">
      <InlineCategorization
        category={category}
        operationId={operation.id}
        operation={operation}
        operationHeadline={operationHeadline}
        values={[...labels]}
        onChanging={onCategoryLabelChange}
        onChange={onCategoryLabelChanged}
      />
    </td>
  );
};

CategoryColumn.propTypes = {
  operationId: PropTypes.string.isRequired,
  category: PropTypes.shape({}).isRequired,
  categoryLabels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  operationHeadline: PropTypes.string.isRequired,
  onCategoryLabelChange: PropTypes.func.isRequired,
  onCategoryLabelChanged: PropTypes.func.isRequired,
}

export default CategoryColumn;
