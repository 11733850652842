import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import Row from '../../ListingTable/Row';
import Icon from '../../Icon';
import DeleteModal from '../DeleteModal';
import { showPath, editPath } from '../../../routes/trainers/trainers';
import { showPath as plannedEventPath } from '../../../routes/trainings/planned_events';
import { showPath as privateClassPath } from '../../../routes/trainings/private_classes';

const TrainerProfileRow = (props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const hideDeleteModal = () => { setShowDeleteModal(false); };
  const handleDeleteButtonClick = () => { setShowDeleteModal(true); };
  const {
    onRowDelete,
    item,
  } = props;
  const {
    id,
    picture_url,
    full_name,
    email,
    future_public_events,
    future_private_classes,
  } = item;

  return (
    <Row {...props}>
      <td style={{ width: '20%' }}>
        <img
          className="trainer-profile-picture img-responsive small"
          src={picture_url}
          alt={full_name}
        />
      </td>

      <td>
        <a href={showPath(id)}><big>{full_name}</big></a>
      </td>

      <td>
        <a href={`mailto:${email}`}>{email}</a>
      </td>

      <td>
        <ul className="list-unstyled">
          {
            (future_public_events || []).map(({ id, headline, starts_on }) => (
              <li key={id}>
                <a href={plannedEventPath(id)} className="text-truncate">
                  <Icon name="ticket-alt" fw />
                  &nbsp;
                  {starts_on}
                  &nbsp;
                  -
                  &nbsp;
                  {headline}
                </a>
              </li>
            ))
          }

          {
            (future_private_classes || []).map(({ id, headline, starts_on }) => (
              <li key={id}>
                <a href={privateClassPath(id)} className="text-truncate">
                  <Icon name="chalkboard-teacher" fw />
                  &nbsp;
                  {starts_on}
                  &nbsp;
                  -
                  &nbsp;
                  {headline}
                </a>
              </li>
            ))
          }
        </ul>
      </td>

      <td className="table-actions" style={{ width: '20%' }}>
        <DeleteModal
          show={showDeleteModal}
          onHide={hideDeleteModal}
          onDelete={({ id }) => { onRowDelete(id); }}
          trainer={item}
        />

        <div className="btn-group">
          <Button variant="light" size="sm" href={showPath(id)}>
            <Icon name="info" fw />
          </Button>

          <Button variant="light" size="sm" href={editPath(id)}>
            <Icon name="pencil-alt" fw />
          </Button>

          <Button variant="danger" size="sm" onClick={handleDeleteButtonClick}>
            <Icon name="trash" fw />
          </Button>
        </div>
      </td>
    </Row>
  );
};

TrainerProfileRow.propTypes = {
  id: PropTypes.string.isRequired,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  full_name: PropTypes.string,
  email: PropTypes.string,
  picture_url: PropTypes.string,
  onRowDelete: PropTypes.func.isRequired,
  item: PropTypes.shape({}).isRequired,
};

export default TrainerProfileRow;
