import React from 'react';

const Entity = ({ entity }) => {
  const { entityType } = entity;

  return (
    <div>
      foo
    </div>
  );
};

export default Entity;
