import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';

import Icon from '../Icon';
import Input from '../RHF/Input';
import SelectInput from '../RHF/SelectInput';
import DateInput from '../RHF/DateInput';
import PriceInput from '../RHF/PriceInput';

const ExpectationForm = ({
  countries,
  currencies,
  accounts,
  formId,
  defaultValues,
  onSave,
}) => {
  const { handleSubmit, control, formState: { errors } } = useForm({ defaultValues });

  const onSubmit = (params) => {
    const { amount } = params;

    onSave({
      ...params,
      ...amount,
    });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} id={formId}>
        <Input
          label="Headline"
          name="headline"
          errors={errors}
          control={control}
          size="lg"
          required
        />

        <div className="row">
          <div className="col-6">
            <DateInput
              label="Expected on date"
              name="expected_on_date"
              errors={errors}
              control={control}
              required
            />
          </div>

          <div className="col-6">
            <SelectInput
              label="Account"
              name="expected_on_account_id"
              errors={errors}
              control={control}
              options={accounts}
              size="lg"
              required
            />
          </div>
        </div>

        <PriceInput
          name="amount"
          errors={errors}
          control={control}
          netOptions={{
            name: 'expected_net_amount',
          }}
          vatOptions={{
            name: 'expected_amount_vat',
          }}
          grossOptions={{
            auto: true,
            readOnly: true,
            name: 'expected_amount',
          }}
          withholdingTaxOptions={{
            name: 'withholding_tax'
          }}
          otherWithholdingTaxOptions={{
            name: 'other_withholding_tax'
          }}
          showWithholdingFields
        />

        <div className="row">
          <div className="col-6">
            <SelectInput
              label="Country"
              name="country"
              errors={errors}
              control={control}
              options={countries}
              size="lg"
              required
            />
          </div>

          <div className="col-6">
            <SelectInput
              label="Currency"
              name="currency"
              errors={errors}
              control={control}
              options={currencies}
              size="lg"
              required
            />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Input
              label="From"
              name="counterpart"
              errors={errors}
              control={control}
              size="lg"
              required
            />
          </div>

          <div className="col-6">
            <Input
              label="Related to"
              name="related_to"
              errors={errors}
              control={control}
              size="lg"
            />
          </div>
        </div>

        <Input
          label="Notes"
          name="notes"
          errors={errors}
          control={control}
          size="lg"
          as="textarea"
        />
      </Form>
    </div>
  );
};

ExpectationForm.propTypes = {
  defaultValues: PropTypes.shape({}),
};

ExpectationForm.defaultProps = {
  defaultValues: {},
};

export default ExpectationForm;


