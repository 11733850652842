import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
// import { debounce } from 'lodash';

import {
  convertFromValue,
  convertToValue,
} from './utils';

const TimeDurationInput = React.forwardRef(({
  value, scale, onChange, ...props
}, ref) => {
  const [
    duration,
    setDuration,
  ] = useState(convertFromValue(value, scale));

  // useEffect(() => {
  //   const newDuration = convertFromValue(value, scale);
  //   console.log('useEffect', { newDuration, duration });

  //   if (newDuration !== duration) setDuration(newDuration);
  // }, [value, scale]);

  // const handleInputChange = useCallback(({ target }) => {
  //   console.log('handleInputChange', { target });

  //   setDuration(target.value);
  //   const newValue = convertToValue(target.value, scale);
  //   if (!Number.isNaN(newValue)) onChange(newValue);
  // }, [onChange, scale]);

  // const debouncedOnChange = debounce(onChange, 500);

  const handleInputChange = ({ target }) => {
    const targetValue = target.value;
    setDuration(targetValue);
    const newValue = convertToValue(targetValue, scale);

    if (!Number.isNaN(newValue)) { onChange(newValue); }
  };

  return (
    <Form.Control
      ref={ref}
      type="text"
      value={duration}
      onChange={handleInputChange}
      {...props}
    />
  );
});

TimeDurationInput.propTypes = {
  value: PropTypes.number,
  scale: PropTypes.oneOf(['d', 'h', 'm', 's', 'ms']),
  onChange: PropTypes.func,
};

TimeDurationInput.defaultProps = {
  value: 60,
  scale: 'm',
  onChange: () => {},
};

export default TimeDurationInput;
