import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import classnames from 'classnames';

import { PlainDateInput } from '../inputs/DateInput';
import { extractError } from './utils';

const DateInput = ({
  label,
  errors,
  name,
  control,
  required,
  onChange,
  ...props
}) => {
  const error = extractError(errors, name);
  const errorMessage = error
    ? error.message || 'Required field'
    : null;
  const hasErrors = !!errorMessage;

  return (
    <Form.Group
      controlId={`form-grid-${name}`}
      className={classnames({ 'form-group-invalid': hasErrors })}
    >
      {
        label && (
          <Form.Label className={classnames({ 'text-danger': hasErrors })}>
            {label}
            &nbsp;
            {required && (<abbr title="required">*</abbr>)}
          </Form.Label>
        )
      }
      <Controller
        render={({ field }) => (
          <PlainDateInput
            className="form-control form-control-lg"
            {...props}
            {...field}
            onChange={(e) => {
              onChange(e);
              field.onChange(e);
            }}
          />
        )}
        control={control}
        name={name}
        rules={{ required }}
      />
      {
        errorMessage && (
          <Form.Control.Feedback type="invalid">
            {errorMessage}
          </Form.Control.Feedback>
        )
      }
    </Form.Group>
  );
};

DateInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  errors: PropTypes.shape({}),
  control: PropTypes.shape({}).isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

DateInput.defaultProps = {
  label: 'Input',
  errors: {},
  required: false,
  onChange: () => {},
};

export default DateInput;
