import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import classnames from 'classnames';
import _ from 'lodash';

import CategoryCharts from './CategoryCharts';
import styles from './styles.module.scss';

const updateChangeYearLinks = (categoryId) => {
  const iterate = (el) => {
    const url = el.getAttribute('href');
    const chunks = url.split('&category_id');
    const newUrl = `${chunks[0]}&category_id=${categoryId}`;

    el.setAttribute('href', newUrl);
  };

  document.getElementsByClassName('working-year-link').forEach(iterate);
  document.getElementsByClassName('switch-operation-type-link').forEach(iterate);
};

const CategoriesCharts = ({
  categories,
  operations,
  logic,
  activeCategoriesLabels,
  activeCategoryLabelsFilters, // which filter is active?
  onCategoryLabelsChange,
  onCategoryChange,
  ...options
}) => {
  const categoryIds = categories.map(({ id }) => (id));
  const activeKey = _.includes(categoryIds, options.activeCategoryId)
    ? options.activeCategoryId
    : categoryIds[0];
  const [key, setKey] = useState(activeKey);
  const handleChange = (categoryId) => {
    setKey(categoryId);
    onCategoryChange(categoryId);
  };
  const handleFilteredCategoryLabelsChange = (categoryId, activeLabelIds) => {
    onCategoryLabelsChange(categoryId, activeLabelIds)
  };

  useEffect(() => {
    updateChangeYearLinks(key);
  });

  return (
    <div className={classnames(styles.CategoriesCharts)}>
      <Tabs
        activeKey={key}
        id="operations-categories-charts"
        onSelect={handleChange}
      >
        {
          categories.map((category) => {
            const { category_id, label } = category;
            const active = category_id === key;
            const activeLabels = activeCategoriesLabels[category_id] || {};
            const filteredLabels = activeCategoryLabelsFilters[category_id] || {};

            return (
              <Tab
                eventKey={category_id}
                title={label}
                key={category_id}
                style={{ borderBottom: 0 }}
              >
                <CategoryCharts
                  category={category}
                  operations={operations}
                  logic={logic}
                  active={active}
                  activeLabels={activeLabels}
                  filteredLabels={filteredLabels}
                  onChange={(ids) => { handleFilteredCategoryLabelsChange(category_id, ids) }}
                />
              </Tab>
            );
          })
        }
      </Tabs>
    </div>
  );
};

CategoriesCharts.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  operations: PropTypes.arrayOf(PropTypes.object).isRequired,
  logic: PropTypes.string,
  activeCategoriesLabels: PropTypes.object,
  onCategoryLabelsChange: PropTypes.func,
};

CategoriesCharts.defaultProps = {
  logic: 'positive',
  activeCategoriesLabels: {},
  onCategoryLabelsChange: () => {},
};

export default CategoriesCharts;
