import React from 'react';
import PropTypes from 'prop-types';
import Inputmask from 'inputmask';
import BigDecimal from 'js-big-decimal';
import classnames from 'classnames';
import { InputNumberFormat } from '@react-input/number-format';

import InlineInput from './InlineInput';
import Checkbox from './Checkbox';
import { formatNumber } from '../../utils/money';

const parseNumber = (value) => {
  if (typeof value === 'number') {
    return value;
  }

  const sanitizedValue = value.toString().replace(/[^\d^,]/g, '').replace(',', '.');
  const parsedValue = Number(sanitizedValue);

  return parsedValue;
};

const estimateGross = (net, vat) => {
  const castedNet = new BigDecimal(parseNumber(net));
  const castedVat = new BigDecimal(parseNumber(vat));

  // if (vat === 0) { return Number(castedNet.getvalue()); }

  const gross = castedNet.multiply(
    castedVat.divide(
      new BigDecimal(100),
    ).add(new BigDecimal(1)),
  );

  return Number(gross.round(2).getValue());
};

const DefaultLayout = ({ children, title }) => (
  <div className="card bg-light">
    <div className="card-body">
      <h5 className="card-title">{title}</h5>

      <hr />

      {children}
    </div>
  </div>
);

const NoneLayout = ({ children }) => (
  <div>
    {children}
  </div>
);

class PriceInput extends React.Component {
  constructor(props) {
    super(props);

    this.netRef = React.createRef();
    this.vatRef = React.createRef();
    this.grossRef = React.createRef();
    this.withholdingTaxRef = React.createRef();
    this.otherWithholdingTaxRef = React.createRef();
    this.totalToBePaidRef = React.createRef();

    const {
      net,
      vat,
      gross,
    } = props;

    const withholdingTax = props.withholdingTax || 0;
    const otherWithholdingTax = props.otherWithholdingTax || 0;
    const showAdvancedFields = (withholdingTax + otherWithholdingTax) > 0;

    this.state = {
      net: net || 0,
      vat: vat || 0,
      gross: gross || 0,
      withholdingTax,
      otherWithholdingTax,
      showAdvancedFields,
    };
  }

  handleInputsChange(newState = {}) {
    const {
      onChange,
      netOptions,
      vatOptions,
      grossOptions,
      withholdingTaxOptions,
      otherWithholdingTaxOptions,
    } = this.props;
    const {
      net,
      vat,
      gross,
      withholdingTax,
      otherWithholdingTax,
    } = this.state;

    const prices = {
      net,
      vat,
      gross,
      withholdingTax,
      otherWithholdingTax,
      ...newState,
    };

    onChange({
      ...prices,
      [netOptions.name || 'net']: prices.net,
      [vatOptions.name || 'vat']: prices.vat,
      [grossOptions.name || 'gross']: prices.gross,
      [withholdingTaxOptions.name || 'withholding_tax']: prices.withholdingTax,
      [otherWithholdingTaxOptions.name || 'other_withholding_tax']: prices.otherWithholdingTax,
    });
  }

  handleNetChange(event) {
    const netValue = parseNumber(event.target.value || 0);
    const newState = { net: netValue };
    const { grossOptions, onChange } = this.props;
    const { vat } = this.state;

    if (grossOptions.auto) {
      newState.gross = estimateGross(netValue, vat);
    }

    this.setState(newState);
    this.handleInputsChange(newState);
  }

  handleVatChange(event) {
    const vatValue = Number(event.target.value || 0);
    const newState = { vat: vatValue };
    const { grossOptions } = this.props;

    if (grossOptions.auto) {
      newState.gross = estimateGross(this.netValue(), vatValue);
    }

    this.setState(newState);
    this.handleInputsChange(newState);
  }

  handleWithholdingTaxChange(event) {
    const rawValue = event.target.value || 0;
    const value = parseNumber(rawValue);
    const newState = { withholdingTax: value };

    this.setState(newState);
    this.handleInputsChange(newState);
  }

  handleOtherWithholdingTaxChange(event) {
    const value = parseNumber(event.target.value || 0);
    const newState = { otherWithholdingTax: value };

    this.setState(newState);
    this.handleInputsChange(newState);
  }

  handleGrossChange(event) {
    const newState = { gross: parseNumber(event.target.value || 0) };

    this.setState(newState);
    this.handleInputsChange(newState);
  }

  netValue() {
    return parseNumber(this.state.net);
  }

  vatValue() {
    return parseNumber(this.state.vat) / 100.0;
  }

  grossValue() {
    return parseNumber(this.state.gross);
  }

  hasValidValues() {
    const { net, gross, vat } = this.state;
    const expectedGross = estimateGross(net, vat);

    return expectedGross === gross;
  }

  inputsClassNames({ size = 'xl' }) {
    return `form-control form-control-${size} ${this.hasValidValues() ? 'is-valid' : 'is-invalid'}`;
  }

  setShowAdvancedFields(value) {
    this.setState({ showAdvancedFields: value });
  }

  render() {
    const {
      net,
      vat,
      gross,
      withholdingTax,
      otherWithholdingTax,
      showAdvancedFields,
    } = this.state;

    const totalToBePaid = gross - withholdingTax - otherWithholdingTax;

    const {
      netOptions,
      vatOptions,
      grossOptions,
      withholdingTaxOptions,
      otherWithholdingTaxOptions,
      totalToBePaidOptions,
      title,
      showWithholdingFields,
      layout,
    } = this.props;

    const classNameXL = this.inputsClassNames({ size: 'xl' });
    const classNameMD = this.inputsClassNames({ size: 'md' });
    const Layout = layout ? DefaultLayout : NoneLayout;
    const initialNet = formatNumber(this.props.net || 0, { style: 'default' });
    const initialWithholdingTax = formatNumber(this.props.withholdingTax || 0, { style: 'default' });
    const initialOtherWithholdingTax = formatNumber(this.props.otherWithholdingTax || 0, { style: 'default' });

    return (
      <Layout title={title}>
        <div className={classnames('form-row price-input form-group mb-2')}>
          <InlineInput
            column={netOptions.column || 'col'}
            label={netOptions.label || 'Net amount'}
            placeholder={netOptions.label || 'Net amount'}
            ref={this.netRef}
            className={classnames(classNameXL, netOptions.className)}
            readOnly={!!netOptions.readOnly}
            name={netOptions.name || 'net'}
            onChange={(e) => this.handleNetChange(e)}
            components={{ Input: InputNumberFormat }}
            format="currency"
            currency="EUR"
            defaultValue={initialNet}
            currencyDisplay="symbol"
            locales="it-IT"
          />

          <InlineInput
            label="VAT %"
            column={vatOptions.column || 'col-md-2'}
            value={vat}
            placeholder="VAT %"
            ref={this.vatRef}
            className={classnames(classNameXL, vatOptions.className)}
            readOnly={!!vatOptions.readOnly}
            name={vatOptions.name || 'vat'}
            onChange={(e) => this.handleVatChange(e)}
          />

          <InlineInput
            column={grossOptions.column || 'col'}
            label="Gross amount"
            value={formatNumber(gross, { style: 'default' })}
            ref={this.grossRef}
            placeholder="Gross amount"
            className={classnames(classNameXL, 'price-input__gross', grossOptions.className)}
            readOnly={!!grossOptions.readOnly}
            name={grossOptions.name || 'gross'}
            onChange={(e) => this.handleGrossChange(e)}
          />
        </div>

        {
          showWithholdingFields && (
            <>
              <div className={classnames('form-row price-input form-group mb-2', { 'd-none': !showAdvancedFields })}>
                <InlineInput
                  size="md"
                  label="Withholdig tax (Rit. d'acconto)"
                  placeholder=""
                  ref={this.withholdingTaxRef}
                  className={classnames(classNameMD, withholdingTaxOptions.className)}
                  readOnly={!!withholdingTaxOptions.readOnly}
                  name={withholdingTaxOptions.name || 'withholding_tax'}
                  onChange={(e) => this.handleWithholdingTaxChange(e)}
                  components={{ Input: InputNumberFormat }}
                  format="currency"
                  currency="EUR"
                  defaultValue={initialWithholdingTax}
                  currencyDisplay="symbol"
                  locales="it-IT"
                />

                <InlineInput
                  size="md"
                  label="Other withholding tax (Altra rit.)"
                  placeholder=""
                  ref={this.otherWithholdingTaxRef}
                  className={classnames(classNameMD, otherWithholdingTaxOptions.className)}
                  readOnly={!!otherWithholdingTaxOptions.readOnly}
                  name={otherWithholdingTaxOptions.name || 'other_withholding_tax'}
                  onChange={(e) => this.handleOtherWithholdingTaxChange(e)}
                  components={{ Input: InputNumberFormat }}
                  format="currency"
                  currency="EUR"
                  defaultValue={initialOtherWithholdingTax}
                  currencyDisplay="symbol"
                  locales="it-IT"
                />

                <InlineInput
                  column="col-md-5"
                  size="md"
                  label="Total to be paid"
                  value={formatNumber(totalToBePaid, { style: 'default' })}
                  ref={this.totalToBePaidRef}
                  placeholder=""
                  className={classnames(classNameMD, 'price-input__gross', totalToBePaidOptions.className)}
                  readOnly={totalToBePaidOptions.readOnly}
                  name={totalToBePaidOptions.name}
                />
              </div>

              <Checkbox
                label="Show advanced fields"
                onChange={(value) => { this.setShowAdvancedFields(value); }}
                checked={showAdvancedFields}
                name="advanced"
              />
            </>
          )
        }
      </Layout>
    );
  }
}

const inputOptionsShape = PropTypes.shape({
  readOnly: PropTypes.bool,
  auto: PropTypes.bool,
});

PriceInput.propTypes = {
  title: PropTypes.string,
  net: PropTypes.number,
  vat: PropTypes.number,
  gross: PropTypes.number,
  netOptions: inputOptionsShape,
  vatOptions: inputOptionsShape,
  grossOptions: inputOptionsShape,
  layout: PropTypes.bool,
  showWithholdingFields: PropTypes.bool,
  withholdingTaxOptions: inputOptionsShape,
  otherWithholdingTaxOptions: inputOptionsShape,
  totalToBePaidOptions: inputOptionsShape,
  onChange: PropTypes.func,
};

PriceInput.defaultProps = {
  title: 'Amount',
  net: 0,
  vat: 22,
  gross: 0,
  netOptions: {},
  vatOptions: {},
  grossOptions: { readOnly: true },
  withholdingTaxOptions: {},
  otherWithholdingTaxOptions: {},
  totalToBePaidOptions: { readOnly: true },
  showWithholdingFields: true,
  layout: true,
  onChange: () => {},
};

export default PriceInput;
