import React from 'react';
import PropTypes from 'prop-types';

import ConfirmModal from '../ConfirmModal';
import { deleteTrainer } from '../../api/trainers/trainers';
import handleError from '../../utils/error-handler';
import { success } from '../../utils/notifier';

const DeleteModal = ({
  trainer, show, onHide, onDelete, displaySuccessMessage,
}) => {
  const { id, full_name } = trainer;
  const deleteMessage = (
    <h5 className="mb-3">
      Do you really want to remove the trainer profile
      &nbsp;
      <strong>
        &quot;
        {full_name}
        &quot;
      </strong>
      ?
    </h5>
  );
  const handleDelete = () => (
    deleteTrainer({ trainerId: id })
      .then((response) => response.json())
      .then((response) => {
        if (displaySuccessMessage) {
          success({ message: response.message });
        }
        onDelete({ id, response });
      })
      .catch(handleError)
  );

  return (
    <ConfirmModal
      variant="danger"
      title="Remove the selected trainer profile"
      description={deleteMessage}
      show={show}
      onHide={onHide}
      onConfirm={handleDelete}
    />
  );
};

DeleteModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onDelete: PropTypes.func,
  displaySuccessMessage: PropTypes.bool,
  trainer: PropTypes.shape({
    id: PropTypes.string,
    full_name: PropTypes.string,
  }).isRequired,
};

DeleteModal.defaultProps = {
  show: false,
  onHide: () => {},
  onDelete: () => {},
  displaySuccessMessage: true,
};

export default DeleteModal;
