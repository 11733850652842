import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Icon from './Icon';

const Formatter = ({ value, decimals = 0 }) => {
  const humanValue = value >= 1000
    ? (parseFloat(value) / 1000.0).toFixed(decimals)
    : value;
  const suffix = value >= 1000
    ? <small className="opacity-50 smaller">k</small>
    : null;

  return (
    <span className="text-nowrap" title={value}>
      {humanValue}
      {suffix}
    </span>
  );
};

const DashboardCompareCounterWidget = (({
  wip,
  icon,
  currCounter,
  currYear,
  prevCounter,
  prevPartialCounter,
  prevYear,
  link,
  label,
  shortLabel,
  description,
  hint,
  theme,
}) => {
  const wipClass = wip === true ? 'wip' : '';
  const percentage = prevPartialCounter !== 0
    ? ((100.0 / prevPartialCounter) * currCounter) - 100.0
    : (prevPartialCounter === currCounter ? 0.0 : 100.0);
  const absPercentage = Math.round(Math.abs(percentage));
  const diff = currCounter - prevPartialCounter;
  const isPositive = currCounter > prevPartialCounter;
  const isNegative = currCounter < prevPartialCounter;
  const sLabel = shortLabel || label;
  const themedTextClassname = !theme
    ? { 'text-success': isPositive, 'text-danger': isNegative }
    : `text-${theme}`;

  return (
    <div className={`${wipClass} card-condensed`}>
      {
        hint && (
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip>
                {hint}
              </Tooltip>
            )}
          >
            <div className="card-hint">
              <Icon name="info" fw />
            </div>
          </OverlayTrigger>
        )
      }
      <a href={link} className="card-header">
        <Icon name={icon} className="card-icon" size="5x" />

        <div className="pr-2">
          <div className="text-right font-weight-bold text-value-big">
            <span className={classnames(themedTextClassname)}>
              { isPositive && (
                <Icon name="plus" size="xs" fw />
              )}
              { isNegative && (
                <Icon name="minus" size="xs" fw />
              )}
              {absPercentage}
              %
            </span>
          </div>
          <div className="text-muted text-right text-uppercase font-weight-bold small">
            {label}
          </div>
          <div className="text-muted text-right small">
            &nbsp;
            {
              description || (
                <span>
                  <strong className={classnames(themedTextClassname)}>
                    {isPositive ? '+' : ''}
                    {diff.toFixed(0)}
                  </strong>
                  &nbsp;
                  {`${sLabel} compared to`}
                  &nbsp;
                  <strong>{prevYear}</strong>
                </span>
              )
            }
          </div>
        </div>
      </a>

      <div className="card-body">
        <div className="card-content pt-2">
          <div className="row">
            <div className="col">
              <div className="d-flex justify-content-center">
                <div className="font-weight-bold h1 mb-0">
                  <Formatter value={prevPartialCounter} />
                  {
                    prevCounter !== prevPartialCounter && (
                      <small className="smaller" title={`${prevCounter} total ${sLabel} on ${prevYear}`}>
                        /
                        <Formatter value={prevCounter} />
                      </small>
                    )
                  }
                </div>
                <div className="pl-1 py-1 text-muted text-uppercase font-weight-bold small text-left text-nowrap">
                  {prevYear}
                  <br />
                  <small className="text-muted text-uppercase">{sLabel}</small>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="d-flex justify-content-center">
                <div
                  className={classnames(
                    'font-weight-bold h1 mb-0',
                    themedTextClassname,
                  )}
                >
                  <Formatter value={currCounter} />
                </div>
                <div className="pl-1 py-1 text-muted text-uppercase font-weight-bold small text-left text-nowrap">
                  {currYear}
                  <br />
                  <small className="text-muted text-uppercase">{sLabel}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

DashboardCompareCounterWidget.propTypes = {
  label: PropTypes.string,
  shortLabel: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  currCounter: PropTypes.number,
  currYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  prevCounter: PropTypes.number,
  prevPartialCounter: PropTypes.number,
  prevYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  hint: PropTypes.string,
  theme: PropTypes.string,
  wip: PropTypes.bool,
};

DashboardCompareCounterWidget.defaultProps = {
  label: 'Counter Widget',
  shortLabel: null,
  description: null,
  link: '/',
  currCounter: 0,
  currYear: null,
  prevCounter: 0,
  prevPartialCounter: 0,
  prevYear: null,
  icon: 'info',
  wip: false,
  hint: null,
  theme: null,
};

export default DashboardCompareCounterWidget;
