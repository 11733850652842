import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import EventsDefaultPriorityUpdateModal from './EventsDefaultPriorityUpdateModal';
import { calendarShape } from './utils';
import { visit } from '../../../utils/router';
import Icon from '../../Icon';
import { success } from '../../../utils/notifier';
import { showPath } from '../../../routes/smart_planner/accounts';

const EventsDefaultPriorityInplaceInput = ({ calendar, className }) => {
  const { account_id, events_default_priority } = calendar;
  const [showModal, setShowModal] = useState(false);
  const hideModal = () => { setShowModal(false); };
  const handleEventsDefaultPriorityUpdate = ({ response }) => {
    visit(showPath(account_id)).then(() => { success({ message: response.message }); });
  };
  const handleClick = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <div className={classnames('EventsDefaultPriorityInplaceInput', className)}>
      <EventsDefaultPriorityUpdateModal
        show={showModal}
        calendar={calendar}
        onHide={hideModal}
        onUpdate={handleEventsDefaultPriorityUpdate}
      />

      <a href="#events-default-priority" onClick={handleClick}>
        <span className="editable">{events_default_priority || 'N/A'}</span>
        &nbsp;
        <Icon name="pencil-alt" fw />
      </a>
    </div>
  );
};

EventsDefaultPriorityInplaceInput.propTypes = {
  calendar: calendarShape.isRequired,
};

EventsDefaultPriorityInplaceInput.defaultProps = {};

export default EventsDefaultPriorityInplaceInput;

