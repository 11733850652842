import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import Localize from './Localize';
import Icon from '../../Icon';

import { wtToLabel } from './utils';

const minToFloat = (min) => ((1.0 / 60.0) * min);

const hours = (from, to) => {
  const hFloat = parseFloat(to.hour) - parseFloat(from.hour);
  const m = to.minute - from.minute;
  const mFloat = minToFloat(m);

  return hFloat + mFloat;
};

const WorkingTimeSlots = ({ dayName, slots }) => {
  if (slots.length === 0) { return null; }

  return (
    <div className="WorkingTimeSlots">
      <table className="table table-sm mb-0">
        <thead>
          <tr>
            <th colSpan="4">
              <Localize label={`dayNames.${dayName}`} />
            </th>
          </tr>
        </thead>

        <tbody>
          {
            slots.map(({ from, to }) => (
              <tr key={wtToLabel(from)} className="row">
                <td className="align-middle col">{wtToLabel(from)}</td>
                <td className="align-middle col">{wtToLabel(to)}</td>
                <td className="align-middle col">
                  <em>
                    {hours(from, to)}
                    h
                  </em>
                </td>
                <td className="text-center col">
                  <Button
                    size="sm"
                    variant="light"
                    onClick={() => { alert('WIP: Open a modal with constraints rules'); }}
                  >
                    <Icon name="wrench" fw />
                  </Button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
};

WorkingTimeSlots.defaultProps = {
  slots: [],
};

WorkingTimeSlots.propTypes = {
  dayName: PropTypes.string.isRequired,
  slots: PropTypes.arrayOf(PropTypes.shape({})),
};

export default WorkingTimeSlots;
