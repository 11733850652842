import { patch, post } from '../base';

export const updateCertification = ({ certificationId, attributes }) => (
  patch(`/trainings/certifications/${certificationId}.json`, {
    certification: attributes,
  })
);

export const generateCertificates = ({ certificationId }) => (
  post(`/trainings/certifications/${certificationId}/generate_certificates.json`)
);

export const addCertifiedAttendees = ({ certificationId, certifiedAttendees }) => (
  post(`/trainings/certifications/${certificationId}/certified_attendees.json`, {
    certification: { certification_attendees: certifiedAttendees },
  })
);

export default {
  updateCertification,
  generateCertificates,
  addCertifiedAttendees,
};
