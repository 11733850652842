import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import _ from 'lodash';

import { colorizedLabel } from '../../../utils/money';
import InlineCategorization from './InlineCategorization';
import EditModal from '../../OperationsPage/EditModal';
import TrackParentModal from '../../OperationsPage/TrackParentModal';
import Icon from '../../Icon';
import Row from '../../ListingTable/Row';

const extractStateFromProps = (props) => {
  const {
    id,
    state,
    operation_headline,
    counterpart,
    amount,
    operation_date,
    operation_notes,
    operation_reference,
    operation_country,
    operation_document_url,
    operation_type,
    credit_note_id,
    human_operation_type,
    category_labels,
    categories,
    typology,
    creditNotes,
    items,
    item,
  } = props;

  return {
    id,
    state,
    operationHeadline: operation_headline,
    counterpart,
    amount,
    categoryLabels: category_labels,
    categories,
    typology,
    operationType: operation_type,
    humanOperationType: human_operation_type,
    operationDate: operation_date,
    operationReference: operation_reference,
    operationDocumentUrl: operation_document_url,
    operationNotes: operation_notes,
    operationCountry: operation_country,
    credit_note_id,
    creditNotes,
    items,
    item: { ...item },
  };
};

// NOTE: this could be wrong
const generateUrl = (id = null, action = null) => {
  const baseUrl = '/billing/issued_invoices';
  const urlChunks = [baseUrl];

  if (id) { urlChunks.push(id); }
  if (action) { urlChunks.push(action); }

  return urlChunks.join('/');
};

const LinkedOperation = ({ operation, prefix = 'Operation' }) => {
  if (!operation) { return ''; }

  return (
    <small className="d-block mt-1">
      <strong>
        <Icon name="file-invoice" />
        &nbsp;
        {operation.label}
      </strong>
    </small>
  );
};

const DownloadButton = ({ url = null }) => {
  if (!url) {
    return (
      <Button rel="noopener noreferrer" target="_blank" className="btn btn-sm btn-light disabled">
        <Icon name="download" fw />
      </Button>
    );
  }

  return (
    <Button
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      className="btn btn-sm btn-light"
    >
      <Icon name="download" fw />
    </Button>
  );
};

const EditButton = ({
  onClick, title = '', icon = 'info', theme = 'info',
}) => (
  <Button
    rel="noopener noreferrer"
    className={`btn btn-sm btn-${theme}`}
    onClick={onClick}
    title={title}
  >
    <Icon name={icon} fw />
  </Button>
);

const TrackParentButton = ({ onClick, operationType }) => {
  const untrackableOperations = ['credit_note', 'expense_credit_note'];

  if (_.includes(untrackableOperations, operationType)) {
    return '';
  }

  return (
    <EditButton
      theme="warning"
      icon="external-link-alt"
      title="Track parent operation"
      onClick={onClick}
    />
  );
};

class OperationRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...extractStateFromProps(props),
      showEditModal: false,
      showTrackParentModal: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(extractStateFromProps(nextProps));
  }

  handleEditClick() {
    this.showEditModal(true);
  }

  handleTrackParentClick() {
    this.showTrackParentModal(true);
  }

  handleConversion({ operationId }) {
    const { onDelete } = this.props;
    onDelete({ operationId });
    this.showEditModal(false);
  }

  handleParentModalChange({ parentOperationId }) {
    const { onUpdate } = this.props;
    const { item } = this.state;

    this.showTrackParentModal(false);

    onUpdate({
      operation: {
        ...item,
        credit_note_id: parentOperationId,
      },
    });
  }

  findCategoryLabels(category) {
    if (!category) { return false; }

    const { categoryLabels } = this.state;

    return categoryLabels.filter((label) => (
      label.category_id === category.category_id
    ));
  }

  showEditModal(value) {
    this.setState({ showEditModal: value });
  }

  showTrackParentModal(value) {
    this.setState({ showTrackParentModal: value });
  }

  findParentOperation() {
    const { item, items } = this.state;
    const { parent_operation_id } = item;

    return items.find(({ operation_id }) => (operation_id === parent_operation_id));
  }

  findCreditNote() {
    const { items, credit_note_id } = this.state;

    return items.find(({ operation_id }) => (operation_id === credit_note_id));
  }

  renderOperationType() {
    const { humanOperationType } = this.state;

    return (
      <span className={classnames('badge', 'badge-light')}>
        {humanOperationType}
      </span>
    );
  }

  renderCategoryColumns() {
    const { id, categories, operationHeadline } = this.state;
    const { onCategoryLabelChange, onCategoryLabelChanged } = this.props;

    return categories.map((category) => {
      const labels = this.findCategoryLabels(category);

      return (
        <td className="h5" key={category.category_id}>
          <InlineCategorization
            category={category}
            operationId={id}
            operationHeadline={operationHeadline}
            values={labels}
            onChanging={onCategoryLabelChange}
            onChange={onCategoryLabelChanged}
          />
        </td>
      );
    });
  }

  renderTrackParentModal() {
    const {
      showTrackParentModal,
      creditNotes,
      item,
    } = this.state;

    return (
      <TrackParentModal
        show={showTrackParentModal}
        onHide={() => { this.showTrackParentModal(false); }}
        onChange={(args) => { this.handleParentModalChange(args); }}
        creditNotes={creditNotes}
        operation={item}
      />
    );
  }

  render() {
    const {
      id,
      operationHeadline,
      counterpart,
      amount,
      operationDate,
      operationReference,
      operationNotes,
      operationCountry,
      operationDocumentUrl,
      showEditModal,
      typology,
      operationType,
      humanOperationType,
      item,
    } = this.state;

    // const parentOperation = this.findParentOperation();
    const relatedCreditNote = this.findCreditNote();

    return (
      <Row {...this.props}>
        <td className="h5" scope="row">
          N°
          <strong>{operationReference || '?'}</strong>
          &nbsp;of&nbsp;
          {operationDate}
          <br />
          <small className="pt-2 d-block">
            { operationHeadline }
          </small>

          <LinkedOperation operation={relatedCreditNote} />
        </td>

        <td className="h5 text-right">
          <strong>{colorizedLabel(amount)}</strong>
          <br />
          {this.renderOperationType()}
        </td>

        <td className="h5">
          <a href={generateUrl(id)}>
            {counterpart}
          </a>
        </td>

        { this.renderCategoryColumns() }

        <td className="h5 text-center">
          {this.renderTrackParentModal()}

          <EditModal
            show={showEditModal}
            onHide={() => { this.showEditModal(false); }}
            onConversion={(args) => { this.handleConversion(args); }}
            operationId={id}
            operationReference={operationReference}
            operationHeadline={operationHeadline}
            amount={amount}
            operationDate={operationDate}
            operationCountry={operationCountry}
            operationNotes={operationNotes}
            typology={typology}
            humanOperationType={humanOperationType}
            counterpart={counterpart}
            operation={item}
          />

          <div className="btn-group">
            <TrackParentButton
              onClick={() => { this.handleTrackParentClick(); }}
              operationType={operationType}
            />

            <EditButton
              onClick={() => { this.handleEditClick(); }}
            />

            <DownloadButton
              url={operationDocumentUrl}
            />
          </div>
        </td>
      </Row>
    );
  }
}

OperationRow.propTypes = {
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onCategoryLabelChange: PropTypes.func,
  onCategoryLabelChanged: PropTypes.func,
  operationReference: PropTypes.string,
};

OperationRow.defaultProps = {
  onUpdate: () => {},
  onDelete: () => {},
  onCategoryLabelChange: () => {},
  onCategoryLabelChanged: () => {},
  operationReference: 'N/A',
};

export default OperationRow;
