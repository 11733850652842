import React from 'react';
import classnames from 'classnames';

import styles from './WeekViewCalendar.module.scss';

const WeekDetails = ({ weekNumber, selected, onClick }) => {
  const elementClassNames = classnames(
    'WeekDetails',
    styles.colLg,
    styles.cell,
    styles.dayCell,
    {
      [styles.selected]: selected,
    },
  );
  const handleClick = () => {
    onClick(weekNumber);
  };

  return (
    <div
      className={elementClassNames}
      onKeyDown={() => { handleClick(); }}
      onClick={() => { handleClick(); }}
      role="treegrid"
      tabIndex={0}
    >
      <span className={classnames(styles.shortCellNumber)}>
        WEEK
        &nbsp;
        {weekNumber}
      </span>
    </div>
  );
};

export default WeekDetails;
