import {
  get,
  post,
  patch,
  httpDelete,
} from '../base';

const labelsBasePath = () => ('/smart_planner/labels');
const userLabelsBasePath = () => ('/smart_planner/user_labels');
const systemLabelsBasePath = () => ('/smart_planner/system_labels');

export const fetchLabels = () => (
  get(`${labelsBasePath()}.json`)
);

export const fetchUserLabels = () => (
  get(`${userLabelsBasePath()}.json`)
);

export const fetchSystemLabels = () => (
  get(`${systemLabelsBasePath()}.json`)
);

// export const deleteLabel = (labelId) => (
//   httpDelete(`${basePath()}/${labelId}.json`)
// );

export const createUserLabel = ({
  name,
  description,
  color,
}) => (
  post(`${userLabelsBasePath()}.json`, {
    labels: {
      name,
      color,
      description,
    },
  })
);

export const createSystemLabel = ({
  name,
  description,
  color,
}) => (
  post(`${systemLabelsBasePath()}.json`, {
    labels: {
      name,
      color,
      description,
    },
  })
);

export const updateLabel = (labelId, {
  name,
  description,
  color,
}) => (
  patch(`${labelsBasePath()}/${labelId}.json`, {
    labels: {
      name,
      color,
      description,
    },
  })
);

export const deleteLabel = (labelId) => (
  httpDelete(`${labelsBasePath()}/${labelId}.json`)
);

export default {
  fetchUserLabels,
  createUserLabel,
  fetchSystemLabels,
  createSystemLabel,
  fetchLabels,
  updateLabel,
  deleteLabel,
};
