export const visit = (path) => (new Promise((resolve) => {
  const handlePageLoad = (event) => { resolve(event); };
  document.addEventListener('turbolinks:load', handlePageLoad, { once: true });

  Turbolinks.visit(path);
}));

export const reload = () => (visit(global.location.href));

export default {
  visit,
  reload,
};
