import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { DateTime } from 'luxon';

import ExpectationForm from '../ExpectationForm';
import Icon from '../../Icon';
import { serializeFloat } from '../../../utils/money';
// import handleError from '../../../utils/error-handler';
// import { success } from '../../../utils/notifier';
// import { cancelPlannedEvent } from '../../../api/trainings/planned_events';
// import { eventShape } from '../utils';

const formDomId = 'expectation-form-id';

const AddExpectationModal = ({
  show,
  view,
  onHide,
  countries,
  currencies,
  accounts,
  onExpectationSave,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleExpectationSave = (params) => {
    if (isLoading) { return; }
    setIsLoading(true);

    const {
      expected_on_date,
      expected_net_amount,
      expected_amount_vat,
      expected_amount,
      expected_on_account_id,
      withholding_tax,
      other_withholding_tax,
      currency,
      country,
    } = params;

    const direction = view === 'invoices' ? 'in' : 'out';
    const signAdjustedAmount = view === 'invoices'
      ? expected_amount
      : expected_amount * -1;

    // Adapt the expectation value to a serialized one from UI
    onExpectationSave({
      ...params,
      expected_on_date: expected_on_date.toISOString().split('T')[0],
      expected_net_amount: serializeFloat(expected_net_amount),
      expected_amount_vat,
      expected_amount: serializeFloat(expected_amount),
      withholding_tax: serializeFloat(withholding_tax),
      other_withholding_tax: serializeFloat(other_withholding_tax),
      direction,
      sign_adjusted_amount: serializeFloat(signAdjustedAmount),
      currency: currency.value,
      country: country.value,
      expected_on_account_id: expected_on_account_id.value,
    });

    onHide();
    setIsLoading(false);
  };

  const variant = view === 'invoices' ? 'success' : 'danger';
  const defaultValues = {};

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong className={`text-${variant}`}>
            Add compensating expectation for {view}
          </strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ExpectationForm
          formId={formDomId}
          countries={countries}
          currencies={currencies}
          accounts={accounts}
          defaultValues={defaultValues}
          onSave={handleExpectationSave}
        />
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button
          variant={variant}
          disabled={isLoading}
          type="submit"
          form={formDomId}
        >
          <Icon name="check" fw />
          &nbsp;
          { isLoading ? 'Loading...' : 'Add expectation' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddExpectationModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onExpectationSave: PropTypes.func,
};

AddExpectationModal.defaultProps = {
  show: false,
  onExpectationSave: () => {},
};

export default AddExpectationModal;

