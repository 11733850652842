import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';

import Money from '../../../utils/money';
import Icon from '../../Icon';
import ConfirmModalButton from '../../ConfirmModalButton';

import RowWrapper from '../../ListingTable/Row';
import PriceValue from '../../expectations/PriceValue';
import ColorizedDeadline from '../../ColorizedDeadline';

const Row = (props) => {
  const { 
    item, 
    currentIds, 
    hiddenIds, 
    showDiscardButton,
    showCostCenter,
    onExpectationDelete,
  } = props;
  const theme =            props.theme || 'primary';
  const id =               item.id;
  const headline =         item.headline;
  const currency =         item.currency;
  const counterpart =      item.counterpart;
  const expected_amount =  item.expected_amount;
  const adjusted_amount =  item.sign_adjusted_amount;
  const expected_on_date = item.expected_on_date;
  const expectation_type = item.expectation_type;
  const state =            item.state;
  const isSelected = currentIds.includes(id);

  const handleDiscardClick = () => {
    onExpectationDelete(item)
  };

  return (
    <RowWrapper
      {...props}
      className={classnames({
        [`table-${theme}`]: isSelected,
        'd-none': hiddenIds.includes(id),
      })}
      style={{ cursor: 'pointer' }}
    >
      <td className="text-right col-lg-3">
        <PriceValue expectation={item} />

        {
          state === 'FULFILLED' && (
            <span className="badge badge-success d-block">PAID</span>
          )
        }
      </td>

      <td className="col-lg-6">
        <div className="text-truncate-disabled" title={headline}>
          <strong>{headline}</strong>
          <br />
          <small>{counterpart}</small>
        </div>
      </td>

      <td className="col-lg-3 text-center">
        <h5>
          <ColorizedDeadline deadline={expected_on_date} />
        </h5>

        {
          showDiscardButton && (
            <ConfirmModalButton
              onConfirm={handleDiscardClick}
              buttonCaption={<span><Icon name="trash" fw /> Discard</span>}
              variant="danger"
              title={`Discard expected document`}
              description={(
                <h5>
                  Are you sure you want to discard the expected document:
                  &nbsp;
                  "<strong>{headline}</strong>"
                  ?
                </h5>
              )}
              size="sm"
              buttonProps={{ 
                variant: 'outline-danger',
                className: 'w-100 text-nowrap small py-0'
              }}
            />
          )
        }
      </td>
    </RowWrapper>
  );
};

Row.propTypes = {
  showDiscardButton: PropTypes.bool,
  showCostCenter: PropTypes.bool,
}

Row.defaultProps = {
  showDiscardButton: false,
  showCostCenter: false,
}

export default Row;

