import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import Icon from '../../Icon';
import ConfirmModal from './ConfirmModal';
import { success } from '../../../utils/notifier';
import { visit } from '../../../utils/router';
import { eventShape } from '../utils';

const ConfirmButton = ({ event, size, className }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const hideConfirmModal = () => { setShowConfirmModal(false); };
  const { acl, details_path, human_training_typology } = event;
  const { canConfirm } = acl;

  if (!canConfirm) { return ''; }

  const handleConfirmButtonClick = () => {
    setShowConfirmModal(true);
  };

  const handleConfirmConfirmation = ({ response }) => {
    visit(details_path).then(() => { success({ message: response.message }); });
  };

  return (
    <div className="cancel-button d-inline-block">
      <ConfirmModal
        show={showConfirmModal}
        event={event}
        onHide={hideConfirmModal}
        onConfirm={handleConfirmConfirmation}
      />

      <Button
        size={size}
        variant="success"
        title={`Confirm ${human_training_typology}`}
        onClick={handleConfirmButtonClick}
        className={className}
      >
        <Icon name="check" fw />
        &nbsp;
        Confirm
      </Button>
    </div>
  );
};

ConfirmButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  event: eventShape.isRequired,
};

ConfirmButton.defaultProps = {
  size: 'md',
  className: '',
};

export default ConfirmButton;
