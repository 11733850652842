import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import { Tabs, Tab } from 'react-bootstrap';

import Context from './Context';
import ReconciliationForm from './ReconciliationForm';
import History from './History';
import Icon from '../../Icon';

const title = "Select the billing documents and expectations to be reconciled from the respective lists";

const TabTitle = ({ title, icon, iconFamily = 'fas' }) => (
  <span>
    <Icon name={icon} family={iconFamily} fw />
    &nbsp;
    {title}
  </span>
);

const unreconciledInvoicesUrl = '/financial_analysis/reconciliations/unreconciled_invoices.json';
const plannedInvoicesUrl = '/financial_analysis/reconciliations/planned_invoices.json';

const unreconciledExpensesUrl = '/financial_analysis/reconciliations/unreconciled_expenses.json';
const plannedExpensesUrl = '/financial_analysis/reconciliations/planned_expenses.json';

const Body = ({ className }) => {
  const {
    currentUserId,
    countries,
    currencies,
    accounts,
    isLoading,
    onUnreconciledExpectationClick,
    onPlannedExpectationClick,
    unreconciledExpectations,
    plannedExpectations,
    processedReconciliations,
    reconciliations,
    onReconcile,
    onSubmit,
    onReconciliationCancellation,
    onNewExpectation,
    newExpectations,
    onExpectationDelete,
  } = useContext(Context);

  const currUrl = location.href.toString()
  let urlParams = new URLSearchParams(currUrl.split('?')[1] || '');

  const initialTab = urlParams.get('view') || 'invoices';
  const [key, setKey] = useState(initialTab);
  const handleTabChange = (newKey) => {
    const newUrl = `${currUrl.split('?')[0]}?view=${newKey}`

    window.history.replaceState({}, '', newUrl);
    setKey(newKey);
  };

  return (
    <div className={className}>
      <Tabs
        activeKey={key}
        id="reconciliation-tabs"
        onSelect={handleTabChange}
      >
        <Tab
          eventKey="invoices"
          title={<TabTitle title="Invoices" icon="sign-in-alt" />}
        >
          <ReconciliationForm
            title={title}
            view="invoices"
            onReconciliationCancellation={onReconciliationCancellation}
            unreconciledExpectationsUrl={unreconciledInvoicesUrl}
            plannedExpectationsUrl={plannedInvoicesUrl}
            onUnreconciledExpectationClick={onUnreconciledExpectationClick}
            onPlannedExpectationClick={onPlannedExpectationClick}
            unreconciledExpectations={unreconciledExpectations}
            plannedExpectations={plannedExpectations}
            onReconcile={onReconcile}
            processedReconciliations={processedReconciliations}
            reconciliations={reconciliations}
            onSubmit={onSubmit}
            isLoading={isLoading}
            countries={countries}
            currencies={currencies}
            accounts={accounts}
            onNewExpectation={onNewExpectation}
            newExpectations={newExpectations}
            onExpectationDelete={onExpectationDelete}
          />
        </Tab>

        <Tab
          eventKey="expenses"
          title={<TabTitle title="Expenses" icon="sign-out-alt" />}
        >
          <ReconciliationForm
            title={title}
            view="expenses"
            onReconciliationCancellation={onReconciliationCancellation}
            unreconciledExpectationsUrl={unreconciledExpensesUrl}
            plannedExpectationsUrl={plannedExpensesUrl}
            onUnreconciledExpectationClick={onUnreconciledExpectationClick}
            onPlannedExpectationClick={onPlannedExpectationClick}
            unreconciledExpectations={unreconciledExpectations}
            plannedExpectations={plannedExpectations}
            processedReconciliations={processedReconciliations}
            reconciliations={reconciliations}
            onReconcile={onReconcile}
            onSubmit={onSubmit}
            isLoading={isLoading}
            countries={countries}
            currencies={currencies}
            accounts={accounts}
            onNewExpectation={onNewExpectation}
            newExpectations={newExpectations}
            onExpectationDelete={onExpectationDelete}
          />
        </Tab>

        <Tab
          eventKey="history"
          title={<TabTitle title="Past reconciliations" icon="clock" />}
        >
          <History />
        </Tab>
      </Tabs>
    </div>
  );
};

Body.propTypes = {};

export default Body;
