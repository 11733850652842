import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';

import Icon from './Icon';

import styles from './BlankState.module.scss';

const spinnerIcon = 'spinner';
const spinnerIconFamily = 'fas';

const BlankState = ({
  title,
  description,
  icon,
  iconFamily,
  className,
  buttonLabel,
  onClick,
  buttonProps,
  isLoading,
}) => (
  <div className={classnames('BlankState', styles.BlankState, className)}>
    <Icon
      name={isLoading ? spinnerIcon : icon}
      family={isLoading ? spinnerIconFamily : iconFamily}
      size="3x"
      spin={isLoading}
      fw
    />

    <h3 className="mt-4 bt-2">{title}</h3>

    <p className="mb-4">{description}</p>

    <Button
      onClick={onClick}
      size="lg"
      variant="primary"
      className={classnames({ disabled: isLoading })}
      disabled={isLoading}
      {...buttonProps}
    >
      {buttonLabel}
    </Button>
  </div>
);

BlankState.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
  iconFamily: PropTypes.string,
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  buttonProps: PropTypes.shape({}),
  isLoading: PropTypes.bool,
};

BlankState.defaultProps = {
  icon: 'edit',
  iconFamily: 'far',
  title: '',
  description: '',
  className: null,
  buttonLabel: 'Continue',
  onClick: () => {},
  buttonProps: {},
  isLoading: false,
};

export default BlankState;
