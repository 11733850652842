import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { DateTime } from 'luxon';

import Icon from '../../Icon';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';
import { confirmPlannedEvent } from '../../../api/trainings/planned_events';

const ConfirmModal = ({
  show,
  onHide,
  onConfirm,
  event,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState('');
  const handleNotesUpdate = (event) => {
    const input = event.currentTarget;
    const { value } = input;

    setNotes(value);
  };

  const plannedEventId = event.id;
  const handleConfirm = () => {
    if (isLoading) { return; }
    setIsLoading(true);

    confirmPlannedEvent({ plannedEventId, notes })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        success({ message: data.message });
        onHide();
        onConfirm({ plannedEventId, response: data });
      })
      .catch(handleError);
  };

  const { headline, starts_on } = event;
  const startsOn = DateTime.fromISO(starts_on);
  const humanDate = startsOn.toFormat('dd LLL yyyy');

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Confirm public training event</strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h4 className="mb-3">
          Do you really want to confirm the public training event
          &nbsp;
          <strong>
            &quot;
            {headline}
            &quot;
          </strong>
          &nbsp;
          on
          &nbsp;
          {humanDate}
          ?
        </h4>

        <Form>
          <Form.Group controlId="cancelForm.notes">
            <Form.Label>Notes about the confirmation:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="The event was confirmed because ..."
              value={notes}
              onChange={handleNotesUpdate}
            />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="success" onClick={handleConfirm} disabled={isLoading}>
          <Icon name="check" fw />
          { isLoading ? 'Loading...' : 'Confirm' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  event: PropTypes.shape({
    id: PropTypes.string,
    headline: PropTypes.string,
    starts_on: PropTypes.string,
  }).isRequired,
  onConfirm: PropTypes.func,
};

ConfirmModal.defaultProps = {
  show: false,
  onConfirm: () => {},
};

export default ConfirmModal;
