import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useInfiniteScroll from 'react-infinite-scroll-hook';

// import InfiniteScroll from 'react-infinite-scroller';
// import InfiniteScroll from 'react-infinite-scroll-component';

import Spinner from '../Spinner';

const Body = ({
  className, children, infinite, infiniteProps,
}) => {
  if (!infinite) {
    return (
      <tbody className={classnames('listing-table-body', className)}>
        {children}
      </tbody>
    );
  }

  const {
    hasMore,
    loadMore,
    loading,
    // scrollRef,
  } = infiniteProps;

  const [infiniteRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: loadMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    // disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    // rootMargin: '0px 0px 400px 0px',
  });

  return (
    <tbody
      className={classnames('listing-table-body--infinite', 'listing-table-body', className)}
    >
      {children}

      {
        hasMore && (
          <tr ref={infiniteRef} key="loading-row">
            <td
              colSpan="100"
              className={classnames('text-center pt-4 pb-5')}
              style={{ borderBottom: 0 }}
            >
              <Spinner />
            </td>
          </tr>
        )
      }
    </tbody>
  );
};

Body.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  infinite: PropTypes.bool,
  infiniteProps: PropTypes.shape({
    loading: PropTypes.bool,
    hasMore: PropTypes.bool,
    loadMore: PropTypes.func,
    scrollRef: PropTypes.shape({}),
  }),
};

Body.defaultProps = {
  className: '',
  infinite: false,
  infiniteProps: {},
};

export default Body;
