import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { debounce } from 'lodash';

import Icon from '../../Icon';

const TicketsSearchBar = ({
  cancelledTickets,
  placedTickets,
  onFilter,
  initialStateFilter,
}) => {
  const [ticketsState, setTicketsState] = useState(initialStateFilter);
  const textRef = React.createRef();
  const handleChange = () => {
    if (!textRef.current) { return; }

    onFilter({
      text: textRef.current.value,
      ticketsState,
    });
  };
  const handleTicketsStateChange = (value) => {
    setTicketsState(value);

    onFilter({
      text: textRef.current.value,
      ticketsState: value,
    });
  };

  const debouncedHandleChange = debounce(handleChange, 200);

  // Did mount
  useEffect(() => {
    onFilter({
      text: textRef.current.value,
      ticketsState,
    });

    // return () => {
    //   // mounted = false;
    //   // EventBus.unsubscribe(eventBusSubscription);
    // };
  }, []);

  return (
    <div className="row mb-3 mt-3 justify-content-center">
      <div className="col-12 col-md-10 align-self-center">
        <div className="input-group">
          <input
            type="text"
            placeholder="Search..."
            className="input-sm form-control"
            onChange={debouncedHandleChange}
            ref={textRef}
          />

          <span className="input-group-append">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={handleChange}
            >
              Search
            </button>
          </span>
        </div>
      </div>

      <div className="col-12 col-md-10 align-self-center text-center mt-4">
        <ToggleButtonGroup type="radio" name="options" value={ticketsState} onChange={handleTicketsStateChange}>
          <ToggleButton value="placed" variant="light">
            <Icon name="check" fw />
            &nbsp;
            Placed tickets ({placedTickets.length})
          </ToggleButton>

          <ToggleButton value="cancelled" variant="light">
            <Icon name="trash" fw />
            &nbsp;
            Cancelled tickets ({cancelledTickets.length})
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  );
};

TicketsSearchBar.propTypes = {
  onFilter: PropTypes.func.isRequired,
  initialStateFilter: PropTypes.oneOf(['placed', 'cancelled']),
};

TicketsSearchBar.defaultProps = {
  initialStateFilter: 'placed',
};

export default TicketsSearchBar;
