import { get, patch, fetchPaginatedResources } from '../base';

export const archiveIssuedInvoice = (id) => (
  patch(`/billing/issued_invoices/${id}/archive.json`)
);

export const syncIssuedInvoice = (id) => (
  patch(`/billing/issued_invoices/${id}/sync.json`)
);

export const paginatedIssuedInvoices = ({ page }) => (
  get(`/billing/issued_invoices.json?page=${page || 1}`)
);

export const issuedInvoices = async () => (
  fetchPaginatedResources({ fetchApi: paginatedIssuedInvoices })
);

export default {
  archiveIssuedInvoice,
  paginatedIssuedInvoices,
  issuedInvoices,
};
