import { urlFor } from '../utils';

const basePath = '/trainings/certifications';

export const indexPath = (options = {}) => urlFor(basePath, options);

export const newPath = (options = {}) => urlFor(`${basePath}/new`, options);

export const showPath = (id, options = {}) => urlFor(`${basePath}/${id}`, options);

export const editPath = (id, options = {}) => urlFor(`${basePath}/${id}/edit`, options);

export const deletePath = showPath;

export const certifiedAttendeesPath = (id, options = {}) => {
  const path = `${basePath}/${id}/certified_attendees`;
  const { format } = options;

  if (!format || format === 'html') { return path; }

  return `${path}.${format}`;
};

export const verifyPath = (verificationCode, options = {}) => urlFor(
  `/verify/${verificationCode}`, options,
);

export default {
  indexPath,
  newPath,
  showPath,
  editPath,
  deletePath,
  certifiedAttendeesPath,
  verifyPath,
};
