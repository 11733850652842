import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';

import Input from '../../../RHF/Input';
import SelectInput from '../../../RHF/SelectInput';
import NestedLabelFields from './NestedLabelFields';
import Icon from '../../../Icon';
import { createCategory, updateCategory } from '../../../../api/financialAnalysis/classification/categories';
import handleError from '../../../../utils/error-handler';
import { visit } from '../../../../utils/router';

const buildEmptyLabel = () => ({
  name: '',
  color: '#2b7bb9',
  labelId: uuidv4(),
});

const formDomId = 'category-form';
const deserializeLabels = (labels) => (labels.map(({ label_id, color, name }) => ({
  labelId: label_id,
  color,
  name,
})));

const CategoryForm = ({
  categoryId,
  label,
  type,
  notes,
  labels,
  availableTypes,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const initialLabels = labels.length === 0
    ? [buildEmptyLabel()]
    : deserializeLabels(labels);
  const { handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      type: availableTypes.find(({ value }) => (value === type)),
      labels: initialLabels,
      categoryId,
      label,
      notes,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'labels',
  });

  const handleAdd = () => { append(buildEmptyLabel()); };

  const serializeLabels = (dLabels) => (dLabels.map(({ labelId, ...attributes }) => ({
    ...attributes,
    label_id: labelId,
  })));

  const handleCreate = (params) => {
    createCategory({
      ...params,
      type: params.type.value,
      labels: serializeLabels(Object.values(params.labels)),
    }).then((response) => response.json())
      .then(({ redirect_to }) => { visit(redirect_to); })
      .catch(handleError);
  };

  const handleUpdate = (params) => {
    updateCategory({
      ...params,
      type: params.type.value,
      labels: serializeLabels(Object.values(params.labels)),
      categoryId,
    }).then((response) => response.json())
      .then(({ redirect_to }) => { visit(redirect_to); })
      .catch(handleError);
  };

  const onSubmit = (params) => {
    setIsLoading(true);

    if (categoryId) {
      handleUpdate(params);
    } else {
      handleCreate(params);
    }
  };

  return (
    <div className="SearchMultipleAvailableSlotsPage__form">
      <Form onSubmit={handleSubmit(onSubmit)} id={formDomId}>
        <div className="row">
          <div className="col-8">
            <Input
              label="Label"
              placeholder="The category name"
              name="label"
              errors={errors}
              control={control}
              size="lg"
              required
            />
          </div>

          <div className="col-4">
            <SelectInput
              label="Typology"
              name="type"
              errors={errors}
              control={control}
              options={availableTypes}
              className="Select-lg"
              required
            />
          </div>
        </div>

        <Input
          label="Notes"
          placeholder=""
          name="notes"
          errors={errors}
          control={control}
          size="lg"
          as="textarea"
        />

        <div className="NestedLabels mt-5">
          <h4>
            <Icon name="tags" fw />
            &nbsp;
            Labels
          </h4>

          <hr />

          <div>
            {
              fields.map(({ labelId }, index) => (
                <NestedLabelFields
                  key={labelId}
                  errors={errors}
                  control={control}
                  index={index}
                  onRemove={() => remove(index)}
                />
              ))
            }
          </div>

          <Button size="sm" onClick={handleAdd}>
            <Icon name="plus" fw />
            &nbsp;
            Add label
          </Button>
        </div>

        <hr />

        <div className="text-center mt-3">
          <Button
            type="submit"
            form={formDomId}
            variant="success"
            disabled={isLoading}
            size="lg"
          >
            <Icon name="check" fw />
            &nbsp;
            { isLoading ? 'Loading...' : 'Save category' }
          </Button>
        </div>
      </Form>
    </div>
  );
};

CategoryForm.propTypes = {
  categoryId: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  notes: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.shape({})),
  availableTypes: PropTypes.arrayOf(PropTypes.shape({})),
};

CategoryForm.defaultProps = {
  categoryId: undefined,
  label: '',
  type: undefined,
  notes: '',
  labels: [],
  availableTypes: [],
};

export default CategoryForm;
