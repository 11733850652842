import { post, httpDelete } from '../base';

const basePath = () => (
  '/financial_analysis'
);

export const createPublicTrainingRule = ({
  publicTrainingId,
  categoryId,
  categoryLabels,
}) => (
  post(`${basePath()}/public-training-rules.json`, {
    rules: {
      public_training_id: publicTrainingId,
      category_labels: {
        category_id: categoryId,
        category_labels: categoryLabels,
      },
    },
  })
);

export const resetPublicTrainingCategoryRules = ({ publicTrainingId, categoryId }) => (
  httpDelete(`${basePath()}/public-training-rules/reset.json`, {
    rules: {
      public_training_id: publicTrainingId,
      category_id: categoryId,
    },
  })
);

export default {
  createPublicTrainingRule,
};
