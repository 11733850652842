import { withFormat } from '../utils';

const basePath = '/trainings/private_classes';

export const indexPath = (options = {}) => (withFormat(basePath, options));

export const newPath = () => (`${basePath}/new`);

export const showPath = (id, options = {}) => (withFormat(`${basePath}/${id}`, options));

export const editPath = (id, options = {}) => (withFormat(`${basePath}/${id}/edit`, options));

export const deletePath = showPath;

export default {
  indexPath,
  newPath,
  showPath,
  editPath,
  deletePath,
};
