import Mousetrap from 'mousetrap';
import { useEffect, useRef } from 'react';

/**
 * Use mousetrap hook
 *
 * @param handlerKey - A key, key combo or array of combos according to Mousetrap documentation.
 * @param  handlerCallback - A function that is triggered on key combo catch.
 */
function useMousetrap(
  handlerKey,
  handlerCallback,
  elementOrElementRef,
) {
  const actionRef = useRef(null);
  actionRef.current = handlerCallback;
  const element =
    'current' in elementOrElementRef ? elementOrElementRef.current : document;

  useEffect(() => {
    const instance = new Mousetrap(element);

    instance.bind(handlerKey, (e, combo) => {
      typeof actionRef.current === 'function' && actionRef.current(e, combo);
    });

    return () => {
      instance.unbind(handlerKey);
    };
  }, [handlerKey, element]);
}

export default useMousetrap;
