import $ from 'jquery';
import uuidv4 from 'uuid/v4';
import sleep from './sleep';

const notificationArea = '#notification-area';

export const template = ({
  message, title, subtitle, theme, id,
}) => {
  return (`
    <div id="${id}" role="alert" aria-live="assertive" aria-atomic="true" class="toast fade" data-autohide="false">
      <div class="toast-header bg-${theme || 'info'}">
        <strong class="mr-auto">${title || 'Success'}</strong>
        <small>${subtitle || ''}</small>
        <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="toast-body">
        ${message}
      </div>
    </div>
  `);
};

export const show = (options) => {
  const id = options.id || uuidv4();
  const wait = options.wait || 3000;
  $(notificationArea).append(template({ ...options, id }));
  $('.toast').toast('show');

  sleep(wait).then(() => {
    $(`#${id}`).fadeOut(300, () => {
      $(`#${id}`).remove();
    });
  });
};

export const success = (options) => (show({ ...options, theme: 'success' }));
export const warning = (options) => (show({ ...options, theme: 'warning' }));
export const danger = (options) => (show({ ...options, theme: 'danger' }));

export default {
  template,
  show,
  info: show,
  success,
  warning,
  danger,
};
