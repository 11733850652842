import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Money, { formatRubyMoney, formatMoney } from '../../../utils/money';

const TicketClassListRow = ({ ticketClass }) => {
  const { name, capacity, net_amount, gross_amount } = ticketClass;

  return (
    <tr className="row">
      <td className="col-lg-5">
        <h6 className="mb-0">
          {name}
        </h6>
      </td>

      <td className="col-lg-1">
        {capacity}
      </td>

      <td className="col-lg-3">
        {formatRubyMoney(net_amount)}
      </td>

      <td className="col-lg-3">
        {formatRubyMoney(gross_amount)}
      </td>
    </tr>
  );
};

const TicketClassesList = ({ ticketClasses, className }) => {
  const totals = ticketClasses.reduce((data, { capacity, net_amount, gross_amount }) => {
    data.capacity += capacity;

    const tcNet = Money(net_amount).multiply(capacity);
    data.net = data.net.add(tcNet);

    const tcGross = Money(gross_amount).multiply(capacity);
    data.gross = data.gross.add(tcGross);

    return data;
  }, { capacity: 0, net: Money({ cents: 0 }), gross: Money({ gross: 0 }) });

  return (
    <table className={classnames('table table-striped table-sm mt-2', className)}>
      <thead>
        <tr className="row">
          <th className="col-lg-5">
            Name
          </th>

          <th className="col-lg-1">
            Qta
          </th>

          <th className="col-lg-3">
            Net amount
          </th>

          <th className="col-lg-3">
            Gross amount
          </th>
        </tr>
      </thead>

      <tbody>
        {
          ticketClasses.map((tc) => (
            <TicketClassListRow 
              key={tc._id}
              ticketClass={tc}
            />
          ))
        }
      </tbody>

      <tfoot>
        <tr className="row">
          <th className="col-lg-5">
            Total:
          </th>

          <th className="col-lg-1">
            {totals.capacity}
          </th>

          <th className="col-lg-3">
            {formatMoney(totals.net)}
          </th>

          <th className="col-lg-3">
            {formatMoney(totals.gross)}
          </th>
        </tr>
      </tfoot>
    </table>
  );
};

export default TicketClassesList;
