import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import {
  formatIsoDate,
  formatIsoDateTime,
} from '../../../utils/datetime';
import { formatRubyMoney } from '../../../utils/money';

import { showPath } from '../../../routes/trainings/planned_events';

import Icon from '../../Icon';
import Row from '../../ListingTable/Row';
import { ticketShape } from '../proptypes';

const PlacedTicketRow = (props) => {
  const { item } = props;
  const {
    purchased_at,
    attendee_first_name,
    attendee_last_name,
    attendee_email,
    company_name,
    public_training_edition_id,
    training,
    gross_price,
    base_price,
  } = item;

  return (
    <Row {...props}>
      <td className="h6 text-nowrap">
        <Icon name="calendar" family="far" fw />
        &nbsp;
        {formatIsoDate(training.starts_on)}
      </td>

      <td className="h6">
        <a
          href={showPath(public_training_edition_id)}
          className="d-block text-truncate"
          title={training.headline}
        >
          {training.headline}
        </a>
      </td>

      <td className="h6 text-nowrap">
        {formatIsoDateTime(purchased_at)}
      </td>

      <td className="h6">
        {attendee_first_name}
      </td>

      <td className="h6">
        {attendee_last_name}
      </td>

      <td className="h6">
        <a href={`mailto:${attendee_email}`}>
          {attendee_email}
        </a>
      </td>

      <td className="h6">
        {formatRubyMoney(gross_price)}
      </td>

      <td className="h6">
        {formatRubyMoney(base_price)}
      </td>
    </Row>
  );
};

PlacedTicketRow.propTypes = {
  item: ticketShape.isRequired,
  // onUpdate: PropTypes.func,
  // onDelete: PropTypes.func,
};

PlacedTicketRow.defaultProps = {
  // onUpdate: () => {},
  // onDelete: () => {},
};

export default PlacedTicketRow;
