import React, {
  useState,
  createContext,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
// import { debounce } from 'lodash';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';
// import EventBus from '../../../packs/event_bus';

import { search } from '../../../api/smart_planner/search_opportunities';
import { createRecipe } from '../../../api/smart_planner/recipes';

const Context = createContext({});

const offsetTypeValue = (offsetType) => (offsetType.value || offsetType);
const serializeStep = ({ step_type, offset_type, ...attrs }) => ({
  ...attrs,
  offset_type: step_type === 'offset' ? offsetTypeValue(offset_type) : null,
  step_type,
});

// TODO/MAYBE:
// - eager load the available fad foreach recipes
export const Provider = ({
  children,
  recipes,
  users,
  currentUserId,
  hideDesigner,
  showStepsList,
  hideRecipeInput,
  initialRecipe,
  showNewOpportunityCTA,
  showEditOpportunityCTA,
  preload
}) => {
  const [opportunitySteps, setOpportunitySteps] = useState([]);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [externalRecipes, setExternalRecipes] = useState(recipes);
  const [searchParams, setSearchParams] = useState({});
  const [currentFADIndex, setCurrentFADIndex] = useState(0);
  const [currentFADs, setCurrentFADs] = useState([]);
  const [isCurrentFADLoading, setIsCurrentFADLoading] = useState([]);
  const isLoading = !!isCurrentFADLoading[currentFADIndex];
  const setIsLoading =  (value) => {
    setIsCurrentFADLoading((values)=> {
      const newValues = [...values];
      newValues[currentFADIndex] = value;
      return newValues;
    });
  };
  const currentFAD = currentFADs[currentFADIndex]
  const setCurrentFAD = (FAD) => {
    setCurrentFADs((FADs) => {
      const newFADs = [...FADs];
      newFADs[currentFADIndex] = FAD;
      return newFADs;
    })
  };
  const resetFAD = () => {
    setCurrentFADIndex(0);
    setCurrentFADs([]);
  };
  const hasResults = !!currentFAD;
  const onSearch = (({ from, steps, ...attrs }) => {
     setIsLoading(true);
     resetFAD();

    const currentSearchParams = {
      ...attrs,
      from: from ? from.toISOString() : null,
      steps: steps.map(serializeStep),
    };

    setSearchParams(currentSearchParams);

    search(currentSearchParams)
      .then((response) => (response.json()))
      .then(({ data }) => {
        setCurrentFAD(data);
        setIsFormDirty(false);
        setIsLoading(false);
      })
      .catch(handleError);
  });

  const searchCurrentIndexFAD = () => {
    if (currentFADIndex === 0) { return; }

    setIsLoading(true);

    const lastFAD = currentFADs[currentFADIndex - 1];
    const from = lastFAD.draft_allocations[0].max_end_at
    const nextFADSearchParams = {
      ...searchParams,
      from,
    };

    search(nextFADSearchParams)
      .then((response) => (response.json()))
      .then(({ data }) => {
        setCurrentFAD(data);
        setIsLoading(false);
      })
      .catch(handleError);
  };

  useEffect(() => {
    if (currentFADIndex === 0) { return; }
    if (currentFAD) { return; }

    searchCurrentIndexFAD();
  }, [currentFADIndex]);

  const buildRecipe = ({
    id,
    color,
    name,
    description,
    priority,
    duration,
  }) => ({
    id,
    color,
    name,
    description,
    default_priority: priority,
    default_duration: duration,
  });

  const pushRecipe = (recipe) => {
    setExternalRecipes((currentTypes) => ([recipe, ...currentTypes]));
  };

  const enhancedCreateRecipe = (params) => (
    createRecipe(params)
      .then((response) => response.json())
      .then(({ message, id }) => {
        const recipe = buildRecipe({ ...params, id });
        pushRecipe(recipe);
        success({ message });

        return recipe;
      })
      .catch(handleError)
  );

  const handleOnPrevFad = () => {
    setCurrentFADIndex((index) => (
      index === 0 ? 0 : index - 1
    ));
  };
  const handleOnNextFad = () => {
    setCurrentFADIndex((index) => (index + 1));
  };

  const handleFormChange = (params) => {
    const { steps } = params;
    setOpportunitySteps(steps);
    setIsFormDirty(true);
    resetFAD();
  }

  const canSearch = isFormDirty && opportunitySteps && opportunitySteps.length > 0;

  const contextState = {
    isLoading,
    setIsLoading,
    hasResults,
    onSearch,
    recipes: externalRecipes,
    users,
    currentUserId,
    initialRecipe,
    createRecipe: enhancedCreateRecipe,
    opportunitySteps,
    onFormChange: handleFormChange,
    currentFAD,
    currentFADIndex,
    isFormDirty,
    canSearch,
    showStepsList,
    showNewOpportunityCTA,
    showEditOpportunityCTA,
    hideRecipeInput,
    hideDesigner,
    preload,
    onPrevFad: handleOnPrevFad,
    onNextFad: handleOnNextFad,
  };

  const expirationValues = [
    recipes,
    currentFAD,
    currentFADs,
    isLoading,
    isCurrentFADLoading,
    isFormDirty,
    opportunitySteps,
    currentFADIndex,
  ];

  // The shared state
  const value = useMemo(() => (contextState), expirationValues);

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  showNewOpportunityCTA: PropTypes.bool,
  showEditOpportunityCTA: PropTypes.bool,
  showStepsList: PropTypes.bool,
  hideRecipeInput: PropTypes.bool,
  hideDesigner: PropTypes.bool,
  preload: PropTypes.bool,
};

Provider.defaultProps = {
  showNewOpportunityCTA: false,
  showEditOpportunityCTA: false,
  showStepsList: false,
  hideRecipeInput: false,
  hideDesigner: false,
  preload: false,
};

export default Context;
