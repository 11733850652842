import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';

import { success } from '../utils/notifier';
import handleError from '../utils/error-handler';
import { colorizedLabel } from '../utils/money';
import { archiveIssuedInvoice } from '../api/billing/issued_invoices';
import { archiveExpense } from '../api/billing/expenses';
import Icon from './Icon';
import ConfirmModal from './ConfirmModal';
import Row from './ListingTable/Row';
import SyncButton from './billing/SyncButton';

const isPresent = (text) => (text && text.toString().length > 0);
const isBlank = (text) => (!isPresent(text));

const generateUrl = (id = null, action = null) => {
  let url = '/billing/billing_documents/';

  if (id) url = url + id + '/';
  if (action) url = url + action;

  return url;
};

const colorizeDeadline = (deadline) => {
  let color = '';

  if (Date.parse(deadline) < Date.now()) {
    color = 'badge badge-warning';
  } else {
    color = 'badge badge-light';
  }

  return color;
};

const getArchiveApi = (item) => {
  const { document_type } = item;

  switch (document_type) {
    case 'outbound_invoice':
      return archiveIssuedInvoice;
    case 'expense':
      return archiveExpense;
    default:
      return null;
  }
};

class BillingDocumentRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showArchiveModal: false,
    };
  }

  handleArchiveButtonClink() {
    if (!this.canArchive()) { return; }

    this.setState({ showArchiveModal: true });
  }

  handleArchive() {
    if (!this.canArchive()) { return; }

    const { item, onRowDelete } = this.props;
    const { id } = item;
    const archiveApi = getArchiveApi(item);

    archiveApi(id)
      .then((response) => response.json())
      .then((data) => {
        onRowDelete((id));
        success({ message: data.message });
      })
      .catch(handleError);
  }

  canArchive() {
    const { item } = this.props;

    return getArchiveApi(item) !== null;
  }

  canSync() {
    const { item } = this.props;
    const { external_reference } = item;

    return isPresent(external_reference);
  }

  renderStateBadge() {
    const { item } = this.props;
    const { state } = item;

    const badgeClass = state === 'PAID' ? 'badge-success' : 'badge-warning';

    return (
      <span className={classnames('badge', badgeClass)}>
        {state}
      </span>
    );
  }

  renderDocumentType() {
    const { item } = this.props;
    const { human_document_type } = item;

    return (
      <span className={classnames('badge', 'badge-light')}>
        {human_document_type}
      </span>
    );
  }

  renderDeadline() {
    const { item } = this.props;
    const { due_date } = item;

    if (due_date) {
      return (
        <span className={colorizeDeadline(due_date)}>{due_date}</span>
      );
    }

    return this.renderStateBadge();
  }

  render() {
    const { showArchiveModal } = this.state;
    const { item } = this.props;
    const {
      id,
      headline,
      customer,
      issued_on,
      due_date,
      progressive_number,
      document_url,
      human_document_type,
      sign_adjusted_amount,
    } = item;

    const caption = (
      <div>
        N°
        <strong>{progressive_number || 'N/A'}</strong>
        &nbsp;of&nbsp;
        {issued_on}
        <br />
        <small>{ headline }</small>
      </div>
    );

    const archiveDescription = (
      <div>
        <h5>
          Do you really want to archive the
          &nbsp;
          <em>{human_document_type}</em>
          ?
        </h5>

        <div className="alert alert-secondary mt-3">
          {caption}
        </div>
      </div>
    );

    return (
      <Row {...this.props}>
        <td className="h5 col-4">
          {caption}
        </td>

        <td className="h5 col-2">
          <a href={generateUrl(id)}><strong>{customer}</strong></a>
        </td>

        <td className="h5 col-2">
          {this.renderDocumentType()}
        </td>

        <td className="h5 col-2 text-right">
          {colorizedLabel(sign_adjusted_amount)}
        </td>

        <td className="h5 col-1 text-right">
          {this.renderDeadline()}
        </td>

        <td className="h5 col-1 text-center">
          <div className="btn-group">
            <Button
              href={document_url}
              rel="noopener noreferrer"
              target="_blank"
              variant="light"
              size="sm"
              disabled={isBlank(document_url)}
              className={classnames({ disabled: isBlank(document_url) })}
            >
              <Icon name="download" fw />
            </Button>

            {
              this.canSync() && (
                <SyncButton
                  id={id}
                  humanDocumentType={human_document_type}
                  progressiveNumber={progressive_number}
                  headline={headline}
                  issuedOn={issued_on}
                />
              )
            }

            <ConfirmModal
              variant="danger"
              title={`Archive the selected ${human_document_type}`}
              description={archiveDescription}
              show={showArchiveModal}
              onHide={() => { this.setState({ showArchiveModal: false }); }}
              onConfirm={() => { this.handleArchive(); }}
            />

            <Button
              disabled={!this.canArchive()}
              className={{ disabled: !this.canArchive() }}
              variant="danger"
              size="sm"
              onClick={() => { this.handleArchiveButtonClink(); }}
            >
              <Icon name="times" fw />
            </Button>
          </div>
        </td>
      </Row>
    );
  }
}

BillingDocumentRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    headline: PropTypes.string,
    customer: PropTypes.string,
    progressive_number: PropTypes.string,
    state: PropTypes.string,
    document_type: PropTypes.string,
    human_document_type: PropTypes.string,
    document_url: PropTypes.string,
  }).isRequired,
  onRowDelete: PropTypes.func.isRequired,
};

export default BillingDocumentRow;
