import { get, post, patch } from './base';

export const syncStatus = () => (
  get('/financial_analysis/operations/sync-status.json')
);

export const syncOperations = () => (
  post('/financial_analysis/operations/sync.json')
);

export const trackRevenueOperation = ({ operationId }) => (
  patch(`/financial_analysis/operations/${operationId}/track_revenue.json`)
);

export const trackExpenseOperation = ({ operationId }) => (
  patch(`/financial_analysis/operations/${operationId}/track_expense.json`)
);

export const trackParentOperation = ({ operationId, parentOperationId }) => (
  patch(`/financial_analysis/operations/${operationId}/track_parent.json`, {
    track_parent_operation: { parent_operation_id: parentOperationId },
  })
);

export default {
  syncOperations,
  trackRevenueOperation,
  trackExpenseOperation,
  trackParentOperation,
};
