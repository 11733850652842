import React from 'react';
import Dinero from 'dinero.js';

const DEFAULT_CURRENCY = 'EUR';

const Money = (options = {}) => (
  Dinero({
    amount: Math.round(options.cents || 0),
    currency: options.currencyIso || options.currency_iso || DEFAULT_CURRENCY,
  }).setLocale('it-IT')
);

export const serializeFloat = (number, currency = DEFAULT_CURRENCY) => ({
  cents: number * 100.0,
  currency_iso: currency,
});

export const monetize = (number, currency = DEFAULT_CURRENCY) => (
  new Money({
    cents: number * 100.0,
    currencyIso: currency,
  })
);

// Standardize the money format to ruby backend. Es: $1.000,00
export const formatMoney = (money, options = {}) => {
  const style = options.style || 'custom'; // Use custom style option as default

  // 'default' style
  const formattedValue = money.toFormat();

  if (style !== 'custom') {
    return formattedValue;
  }

  return [
    formattedValue[formattedValue.length - 1],
    formattedValue.substring(0, formattedValue.length - 2),
  ].join('');
  // Javascript custom currency localization is not so easy...
  // return new Intl.NumberFormat('custom', {
  //   style: 'currency',
  //   currency: currency,
  //   currencySign: 'accounting',
  //   signDisplay: 'always'
  // }).format(money.toUnit());
};

export const formatRubyMoney = (rubyMoney, options = {}) => formatMoney(Money(rubyMoney), options);

// Standardize the money format to ruby backend.
// Es: $1.000,00  // custom style
// Es: 1.000,00 $ // default style
export const formatNumber = (number, options = {}) => {
  const { currency, ...otherOptions } = options;
  const money = monetize(number, currency || DEFAULT_CURRENCY);

  return formatMoney(money, otherOptions);
};

export const colorize = (money) => {
  const realMoney = money.isPositive ? money : Money(money);
  return realMoney.isPositive() ? 'green' : 'red';
};

export const colorizeMoney = (money) => (
  <span className={colorize(money || {})}>
    {formatMoney(money)}
  </span>
);

export const colorizedLabel = (money) => (
  <span className={colorize(money || {})}>
    {formatMoney(Money(money || {}))}
  </span>
);

export const moneyToChartValue = (money) => ({
  v: money.toUnit(),
  f: money.toFormat(),
});

export default Money;
