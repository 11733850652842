import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../Icon';

const VenueIcon = ({ venue }) => {
  if (venue.toString().length > 0) {
    return (
      <Icon name="map-marker" fw />
    );
  }

  return (
    <Icon name="globe-africa" fw />
  );
};

VenueIcon.propTypes = {
  venue: PropTypes.string.isRequired,
};

export default VenueIcon;
