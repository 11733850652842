import { post } from '../base';

export const reconcileExpectations = ({
  id,
  unreconciledExpectationIds,
  plannedExpectationIds,
  newExpectations,
  costCenter,
}) => (
  post('/financial_analysis/reconciliations.json', {
    reconciliation: {
      id,
      unreconciled_expectation_ids: unreconciledExpectationIds,
      planned_expectation_ids: plannedExpectationIds,
      new_expectations: newExpectations,
      cost_center: costCenter,
    },
  })
);

export default {
  reconcileExpectations,
};
