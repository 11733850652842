import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Label from './LabelBadge';
import UnspecifiedLabel from './UnspecifiedLabel';
import RepartitionsChart from './AdvancedCategorizationModal/RepartitionsChart';
import Icon from '../../../Icon';

import styles from '../InlineCategorization.module.scss';

const Badges = ({ labels }) => {
  const badges = labels.map((label) => (
    <Label
      name={label.name}
      repartition={label.repartition}
      color={label.color}
      key={label.name}
    />
  ));

  if (badges.length === 0) {
    badges.push(<UnspecifiedLabel key="unspecified" />);
  }

  return badges;
};

const EditLabel = ({
  labels, onClick, onRemove, onAdvanced,
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    // TODO: with more than one label trigger the advanced modal
    onClick();
  };

  const handleAdvancedClick = (e) => {
    e.preventDefault();
    onAdvanced();
  };

  const handleRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onRemove();
  };

  const RemoveIcon = (
    <a
      href="#remove"
      onClick={handleRemove}
      className={classnames(styles.RemoveLabelIcon)}
    >
      <Icon name="times" fw />
    </a>
  );

  const EditIcon = (
    <a
      href="#edit"
      onClick={handleClick}
      className={classnames(styles.EditLabelIcon)}
    >
      <Icon name="pencil-alt" fw />
    </a>
  );

  const AdvancedIcon = (
    <a
      href="#advanced"
      onClick={handleAdvancedClick}
      className={classnames(styles.AdvancedLabelIcon)}
    >
      <Icon name="cogs" fw />
    </a>
  );

  const renderBadges = () => (
    <a href="#edit" onClick={handleClick}>
      <Badges labels={labels} />
      &nbsp;
    </a>
  );

  const renderChart = () => {
    const anchorStyle = {
      width: 88,
      height: 40,
      display: 'block',
      overflow: 'visible',
      marginTop: -3,
      zIndex: 0,
      position: 'relative',
    };

    return (
      <a href="#edit" onClick={handleClick} style={anchorStyle}>
        <RepartitionsChart
          entities={labels}
          height={45}
          scale={1.75}
          pieHole={0.3}
        />
      </a>
    );
  };

  const willRenderChart = labels.length >= 2;
  const wrapperClassNames = classnames(
    styles.EditLabel,
    { [styles.EditLabelWithChart]: willRenderChart },
  );
  const Caption = willRenderChart
    ? renderChart()
    : renderBadges();

  return (
    <span className={wrapperClassNames}>
      { Caption }

      { labels.length > 0 ? RemoveIcon : EditIcon }

      { AdvancedIcon }
    </span>
  );
};

EditLabel.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onAdvanced: PropTypes.func.isRequired,
};

EditLabel.defaultProps = {
  labels: [],
};

export default EditLabel;
