import React, { useState, useEffect, useCallback } from 'react';
import { Chart } from 'react-google-charts';
import { parseISO, formatISO, differenceInDays, addDays, addYears, addMonths } from 'date-fns';

import CustomLegend from '../../charts/CustomLegend';

const NOT_CLASSIFIED = " "

const INVOICE = 1
const EXPENSE = -1

const startWithEmojiRegex = new RegExp(/^\p{Emoji_Presentation}.*/u)
const expectationCategory = (expectation) => (startWithEmojiRegex.test(expectation.headline)) ? Array.from(expectation.headline)[0] : NOT_CLASSIFIED

const useStateWithCallback = (initialValue, callback) => {
  let [value, setValue] = useState(initialValue);

  const customSetValue = useCallback((newValue) => {
    setValue((oldValue) => {
      callback(newValue)
      setValue(newValue)
      return newValue
    });
  }, []);

  return [value, customSetValue];
}

// ============================================================================

const ForecastLiquidityChart = ({expectations_data, account_details, default_time_selection, forecast_confidence_levels, selection_configuration_settings, initially_selected_setting}) => {

  const allEmoji = expectations_data
    .map(e => expectationCategory(e))
    .reduce(
      (a, e) => a.includes(e) ? a : a.concat([e]),
      Object.keys(forecast_confidence_levels)
    )
    .filter(e => e != NOT_CLASSIFIED)
    // .sort()

  const initialBalanceByAccountType =
    Object.entries(account_details
      .reduce((a, v) => {
        const accountType = v['_account_type']
        a[accountType] = ((a[accountType]) ? a[accountType] : 0) + Math.round(v['sign_adjusted_current_balance']['cents'] / 100)
          return a
        }, {}
      )
    )
    .sort((a, b) => b[1] - a[1])

  const emojiStatus   = (o) => allEmoji.map((v, i) => selection_configuration_settings[o]['emoji'][v])
  const accountStatus = (o) => initialBalanceByAccountType.map((v, i) => selection_configuration_settings[o]['accounts'][v[0]])

  const updateSettings = (option) => {
    setLegendStates(emojiStatus(option))
    setLegendAccountStates(accountStatus(option))
  }
  
  // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

  const [defaultSettings, setDefaultSettings]             = useStateWithCallback(initially_selected_setting, updateSettings)
  const [selectedTimeRange, setSelectedTimeRange]         = useState(default_time_selection);
  const [legendStates, setLegendStates]                   = useState(emojiStatus(defaultSettings));
  const [legendAccountStates, setLegendAccountStates]     = useState(accountStatus(defaultSettings));
  const [showWorstCaseScenario, setShowWorstCaseScenario] = useState(false);

  console.log("SHOW WORST CASE SCENARIO", showWorstCaseScenario)
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const filterRowsWorstCaseScenario    = (values, index) => showWorstCaseScenario ? values : values.filter((v, i) => (index == i) ? false : true)
  const filterColumnsWorstCaseScenario = (values, index) => showWorstCaseScenario ? values : values.map(r => r.filter((v, i) => (index == i) ? false : true))

  const fromDate = Date.now()  
  const toDate   = (selectedTimeRange === 'year') ? addYears(fromDate, 1) : ((selectedTimeRange === 'semester') ? addMonths(fromDate, 6) : addMonths(fromDate, 3))
  const numberOfDays = differenceInDays(toDate, fromDate)

  const columns = [
    { type: 'date',   label: 'Date',            },

    { type: 'number', label: 'Initial balance', },
    { type: 'number', label: 'Baseline', },
    { type: 'number', label: 'Selected scenario', },
    { type: 'number', label: 'Worst case scenario', },
    // { type: 'number', label: 'Expectations', },
  
    { type: 'number', label: 'Invoice',         },
    { type: 'number', label: 'Expense',         },
  ];
  // const activeColumns = columns     //  #################################
  const activeColumns = filterRowsWorstCaseScenario(columns, 4)

  const selected_emoji_color = 'MediumSeaGreen'

  const series = [
    { color: 'orange', type: 'line'},            //  Current balance
    { color: 'lightblue', type: 'area'},            //  Current balance
    { color: selected_emoji_color, type: 'line'},   //  Scenario with currently selected emoji
    { color: '#ee8888', type: 'line'},              //  Worst case scenario
    // { color: 'gold', type: 'line'},                 //  Expectations

    { color: 'green'}, //  Invoice
    { color: 'brown'},  //  Expense
  ]
  // const activeSeries = series     //  #################################
  const activeSeries = filterRowsWorstCaseScenario(series, 3)

  // ==================================================================
    
  const handleAccountLegendClick = (index) => {
    setLegendAccountStates((items) => {
      const newItems = [...items];
      newItems[index] = !newItems[index];

      return newItems;
    });
  };

  const accountEntries = initialBalanceByAccountType
    .map((label, index) => {
      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
      
        trailingZeroDisplay: 'stripIfInteger',
        maximumFractionDigits: 0
      });
      
      return ({
        label: `${label[0]} [${formatter.format(label[1])}]`,
        key: index,
        active: legendAccountStates[index],
        color: 'lightblue',
      })
    })
    
  const initialBalance = initialBalanceByAccountType
    .filter((v, i) => legendAccountStates[i])
    .map(v => v[1])
    .reduce((a, v) => a + v, 0)

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const handleLegendClick = (index) => {
    setLegendStates((items) => {
      const newItems = [...items];
      newItems[index] = !newItems[index];

      return newItems;
    });
  };

  const legendEntries = allEmoji
    .map((label, index) => ({
      label: label +  (forecast_confidence_levels[label] ? (" " + forecast_confidence_levels[label]) :  "" ),
      key: index,
      active: legendStates[index],
      color: selected_emoji_color,
    }))

  // ==================================================================

  const amountsAtDate = (date, sign) => expectations_data
    .filter(e => e.sign == sign)
    .filter(e => e.expected_on_date == formatISO(date, { representation: 'date' }))
    .filter(e => {
      const category = expectationCategory(e)
      return (category === NOT_CLASSIFIED || legendStates[allEmoji.indexOf(category)])
    })
    .reduce(
      (a, e) => a + Math.round(e.sign_adjusted_amount.cents / 100),
      0
    )

  const current_forecast_filter = (date, expectation) => {
    let result = true
    result &= expectation.expected_on_date <= formatISO(date, { representation: 'date' })
    result &= expectationCategory(expectation) === NOT_CLASSIFIED

    return result
  }

  const worst_case_scenario_filter = (date, expectation) => {
    let result = true
    result &= expectation.expected_on_date <= formatISO(date, { representation: 'date' })
    result &= expectationCategory(expectation) === NOT_CLASSIFIED
    result &= expectation.sign === -1

    return result
  }

  const selected_emoji_filter = (date, expectation) => {
    const category = expectationCategory(expectation)
    let result = true
    result &= expectation.expected_on_date <= formatISO(date, { representation: 'date' })
    result &= (category === NOT_CLASSIFIED || legendStates[allEmoji.indexOf(category)])

    return result
  }

  const runningBalanceAtDate = (date, filter, balance) => expectations_data
  .filter(e => filter(date, e))
  .reduce(
    (a, e) => a + Math.round(e.sign_adjusted_amount.cents / 100),
    balance
  )

  const dailyDynamicData = [...Array(numberOfDays).keys()].map(d => {
    const date = addDays(fromDate, d)
    return ([
      date,
      
      (d == 0) ? initialBalance: null,
      runningBalanceAtDate(date, current_forecast_filter, initialBalance),    //  current forecast
      runningBalanceAtDate(date, selected_emoji_filter, initialBalance),      //  scenario with selected emoji
      runningBalanceAtDate(date, worst_case_scenario_filter, initialBalance), //  worst case scenario
      // runningBalanceAtDate(date, current_forecast_filter, 0),                 //  expectations

      amountsAtDate(date, INVOICE),
      amountsAtDate(date, EXPENSE),
    ])
  })
  // const activeDailyDynamicData = dailyDynamicData     //  ########################
  const activeDailyDynamicData = filterColumnsWorstCaseScenario(dailyDynamicData, 4)


  const options = {
    legend: 'bottom',
    vAxis: {
      // format: 'currency',
      format: '\u20AC#,###',
    },
    isStacked: false,
    series: activeSeries,
    backgroundColor: '#f8f9fa',
    seriesType: 'bars',
    chartArea: {
      width: '80%',
      height: '80%',
    },
  };

  return (
    <>
      <div className="LiquidityChart">
        <Chart
          chartType="ComboChart"
          width='100%'
          height='800px'
          rows={activeDailyDynamicData}
          columns={activeColumns}
          options={options}
        />
      
        <div className="graph-options">
          {/* <h4>Forecast options</h4> */}
          <div>
            <select name="timeRange" id="time_range"
              defaultValue={selectedTimeRange}
              onChange={e => setSelectedTimeRange(e.target.value)}
            >
              <option value="year">Next Year</option>
              <option value="semester">Next Six Months</option>
              <option value="trimester">Next Three Months</option>
            </select>

            <select name="default-settings" id="default-settings"
              defaultValue={defaultSettings}
              onChange={e => setDefaultSettings(e.target.value)}
            >
              { Object.keys(selection_configuration_settings).map(k => <option value={k} key={k}>{selection_configuration_settings[k].description}</option>) }
            </select>

            <p>{selection_configuration_settings[defaultSettings].note}</p>
            <hr />

            <fieldset>
              <legend>Expectation types included in the forecast</legend>
              {legendEntries.map((v, i) => <div key={i} ><label><input type="checkbox" onClick={() => handleLegendClick(i) } defaultChecked={ legendStates[i] } /> {v.label}</label></div> ) }
            </fieldset>

            <fieldset>
              <legend>Accounts included into the initial balance</legend>
              {accountEntries.map((v, i) => <div key={i}><label><input type="checkbox" onClick={() => handleAccountLegendClick(i) } defaultChecked={ legendAccountStates[i] } /> {v.label}</label></div> ) }
            </fieldset>

            <fieldset>
              <legend>Worst Case Scenario</legend>
              <div><label><input type="checkbox" onClick={() => setShowWorstCaseScenario(! showWorstCaseScenario) } defaultChecked={ showWorstCaseScenario } /> Show Worst Case Scenario</label></div>
            </fieldset>

          </div>
        </div>
      </div>
    </>
  )
}

export default ForecastLiquidityChart;
