import React from 'react';
import PropTypes from 'prop-types';
import Money from '../../utils/money';
import Cable from '../../channels/FinancialAnalysis';
import { accountTransactions } from '../../api/bankingAccounts';

class ReactiveTotalLiquitity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: props.initialValues,
    };
  }

  componentDidMount() {
    // this.cableSubscriptionId = Cable.subscribe(
    //   'account_mirrors_view:updated',
    //   this.handleAccountUpdate,
    //   this,
    // );
  }

  componentWillUnmount() {
    // Cable.unsubscribe(this.cableSubscriptionId);
  }

  // handleAccountUpdate({ id }) {
  //   accountTransactions({ id })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       const balance = response.banking_account.current_balance;
  //       const { values } = this.state;
  //       const newValues = {
  //         ...values,
  //         [id]: balance,
  //       };

  //       this.setState({ values: newValues });
  //     });
  // }

  render() {
    const zeroParams = { cents: 0, currencyIso: 'EUR' };
    const { values } = this.state;

    const liquidity = Object.values(values).reduce((t, value) => {
      let amount;
      const {
        _account_type,
        sign_adjusted_current_balance,
        current_balance
      } = value;

      if (_account_type === 'shareholder_financing') {
        amount = new Money(); // Do not consider this account type
      } else {
        amount = new Money(sign_adjusted_current_balance);
      }

      return t.add(amount);
    }, new Money(zeroParams));

    const total = Object.values(values).reduce((t, value) => {
      const { _account_type, sign_adjusted_current_balance } = value;
      const amount = new Money(sign_adjusted_current_balance || zeroParams);

      return t.add(amount);
    }, new Money(zeroParams));

    const shareholderFinancingAmount = Object.values(values).reduce((t, value) => {
      const { _account_type, current_balance } = value;
      let amount;

      if (_account_type === 'shareholder_financing') {
        amount = new Money(current_balance);
      } else {
        amount = new Money(); // Do not consider this account type
      }

      return t.add(amount);
    }, new Money(zeroParams));

    return (
      <div style={{ lineHeight: '1.2rem' }}>
        {liquidity.toFormat()}
        <br/>
        <small className="smaller text-muted d-block mt-1"  style={{ lineHeight: '0.95rem' }}>
          of which
          &nbsp;
          <strong>{shareholderFinancingAmount.toFormat()}</strong>
          <br />
          shareholder financing
        </small>
      </div>
    );
  }
}

ReactiveTotalLiquitity.propTypes = {
  initialValues: PropTypes.object,
};

ReactiveTotalLiquitity.defaultProps = {
  initialValues: {},
};

export default ReactiveTotalLiquitity;
