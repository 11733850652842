export const priorityMarks = {
  0: 'Min',
  51: 'Ignore events',
  76: 'Ignore working hours',
  100: 'Max',
};

export const startTimeHours = [
  '00:00', '00:30',
  '01:00', '01:30',
  '02:00', '02:30',
  '03:00', '03:30',
  '04:00', '04:30',
  '05:00', '05:30',
  '06:00', '06:30',
  '07:00', '07:30',
  '08:00', '08:30',
  '09:00', '09:30',
  '10:00', '10:30',
  '11:00', '11:30',
  '12:00', '12:30',
  '13:00', '13:30',
  '14:00', '14:30',
  '15:00', '15:30',
  '16:00', '16:30',
  '17:00', '17:30',
  '18:00', '18:30',
  '19:00', '19:30',
  '20:00', '20:30',
  '21:00', '21:30',
  '22:00', '22:30',
  '23:00', '23:30',
];

export const repeatEveryOptions = [
  { value: 'days', label: 'Days' },
  { value: 'weeks', label: 'Weeks' },
  { value: 'months', label: 'Months' },
];

export const offsetOptionsMetadata = {
  exactly_after: {
    label: 'EXACTLY AFTER',
    icon: 'clock',
  },
  later_than: {
    label: 'LATER THAN',
    icon: 'long-arrow-alt-right',
  },
  no_later_than: {
    label: 'NO LATER THAN',
    icon: 'hand-paper',
  },
  day_after: {
    label: 'DAY AFTER',
    icon: 'calendar-day',
  },
  recurring_after: {
    label: 'RECURRING AFTER',
    icon: 'history',
  },
};

export const offsetOptions = Object.keys(offsetOptionsMetadata).reduce((options, key) => (
  [
    ...options,
    {
      ...offsetOptionsMetadata[key],
      value: key,
    },
  ]
), []);

export const findOffsetOption = (key) => {
  if (typeof key === 'string') {
    return offsetOptions.find(({ value }) => (value === key));
  }

  // The key could be an already decorated offset option
  return key;
};

export const offsetTypeLabel = (key) => (offsetOptionsMetadata[key].label);

export const offsetTypeIcon = (key) => (offsetOptionsMetadata[key].icon);

export const deserializeStep = (({
  duration,
  minutes_duration,
  offset_type,
  ...attrs
}) => ({
  ...attrs,
  duration: duration || minutes_duration,
  offset_type: findOffsetOption(offset_type),
}));

export const deserializeSteps = ((steps) => (steps.map(deserializeStep)));

export default {
  priorityMarks,
  startTimeHours,
  repeatEveryOptions,
  offsetOptionsMetadata,
  offsetOptions,
  findOffsetOption,
  offsetTypeLabel,
  offsetTypeIcon,
  deserializeStep,
  deserializeSteps,
};
