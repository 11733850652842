import React from 'react';
import PropTypes from 'prop-types';

const PdfViewer = ({ url, width, height }) => {
  const fullUrl = `${url}#view=FitH&toolbar=0&statusbar=0&navpanes=0`;
  const type = 'application/pdf';
  const style = { width, height };

  return (
    <object data={fullUrl} type={type} style={style}>
      <embed src={fullUrl} type={type} />
    </object>
  );
};

PdfViewer.propTypes = {
  url: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

PdfViewer.defaultProps = {
  width: '300px',
  height: '215px',
};

export default PdfViewer;
