import PropTypes from 'prop-types';
import UUID from '../../../../utils/uuid';
import { randomColor } from '../../../../utils/colors';
import api from '../../../../api/financialAnalysis/classification/categories';
// import handleError from '../../../../utils/error-handler';

export const buildLabel = ({ name, categoryId, ...options }) => {
  const id = options.id || options._id || UUID.generate();
  const color = options.color || `#${randomColor()}`;
  const repartition = options.repartition || 1;

  return {
    ...options,
    name,
    label: name,
    label_id: id,
    id,
    _id: id,
    category_id: categoryId,
    color,
    repartition,
  };
};

// TODO: refactor me later and return a Promise
export const createLabel = ({
  name, categoryId, onSuccess,
}) => {
  const labelId = UUID.generate();
  const color = `#${randomColor()}`;
  const newLabel = {
    name,
    label_id: labelId,
    id: labelId,
    category_id: categoryId,
    color,
  };

  api.createCategoryLabel({
    categoryLabelId: labelId,
    name,
    categoryId,
    color,
  }).then(() => {
    if (onSuccess) {
      onSuccess(newLabel);
    }
  });

  return newLabel;
};

// Categorize category with single label
export const categorizeLabel = ({
  categoryLabelId, categoryId, operationId, repartition = 1,
}) => (
  api.categorize({
    operationId, categoryLabelId, categoryId, repartition,
  })
);

// Categorize category with multiple labels
export const categorizeLabels = ({
  labels, categoryId, operationId,
}) => (api.bulkCategorize({ operationId, labels, categoryId }));

export const decimal = (number, decimals = 2) => {
  const stringValue = parseFloat(number).toFixed(decimals + 1);

  return parseFloat(stringValue.substring(0, stringValue.length - 1));
};

export const totalRepartition = (entities) => {
  // toFixed is used to avoid floating point errors
  const repartitions = entities.map((e) => (decimal(e.repartition * 100.0)));
  const total = repartitions.reduce((acc, r) => (acc + r), 0);

  return decimal(total);
};

export const isValidRepartition = (repartition) => (repartition >= 0.01 && repartition <= 100);

export const categoryShape = PropTypes.shape({
  category_id: PropTypes.string,
});

export default {
  decimal,
  buildLabel,
  createLabel,
  categorizeLabel,
  totalRepartition,
  isValidRepartition,
};
