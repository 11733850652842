export const baseUrl = () => (
  '/billing/documents'
);

export const generateUrl = ({ id, action }) => {
  const urlChunks = [baseUrl()];

  if (id) { urlChunks.push(id); }
  if (action) { urlChunks.push(action); }

  return urlChunks.join('/');
};

export default {
  baseUrl,
  generateUrl,
};
