import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';

import Icon from './Icon';

const ConfirmModal = ({
  show,
  onHide,
  onConfirm,
  variant,
  size,
  title,
  description,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const close = () => {
    setIsLoading(false);
    onHide();
  };
  const handleConfirm = () => {
    if (isLoading) { return; }

    setIsLoading(true);

    onConfirm({ close });
    close();
  };

  const ConfirmButton = (
    <Button variant={variant} onClick={handleConfirm}>
      <Icon name="check" fw />
      &nbsp;
      Confirm
    </Button>
  );

  const LoadingButton = (
    <Button variant={variant} disabled>
      <Icon name="spinner" fw spin />
      &nbsp;
      Loading ...
    </Button>
  );

  return (
    <Modal show={show} onHide={onHide} size={size}>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong className={classnames({ 'text-danger': variant === 'danger' })}>
            {title}
          </strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>{description}</div>
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={onHide}>
          <Icon name="times" fw />
          &nbsp;
          Close
        </Button>
        { isLoading ? LoadingButton : ConfirmButton}
      </Modal.Footer>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  variant: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

ConfirmModal.defaultProps = {
  show: false,
  onConfirm: () => {},
  variant: 'primary',
  size: 'lg',
  title: 'Confirm',
  description: '',
};

export default ConfirmModal;
