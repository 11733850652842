import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import Money, { moneyToChartValue } from '../utils/money';
import sleep from '../utils/sleep';
import { buttonsCtas as ctas } from '../behaviors/toggable-class';
import Cable from '../channels/FinancialAnalysis';
import { accountTransactions } from '../api/bankingAccounts';


const computeData = (liquidityData, { sign = -1.0 }) => {
  const rows = liquidityData.map((data) => {
    const dailyAmount = Money(data.daily_amount) * sign;
    const dailyBalance = Money(data.daily_balance) * sign;

    const row = [
      new Date(data.date),
      moneyToChartValue(dailyAmount),
      moneyToChartValue(dailyBalance),
    ];

    return row;
  });

  // rows.unshift([new Date(), 0, this.moneyToChartValue(balance)])
  return rows;
};


class LegacyLiquidityAccountChart extends React.Component {
  constructor(props) {
    super(props);

    const { liquidityData, color } = props;
    this.state = { canvasWidth: '100%', liquidityData, color };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    this.cableSubscriptionId = Cable.subscribe(
      'account_mirrors_view:updated',
      this.handleAccountUpdate,
      this,
    );

    const { autoResize, autoResizeEvery, resizeOnTogglable } = this.props;

    if (autoResize) {
      // TODO: chart drew into hidden div are rendered small...
      // Find a better way to auto resize the chart when it appears
      this.intervalHandler = setInterval(this.redrawChart.bind(this), autoResizeEvery);
    }

    if (resizeOnTogglable) {
      const items = document.querySelectorAll(ctas);

      items.forEach((item) => {
        item.addEventListener('click', this.handleResizeOnTogglable.bind(this));
      });
    }
  }

  componentWillUnmount() {
    Cable.unsubscribe(this.cableSubscriptionId);

    if (this.intervalHandler) {
      clearInterval(this.intervalHandler);
      this.intervalHandler = undefined;
    }

    const items = document.querySelectorAll(ctas);

    items.forEach((item) => {
      item.removeEventListener('click', this.handleResizeOnTogglable);
    });
  }

  handleAccountUpdate({ id }) {
    const { accountId } = this.props;

    if (id !== accountId) {
      return;
    }

    accountTransactions({ id })
      .then((response) => response.json())
      .then((response) => {
        const { color, daily_summaries } = response.banking_account;
        this.setState({ color, liquidityData: daily_summaries });
      });
  }

  // fit the available width
  redrawChart() {
    const { clientWidth } = this.wrapperRef.current;
    const { canvasWidth } = this.state;

    if (clientWidth !== canvasWidth) {
      this.setState({ canvasWidth: this.wrapperRef.current.clientWidth });
    }
  }

  handleResizeOnTogglable() {
    [0, 25, 50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300].forEach((timing) => {
      sleep(timing).then(this.redrawChart.bind(this));
    });
  }

  render() {
    const { canvasWidth } = this.state;
    const { liquidityData, color } = this.state;
    const rows = computeData(liquidityData);
    const columns = [
      {
        type: 'date',
        label: 'Date',
      },
      {
        type: 'number',
        label: 'Amount',
      },
      {
        type: 'number',
        label: 'Balance',
      },
    ];

    const amountSerie = {};
    if (color) { amountSerie.color = color; }

    return (
      <div ref={this.wrapperRef}>
        <Chart
          chartType="SteppedAreaChart"
          width={canvasWidth}
          height="450px"
          chartArea={{
            width: canvasWidth,
            height: '100%',
          }}
          rows={rows}
          columns={columns}
          options={{
            title: 'Liquidity on account',
            hAxis: {
              title: 'Time',
              titleTextStyle: { color: '#333' },
              continuous: true,
            },
            vAxis: {
              title: 'Liquidity',
              minValue: 0,
            },
            backgroundColor: '#f8f9fa',
            isStacked: false,
            series: {
              0: { color: '#1bff04' },
              1: amountSerie,
            },
            chartArea: {
              width: '65%',
              height: '80%',
            },
          }}
        />
      </div>
    );
  }
}

LegacyLiquidityAccountChart.propTypes = {
  resizeOnTogglable: PropTypes.bool,
  autoResize: PropTypes.bool,
  autoResizeEvery: PropTypes.number,
};

LegacyLiquidityAccountChart.defaultProps = {
  resizeOnTogglable: false,
  autoResize: false,
  autoResizeEvery: 250,
};

export default LegacyLiquidityAccountChart;
