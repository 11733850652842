import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import classnames from 'classnames';

import Spinner from '../../Spinner';
import Context from './Context';

import ListingTable from '../../ListingTable';
import Row from './AccountRow';

import BlankState from '../../BlankState';

const filterKeys = ['account_name', 'provider'];
const headers = ['Name', 'Provider', 'Description', 'Actions'];
const sortableColumns = {
  Name: 'account_name',
  Description: 'description',
  Provider: 'provider',
};

const Datatable = () => {
  const {
    loading,
    userAccounts,
    deleteAccount,
  } = useContext(Context);

  if (loading) {
    return (
      <div className={classnames('Datatable', 'card')}>
        <div className="card-body">
          <Spinner description="Loading user accounts..." />
        </div>
      </div>
    );
  }

  if (userAccounts.length === 0) {
    return (
      <div className={classnames('Datatable', 'card')}>
        <BlankState
          buttonProps={{ href: '/smart_planner/google_calendars/new' }}
          buttonLabel="Add google account"
          description="No account configured"
          iconFamily="fab"
          icon="google"
        />
      </div>
    );
  }

  return (
    <div className="Datatable">
      <div className={classnames('Datatable', 'card')}>
        <div className="card-body">
          <ListingTable
            items={userAccounts}
            filterKeys={filterKeys}
            headers={headers}
            sortableColumns={sortableColumns}
            headerRowProps={{ className: 'text-nowrap' }}
            components={{ Row }}
            rowProps={{ onDelete: deleteAccount }}
          />
        </div>
      </div>
    </div>
  );
};

export default Datatable;
