import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import WithTrainerPublicEvents from './WithTrainerPublicEvents';
import Spinner from '../../Spinner';
import ListingTable from '../../ListingTable';
import Row from './PublicEventRow';

const PublicEventsPage = ({ trainer, loading, publicEvents }) => {
  const { full_name } = trainer;

  if (loading) {
    return (
      <div>
        <Spinner description={`Loading ${full_name} public events...`} />
      </div>
    );
  }

  const headers = [
    'Date',
    'Status',
    'Headline',
    'Trainers',
    'Venue',
    'Sold tickets',
  ];

  const sortableColumns = {
    Date: 'starts_on',
    Headline: 'headline',
    Trainers: 'trainers_name',
    Status: 'status',
    Venue: 'venue',
    'Sold tickets': 'tickets_sold',
  };

  return (
    <div className={classnames('trainer-public-events-page')}>
      <ListingTable
        items={publicEvents}
        filterKeys={[
          'headline', 'description', 'trainers_name', 'city', 'venue',
        ]}
        headers={headers}
        sortableColumns={sortableColumns}
        headerRowProps={{ className: 'text-nowrap' }}
        components={{ Row }}
      />
    </div>
  );
};

PublicEventsPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  trainer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    full_name: PropTypes.string,
    email: PropTypes.string,
    public_page_url: PropTypes.string,
    picture_url: PropTypes.string,
  }).isRequired,
};

PublicEventsPage.defaultProps = {
};

export default WithTrainerPublicEvents(PublicEventsPage);
