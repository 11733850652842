import React from 'react';

import ListingTable from '../../ListingTable/Infinite';
import Row from '../../ExpectationsListRow';

const RunningDatatable = () => {
  const endpoint = '/forecast/expectations/running.json';

  return (
    <ListingTable
      initialEndpoint={endpoint}
      components={{ Row }}
      filterKeys={['headline', 'notes', 'counterpart']}
    />
  );
};

RunningDatatable.propTypes = {
};

RunningDatatable.defaultProps = {
};

export default RunningDatatable;
