import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import EventBus from '../../../packs/event_bus';
import PdfViewer from '../../PdfViewer';
import sleep from '../../../utils/sleep';

class CertificationPreview extends React.Component {
  constructor(props) {
    super(props);
    const { certification } = props;
    const { id } = certification;
    const updatedAt = DateTime.now().toISO();

    this.state = { id, updatedAt };
  }

  componentDidMount() {
    this.certificationEventsSubscriptionId = EventBus.subscribe(
      [
        'Trainings::Certifications::CertificationDetailsUpdated',
      ],
      this.handleCertificationEventsSubscription,
      this,
    );
  }

  componentWillUnmount() {
    EventBus.unsubscribe(this.certificationEventsSubscriptionId);
  }

  getPreviewUrl() {
    const { updatedAt, id } = this.state;
    return `/trainings/certifications/${id}/preview.pdf?t=${updatedAt}`;
  }

  handleCertificationEventsSubscription() {
    // TODO: se loading...
    //
    sleep(250).then(() => {
      const updatedAt = DateTime.now().toISO();
      this.setState({ updatedAt });
    });
  }

  render() {
    const previewUrl = this.getPreviewUrl();

    return (
      <div className="card" style={{ background: '#333333' }}>
        <PdfViewer
          url={previewUrl}
          width="100%"
          height="525px"
        />
      </div>
    );
  }
}

CertificationPreview.propTypes = {
  certification: PropTypes.shape({
    id: PropTypes.string,
    headline: PropTypes.string,
    description: PropTypes.string,
    venue: PropTypes.string,
  }).isRequired,
};

CertificationPreview.defaultProps = {
};

export default CertificationPreview;
