import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';

import Icon from '../../Icon';
import { calendarEventShape } from '../proptypes';
import AddLabelModalLabels from './AddLabelModalLabels';
import AddLabelModalNewForm from './AddLabelModalNewForm';
import AddLabelModalEditForm from './AddLabelModalEditForm';
import AddLabelModalConfirmDelete from './AddLabelModalConfirmDelete';
import styles from './LabelsPicker.module.scss';

const AddLabelModal = ({
  show,
  onClose,
  onLabelCreate,
  onLabelUpdate,
  onLabelDelete,
  onLabelToggle,
  className,
  labels,
  event,
  user,
}) => {
  // index | new | edit
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [currentView, setCurrentView] = useState('index');
  const showLabelsList = currentView === 'index';
  const showNewLabelForm = currentView === 'new';
  const showEditLabelForm = currentView === 'edit' && selectedLabel;
  const showDeleteConfirmMessage = currentView === 'delete' && selectedLabel;

  let title;

  switch (currentView) {
    case 'index':
      title = 'Choose labels';
      break;
    case 'new':
      title = 'Create label';
      break;
    case 'edit':
      title = 'Edit label';
      break;
    case 'delete':
      title = 'Delete label';
      break;
  }

  const handleBack = () => {
    setSelectedLabel(null);
    setCurrentView('index');
  };
  const handleEditLabel = (label) => {
    setSelectedLabel(label);
    setCurrentView('edit');
  }
  const handleDeleteLabel = (label) => {
    setSelectedLabel(label);
    setCurrentView('delete');
  }
  const handleClose = () => {
    setSelectedLabel(null);
    setCurrentView('index');
    onClose();
  };

  return (
    <div
      className={classnames(
        className, styles.AddLabelModal,
        { 'd-none': !show },
      )}
    >
      <div className={styles.AddLabelModal__header}>
        <h5>
          <a
            role="button"
            onClick={handleClose}
            className={styles.AddLabelModal__close}
          >
            <Icon name="times" fw />
          </a>
          {title}:
        </h5>
      </div>

      <AddLabelModalLabels
        labels={labels}
        show={showLabelsList}
        onShowNewLabelForm={() => { setCurrentView('new'); }}
        onToggle={onLabelToggle}
        onEdit={handleEditLabel}
        onDelete={handleDeleteLabel}
        event={event}
        user={user}
      />

      <AddLabelModalNewForm
        show={showNewLabelForm}
        onBack={handleBack}
        onSubmit={onLabelCreate}
        user={user}
      />

      <AddLabelModalEditForm
        show={showEditLabelForm}
        onBack={handleBack}
        onUpdate={onLabelUpdate}
        label={selectedLabel}
        user={user}
      />

      <AddLabelModalConfirmDelete
        show={showDeleteConfirmMessage}
        onBack={handleBack}
        onDelete={onLabelDelete}
        label={selectedLabel}
        user={user}
      />
    </div>
  );
};

AddLabelModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onLabelCreate: PropTypes.func,
  onLabelUpdate: PropTypes.func,
  onLabelDelete: PropTypes.func,
  onUpdatedLabel: PropTypes.func,
  onClick: PropTypes.func,
  event: calendarEventShape,
};

AddLabelModal.defaultProps = {
  show: false,
  onClick: () => {},
  onClose: () => {},
  onLabelCreate: () => {},
  onLabelUpdate: () => {},
  onLabelDelete: () => {},
  onLabelToggle: () => {},
  event: {},
};

export default AddLabelModal;
