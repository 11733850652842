import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CategoriesCharts from './CategoriesCharts';
import DataTable from './DataTable';

import Spinner from '../Spinner';
import WithOperations from './WithOperations';

const OperationsPage = (({
  logic,
  activeCategoryId,
  operations,
  categories,
  loading,
  isUpdating,
  activeCategoriesLabels,
  activeCategoryLabelsFilters,
  onUpdate,
  onDelete,
  onCategoryLabelChange,
  onCategoryLabelChanged,
  onCategoryLabelsFiltersToggle,
  onCategoryChange,
  typology,
}) => {
  if (loading) {
    return (
      <div>
        <Spinner
          description="Loading operations drill down..."
        />
      </div>
    );
  }

  return (
    <div className="OperationsPage">
      <CategoriesCharts
        categories={categories}
        operations={operations}
        logic={logic}
        activeCategoryId={activeCategoryId}
        activeCategoriesLabels={activeCategoriesLabels}
        activeCategoryLabelsFilters={activeCategoryLabelsFilters}
        onCategoryLabelsChange={onCategoryLabelsFiltersToggle}
        onCategoryChange={onCategoryChange}
      />

      <DataTable
        categories={categories}
        operations={operations}
        onUpdate={onUpdate}
        onDelete={onDelete}
        onCategoryLabelChange={onCategoryLabelChange}
        onCategoryLabelChanged={onCategoryLabelChanged}
        activeCategoryId={activeCategoryId}
        activeCategoryLabelsFilters={activeCategoryLabelsFilters}
        typology={typology}
        isUpdating={isUpdating}
      />
    </div>
  );
});

OperationsPage.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  operations: PropTypes.arrayOf(PropTypes.object).isRequired,
  logic: PropTypes.string,
  loading: PropTypes.bool,
  isUpdating: PropTypes.bool,
  activeCategoryId: PropTypes.string,
  activeCategoriesLabels: PropTypes.shape({}).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCategoryLabelChange: PropTypes.func.isRequired,
  onCategoryLabelChanged: PropTypes.func.isRequired,
  typology: PropTypes.oneOf(['revenue', 'expense']).isRequired,
};

OperationsPage.defaultProps = {
  logic: 'positive',
  activeCategoryId: '',
  loading: false,
  isUpdating: false,
};

export default WithOperations(OperationsPage);
