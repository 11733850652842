import React from 'react';
import PropTypes from 'prop-types';
import { calendarEventShape } from './proptypes';
import DatetimeRangeLabel from '../DatetimeRangeLabel';

const CalendarEventDatetimeLabel = ({ event, compact, className }) => {
  const {
    start_at,
    end_at,
    start_on,
    end_on,
  } = event;

  return (
    <DatetimeRangeLabel
      start_at={start_at}
      end_at={end_at}
      start_on={start_on}
      end_on={end_on}
      className={className}
      compact={compact}
      separator="-"
    />
  );
};

CalendarEventDatetimeLabel.propTypes = {
  event: calendarEventShape,
  compact: PropTypes.bool,
  className: PropTypes.string,
};

CalendarEventDatetimeLabel.defaultProps = {
  event: {},
  compact: false,
  className: '',
};

export default CalendarEventDatetimeLabel;
