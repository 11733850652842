import React from 'react';

import { Provider } from './Context';
import SearchForm from './SearchForm';
import Results from './Results';

const SearchMultipleAvailableSlotsPage = (props) => (
  <Provider {...props}>
    <div className="SearchMultipleAvailableSlotsPage card">
      <div className="card-body">
        <SearchForm />

        <Results />
      </div>
    </div>
  </Provider>
);

SearchMultipleAvailableSlotsPage.propTypes = {
};

export default SearchMultipleAvailableSlotsPage;
