import React from 'react';
import PropTypes from 'prop-types';

import { weekDays, serializeSegments } from './utils';
import WorkingTimeSlots from './WorkingTimeSlots';

const SelectedWorkingTimes = ({ weeklySegments, interval }) => {
  const weeklyWorkingTimes = weekDays.reduce((data, day) => {
    data[day] = serializeSegments(weeklySegments[day], interval);

    return data;
  }, {});

  const hasSlots = Object.values(weeklyWorkingTimes)
    .some((slots) => (slots.length > 0));


  if (!hasSlots) {
    return (
      <div className="SelectedWorkingTimes">
        <hr/>

        <h6 className="">
          <em>Select one or more slots...</em>
        </h6>
      </div>
    )
  }

  return (
    <div className="SelectedWorkingTimes mt-3">
      {
        weekDays.map((dayName) => (
          <WorkingTimeSlots
            key={dayName}
            dayName={dayName}
            slots={weeklyWorkingTimes[dayName]}
          />
        ))
      }
    </div>
  );
};

SelectedWorkingTimes.propTypes = {
  interval: PropTypes.number.isRequired,
  weeklySegments: PropTypes.shape({
    mon: PropTypes.shape({}),
    tue: PropTypes.shape({}),
    wed: PropTypes.shape({}),
    thu: PropTypes.shape({}),
    fri: PropTypes.shape({}),
    sat: PropTypes.shape({}),
    sun: PropTypes.shape({}),
  }).isRequired,
};

export default SelectedWorkingTimes;
