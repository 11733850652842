import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';

import ListingTable from '../ListingTable';
import { itemMatchSearchTerm } from '../ListingTable/utils';
import SearchBar from './SearchBar';
import BulkToolbar from './BulkToolbar';
import Row from '../OperationRow';
import Spinner from '../Spinner';

import styles from './OperationsPage.module.scss';

const operationFilterKeys = [
  'counterpart',
  'operation_reference',
  'operation_headline',
  'operation_notes',
];

const DataTable = ({
  categories,
  operations,
  onUpdate,
  onDelete,
  onCategoryLabelChange,
  onCategoryLabelChanged,
  isUpdating,
  activeCategoryId,
  activeCategoryLabelsFilters,
}) => {
  // const [wrapperHeight, setWrapperHeight] = useState(700);
  const wrapperHeight = 700;
  const [activeOperationIds, setActiveOperationIds] = useState([]);
  // const [activeFilters, setActiveFilters] = useState({});
  const categoryLabels = categories.map(({ label }) => (label));
  const headers = [
    'Reference',
    'Amount',
    'Counterpart',
  ].concat(categoryLabels, 'Actions');

  const sortableColumns = {
    Reference: ['operation_date', 'operation_reference'],
    Amount: (op) => (op.abs_amount.cents),
    Counterpart: 'counterpart',
  };

  categories.forEach((category) => {
    sortableColumns[category.label] = (op) => (
      (op.category_labels_names || {})[category.category_id]
    );
  });

  const categoryIds = categories.map(({ id }) => (id));
  const categoriesCount = categories.length;
  const itemWithUnspecifiedLabels = (item) => {
    const itemCategoryIds = _.compact(_.uniq(item.category_labels.map(
      ({ category_id }) => (
        _.includes(categoryIds, category_id)
          ? category_id
          : null
      )
    )));

    return (categoriesCount !== itemCategoryIds.length);
  };


  const itemWithActiveLabels = ({ activeCategoryLabelsFilters, activeCategoryId, item }) => {
    const currentCategory = categories.find(({ id }) => (id === activeCategoryId));

    // NOTE:
    // If all labels in the category are active, the item is to be displayed anyway
    if (currentCategory.labels.length === activeCategoryLabelsFilters[activeCategoryId].length) {
      return true;
    }

    const currentActiveLabelIds = activeCategoryLabelsFilters[activeCategoryId];
    const itemCategoryLabels = item.category_labels.filter(({ category_id }) => (category_id === activeCategoryId));
    const itemActiveCategoryLabels = item.category_labels.filter(({ label_id }) => (_.includes(currentActiveLabelIds, label_id)));
    const itemActiveCategoryLabelIds = itemActiveCategoryLabels.map(({ label_id }) => (label_id));
    const intersectedLabels = _.intersection(itemActiveCategoryLabelIds, currentActiveLabelIds);
    const hasIntersectedActiveLabels = intersectedLabels.length > 0
    const isUnclassifiedLabelActive = _.includes(currentActiveLabelIds, 'unclassified')
    const isUnclassifiedItem = itemCategoryLabels.length === 0;

    return hasIntersectedActiveLabels
      || (isUnclassifiedLabelActive && isUnclassifiedItem);
  };

  const handleOnSearch = ({ items, filters, filterKeys }) => {
    const activeItem = (item) => (_.includes(activeOperationIds, item.id));
    const { text, unspecified } = filters;
    const term = (text || '').toLowerCase();

    const filteredItems = items.filter((item) => {
      const result1 = itemMatchSearchTerm({ item, filterKeys, term });
      const result2 = itemWithActiveLabels({ activeCategoryLabelsFilters, activeCategoryId, item });
      const result = result1 && result2;

      if (unspecified) {
        const hasUnspecifiedLabels = itemWithUnspecifiedLabels(item);

        return result && (hasUnspecifiedLabels || activeItem(item))
      }

      return result;
    });

    return filteredItems;
  };

  const trackActiveOperationIds = (ids) => {
    const newActiveOperationIds = _.uniq([...activeOperationIds, ...ids]);

    setActiveOperationIds(newActiveOperationIds);
  };

  const enhancedOnCategoryLabelChangeStart = (args) => {
    const { operationId } = args;
    const operationIds = Array.isArray(operationId)
      ? operationId
      : [operationId];

    trackActiveOperationIds(operationIds);

    return onCategoryLabelChange(args);
  };

  const wrapperStyles = { height: wrapperHeight };
  const creditNotesTypes = ['credit_note', 'expense_credit_note'];
  const creditNotes = operations.filter(({ operation_type }) => (
    _.includes(creditNotesTypes, operation_type)
  ));

  const spinnerClassNames = classnames(
    'd-flex align-items-center justify-content-center',
    styles.bodySpinner,
    { [styles.bodySpinnerActive]: isUpdating },
  );

  const style = {
    boxShadow: 'none',
    borderRadius: '0 0 4px 4px',
    borderTop: '0px',
  };

  return (
    <div className="card my-10" style={style}>
      <div className={classnames('card-body', styles.bodyWrapper)}>
        <div className={spinnerClassNames}>
          <Spinner description="Updating rows categorization..." title="" />
        </div>

        <ListingTable
          items={operations}
          filterKeys={operationFilterKeys}
          headers={headers}
          headerRowProps={{ className: 'text-nowrap' }}
          rowProps={{
            categories,
            creditNotes,
            onUpdate,
            onDelete,
            onCategoryLabelChange: enhancedOnCategoryLabelChangeStart,
            onCategoryLabelChanged,
            isUpdating,
            activeCategoryId,
          }}
          onSearch={handleOnSearch}
          components={{ SearchBar, BulkToolbar, Row }}
          wrapperClassName={styles.DataTableWrapper}
          wrapperStyles={wrapperStyles}
          sortableColumns={sortableColumns}

          selectable
          stickyHeader
        />
      </div>
    </div>
  );
};

DataTable.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  operations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onCategoryLabelChange: PropTypes.func,
  onCategoryLabelChanged: PropTypes.func,
  isUpdating: PropTypes.bool,
};

DataTable.defaultProps = {
  onUpdate: () => {},
  onDelete: () => {},
  onCategoryLabelChange: () => {},
  onCategoryLabelChanged: () => {},
  isUpdating: false,
};

export default DataTable;
