import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { DateTime } from 'luxon';

import CalendarEventDatetimeLabel from '../CalendarEventDatetimeLabel';
import Input from '../../inputs/Input';
import Icon from '../../Icon';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';
import { updatePriority } from '../../../api/smart_planner/calendar_events';
import { calendarEventShape } from '../proptypes';
import PrioritySliderInput from '../PrioritySliderInput';

const radios = [
  { name: 'Single event', value: 'single_event' },
  { name: 'This event and subsequent events', value: 'recurring_from_child' },
  { name: 'All events', value: 'all_recurring_events' },
];

const isRecurring = (event) => (
  event.parent_id || event.recurring
);

const PriorityUpdateModal = ({
  show,
  onHide,
  onUpdate,
  event,
  label,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  // single_event | recurring | recurring_from_child
  const [priorityMode, setPriorityMode] = useState('single_event');
  const [priority, setPriority] = useState(event.priority);

  const handlePriorityChange = (value) => {
    setPriority(value);
  };

  const handlePriorityModeChange = (event) => {
    setPriorityMode(event.target.value);
  };

  const handleUpdate = () => {
    if (isLoading) { return; }
    setIsLoading(true);

    updatePriority(event.id, { priority, mode: priorityMode })
      .then((response) => response.json())
      .then(({ message }) => {
        success({ message });
        setIsLoading(false);
        onHide();
        onUpdate(event.id, { priority });
      })
      .catch(handleError);
  };

  const { summary } = event;

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Update calendar event priority</strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ zIndex: 301 }}>
        <h5 className="mb-3">
          Choose the priority for the
          &nbsp;
          <strong>
            &quot;
            {summary}
            &quot;
          </strong>
          &nbsp;
          calendar event
          on
          &nbsp;
          <CalendarEventDatetimeLabel event={event} separator=" - " />
          .
        </h5>

        <h5 className="mt-5 mb-3">
          Selected priority:
          &nbsp;
          <strong>{priority}</strong>
        </h5>

        <PrioritySliderInput
          initialPriority={event.priority}
          onChange={handlePriorityChange}
          className="px-2 pb-5"
          debounceTimer={0}
        />

        {
          isRecurring(event) && (
            <>
              <h5>Change periodic event:</h5>
              {
                radios.map(({ name, value }) => (
                  <Form.Check
                    defaultChecked={value === 'single_event'}
                    key={value}
                    type="radio"
                    id={`priority-mode-${value}`}
                    label={name}
                    name="priority_mode"
                    value={value}
                    onChange={handlePriorityModeChange}
                  />
                ))
              }
            </>
          )
        }
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={handleUpdate} disabled={isLoading}>
          <Icon name="check" fw />
          { isLoading ? 'Loading...' : 'Update' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

PriorityUpdateModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  event: calendarEventShape.isRequired,
  onUpdate: PropTypes.func,
};

PriorityUpdateModal.defaultProps = {
  show: false,
  onUpdate: () => {},
};

export default PriorityUpdateModal;

