import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import Icon from '../../Icon';
import RetireModal from './RetireModal';
import { success } from '../../../utils/notifier';
import { visit } from '../../../utils/router';
import { indexPath } from '../../../routes/trainings/planned_events';
import { eventShape } from '../utils';

const RetireButton = ({ event, size, className }) => {
  const [showRetireModal, setShowRetireModal] = useState(false);
  const hideRetireModal = () => { setShowRetireModal(false); };
  const { acl, training_typology, human_training_typology } = event;
  const { canRetire } = acl;

  if (!canRetire) { return ''; }

  const handleRetireButtonClick = () => {
    setShowRetireModal(true);
  };

  const handleRetireConfirmation = ({ response }) => {
    visit(indexPath(training_typology))
      .then(() => { success({ message: response.message }); });
  };

  return (
    <div className="retire-button d-inline-block">
      <RetireModal
        show={showRetireModal}
        event={event}
        onHide={hideRetireModal}
        onConfirm={handleRetireConfirmation}
      />

      <Button
        size={size}
        variant="warning"
        title={`Retire ${human_training_typology}`}
        onClick={handleRetireButtonClick}
        className={className}
      >
        <Icon name="times" fw />
        &nbsp;
        Retire
      </Button>
    </div>
  );
};

RetireButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  event: eventShape.isRequired,
};

RetireButton.defaultProps = {
  size: 'md',
  className: '',
};

export default RetireButton;
