import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Input from '../inputs/ColorInput';

const extractError = (errors, name) => {
  const chunks = name.split(/[[\]]/).filter((chunk) => (chunk.trim().length > 0));

  return chunks.reduce((acc, chunk) => {
    if (!acc) { return null; }

    return acc[chunk] || null;
  }, errors);
};

const ColorInput = ({
  label,
  errors,
  name,
  control,
  required,
  defaultValue,
  ...props
}) => {
  const error = extractError(errors, name);
  const errorMessage = error
    ? error.message || 'Required field'
    : null;

  const handleChange = ({ value, field }) => {
    field.onChange(value);
  };

  return (
    <Form.Group controlId={`form-grid-${name}`}>
      {
        label && (
          <Form.Label>
            {label}
            &nbsp;
            {required && (<abbr title="required">*</abbr>)}
          </Form.Label>
        )
      }
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={{
          required,
        }}
        render={({ field }) => (
          <Input
            {...props}
            {...field}
            initialValue={defaultValue}
            isInvalid={!!errorMessage}
            onChange={(value) => { handleChange({ value, field }); }}
          />
        )}
      />
      {errorMessage && (
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

ColorInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string.isRequired,
  errors: PropTypes.shape({}),
  control: PropTypes.shape({}).isRequired,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
};

ColorInput.defaultProps = {
  label: 'Color',
  errors: {},
  required: false,
  defaultValue: '#2b7bb9',
};

export default ColorInput;
