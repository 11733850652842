import React from 'react';
import Icon from '../../../Icon';

const labelStyles = (color) => ({
  textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
  color: 'white',
  backgroundColor: color,
});

const LabelBadge = ({ name, color, repartition = 1 }) => {
  const repartitionCaption = repartition === 1
    ? null
    : (<small>{ repartition * 100 }%</small>);

  return (
    <span className="badge badge-secondary mr-1 px-2" style={labelStyles(color)}>
      <Icon name="tag" />
      { ` ${name} ` }
      {repartitionCaption}
    </span>
  );
};

export default LabelBadge;
