import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';

import { findFirstAvailableDate } from '../../api/smart_planner/user_availabilities';
import { secondsToHumanLabel } from '../../utils/datetime';
import Spinner from '../Spinner';

/// // Minutes from 30 to 480
/// const defaultDurations = (new Array(16)).fill(0).map((el, idx) => (((idx + 1.0) * 30.0)));

// Minutes from 30 to 240
const defaultDurations = (new Array(8)).fill(0).map((el, idx) => (((idx + 1.0) * 30.0)));

const options = {
  legend: 'none',
  backgroundColor: 'transparent',
  title: 'First available date',
  hAxis: {
    title: 'Hours from now',
    minValue: 0,
  },
  vAxis: {
    title: 'Slots (hours)',
    minValue: 0.5,
    ticks: defaultDurations.map((duration) => (duration / 60.0)),
  },
  bars: 'horizontal',
  chartArea: { height: '80%', width: '80%' },
};

const makeFetchFad = (duration) => (
  findFirstAvailableDate(duration)
    .then((response) => (response.json()))
    .then((response) => ({ ...response, duration }))
);

const FadSummaryChart = ({ durations }) => {
  const initialState = durations.reduce((data, duration) => ({
    ...data,
    [duration]: { slot: null, duration },
  }), {});

  const [isLoading, setIsLoading] = useState(true);
  const [fads, setFads] = useState(initialState);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      const promises = durations.map((duration) => (makeFetchFad(duration)));

      Promise.all(promises).then((values) => {
        setFads(values.reduce((data, value) => ({
          ...data,
          [value.duration]: value,
        }), {}));

        setIsLoading(false);
      });
    }

    return () => { mounted = false; };
  }, []);

  const legend = ['Slot', 'FAD', { role: 'style' }, { role: 'annotation' }];
  const chartData = Object.values(fads).map(({ duration, hours_from_now, minutes_from_now }) => (
    [
      duration / 60.0,
      hours_from_now,
      '#4dbd74',
      secondsToHumanLabel(minutes_from_now * 60),
    ]
  ), []);

  const fullChartData = [
    legend,
    ...chartData,
  ];

  if (isLoading) {
    return (
      <div className="card">
        <Spinner title="Loading FAD chart..." />
      </div>
    );
  }

  return (
    <div className="card">
      <Chart
        chartType="BarChart"
        width="100%"
        height="1000px"
        data={fullChartData}
        options={options}
      />
    </div>
  );
};

FadSummaryChart.propTypes = {
  durations: PropTypes.arrayOf(PropTypes.number),
};

FadSummaryChart.defaultProps = {
  durations: defaultDurations,
};

export default FadSummaryChart;
