import React from "react"
import EventBus from '../packs/event_bus';

class SendCertificatesBox extends React.Component {

  state = {
    isRunning: false,
    currentlyProcessed: 0,
    events: []
  };

  componentWillUnmount() {
    EventBus.unsubscribe(this.eventSubscriptionId)
  }

  onReceivedCertificateGenerated(event) {
    // console.debug(event)
    const {public_training_edition_id} = event
    if(public_training_edition_id != this.props.publicEditionId)
      return

    let processed = this.state.currentlyProcessed + 1
    let isRunning = processed != this.props.attendeesCount
    if(!isRunning)
      processed = 0;

    this.setState({
      events: [...this.state.events, event],
      currentlyProcessed: processed,
      isRunning: isRunning
    })
  }

  componentDidMount() {
    this.eventSubscriptionId = EventBus.subscribe("Trainings::Certificates::CertificateEmailSent", this.onReceivedCertificateGenerated, this)
  }

  startGenerate = (event) => {
    this.setState({...this.state, events: [], isRunning: true})

    fetch(`/trainings/planned_events/${this.props.publicEditionId}/email_certificates`, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
  }

  computeProgress() {
    return Math.round((this.state.currentlyProcessed ? (this.state.currentlyProcessed/this.props.attendeesCount) : 0)*100)
  }

  findAttendee(participationId){
    return this.props.attendees.find(a => a.participation_id === participationId)
  }

  renderAttendee(event) {
    const { certificate_id, pdf_uri, participation_id, verification_code } = event
    const attendee = this.findAttendee(participation_id);

    return <tr key={certificate_id}>
      <td>{attendee.first_name} {attendee.last_name}<br />{attendee.email}</td>
      <td className="text-center">
        <i className="fa fa-check"></i>
      </td>
    </tr>

  }

  renderAttendeesList(){
    let instance = this
    if(this.state.events.length > 0){
    return <table className="table m-t-sm">
      <thead><tr><th>Attendee</th><th>#</th></tr></thead>
      <tbody>
      {instance.state.events.map(function(attendee) {
        return instance.renderAttendee(attendee)
      })}
      </tbody>
      </table>
    }
  }

  renderProgress() {
    if(this.state.isRunning)
    {
      return <div className="m-t-sm">
                <div className="progress progress-striped active m-b-sm">
                  <div style={{width: `${this.computeProgress()}%`}} className="progress-bar"></div>
                </div>
                <small>Spamming... <strong>{this.computeProgress()}%</strong>.</small>
              </div>
    }
  }

  render () {
    return  <div className="text-center">
              <p>
                <button className="btn btn-lg btn-primary" disabled={this.state.isRunning} onClick={this.startGenerate}>Send Certificates</button>
              </p>
              {this.renderProgress()}
              {this.renderAttendeesList()}
            </div>
  }
}

export default SendCertificatesBox
