import { compareAsc, setDay } from 'date-fns';
// import { DateRange } from '../types';

const getEarliestTimeRange = (ranges) => (
  [...ranges].sort(([startA], [startB]) => (
    compareAsc(setDay(startA, 0), setDay(startB, 0))
  ))[0]
);

export default getEarliestTimeRange;
