import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { debounce } from 'lodash';

const SearchBar = ({ onFilter }) => {
  const unspecifiedRef = React.createRef();
  const textRef = React.createRef();
  const handleChange = () => {
    if (!textRef.current) { return; }

    const filters = {
      text: textRef.current.value,
      unspecified: unspecifiedRef.current.checked,
    };

    onFilter(filters);
  };

  const debouncedHandleChange = debounce(handleChange, 200);

  return (
    <div className="row mb-3 justify-content-center">
      <div className="col-12 col-md-10 align-self-center">
        <div className="input-group">
          <input
            type="text"
            placeholder="Search operations..."
            className="input-sm form-control"
            onChange={debouncedHandleChange}
            ref={textRef}
          />
          <span className="input-group-append">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={handleChange}
            >
              Search
            </button>
          </span>
        </div>
      </div>

      <div className="col-12 col-md-10 align-self-center text-center mt-2">
        <Form.Group controlId="unspecifiedCheckbox">
          <Form.Check
            type="checkbox"
            label="Show only unspecified operations"
            ref={unspecifiedRef}
            onChange={handleChange}
          />
        </Form.Group>
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  onFilter: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {

};

export default SearchBar;
