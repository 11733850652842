import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';

import Input from '../../RHF/Input';
import ColorInput from '../../RHF/ColorInput';
import { randomColor } from '../../../utils/colors';
import Icon from '../../Icon';
import { calendarEventShape } from '../proptypes';
import {
  createUserLabel,
  createSystemLabel,
} from '../../../api/smart_planner/labels';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';

import FormFields from './FormFields';
import Checkbox from '../../RHF/Checkbox';
import styles from './LabelsPicker.module.scss';

const AddLabelModalNewForm = ({
  onBack,
  onSubmit,
  className,
  show,
  formId,
  initialColor,
}) => {
  if (!show) { return null; }

  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      system: false,
      color: initialColor || `#${randomColor()}`,
    },
  });

  const enhancedOnSubmit = ({ name, color, system }) => {
    setIsLoading(true);

    const api = system ? createSystemLabel : createUserLabel;

    api({ name, color })
      .then((response) => (response.json()))
      .then(({ message, id }) => {
        success({ message });
        setIsLoading(false);
        onBack();
        onSubmit({ id, name, color, system });
      })
      .catch(handleError);
  };

  return (
    <div
      className={classnames(
        styles.AddLabelModalForm, className,
        { 'd-none': !show },
      )}
    >
      <Form onSubmit={handleSubmit(enhancedOnSubmit)} id={formId}>
        <div className={styles.AddLabelModal__body}>
          <FormFields
            errors={errors}
            control={control}
            disabled={isLoading}
          />

          <Checkbox
            name="system"
            errors={errors}
            control={control}
            label="Create as System label"
            disabled={isLoading}
          />
        </div>

        <div className={styles.AddLabelModal__footer}>
          <Button
            type="submit"
            variant="primary"
            className="float-right"
            disabled={isLoading}
          >
            {
              isLoading
                ? (<Icon name="spinner" spin fw />)
                : (<Icon name="check" fw />)
            }
            &nbsp;
            Create
          </Button>

          <Button
            variant="secondary"
            onClick={() => { onBack(); }}
            disabled={isLoading}
          >
            <Icon name="angle-left" fw />
            &nbsp;
            Back
          </Button>
        </div>
      </Form>
    </div>
  );
};

AddLabelModalNewForm.propTypes = {
  formId: PropTypes.string,
};

AddLabelModalNewForm.defaultProps = {
  formId: 'new-label-form',
};

export default AddLabelModalNewForm;
