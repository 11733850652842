import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const InfoPage = ({ trainer }) => {
  const {
    first_name,
    last_name,
    email,
    public_page_url,
    picture_url,
  } = trainer;

  return (
    <div className={classnames('trainer-info-page')}>
      <div className="row">
        <div className="col-lg-8">
          <dl>
            <dt>First name</dt>
            <dd>{first_name}</dd>

            <dt>Last name</dt>
            <dd>{last_name}</dd>

            <dt>Email</dt>
            <dd>{ email ? (<a href={`mailto:${email}`}>{email}</a>) : '...' }</dd>

            <dt>Public page</dt>
            <dd>
              {
                public_page_url && (
                  <a rel="noreferrer" href={public_page_url} target="_blank">{public_page_url}</a>
                )
              }
            </dd>
          </dl>
        </div>

        <div className="col-lg-4">
          {
            picture_url && (
              <img
                alt="Trainer"
                className="media-object trainer-profile-picture float-right"
                src={picture_url}
              />
            )
          }
        </div>
      </div>
    </div>
  );
};

InfoPage.propTypes = {
  trainer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    public_page_url: PropTypes.string,
    picture_url: PropTypes.string,
  }).isRequired,
};

InfoPage.defaultProps = {
};

export default InfoPage;
