import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import classnames from 'classnames';

import Icon from '../../Icon';
import styles from './styles.module.scss';

const EmptyState = ({ className, onClick }) => (
  <div className={classnames('text-center my-4', className)}>
    <h5 className="mb-4">Start adding a new opportunity moment</h5>

    <Button
      onClick={() => { onClick({ position: 0, step_type: 'moment' }); }}
      variant="primary"
      size="md"
    >
      <Icon name="plus" fw /> Add Moment step
    </Button>
  </div>
);

EmptyState.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

EmptyState.defaultProps = {
  className: null,
  onClick: () => {},
};

export default EmptyState;
