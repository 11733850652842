import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AreaChart from './YearlyCategoryAreaChart';
import BarChart from './YearlyCategoryBarChart';
import CustomLegend from '../../charts/CustomLegend';

const unclassifiedLabel = {
  id: 'unclassified',
  key: 'unclassified',
  label_id: 'unclassified',
  color: '#cccccc',
  name: 'Unclassified',
  label: 'Unclassified',
  active: true,
};

const YearlyCategoryCharts = ({ category, labels }) => {
  const initialLegendLabels = [unclassifiedLabel].concat(labels.map((label) => ({
    ...label,
    active: true,
    label: label.name,
    key: label.id,
  })));
  const [legendLabels, setLegendLabels] = useState(initialLegendLabels);
  const handleClick = (id) => {
    setLegendLabels(legendLabels.map((label) => ({
      ...label,
      active: label.id === id ? !label.active : label.active,
    })));
  };
  const activeLabels = legendLabels.filter(({ active }) => (active === true));

  return (
    <div className="YearlyCategoryCharts">
      <div className="row">
        <div className="col-md-12">
          <BarChart
            title="Revenues"
            category={category}
            labels={activeLabels}
            dataSet="yearly_revenues"
            chartArea={{
              width: '86%',
              height: '80%',
            }}
          />
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col-md-6">
          <AreaChart
            title="Net profits"
            category={category}
            labels={activeLabels}
            dataSet="yearly_profits"
            height="300px"
          />
        </div>

        <div className="col-md-6">
          <AreaChart
            title="Expenses"
            category={category}
            labels={activeLabels}
            dataSet="yearly_expenses"
            height="300px"
          />
        </div>
      </div>

      <hr />

      <CustomLegend
        items={legendLabels}
        onClick={handleClick}
      />
    </div>
  );
};

YearlyCategoryCharts.propTypes = {
  category: PropTypes.object.isRequired,
  labels: PropTypes.arrayOf(PropTypes.object).isRequired,
};

YearlyCategoryCharts.defaultProps = {
};

export default YearlyCategoryCharts;
