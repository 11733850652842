import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';

import DraftAllocation from './DraftAllocation';
import GroupedDraftAllocation from './GroupedDraftAllocation';
import Frame from './Frame';
import SearchButton from './SearchButton';
import CalendarScheduler from './CalendarScheduler';
import Icon from '../../Icon';
import { formatIsoDateTime } from '../../../utils/datetime';


const EmptyState = ({
  formId, isLoading, title, className, canSearch,
  height,
}) => (
  <Frame className={classnames('d-flex align-items-center text-center', className)} height={height}>
    <div className="w-100">
      <h4>
        {title}
      </h4>

      <div className="text-center mt-4">
        <SearchButton
          formId={formId}
          isLoading={isLoading}
          size="lg"
          caption="Search slots"
          disabled={!canSearch}
        />
      </div>
    </div>
  </Frame>
);

const Results = ({
  isLoading,
  currentFAD,
  currentFADIndex,
  formId,
  canSearch,
  height,
  hideDesigner,
  onPrevFad,
  onNextFad,
}) => {
  if (!currentFAD) {
    return (
      <EmptyState
        isLoading={isLoading}
        canSearch={canSearch}
        formId={formId}
        title="Start a new search to view the available slots"
        height={height}
      />
    );
  }

  if (currentFAD && currentFAD.draft_allocations.length === 0) {
    return (
      <EmptyState
        isLoading={isLoading}
        canSearch={canSearch}
        formId={formId}
        title={(
          <span className="">
            <Icon name="frown" size="2x" className="mb-3" family="far" fw />
            <br />
            No FAD was found available with the current criteria...
          </span>
        )}
        height={height}
      />
    );
  }

  const {
    draft_allocations,
    human_footprint,
    human_elapsed_footprint,
    min_start_at,
    min_end_at,
    human_tolerance,
    grouped_slots,
  } = currentFAD;

  const isPrevFADButtonDisabled = isLoading || currentFADIndex === 0;
  const isNextFADButtonDisabled = isLoading;

  const colBreakPoints = hideDesigner
    ? 'col-12 col-sm-4 col-lg-6 col-xl-2'
    : 'col-12 col-sm-4 col-lg-4 col-xl-4';

  return (
    <Frame height={height}>
      <div className="row">
        <div className="col-xl-9 text-left">
          <h4 className="mt-1 mb-3 mb-xl-0 text-center text-xl-left text-dark">
            <Icon name="calendar-alt" family="far" fw />
            &nbsp;
            <span>
              <strong>First available date:</strong>
              &nbsp;
              {
                currentFAD && (
                  <small>
                    {formatIsoDateTime(currentFAD.min_start_at)}
                  </small>
                )
              }
            </span>
          </h4>
        </div>

        <div className="col-xl-3 text-right">
          <Button
            variant="warning"
            onClick={() => { onPrevFad() }}
            className={classnames({ disabled: isPrevFADButtonDisabled })}
            disabled={isPrevFADButtonDisabled}
          >
            <Icon name="arrow-left" fw />
            &nbsp;
            Prev FAD
          </Button>
          &nbsp;
          <Button
            variant="warning"
            onClick={ () => { onNextFad() }}
            className={classnames({ disabled: isNextFADButtonDisabled })}
            disabled={isNextFADButtonDisabled}
          >
            Next FAD
            &nbsp;
            <Icon name="arrow-right" fw />
          </Button>

          <SearchButton
            formId={formId}
            isLoading={isLoading}
            size="md"
            className="btn-block d-none"
            disabled={!canSearch}
          />
        </div>
      </div>

      <hr className="mt-3 mb-4" />

      <div className="row d-none">
        {
          Object.values(grouped_slots).map(({ slot, draft_allocations }) => (
            <div className={colBreakPoints}>
              <GroupedDraftAllocation
                key={slot.uuid}
                draftAllocations={draft_allocations}
                slot={slot}
              />
            </div>
          ))
        }
      </div>

      <div>
        <CalendarScheduler fad={currentFAD} />
      </div>

      <table className="table mt-3">
        <thead>
          <tr>
            <th>Footprint</th>
            <th>Elapsed Footprint</th>
            <th>Start at</th>
            <th>End at</th>
            <th>Tolerance</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>{human_footprint}</td>
            <td>{human_elapsed_footprint}</td>
            <td>{formatIsoDateTime(min_start_at)}</td>
            <td>{formatIsoDateTime(min_end_at)}</td>
            <td>{human_tolerance}</td>
          </tr>
        </tbody>
      </table>
    </Frame>
  );
};

Results.defaultProps = {
  canSearch: false,
  isLoading: false,
  height: 620,
  onPrevFad: () => {},
  onNextFad: () => {},
};

Results.propTypes = {
  canSearch: PropTypes.bool,
  isLoading: PropTypes.bool,
  height: PropTypes.number,
  onPrevFad: PropTypes.func,
  onNextFad: PropTypes.func,
};

export default Results;
