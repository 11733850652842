import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DateTime } from 'luxon';

import LanguageUpdateModal from './LanguageUpdateModal';
import { eventShape } from '../utils';
import { visit } from '../../../utils/router';
import Icon from '../../Icon';
import { success } from '../../../utils/notifier';

const LanguageInplaceInput = ({ event, className }) => {
  const [showModal, setShowModal] = useState(false);
  const hideModal = () => { setShowModal(false); };
  const { language, details_path } = event;
  const handleLanguageUpdate = ({ response }) => {
    visit(details_path).then(() => { success({ message: response.message }); });
  };
  const handleClick = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <div className={classnames('LanguageInplaceInput', className)}>
      <LanguageUpdateModal
        show={showModal}
        event={event}
        onHide={hideModal}
        onUpdate={handleLanguageUpdate}
      />

      <a href="#language" onClick={handleClick}>
        <span className="editable">{language || 'N/A'}</span>
        &nbsp;
        <Icon name="pencil-alt" fw />
      </a>
    </div>
  );
};

LanguageInplaceInput.propTypes = {
  event: eventShape.isRequired,
};

LanguageInplaceInput.defaultProps = {};

export default LanguageInplaceInput;
