import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import moment from 'moment';
import Money from '../../utils/money';

const chartHeight = 600;
const chartControlsHeight = 140;
const serializeRubyMoney = (rubyMoney) => {
  const money = Money(rubyMoney);

  return {
    v: money.toUnit(),
    f: money.toFormat(),
  };
};
const normalizeRows = (series, { factor = 1 }) => (
  series.map((dateValues) => (
    dateValues.map((value, index) => {
      // The first value is the Date
      if (index === 0) {
        return moment(value).toDate();
      }

      const factorizedValue = {
        ...value,
        cents: value.cents * factor,
      };
      return serializeRubyMoney(factorizedValue);
    })
  ))
);
const buildColumns = (legend) => {
  const columns = [
    { type: 'date', label: 'Date' },
    { type: 'number', label: 'Total' },
  ];

  Object.keys(legend).forEach((key) => {
    const item = legend[key];

    columns[item.position] = {
      type: 'number',
      label: item.name,
    };
  });


  return columns;
};
const buildSeriesOptions = (legend) => (
  Object.keys(legend).reduce((accumulator, key) => {
    const item = legend[key];

    accumulator[item.position - 1] = {
      color: item.color,
    };

    return accumulator;
  }, {})
);

class LiquidityAccountChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();

    const {
      legend,
      series,
      sinceDaysAgo,
      factor,
    } = props;
    const fromDate = moment().subtract(sinceDaysAgo, 'day');

    this.state = {
      rows: normalizeRows(series, { factor }),
      columns: buildColumns(legend),
      series: buildSeriesOptions(legend),
      fromDate,
    };
  }

  render() {
    const {
      rows, columns, series, fromDate,
    } = this.state;
    const start = fromDate.toDate();
    const end = (rows[rows.length - 1] || [])[0];
    const wrapperStyle = {
      overflow: 'visible',
      marginBottom: chartControlsHeight,
    };

    return (
      <div className="LiquidityAccountChart" style={wrapperStyle}>
        <Chart
          ref={this.chartRef}
          chartType="SteppedAreaChart"
          width="100%"
          height={chartHeight}
          rows={rows}
          columns={columns}
          loader={<div style={{ textAlign: 'center', padding: 100 }}>Loading account liquidity chart...</div>}
          options={{
            title: 'Liquidity on account',
            // backgroundColor: '#f8f9fa',
            backgroundColor: 'transparent',
            // hAxis: {
            //   title: 'Time',
            //   titleTextStyle: { color: '#333' },
            //   continuous: true,
            // },
            vAxis: {
              title: 'Liquidity',
              minValue: 0,
            },
            isStacked: false,
            series,
            chartArea: { height: '80%', width: '65%' },
          }}
          chartPackages={['corechart', 'controls']}
          controls={[
            {
              controlType: 'ChartRangeFilter',
              options: {
                filterColumnIndex: 0,
                ui: {
                  chartOptions: {
                    backgroundColor: 'transparent',
                    chartArea: { width: '65%', height: '50%' },
                    hAxis: { baselineColor: 'none' },
                  },
                },
              },
              controlPosition: 'bottom',
              controlWrapperParams: {
                state: {
                  range: { start, end },
                },
              },
            },
          ]}
        />
      </div>
    );
  }
}

LiquidityAccountChart.propTypes = {
  sinceDaysAgo: PropTypes.number,
  factor: PropTypes.number,
  legend: PropTypes.object.isRequired,
  series: PropTypes.array.isRequired,
};

LiquidityAccountChart.defaultProps = {
  sinceDaysAgo: 365,
  factor: 1,
};

export default LiquidityAccountChart;
