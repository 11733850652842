import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Icon = ({
  name, fw, size, title, family,
  spin, className,
}) => {
  const classNames = [`fa-${size}`, className];
  // let classNames = `fas fa-${name} fa-${size}`;

  if (name.split(' ').length >= 2) {
    classNames.push(name)
  } else {
    classNames.push(`${family} fa-${name} fa-${size}`);
  }

  if (fw) { classNames.push('fa-fw'); }

  return (
    <i
      className={classnames(classNames, 'Icon', { 'fa-spin': spin })}
      title={title}
    />
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  spin: PropTypes.bool,
  fw: PropTypes.bool,
  title: PropTypes.string,
  size: PropTypes.string,
  family: PropTypes.string,
};

Icon.defaultProps = {
  spin: false,
  fw: false,
  title: '',
  size: '1x',
  family: 'fas',
};

export default Icon;
