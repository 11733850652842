import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import classnames from 'classnames';

import Step from './Step';
import AddButton from './AddButton';
import Icon from '../../Icon';
import styles from './styles.module.scss';

const StepsList = ({ onClick, onAdd, onDelete, steps, readOnly, layout }) => {
  return (
    <>
      {
        !readOnly && steps.length > 0 && (
          <AddButton
            onClick={onAdd}
            position={0}
          />
        )
      }
      {
        steps.map((step, index) => (
          <React.Fragment key={step.id}>
            <Step
              key={step.id}
              step={step}
              position={index}
              onClick={onClick}
              onDelete={onDelete}
              layout={layout}
              readOnly={readOnly}
            />

            {
              !readOnly && (
                <AddButton
                  onClick={onAdd}
                  position={index + 1}
                />
              )
            }
          </React.Fragment>
        ))
      }
    </>
  );
};

StepsList.propTypes = {
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
  readOnly: PropTypes.bool,
  stepsFootprint: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  layout: PropTypes.string,
};

StepsList.defaultPros = {
  onClick: () => {},
  onDelete: () => {},
  onAdd: () => {},
  readOnly: false,
  layout: 'vertical'
};

export default StepsList;
