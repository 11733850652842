import '../stylesheets/application.scss';

import '@coreui/coreui';
import 'bootstrap/dist/js/bootstrap';
import 'moment-timezone';
import 'chartkick/chart.js';
import 'resize-observer-polyfill';

import Inputmask from 'inputmask';
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import jQuery from 'jquery';
import moment from 'moment';
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

import Money from '../utils/money';
import * as DatePickerHelper from '../utils/date-picker';
import * as DateTimePickerHelper from '../utils/datetime-picker';

// TODO: find a better way to handle behaviors lifecycle
import * as Toggable from '../behaviors/toggable-class';
import * as ExpectationForm from '../behaviors/expectation-form';
import markerIo from '../behaviors/marker';

/// Setup Stimulus ///////
const application = Application.start();
const context = require.context('../controllers', true, /\.js$/);
application.load(definitionsFromContext(context));
window.Stimulus = application;
global.Stimulus = application;
/// End Stimulus setup ///

global.Money = Money;
global.moment = moment;
global.$ = global.jQuery = jQuery;

Rails.start();
Turbolinks.start();

// import ReactContentRenderer from './components';

// import Chat from './components/Chat';

// ReactContentRenderer.registerComponent('chat', Chat)
// ReactContentRenderer.loadAll()

// mount(Chat)
// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);

function initCurrencyMask() {
  Inputmask().mask(document.querySelectorAll('input'));
}

// $(document).ready(initCurrencyMask)
document.addEventListener('turbolinks:load', () => {
  jQuery('.toast').toast('show');
  jQuery(document).on('hidden.bs.toast', '.toast', (e) => {
    jQuery(e.target).remove();
  });

  jQuery('[data-toggle="tooltip"]').tooltip();
  initCurrencyMask();
  DatePickerHelper.initAll();
  DateTimePickerHelper.initAll();
  Toggable.initialize();
  ExpectationForm.initialize();
  markerIo.initialize();
});

document.addEventListener('turbolinks:request-start', async () => {
  jQuery('[data-toggle="tooltip"]').tooltip('dispose');
  DatePickerHelper.destroyAll();
  DateTimePickerHelper.destroyAll();
  Toggable.destroy();
  ExpectationForm.destroy();
  markerIo.destroy();
});
