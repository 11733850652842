import React, { useContext } from 'react';
import classnames from 'classnames';

import Spinner from '../../Spinner';
import Context from './Context';

import ListingTable from '../../ListingTable';
import Row from './CalendarRow';
import SyncCalendarsButton from './SyncCalendarsButton';

const filterKeys = ['account_name', 'provider'];
const headers = [
  'Name', 'Description', 'Time zone', 'Primary', 'Events priority', 'Updated at', 'Actions',
];
const sortableColumns = {
  Name: 'label',
  'Time zone': 'time_zone',
  Primary: 'primary',
  Description: 'description',
  'Updated at': 'sync_completed_at',
  'Events priority': 'events_default_priority',
  Actions: 'enabled',
};

const headerColsProps = [
  {},
  {},
  {},
  {},
  {},
  {},
  { className: 'text-center' },
];

const CalendarsDatatable = () => {
  const {
    loadingCalendars,
    calendars,
    enableCalendar,
    disableCalendar,
    isSynchronizingCalendars,
  } = useContext(Context);

  if (loadingCalendars) {
    return (
      <div className={classnames('Datatable', 'card')}>
        <div className="card-body">
          <Spinner description="Loading account calendars..." />
        </div>
      </div>
    );
  }

  const onEnable = (item) => (enableCalendar(item));
  const onDisable = (item) => (disableCalendar(item));

  return (
    <div className={classnames('CalendarsDatatable', 'card')}>
      <div className="card-body">
        <h3>
          <SyncCalendarsButton className="float-right" />

          Calendars:
        </h3>

        <hr />

        <ListingTable
          items={calendars}
          filterKeys={filterKeys}
          headers={headers}
          sortableColumns={sortableColumns}
          headerRowProps={{ className: 'text-nowrap' }}
          components={{ Row }}
          headerColsProps={headerColsProps}
          rowProps={{ onEnable, onDisable, isSynchronizingCalendars }}
          hideSearch
        />
      </div>
    </div>
  );
};

export default CalendarsDatatable;
