import React from 'react';
import _ from 'lodash';

// import EventBus from '../../../packs/event_bus';
// import { get } from '../../../api/base';
import sleep from '../../../utils/sleep';

const decoratePlannedEvent = (plannedEvent) => ({
  ...plannedEvent,
});

const decoratePlannedEvents = (plannedEvents) => plannedEvents.map(decoratePlannedEvent);

const WithPlannedEvents = (WrappedComponent) => {
  class HOC extends React.Component {
    constructor(props) {
      super(props);

      const { plannedEvents } = props;

      this.state = {
        plannedEvents,
        loading: true,
      };
    }

    componentDidMount() {
      sleep(200).then(() => { this.setState({ loading: false }); });

      //   this.plannedEventEventsSubscriptionId = EventBus.subscribe(
      //     [
      //       'FinancialAnalysis::Classification::OperationRegistered',
      //       'FinancialAnalysis::Classification::OperationUpdated',
      //     ],
      //     debounce(
      //       this.handlePlannedEventsEventsSubscription,
      //       500,
      //       { leading: true }
      //     ),
      //     this,
      //   );
      // this.reloadPlannedEvents();
    }

    // componentWillUnmount() {
    //   EventBus.unsubscribe(this.plannedEventsEventsSubscriptionId);
    // }

    handleOnDelete({ plannedEventId }) {
      const { plannedEvents } = this.state;
      const filteredPlannedEvents = plannedEvents.filter((pe) => (
        pe.id !== plannedEventId
      ));

      this.setPlannedEvents(filteredPlannedEvents);
    }

    handleOnUpdate({ plannedEvent }) {
      const { plannedEvents } = this.state;
      const { id } = plannedEvent;
      const updatedPlannedEvents = plannedEvents.map((pe) => (
        pe.id === id ? plannedEvent : pe
      ));

      this.setPlannedEvents(updatedPlannedEvents);
    }

    // handlePlannedEventsEventsSubscription() {
    //   this.reloadPlannedEvents(100);
    // }

    // reloadPlannedEvents(wait = 0) {
    //   const { endpoint } = this.props;

    //   return sleep(wait).then(() => {
    //     get(endpoint)
    //       .then((response) => (response.json()))
    //       .then(({ data }) => { this.setPlannedEvents(data); });
    //   });
    // }

    setPlannedEvents(plainPlannedEvents) {
      const plannedEvents = decoratePlannedEvents(plainPlannedEvents);

      this.setState({
        loading: false,
        plannedEvents,
      });
    }

    render() {
      const {
        plannedEvents,
        loading,
      } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          loading={loading}
          plannedEvents={plannedEvents}
          onDelete={(args) => { this.handleOnDelete(args); }}
          onUpdate={(args) => { this.handleOnUpdate(args); }}
        />
      );
    }
  }

  return HOC;
};

export default WithPlannedEvents;
