import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import classnames from 'classnames';

import Form from '../Recipes/Form';
import Icon from '../../Icon';

const formId = 'RecipeForm';

const NewRecipeModal = ({
  show,
  onHide,
  onCreate,
  size,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const close = () => {
    setIsLoading(false);
    onHide();
  };
  const handleCreate = (params) => {
    if (isLoading) { return; }
    setIsLoading(true);

    onCreate(params).then(() => {
      setIsLoading(false);
      close();
    });
  };

  const CreateButton = () => (
    <Button
      type="submit"
      variant="success"
      form={formId}
      disabled={isLoading}
    >
      <Icon name="check" fw />
      &nbsp;
      { isLoading ? 'Loading...' : 'Create recipe' }
    </Button>
  );

  // const LoadingButton = (
  //   <Button variant="success" disabled>
  //     <Icon name="spinner" fw spin />
  //     &nbsp;
  //     Loading ...
  //   </Button>
  // );

  return (
    <Modal show={show} onHide={onHide} size={size}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Icon name="shapes" fw />
          &nbsp;
          New recipe
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          formId={formId}
          onSubmit={handleCreate}
          showSubmit={false}
        />
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={onHide}>
          <Icon name="times" fw />
          &nbsp;
          Close
        </Button>

        <CreateButton />
      </Modal.Footer>
    </Modal>
  );
};

NewRecipeModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  size: PropTypes.string,
};

NewRecipeModal.defaultProps = {
  show: false,
  onCreate: () => {},
  size: 'lg',
};

export default NewRecipeModal;
