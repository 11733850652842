import React, { useState } from 'react';

import Icon from '../../Icon';
import BlankState from '../../BlankState';
import { success } from '../../../utils/notifier';
import { visit } from '../../../utils/router';
import sleep from '../../../utils/sleep';
import ConfirmModal from './ConfirmModal';
import { eventShape } from '../utils';

const UnconfirmedMissingCertification = ({ event }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const hideConfirmModal = () => { setShowConfirmModal(false); };
  const { acl, details_path } = event;
  const { canConfirm } = acl;
  const { headline } = event;
  const description = `Do you want to confirm for the "${headline}" course?`;
  const buttonLabel = (
    <span>
      <Icon name="check" fw />
      &nbsp;
      Confirm
    </span>
  );

  const handleConfirmButtonClick = () => {
    setShowConfirmModal(true);
  };

  const handleConfirmConfirmation = ({ response }) => {
    setIsLoading(true);

    sleep(1000).then(() => {
      visit(details_path)
        .then(() => { success({ message: response.message }); });
    });
  };

  return (
    <div>
      <ConfirmModal
        show={showConfirmModal}
        event={event}
        onHide={hideConfirmModal}
        onConfirm={handleConfirmConfirmation}
      />

      <BlankState
        title="The planned event has not yet been confirmed"
        description={description}
        buttonLabel={buttonLabel}
        onClick={handleConfirmButtonClick}
        icon="calendar-alt"
        showButton={canConfirm}
        isLoading={isLoading}
        buttonProps={{
          variant: 'success',
        }}
      />
    </div>
  );
};

UnconfirmedMissingCertification.propTypes = {
  event: eventShape.isRequired,
};

UnconfirmedMissingCertification.defaultProps = {
};

export default UnconfirmedMissingCertification;
