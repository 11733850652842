import { get, fetchPaginatedResources } from '../../base';

export const paginatedOperations = ({ page, typology, ...options }) => (
  get(`/financial_analysis/operations/${typology}s.json?page=${page || 1}&per_page=${options.perPage}`)
);

export const operations = ({ typology, ...options }) => {
  const fetchApi = (args = {}) => (paginatedOperations({
    ...args,
    perPage: 200,
    typology,
  }));

  return fetchPaginatedResources({ ...options, fetchApi });
};

export default {
  paginatedOperations,
  operations,
};
