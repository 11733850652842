import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { startOfDay, differenceInDays, addDays, format } from 'date-fns';

import Money from '../../../../utils/money';

import RevenuesChart from './RevenuesChart';
import TicketsChart from './TicketsChart';
import LiquidityForecastChart from '../../../LiquidityForecastChart';

const formatCents = (amount, positiveClass = "text-dark", negativeClass = "text-danger") => {
  let result
  const formatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
    //trailingZeroDisplay: 'stripIfInteger',  //  This is probably what most people want.
                                              //  It will only stop printing the fraction when the input amount is a round number (int) already.
                                              //  If that's not what you need, have a look at the options below.
    //minimumFractionDigits: 0,               //  This suffices for whole numbers, but will print 2500.10 as $2,500.1
    maximumFractionDigits: 0,                 //  Causes 2500.99 to be printed as $2,501
  });
  if (amount != null) {
    const textStyle = (amount >= 0) ? positiveClass : negativeClass
    result = <p className={'text-right ' + textStyle}>{formatter.format(amount / 100)}</p>
  } else {
    result = <p className={'text-right'}>{"---"}</p>
  }

  return result
}

// const ForecastCharts = ({ tickets, costs, expectations, event, salesForecast }) => {
const ForecastCharts = ({ salesForecast }) => {
  if (!salesForecast?.forecast || salesForecast.enabled === false) {
    return (
      <div className="bg-light p-5 text-center">
        <h5>
          Disabled training forecast
        </h5>
      </div>
    )
  }

  console.log("ForecastCharts - salesForecast", salesForecast)

  // //  These constants have been duplicated also in `app/javascript/components/trainings/planned_events/ForecastPage/RevenuesChart.jsx`, as I have been lazy (Giulio Cesare)
  // const FORECAST_HEADLINE_PREFIX          = "👌"
  // const SOLDOUT_FORECAST_HEADLINE_PREFIX  = "🚀"
  // const TRAINER_COSTS_PREFIX              = "[TRAINER]"
  // // console.log("Tickets", tickets)
  // console.log("Expectations", expectations)

  // // REVENUE
  // const forecast_revenues = expectations.filter(e => e._sign === "in").filter(e => e.headline.startsWith(FORECAST_HEADLINE_PREFIX))
  // const soldout_revenues  = expectations.filter(e => e._sign === "in").filter(e => e.headline.startsWith(SOLDOUT_FORECAST_HEADLINE_PREFIX))

  // const revenue_actual_cents   = tickets.reduce((a, t) => a + t.base_price.cents, 0)
  // const revenue_forecast_cents = forecast_revenues.reduce((a, e) => a + e.in_amount.cents, revenue_actual_cents) 
  // const revenue_soldout_cents  = soldout_revenues.reduce((a, e) => a + e.in_amount.cents, revenue_forecast_cents) 

  // //  COSTS
  // const planned_costs   = expectations.filter(e => e._sign === "out").filter(e => !e.headline.startsWith(FORECAST_HEADLINE_PREFIX)  && !e.headline.startsWith(SOLDOUT_FORECAST_HEADLINE_PREFIX) && !e.headline.includes(TRAINER_COSTS_PREFIX))
  // const expected_costs  = expectations.filter(e => e._sign === "out").filter(e =>  e.headline.startsWith(FORECAST_HEADLINE_PREFIX)                                                              && !e.headline.includes(TRAINER_COSTS_PREFIX))
  // const soldout_costs   = expectations.filter(e => e._sign === "out").filter(e =>                                                       e.headline.startsWith(SOLDOUT_FORECAST_HEADLINE_PREFIX) && !e.headline.includes(TRAINER_COSTS_PREFIX))

  // const costs_actual_cents   = costs         .reduce((a, c) => a + c.price,            0)
  //                            + planned_costs .reduce((a, e) => a + e.out_amount.cents, 0)
  // const costs_forecast_cents = expected_costs.reduce((a, e) => a + e.out_amount.cents, costs_actual_cents) 
  // const costs_soldout_cents  = soldout_costs .reduce((a, e) => a + e.out_amount.cents, costs_forecast_cents) 
  
  // //  TRAINER COSTS
  // const actual_trainer_consts   = expectations.filter(e => e._sign === "out").filter(e => e.headline.startsWith(TRAINER_COSTS_PREFIX))
  // const expected_trainer_costs  = expectations.filter(e => e._sign === "out").filter(e => e.headline.startsWith(FORECAST_HEADLINE_PREFIX)         && e.headline.includes(TRAINER_COSTS_PREFIX))
  // const soldout_trainer_costs   = expectations.filter(e => e._sign === "out").filter(e => e.headline.startsWith(SOLDOUT_FORECAST_HEADLINE_PREFIX) && e.headline.includes(TRAINER_COSTS_PREFIX))

  // const trainer_costs_actual_cents    = actual_trainer_consts .reduce((a, e) => a + e.out_amount.cents, 0) 
  // const trainer_costs_forecast_cents  = expected_trainer_costs.reduce((a, e) => a + e.out_amount.cents, trainer_costs_actual_cents) 
  // const trainer_costs_soldout_cents   = soldout_trainer_costs .reduce((a, e) => a + e.out_amount.cents, trainer_costs_forecast_cents) 



  const initialTab = 'revenues';
  // const initialTab = 'tickets';
  const [currentTab, setCurrentTab] = useState(initialTab);
  // const {
  //   initial_capacity,
  //   created_at,
  //   starts_on,
  //   ends_on,
  //   expected_ticket_sales,
  //   expected_average_gross_price,
  //   // expected_average_net_price,
  //   // expected_average_vat_percent,
  //   expected_expenses,
  // } = event;

  // console.log("EVENT", event)
  // const salesStartsOn = new Date(created_at);
  // const salesEndsOn = new Date(starts_on);
  // const soldTicketsAmount = tickets.reduce((amount, ticket) => {
  //   const { gross_price } = ticket;
  //   const price = Money(gross_price);

  //   return amount.add(price);
  // }, Money());

  // const expectationsValues = {
  //   soldTickets: soldTicketsAmount,
  // };

  // const DEFAULT_EASING_FUNCTION = 'linear'
  // const EASING_FUNCTIONS = {
  //   'linear':       function (x) { return (x) },
  //   'easeInSine':   function (x) { return (1 - Math.cos((x * Math.PI) / 2)) },         //  https://easings.net/#easeInSine
  //   'easeInQuart':  function (x) { return (x * x * x * x) },                           //  https://easings.net/#easeInQuart
  //   'easeInExpo':   function (x) { return (x === 0 ? 0 : Math.pow(2, 10 * x - 10)) },  //  https://easings.net/#easeInExpo
  // }

  // const expectations_distribution = (startsOn, endsOn, totalOccurrences, easingFunction) => {
  //   const delta = differenceInDays(endsOn, startsOn)
  //   var counter = 0
  //   var result = []

  //   for (let d = 0; d <= delta; d++) {
  //     const date = addDays(startsOn, d)
  //     const x = d / delta
  //     const y = easingFunction(x)

  //     const n = Math.round(y * totalOccurrences) - counter
  //     counter += n

  //     if (n > 0) {
  //       result.push({date: format(date, 'yyyy-MM-dd'), events: n})
  //     }
  //   }
  //   return result
  // }

  return (
    <div className="ForecastCharts">
      <Tab.Container id="revenues-charts" defaultActiveKey={initialTab} onSelect={ (key) => { setCurrentTab(key); }}>
        <div className="pb-3">
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link eventKey="revenues">Revenues Chart</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="tickets">Tickets Chart</Nav.Link>
            </Nav.Item>

            {/* <Nav.Item>
              <Nav.Link eventKey="default">Forecast Chart</Nav.Link>
            </Nav.Item> */}
          </Nav>
        </div>

        <Tab.Content>
          <Tab.Pane eventKey="revenues">
            <div className='container'>
              <div className='row'>
                <div className='col'>
                  { (salesForecast != null)
                      ? <RevenuesChart  salesForecast = {salesForecast} />
                      : null
                  }
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <table className="table">
                    <thead>
                      <tr className='text-center'>
                        <th scope="col"></th>
                        <th scope="col">actual</th>
                        <th scope="col">👌 forecast</th>
                        <th scope="col" className='text-secondary'>🚀 soldout</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row"  className='table-primary'>Revenues</th>
                        <td>{formatCents(salesForecast?.forecast.economics.actual_values.revenues.cents)}</td>
                        <td>{formatCents(salesForecast?.forecast.economics.forecast_values.revenues.cents)}</td>
                        <td>{formatCents(salesForecast?.forecast.economics.soldout_values.revenues.cents)}</td>
                      </tr>
                      <tr>
                        <th scope="row" className='table-danger'>Costs</th>
                        <td>{formatCents(salesForecast?.forecast.economics.actual_values.costs.cents, "bg-danger text-white")}</td>
                        <td>{formatCents(salesForecast?.forecast.economics.forecast_values.costs.cents)}</td>
                        <td>{formatCents(salesForecast?.forecast.economics.soldout_values.costs.cents)}</td>
                      </tr>
                      <tr>
                        <th scope="row" className='table-info'>Gross earnings</th>
                        <td>{formatCents(salesForecast?.forecast.economics.actual_values.gross_earnings.cents)}</td>
                        <td>{formatCents(salesForecast?.forecast.economics.forecast_values.gross_earnings.cents)}</td>
                        <td>{formatCents(salesForecast?.forecast.economics.soldout_values.gross_earnings.cents)}</td>
                      </tr>
                      <tr>
                        <th scope="row" className='table-warning'>Trainer fees</th>
                        <td>{formatCents(salesForecast?.forecast.economics.actual_values.trainer_fees.cents)}</td>
                        <td>{formatCents(salesForecast?.forecast.economics.forecast_values.trainer_fees.cents)}</td>
                        <td>{formatCents(salesForecast?.forecast.economics.soldout_values.trainer_fees.cents)}</td>
                      </tr>
                      <tr>
                        <th scope="row" className='table-success'>Net earnings</th>
                        <td>{formatCents(salesForecast?.forecast.economics.actual_values.net_earnings.cents)}</td>
                        <td>{formatCents(salesForecast?.forecast.economics.forecast_values.net_earnings.cents)}</td>
                        <td>{formatCents(salesForecast?.forecast.economics.soldout_values.net_earnings.cents)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Tab.Pane>

          <Tab.Pane eventKey="tickets">
            { (salesForecast != null)
                ? <TicketsChart salesForecast = {salesForecast} />
                : null
            }
          </Tab.Pane>

          {/* <Tab.Pane eventKey="default">
            <LiquidityForecastChart
              expectationsData={expectations}
              values={expectationsValues}
            />
          </Tab.Pane> */}
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default ForecastCharts;
