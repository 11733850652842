import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DateTime } from 'luxon';
import Button from 'react-bootstrap/Button';

import ExpectedExpensesUpdateModal from './ExpectedExpensesUpdateModal';
import { eventShape } from '../../utils';
import { reload } from '../../../../utils/router';
import { formatRubyMoney } from '../../../../utils/money';
import Icon from '../../../Icon';
import { success } from '../../../../utils/notifier';

const ExpectedExpensesEditButton = ({ 
  event, 
  className,
  countries,
  accounts,
  currencies,
}) => {
  const [showModal, setShowModal] = useState(false);
  const hideModal = () => { setShowModal(false); };
  const { expected_trainer_revenue_percentage, details_path } = event;
  const handleExpectedExpensesUpdate = ({ response }) => {
    reload().then(() => { success({ message: response.message }); });
  };
  const handleClick = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <>
      <ExpectedExpensesUpdateModal
        show={showModal}
        event={event}
        onHide={hideModal}
        onUpdate={handleExpectedExpensesUpdate}
        countries={countries}
        accounts={accounts}
        currencies={currencies}
      />

      <Button 
        size="sm" 
        variant="info" 
        className={className}
        onClick={handleClick}
      >
        <Icon name="pencil-alt" fw />
        &nbsp;
        Edit expenses
      </Button>
    </>
  );
};

ExpectedExpensesEditButton.propTypes = {
  event: eventShape.isRequired,
};

ExpectedExpensesEditButton.defaultProps = {};

export default ExpectedExpensesEditButton;
