import React from "react"
import jQuery from 'jquery'
import Select from 'react-select';
import EventBus from '../packs/event_bus';

// import 'react-select/dist/react-select.css';


class SelectEmailTemplate extends React.Component {

  state = {
    selectedOption: this.props.selectedIds,
    templates: []
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    //console.log(`Selected:`, selectedOption);
    jQuery(this.props.targetElements.subject).val(selectedOption.subject)
    //jQuery('.note-editor').parent().summernote('code', selectedOption.body)
    EventBus.publish("UI::EmailTemplateSelected", { body: selectedOption.body })
  }

  componentDidMount() {
    fetch(`/configurations/email_templates.json`, {credentials: 'same-origin'})
      .then(response => response.json())
      .then(json => this.setState({
        templates: json
      }))
  }

  render () {
    return <Select
        //name={`${this.props.formInputName}[]`}
        multi={false}
        valueKey="_id"
        labelKey="name"
        value={this.state.selectedOption}
        onChange={this.handleChange}
        options={this.state.templates}
      />
  }
}

export default SelectEmailTemplate
