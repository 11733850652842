import { castArray } from 'lodash';
import uuidv4 from 'uuid/v4';
import Consumer from '../channels/Consumer';

class EventBus {
  constructor() {
    this.handlers = [];

    this.connect();
  }

  connect() {
    Consumer.subscriptions.create({
      channel: 'EventBusChannel',
    }, {
      connected: () => {},
      received: (event) => {
        this.publish(event.event, event.data);
      },
    });
  }

  subscribe(event, handler, context, staticId) {
    const events = castArray(event);
    const subscriptionId = staticId || uuidv4();

    events.forEach((eventName) => {
      this.handlers.push({
        id: subscriptionId,
        event: eventName,
        handler: handler.bind(context || handler),
      });
    });

    return subscriptionId;
  }

  unsubscribe(id) {
    this.handlers = this.handlers.filter((handler) => handler.id !== id);
  }

  publish(event, data) {
    for (let i = 0; i < this.handlers.length; i += 1) {
      if (this.handlers[i].event === event) {
        this.handlers[i].handler.call(null, data);
      }
    }
  }
}

export default new EventBus();
