import React from 'react';
import { includes } from 'lodash';
import EditButton from './EditButton';

const untrackableOperations = ['credit_note', 'expense_credit_note'];

const TrackParentButton = ({ onClick, operationType }) => {
  if (includes(untrackableOperations, operationType)) {
    return '';
  }

  return (
    <EditButton
      theme="warning"
      icon="external-link-alt"
      title="Track parent operation"
      onClick={onClick}
    />
  );
};

export default TrackParentButton;
