import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import EventsCompactView from './EventsCompactView';
import EventsListView from './EventsListView';
import Spinner from '../Spinner';

import styles from './WeekViewCalendar.module.scss';

const DayCellContent = ({
  day,
  events,
  labels,
  isLoading,
  onEventFocus,
  viewMode,
  selected,
}) => {
  const elementClassNames = classnames(
    styles.cellWrapper,
    {
      'd-flex': isLoading,
      'align-items-center': isLoading,
      'justify-content-center': isLoading,
      [styles.cellContentLoading]: isLoading,
    },
  );

  if (isLoading) {
    return (
      <div className={elementClassNames}>
        <Spinner
          theme="secondary"
          compact
        />
      </div>
    );
  }

  return (
    <div className={elementClassNames}>
      <div className={styles.cellBody}>
        {
          viewMode === 'list' && (
            <EventsListView
              day={day}
              events={events}
              labels={labels}
              selected={selected}
              onEventFocus={onEventFocus}
            />
          )
        }
        {
          viewMode === 'compact' && (
            <EventsCompactView
              day={day}
              events={events}
              labels={labels}
              selected={selected}
              onEventFocus={onEventFocus}
            />
          )
        }
      </div>
    </div>
  );
};

DayCellContent.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool,
  onEventFocus: PropTypes.func,
  selected: PropTypes.bool,
  viewMode: PropTypes.string,
};

DayCellContent.defaultProps = {
  isLoading: false,
  onEventFocus: () => {},
  selected: false,
  viewMode: 'compact',
};

export default DayCellContent;
