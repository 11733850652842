import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { DateTime } from 'luxon';

import Icon from '../../Icon';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';
import { completePlannedEvent } from '../../../api/trainings/planned_events';

const CompleteModal = ({
  show,
  onHide,
  onComplete,
  event,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState('');
  const handleNotesUpdate = (browserEvent) => {
    const input = browserEvent.currentTarget;
    const { value } = input;

    setNotes(value);
  };

  const plannedEventId = event.id;
  const handleComplete = () => {
    if (isLoading) { return; }
    setIsLoading(true);

    completePlannedEvent({ plannedEventId, notes })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        success({ message: data.message });
        onHide();
        onComplete({ plannedEventId, response: data });
      })
      .catch(handleError);
  };

  const { headline, starts_on } = event;
  const startsOn = DateTime.fromISO(starts_on);
  const humanDate = startsOn.toFormat('dd LLL yyyy');

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Complete public training event</strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h4 className="mb-3">
          Do you really want to mark the public training event
          &nbsp;
          <strong>
            &quot;
            {headline}
            &quot;
          </strong>
          &nbsp;
          started on
          &nbsp;
          {humanDate}
          as completed?
        </h4>

        <Form>
          <Form.Group controlId="completeForm.notes">
            <Form.Label>Notes about the completed event:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Completed event notes..."
              value={notes}
              onChange={handleNotesUpdate}
            />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="success" onClick={handleComplete} disabled={isLoading}>
          <Icon name="check" fw />
          { isLoading ? 'Loading...' : 'Complete' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CompleteModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  event: PropTypes.shape({
    id: PropTypes.string,
    headline: PropTypes.string,
    starts_on: PropTypes.string,
  }).isRequired,
  onComplete: PropTypes.func,
};

CompleteModal.defaultProps = {
  show: false,
  onComplete: () => {},
};

export default CompleteModal;
