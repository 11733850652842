import React, {
  useState, createContext,
  useMemo,
  // useEffect,
} from 'react';
import PropTypes from 'prop-types';

// import EventBus from '../../../packs/event_bus';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';
import { setWorkingHours } from '../../../api/smart_planner/working_hours';

import {
  workingTimesToSegments,
  interval,
  weekDays,
  boundaryHour,
} from './utils';

const Context = createContext({});

const emptyWeeklyWorkingTimes = {
  mon: [],
  tue: [],
  wed: [],
  thu: [],
  fri: [],
  sat: [],
  sun: [],
};

// const events = [
//   'SmartPlanner::Accounts::GoogleAccoutAdded',
//   'SmartPlanner::Accounts::AccountCalendarsSet',
// ];

export const Provider = ({ children, ...props }) => {
  const {
    i18n, serializedWeeklyWorkingTimes, prefillDays,
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  /// // On mount
  /// useEffect(() => {
  ///   let mounted = true;
  ///   if (mounted) { loadUserAccounts(); }
  ///   return () => { mounted = false; };
  /// }, []);

  /// // Event BUS subscription
  /// useEffect(() => {
  ///   const handleEvent = () => { loadUserAccounts(); };
  ///   const eventBusSubscription = EventBus.subscribe(
  ///     events,
  ///     debounce(handleEvent, 500, { leading: true }),
  ///     this,
  ///   );
  ///   return () => { EventBus.unsubscribe(eventBusSubscription); };
  /// });

  const updateWeeklySchedule = (params) => {
    if (isLoading) { return; }

    setIsLoading(true);

    setWorkingHours(params)
      .then((response) => response.json())
      .then((response) => {
        success({ message: response.message });
        setIsLoading(false);
      })
      .catch(handleError);
  };

  const initialWeeklySegments = weekDays.reduce((data, day) => ({
    ...data,
    [day]: workingTimesToSegments(serializedWeeklyWorkingTimes[day], {
      dayName: day,
      currentInterval: interval,
      currentBoundaryHour: boundaryHour,
    }),
  }), {});

  const state = {
    isLoading,
    i18n,
    updateWeeklySchedule,
    initialPrefillDays: prefillDays,
    initialWeeklySegments,
  };

  // The shared state
  const value = useMemo(
    () => (state),
    [isLoading],
  );

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  i18n: PropTypes.shape({}).isRequired,
  serializedWeeklyWorkingTimes: PropTypes.shape({}),
  prefillDays: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Provider.defaultProps = {
  prefillDays: 90,
  serializedWeeklyWorkingTimes: emptyWeeklyWorkingTimes,
};

export default Context;
