import { withFormat } from '../utils';

const basePath = '/trainings/planned_events';

export const indexPath = (typology) => (
  typology === 'open_event'
    ? '/trainings/planned_events/training-courses'
    : '/company-events/public-events'
);

export const newPath = () => (`${basePath}/new`);

export const showPath = (id, options = {}) => (withFormat(`${basePath}/${id}`, options));

export const editPath = (id, options = {}) => (withFormat(`${basePath}/${id}/edit`, options));

export const deletePath = showPath;

export const ordersPath = (id, options = {}) => (withFormat(`${basePath}/${id}/orders`, options));

export const ticketsPath = (id, options = {}) => (withFormat(`${basePath}/${id}/tickets`, options));

export const attendeesPath = (id, options = {}) => (withFormat(`${basePath}/${id}/attendees`, options));

export default {
  indexPath,
  newPath,
  showPath,
  editPath,
  deletePath,
  ordersPath,
  ticketsPath,
  attendeesPath,
};
