import React from 'react';

import Icon from '../../Icon';
import { formatIsoDateTime } from '../../../utils/datetime';

const SlotRow = ({ slot }) => {
  const {
    duration,
    start_at,
    end_at,
    date,
  } = slot;

  return (
    <tr className="row">
      <td className="col py-2">
        <span className="d-inline-block">
          <Icon name="clock" family="far" fw />
          <strong>&nbsp;{duration / 3600.0} hours</strong>
        </span>
      </td>

      <td className="col py-2">
        <Icon name="calendar-alt" family="far" fw />
        <span>&nbsp;{formatIsoDateTime(start_at, { format: 'default' })}</span>
      </td>

      <td className="col py-2">
        <Icon name="calendar-alt" family="far" fw />
        <span>&nbsp;{formatIsoDateTime(end_at, { format: 'default' })}</span>
      </td>
    </tr>
  );
};

const ResultsDatatable = ({ slots }) => (
  <table className="table mb-0 SearchMultipleAvailableSlotsPage__results-datatable">
    <thead>
      <tr className="row">
        <th className="col">Duration</th>
        <th className="col">Start at</th>
        <th className="col">End at</th>
      </tr>
    </thead>
    <tbody>
      {
        slots.map((slot) => (
          <SlotRow key={slot.id} slot={slot} />
        ))
      }
    </tbody>
  </table>
);

ResultsDatatable.propTypes = {
};

export default ResultsDatatable;
