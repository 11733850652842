import React from 'react';
// import PropTypes from 'prop-types';

import { Provider } from './Context';
import Datatable from './Datatable';

const Index = (props) => (
  <Provider {...props}>
    <div className="RecipesPage">
      <Datatable />
    </div>
  </Provider>
);

Index.propTypes = {};

export default Index;
