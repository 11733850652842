import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';

import { findOffsetOption } from '../utils';
import { footprintStepShape } from '../proptypes';
import OffsetStepForm from './OffsetStepForm';
import Icon from '../../Icon';

const formId = 'new-step-form';

const EditOffsetStepModal = ({
  show,
  onHide,
  size,
  variant,
  onUpdate,
  step,
}) => {
  // const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = (params) => {
    // setIsLoading(true);
    onUpdate(params);
  };

  const enhancedOnHide = () => {
    // setIsLoading(false);
    onHide();
  };


  const defaultValues = step;

  return (
    <Modal show={show} onHide={enhancedOnHide} size={size}>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong className={classnames({ 'text-danger': variant === 'danger' })}>
            Edit offset step
          </strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <OffsetStepForm
          formId={formId}
          onSubmit={handleSubmit}
          defaultValues={defaultValues}
        />
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={enhancedOnHide}>
          <Icon name="times" fw />
          &nbsp;
          Close
        </Button>

        <Button
          type="submit"
          variant="success"
          form={formId}
          disabled={false}
        >
          <Icon name="check" fw />
          &nbsp;
          Save step
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

EditOffsetStepModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  variant: PropTypes.string,
  size: PropTypes.string,
  step: footprintStepShape,
};

EditOffsetStepModal.defaultProps = {
  show: false,
  variant: 'primary',
  size: 'lg',
  step: {},
};

export default EditOffsetStepModal;
