import React from 'react';
import PropTypes from 'prop-types';
import CategoryColumn from './CategoryColumn';

const CategoryColumns = ({
  id,
  operation,
  categories,
  categoryLabels,
  operationHeadline,
  onCategoryLabelChange,
  onCategoryLabelChanged,
}) => (
  <>
    {
      categories.map((category) => (
        <CategoryColumn
          operationId={operation.id}
          operation={operation}
          key={category.category_id}
          category={category}
          categoryLabels={categoryLabels}
          operationHeadline={operationHeadline}
          onCategoryLabelChange={onCategoryLabelChange}
          onCategoryLabelChanged={onCategoryLabelChanged}
        />
      ))
    }
  </>
);

CategoryColumns.propTypes = {
  id: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  categoryLabels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  operationHeadline: PropTypes.string.isRequired,
  onCategoryLabelChange: PropTypes.func.isRequired,
  onCategoryLabelChanged: PropTypes.func.isRequired,
};

export default CategoryColumns;
