import React from 'react';
import Badge from 'react-bootstrap/Badge';

import { editPath, indexPath } from '../routes/authentication/users';
import { deleteUser } from '../api/authentication/users';
import Icon from './Icon';
import ConfirmModalButton from './ConfirmModalButton';
import handleError from '../utils/error-handler';
import { success } from '../utils/notifier';
import { visit } from '../utils/router';

const UserRow = ({
  _id,
  first_name,
  last_name,
  email,
  roles,
}) => {
  const handleDelete = () => {
    deleteUser(_id)
      .then((response) => (response.json()))
      .then((data) => {
        visit(indexPath()).then(() => {
          success({ message: data.message });
        });
      })
      .catch(handleError);
  };

  return (
    <tr key={_id}>
      <td style={{ width: '20%' }}>
        <strong>
          {first_name}
          &nbsp;
          {last_name}
        </strong>
      </td>

      <td>
        <a href={editPath(_id)}>
          <big>{email}</big>
        </a>
      </td>

      <td>
        {
          (roles || []).length === 0
            ? <Badge variant="success">ADMIN</Badge>
            : (roles || []).map((role) => (<Badge className="mr-2 text-uppercase" key={role} variant="primary">{role}</Badge>))
        }
      </td>

      <td className="table-actions">
        <div className="btn-group">
          <a href={editPath(_id)} className="btn btn-light">
            Edit
            &nbsp;
            <Icon name="edit" family="far" fw />
          </a>

          <ConfirmModalButton
            onConfirm={handleDelete}
            buttonCaption={<span>Archive <Icon name="trash" fw /></span>}
            variant="danger"
            title={`Archive user ${email}`}
            description={`Are you sure you want to archive the user ${email}?`}
            size="md"
          />
        </div>
      </td>
    </tr>
  );
};

export default UserRow;
