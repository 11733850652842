import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Dropdown from 'react-bootstrap/Dropdown';

import SyncButton from './SyncButton';
import CancelButton from './CancelButton';
import RetireButton from './RetireButton';
import ConfirmButton from './ConfirmButton';
import CompleteButton from './CompleteButton';
import OpenRegistrationsButton from './OpenRegistrationsButton';
import CloseRegistrationsButton from './CloseRegistrationsButton';
import ConvertIntoOpenEventButton from './ConvertIntoOpenEventButton';
import ConvertIntoTrainingCourseButton from './ConvertIntoTrainingCourseButton';
import { eventShape } from '../utils';
import Icon from '../../Icon';
import { ordersPath, ticketsPath, attendeesPath } from '../../../routes/trainings/planned_events';

const EventToolbar = ({ event, size }) => {
  const { acl } = event;
  const { canConvertIntoOpenEvent, canConvertIntoTrainingCourse } = acl;

  return (
    <div className={classnames('event-toolbar')}>
      <SyncButton
        size={size}
        event={event}
        className="ml-1"
      />

      <OpenRegistrationsButton
        size={size}
        event={event}
        className="ml-1"
      />

      <CloseRegistrationsButton
        size={size}
        event={event}
        className="ml-1"
      />

      <ConfirmButton
        size={size}
        event={event}
        className="ml-1"
      />

      <RetireButton
        size={size}
        event={event}
        className="ml-1"
      />

      <CancelButton
        size={size}
        event={event}
        className="ml-1"
      />

      <CompleteButton
        size={size}
        event={event}
        className="ml-1"
      />

      <Dropdown className="d-inline-block ml-1">
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          Actions
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href={event.external_url} target="_blank">
            <Icon name="safari" family="fab" fw />
            Open landing page
          </Dropdown.Item>

          <Dropdown.Item href={ordersPath(event.id, { format: 'xlsx' })} target="_blank">
            <Icon name="file-excel" family="far" fw />
            Export orders
          </Dropdown.Item>

          <Dropdown.Item href={ticketsPath(event.id, { format: 'xlsx' })} target="_blank" className="d-none">
            <Icon name="ticket-alt" family="fas" fw />
            Export tickets
          </Dropdown.Item>

          <Dropdown.Item href={attendeesPath(event.id, { format: 'xlsx' })} target="_blank">
            <Icon name="user-graduate" family="fas" fw />
            Export attendees
          </Dropdown.Item>

          {
            canConvertIntoOpenEvent && (
              <ConvertIntoOpenEventButton
                ButtonComponent={Dropdown.Item}
                event={event}
              />
            )
          }

          {
            canConvertIntoTrainingCourse && (
              <ConvertIntoTrainingCourseButton
                ButtonComponent={Dropdown.Item}
                event={event}
              />
            )
          }
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

EventToolbar.propTypes = {
  size: PropTypes.string,
  event: eventShape.isRequired,
};

EventToolbar.defaultProps = {
  size: 'md',
};

export default EventToolbar;
