import React, { useState } from 'react';
import { Chart } from 'react-google-charts';
import { startOfDay, differenceInDays, addDays, parse, parseISO, isEqual, isPast, isBefore, addMinutes, subMinutes, format } from 'date-fns';

import CustomLegend from '../../../charts/CustomLegend';

const columns = [
  { type: 'date',   label: 'Date',                            },  //  0

  { type: 'number', label: 'Class capacity',       },  //  1
  { type: 'number', label: 'Sales forecast', },  //  2
  { type: 'number', label: 'Ticket sales [trend]',   },  //  3
  { type: 'number', label: 'Tickets sold',    },  //  4

  { type: 'number', lable: 'Past',           },
];


const series = [
  { color: '#6f42c1', type: 'line'},
  { color: '#6f42c1', type: 'line', lineDashStyle: [4, 4]},
  { color: '#cf92f1', type: 'line', lineWidth: 3},
  { color: '#6f42c1', type: 'steppedArea', lineWidth: 5},

  { color: '#ccc', type: 'steppedArea'},
]

const parseDate = (dateString) => parse(dateString, 'yyyy-MM-dd', new Date())

const TicketsChart = ({ salesForecast }) => {

  const [legendStates, setLegendStates] = useState(new Array(columns.length - 1).fill(true));
  const activeSeries = series.reduce((newSeries, serie, index) => {
    const isSerieActive = legendStates[index];

    if (isSerieActive) {
      newSeries.push(serie); 
    }

    return newSeries;
  }, []);

  const handleLegendClick = (index) => {
    setLegendStates((items) => {
      const newItems = [...items];
      newItems[index] = !newItems[index];

      return newItems;
    });
  };

  const legendEntries = columns.slice(1, 5).map(({ label }, index) => ({
    label,
    key: index,
    active: legendStates[index],
    color: series[index].color,
  }));

  const todayMaxValue =  Math.max(salesForecast?.forecast.tickets.class_capacity.at(-1).value, salesForecast?.forecast.tickets.tickets_sold.at(-1).value) * 1.2    // 20
  const utcStartOfDayDate = (date) => new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0))

  const fromDate = utcStartOfDayDate(parseISO(salesForecast?.assumptions.sales_start_on))
  const toDate   = utcStartOfDayDate(parseISO(salesForecast?.assumptions.sales_end_on))
  // const numberOfDays = differenceInDays(toDate, fromDate)
  const numberOfDays = salesForecast?.forecast.tickets.class_capacity.length

  const dailyDynamicData = [...Array(numberOfDays).keys()].map(d => {
    const date = addDays(fromDate, d)
    return [
      date,

      salesForecast?.forecast.tickets.class_capacity[d]?.value,
      salesForecast?.forecast.tickets.sales_forecast_curve[d]?.value,
      salesForecast?.forecast.tickets.ticket_sales_trend[d]?.value,
      salesForecast?.forecast.tickets.tickets_sold[d]?.value,

      (isPast(date)) ? todayMaxValue: 0,  //  today
    ]
  })

  const activeRows = dailyDynamicData.map((row) => {
    return row.reduce((newRow, value, index) => {
      if (index === 0) { 
        // Always return the date columns
        newRow.push(value); 

        return newRow;
      } 

      const isValueActive = legendStates[index - 1];

      if (isValueActive) {
        newRow.push(value); 
      }

      return newRow;
    }, []);
  });

  const activeColumns = columns.reduce((newColumns, column, index) => {
    if (index === 0) { 
      // Always return the date columns
      newColumns.push(column); 

      return newColumns;
    }

    const isColumnActive = legendStates[index - 1];

    if (isColumnActive) {
      newColumns.push(column); 
    }

    return newColumns;
  }, []);

  const options = {
    legend: 'none',
    vAxis: {
      title: 'Tickets',
    },
    // isStacked: false,
    series: activeSeries,
    backgroundColor: '#f8f9fa',
    seriesType: 'lines',
    chartArea: {
      width: '80%',
      height: '80%',
    },
  };

  return (
    <div className="TicketsChart">
      <Chart
        chartType="ComboChart"
        width='100%'
        height='600px'
        rows={activeRows}
        columns={activeColumns}
        options={options}
      />

      <CustomLegend
        items={legendEntries}
        onClick={(key) => { handleLegendClick(key); }}
        className="mt-3"
      />
    </div>
  );
};

// TicketsChart.defaultProps = {
//   width: '100%',
//   height: '600px',
// };

export default TicketsChart;
