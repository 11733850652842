import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';

import MomentStepForm from './MomentStepForm';
import Icon from '../../Icon';

const formId = 'new-step-form';

const AddMomentStepModal = ({
  show,
  onHide,
  size,
  variant,
  onCreate,
}) => {
  // const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = (params) => {
    // setIsLoading(true);
    onCreate(params);
  };

  const enhancedOnHide = () => {
    // setIsLoading(false);
    onHide();
  };

  return (
    <Modal show={show} onHide={enhancedOnHide} size={size}>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong className={classnames({ 'text-danger': variant === 'danger' })}>
            Add new moment step
          </strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <MomentStepForm
          formId={formId}
          onSubmit={handleSubmit}
        />
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={enhancedOnHide}>
          <Icon name="times" fw />
          &nbsp;
          Close
        </Button>

        <Button
          type="submit"
          variant="success"
          form={formId}
          disabled={false}
        >
          <Icon name="check" fw />
          &nbsp;
          Save moment
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddMomentStepModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  variant: PropTypes.string,
  size: PropTypes.string,
};

AddMomentStepModal.defaultProps = {
  show: false,
  variant: 'primary',
  size: 'lg',
};

export default AddMomentStepModal;
