import React from 'react';

import Icon from '../../Icon';
import { calendarEventAttendeeShape } from '../proptypes';

const CalendarEventAttendeeIcon = ({ responseStatus }) => {
  switch (responseStatus) {
    case 'accepted':
      return <Icon name="check" className="text-success" title={responseStatus} fw />;

    case 'declined':
      return <Icon name="times" className="text-danger" title={responseStatus} fw />;

    case 'tentative':
      return <Icon name="user-clock" className="text-warning" title={responseStatus} fw />;

    default:
      return <Icon name="question" title={responseStatus} fw />;
  }
};

const CalendarEventAttendee = ({ attendee }) => (
  <div className="my-1">
    <CalendarEventAttendeeIcon responseStatus={attendee.response_status} />
    &nbsp;
    {attendee.email}
  </div>
);

CalendarEventAttendee.propTypes = {
  attendee: calendarEventAttendeeShape,
};

CalendarEventAttendee.defaultProps = {
  attendee: {},
};

export default CalendarEventAttendee;
