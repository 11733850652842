/* (c) Andrii Ulianenko */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import * as d3 from 'd3';
import moment from 'moment';
import Segment from './Segment';
import TimeResults from './TimeResults';

import './timespanpicker.css';

const config = {
  labelsPAdding: 13,
  segmentsColorsArray: ['#bbb', '#ddd'],
  defaultInnerRadiusIndex: 1.4,
  defaultChartPadding: 60,
};

// Combine the neighbour short time spans in one union
// (e.g. '5:20-5:30' and '5:30-5:40' will be combined in a '5:20-5:40')
const getReducedArray = (state) => {
  const keysArr = Object.keys(state).filter((key) => key !== 'initialObject' && state[key]);

  if (keysArr.length) {
    if (keysArr.length === 1) {
      // if is single, returns it - no needs to combine
      return [state[keysArr[0]]];
    }

    // combine time spans
    const reducedArr = keysArr.reduce((prev, currentKey) => {
      const tempArr = Array.isArray(prev) ? prev : [state[prev]];
      const lastElement = tempArr[tempArr.length - 1];
      const currentElement = state[currentKey];

      if (!currentElement[0].diff(lastElement[1], 'minutes')) {
        // if last element finished in the same time current started,
        // combine them as ['start of the last', 'end of the current]
        tempArr[tempArr.length - 1] = [lastElement[0], currentElement[1]];
      } else {
        tempArr.push(currentElement);
      }

      return tempArr;
    });

    return reducedArr;
  }
  /* if there is no chosen spans in the state, returns empty array */
  return [];
};

// TODO: remove
const labelize = (result, args = {}) => {
  // console.log('LABEL:', result, args);
  return result;
};

// Define an hours labels. "showSingleBoundaryHour" set displaying of
// doubled boundary hours (e.g. '8|20', '16|4')
const getHoursLabels = (originalBoundary, index, showSingleBoundaryHour) => {
  // console.log('getHoursLabels', {
  //   originalBoundary, index, showSingleBoundaryHour
  // });

  const hour24 = index + 12;
  const hour12 = showSingleBoundaryHour
    ? index
    : index || '00';

  const isInBottomQuadrants = (index > 3 && index < 10);
  let boundary = originalBoundary;

  if (boundary > 12) {
    boundary += -12;

    if (index === boundary) {
      if (showSingleBoundaryHour) {
        return labelize(hour24, { res: 1 });
      }

      return labelize(
        isInBottomQuadrants ? `${hour24} | ${hour12}` : `${hour12} | ${hour24}`,
        { res: 2 },
      );
    }

    return labelize(index < boundary ? hour12 : hour24, { res: 3 });
  }

  if (index === boundary) {
    if (showSingleBoundaryHour) {
      return labelize(hour12, { res: 4 });
    }

    return labelize(
      isInBottomQuadrants ? `${hour12} | ${hour24}` : `${hour24} | ${hour12}`,
      { res: 5 },
    );
  }

  return labelize(index < boundary ? hour24 : hour12, {
    res: 6, index, boundary, hour12, hour24,
  });

  // return labelize(hour12, {
  //   index, boundary, hour12, hour24,
  // });
};

class CircularTimespanPicker extends Component {
  constructor(props) {
    super(props);
    const { initialSegments } = props;
    this.state = { ...initialSegments };
  }

  componentWillMount() {
    const {
      outerRadius,
      innerRadius,
      interval,
      boundaryHour,
      totalHours,
      onClick,
      showResults,
    } = this.props;

    const currentInnerRadius = (innerRadius && innerRadius < outerRadius)
      ? innerRadius
      : outerRadius / config.defaultInnerRadiusIndex;

    const width = outerRadius * 2 + config.defaultChartPadding;
    const segmentsInHour = 60 / interval;
    const totalMinutes = totalHours * 60;
    const totalNumberOfSegments = totalMinutes / interval;
    const boundaryIsPostMeridiem = boundaryHour > 12;

    const pie = d3.pie().sort(null).value(() => (1));
    const segmentsArray = pie(new Array(totalNumberOfSegments));
    const hoursLabelsArray = pie(new Array(totalHours));

    // console.log({ totalHours, totalNumberOfSegments, hoursLabelsArray })

    const colorScale = d3.scaleOrdinal().domain([0, 1, 2]).range(config.segmentsColorsArray);
    const segmentsArcFn = d3.arc()
      .outerRadius(outerRadius)
      .innerRadius(currentInnerRadius);
    const minutesArcFn = d3.arc()
      .outerRadius(outerRadius + config.labelsPAdding)
      .innerRadius(outerRadius + config.labelsPAdding)
      .startAngle((d) => d.startAngle + Math.PI / totalNumberOfSegments)
      .endAngle((d) => d.endAngle + Math.PI / totalNumberOfSegments);
    const hoursArcFn = d3.arc()
      .outerRadius(outerRadius + config.labelsPAdding)
      .innerRadius(outerRadius + config.labelsPAdding)
      .startAngle((d) => d.startAngle - 0.26)
      .endAngle((d) => d.endAngle - 0.26);

    const initialObject = {
      interval,
      boundaryHour,
      width,
      segmentsInHour,
      boundaryIsPostMeridiem,
      segmentsArcFn,
      minutesArcFn,
      hoursArcFn,
      segmentsArray,
      showResults,
      onClick,
      hoursLabelsArray,
      colorScale,
      innerRadius: currentInnerRadius,
      outerRadius,
      totalNumberOfSegments,
    };

    this.setState({ initialObject });
  }

  // On click on segment convert simple segment's value [startValue, endValue]
  // in moment.js object and save it in a state as "chosen"
  handleClick(clickedValue, isEntered) {
    const { initialObject } = this.state;
    // skip handling if click anf hover were started out of segments
    if (isEntered && !initialObject.mouseIsClickedDown) { return; }

    const clickedStartValue = clickedValue[0];
    const clickedFinishValue = clickedValue[1];
    const { initialObject: { boundaryHour, onClick }, ...segments } = this.state;
    const segmentPreviousValue = segments[clickedFinishValue];
    const segmentCurrentValue = {
      [String(clickedFinishValue)]: segmentPreviousValue
        ? null
        : [
          moment().set('hour', boundaryHour).set('minute', 0).minute(clickedStartValue),
          moment().set('hour', boundaryHour).set('minute', 0).minute(clickedFinishValue),
        ],
    };

    this.setState(segmentCurrentValue);
    onClick({ ...segments, ...segmentCurrentValue });
  }

  getBoundaryLinesRotationDegree() {
    const { initialObject } = this.state;
    const {
      boundaryHour,
      boundaryIsPostMeridiem,
      totalHours,
    } = initialObject;

    return 30 * (
      boundaryIsPostMeridiem
        ? boundaryHour - totalHours
        : boundaryHour
    );
    /* 1 hour = 360 / 12 = 30 degrees */
  }

  setSegmentsValue(originalIndex) {
    const { initialObject } = this.state;
    const {
      interval, boundaryHour, totalNumberOfSegments, segmentsInHour,
      boundaryIsPostMeridiem,
    } = initialObject;
    const index = boundaryIsPostMeridiem
      ? originalIndex + totalNumberOfSegments
      : originalIndex;
    const boundaryIndex = boundaryHour * segmentsInHour;
    const recalculatedIndex = index - boundaryIndex + (
      index < boundaryIndex ? totalNumberOfSegments : 0
    );
    const startMinutes = recalculatedIndex * interval;

    return [startMinutes, startMinutes + interval];
  }

  storeMouseIsClickedDown(mouseIsClickedDown) {
    const { initialObject } = this.state;

    this.setState({
      initialObject: { ...initialObject, mouseIsClickedDown },
    });
  }

  render() {
    const { initialObject } = this.state;

    if (!initialObject) { return null; }

    const {
      interval, boundaryHour, width, segmentsInHour,
      segmentsArcFn, minutesArcFn, hoursArcFn, segmentsArray,
      hoursLabelsArray, colorScale, outerRadius, innerRadius, showResults,
    } = initialObject;

    return (
      <div
        className="timepickerwrapper"
        onMouseDown={() => { this.storeMouseIsClickedDown(true); }}
        onMouseUp={() => { this.storeMouseIsClickedDown(false); }}
        onMouseLeave={() => { this.storeMouseIsClickedDown(false); }}
      >
        <svg width={width} height={width}>
          <g transform={`translate(${width / 2},${width / 2})`}>
            {segmentsArray.map((item, index) => (
              <Segment
                key={index}
                index={index}
                item={item}
                segmentArcFn={segmentsArcFn}
                minutesArcFn={minutesArcFn}
                label={((index % segmentsInHour) + 1) * interval}
                fill={colorScale((Math.floor(index / segmentsInHour)) % 2)}
                value={this.setSegmentsValue(index)}
                handleClick={(value, isEntered) => { this.handleClick(value, isEntered); }}
                isActive={this.state[this.setSegmentsValue(index)[1]]}
              />
            ))}
            <g className="hoursLabelsGroup">
              {
                hoursLabelsArray.map((item, index) => (
                  <text
                    key={index}
                    className={classnames('hourLabel', { boundary: index === boundaryHour })}
                    transform={`translate(${hoursArcFn.centroid(item)})`}
                    dy=".35em"
                    style={{ textAnchor: 'middle' }}
                  >
                    {getHoursLabels(boundaryHour, index, true)}
                  </text>
                ))
              }
            </g>
            <g className="boundaryGroup">
              <path
                className="boundaryLine"
                d={`M 0 -${innerRadius - 20} V -${outerRadius + 4}`}
                transform={`rotate(${this.getBoundaryLinesRotationDegree()})`}
              />
            </g>
          </g>
        </svg>
        {
          showResults
            ? <TimeResults results={getReducedArray(this.state)} />
            : null
        }
      </div>
    );
  }
}

CircularTimespanPicker.propTypes = {
  initialSegments: PropTypes.shape({}),
  totalHours: PropTypes.number,
  outerRadius: PropTypes.number,
  innerRadius: PropTypes.number,
  showResults: PropTypes.bool,
  onClick: PropTypes.func,
  interval: (props, propName, componentName) => {
    const interval = props[propName];

    if (!Number.isInteger(interval) || interval > 60 || 60 % interval) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. Validation failed.
        Expects integer equal or less than 60 and 60 is divisible by it`,
      );
    }
  },
  boundaryHour: (props, propName, componentName) => {
    const boundaryHour = props[propName];

    if (!Number.isInteger(boundaryHour) || boundaryHour > 24) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. Validation failed.
        Expects integer less than 24`,
      );
    }
  },
};

CircularTimespanPicker.defaultProps = {
  initialSegments: {},
  innerRadius: 50,
  outerRadius: 100,
  interval: 30,
  boundaryHour: 0,
  showResults: true,
  totalHours: 24,
  onClick: (value) => { console.log('Implement the onClick callback', value); },
};

export default CircularTimespanPicker;
