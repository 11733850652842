import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Spinner.module.scss';

const SpinnerIcon = ({ theme }) => (
  <div className={`spinner-border text-${theme}`} role="status">
    <span className="sr-only">Loading...</span>
  </div>
);

const SpinnerCaption = ({ title, description }) => (
  <div>
    { title ? <h3 className="mt-4 bt-2">{title}</h3> : null }
    { description ? <p className="mb-4">{description}</p> : null }
  </div>
);

const Spinner = ({
  title,
  description,
  className,
  compact,
  theme,
}) => (
  <div className={classnames('Spinner', styles.Spinner, className, { [styles.compact]: compact })}>
    <SpinnerIcon theme={theme} />

    {
      !compact && (
        <SpinnerCaption
          title={title}
          description={description}
        />
      )
    }
  </div>
);

Spinner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.string,
  compact: PropTypes.bool,
};

Spinner.defaultProps = {
  title: 'Loading...',
  description: '',
  className: null,
  compact: false,
  theme: 'primary',
};

export default Spinner;
