import React from 'react';
import PropTypes from 'prop-types';

import ConfirmModal from '../../ConfirmModal';
import { footprintStepShape } from '../proptypes';

const DeleteStepModal = ({
  step, show, onHide, onDelete,
}) => {
  const { id, description } = step;
  const deleteMessage = (
    <h5 className="my-3">
      Do you really want to remove the Step
      &nbsp;
      <strong>
        &quot;
        {description}
        &quot;
      </strong>
      ?
    </h5>
  );
  const handleDelete = () => (onDelete({ id }));

  return (
    <ConfirmModal
      variant="danger"
      title="Remove the selected Step"
      description={deleteMessage}
      show={show}
      onHide={onHide}
      onConfirm={handleDelete}
    />
  );
};

DeleteStepModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onDelete: PropTypes.func,
  step: footprintStepShape.isRequired,
};

DeleteStepModal.defaultProps = {
  show: false,
  onHide: () => {},
  onDelete: () => {},
};

export default DeleteStepModal;
