import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import { debounce } from 'lodash';
import classnames from 'classnames';

import { priorityMarks } from './utils';

import 'rc-slider/assets/index.css';

const PrioritySliderInput = ({ onChange, initialPriority, debounceTimer, className }) => {
  const handlePriorityChange = useMemo(() => debounce(onChange, debounceTimer), []);

  return (
    <div className={classnames('PrioritySliderInput', className)}>
      <Slider
        defaultValue={initialPriority}
        min={0}
        max={100}
        onChange={(value) => { handlePriorityChange(value); }}
        marks={priorityMarks}
      />
    </div>
  );
};

PrioritySliderInput.propTypes = {
  initialPriority: PropTypes.number,
  onChange: PropTypes.func,
  debounceTimer: PropTypes.number,
};

PrioritySliderInput.defaultProps = {
  debounceTimer: 50,
}

export default PrioritySliderInput;
