import React from 'react';
import { Chart } from 'react-google-charts';
import _ from 'lodash';
import { format } from 'date-fns';

import CustomLegend from './charts/CustomLegend';
import Money, { moneyToChartValue } from '../utils/money';
import sleep from '../utils/sleep';
import { buttonsCtas as ctas } from '../behaviors/toggable-class';

const date2Str = (date) => (format(date, 'yyyy-MM-dd'));
const atMidnight = (date) => (new Date(date2Str(date)));

const computeData = (expectationsData, originalBalance, trends) => {
  const today = atMidnight(new Date());
  let balance = originalBalance;
  let balanceWorstCase = originalBalance;

  /*
    I am separating positive and negative transactions in different
    row items, to have a different visualization color.
   */

  const data = expectationsData.reduce((accumulator, expectation) => {
    const {
      expectedOnDate,
      expected_on_date,
      signAdjustedAmount,
      sign_adjusted_amount,
      sign
    } = expectation;
    const dailyAmount = Money(signAdjustedAmount || sign_adjusted_amount);
    const dailyIncome = sign === 'in' ? dailyAmount : Money();
    const dailyExpense = sign === 'out' ? dailyAmount : Money();

    const expectedBalanceAtDay = balance.add(dailyAmount);
    const expectedWorstCaseBalanceAtDay = sign === 'out'
      ? balanceWorstCase.add(dailyAmount)
      : balanceWorstCase;

    const date = new Date(expectedOnDate || expected_on_date);
    const currentRawDate = today > date ? today : date; // Pay attention to minutes and hours
    const currentDateKey = date2Str(currentRawDate); // "YYYY-MM-DD"
    const currentDate = new Date(currentDateKey); // Parse again to ensure the midnight date value

    const oldRow = accumulator[currentDateKey] || [
      currentDate,
      Money(),
      Money(),
      Money(),
    ];

    const row = [
      oldRow[0],
      oldRow[1].add(dailyIncome),
      oldRow[2].add(dailyExpense),
      expectedBalanceAtDay,
      expectedWorstCaseBalanceAtDay,
    ];

    accumulator[currentDateKey] = row;

    balance = expectedBalanceAtDay;
    balanceWorstCase = expectedWorstCaseBalanceAtDay;

    return accumulator;
  }, {});

  const initialValue = [[
    today,
    Money(),
    Money(),
    originalBalance,
    originalBalance,
  ]];
  const values = _.sortBy(Object.values(data), (datum) => (datum[0]));
  const allValues = initialValue.concat(values);
  const rows = allValues.map((row) => {
    const datum = [row[0]];

    Object.keys(trends).forEach((key, index) => {
      if (trends[key]) {
        datum.push(moneyToChartValue(row[index + 1]));
      }
    });

    return datum;
  });

  return rows;
};

const trendOptions = {
  incomes: {
    color: 'green',
    type: 'bar',
    isStacked: false,
    label: 'Expected incomes',
  },
  expenses: {
    color: 'brown',
    type: 'bar',
    isStacked: false,
    label: 'Expected espenses',
  },
  balance: {
    color: 'lightblue',
    type: 'area',
    isStacked: true,
    label: 'Resulting balance',
  },
  worstCaseSenario: {
    color: '#eecccc',
    type: 'area',
    isStacked: true,
    label: 'Worst case scenario',
  },
};

/**
 * The graph that goes down, unless you're really really good.
 */
class LiquidityForecastChart extends React.Component {
  constructor(props) {
    super(props);

    const { values } = props;

    this.state = {
      canvasWidth: '100%',
      values,
      trends: {
        incomes: true,
        expenses: true,
        balance: true,
        worstCaseSenario: false, // disabled by default
      },
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    const items = document.querySelectorAll(ctas);

    items.forEach((item) => {
      item.addEventListener('click', this.redrawChart.bind(this));
    });
  }

  componentWillUnmount() {
    const items = document.querySelectorAll(ctas);

    items.forEach((item) => {
      item.removeEventListener('click', this.redrawChart);
    });
  }

  // handleAccountUpdate({ id }) {
  //   accountTransactions({ id })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       const balance = response.banking_account.current_balance;
  //       const { values } = this.state;
  //       const newValues = {
  //         ...values,
  //         [id]: balance,
  //       };

  //       this.setState({ values: newValues });
  //     });
  // }
  // fit the available width
  redrawChart() {
    // TODO: very ugly hack... find a better solution
    [0, 25, 50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300].forEach((timing) => {
      sleep(timing).then(() => {
        const { clientWidth } = this.wrapperRef.current;
        const { canvasWidth } = this.state;

        if (clientWidth !== canvasWidth) {
          this.setState({ canvasWidth: this.wrapperRef.current.clientWidth });
        }
      });
    });
  }

  enabledColumns() {
    const { trends } = this.state;

    return Object.keys(trends).reduce((acc, key) => {
      if (trends[key]) { acc.push(key); }
      return acc;
    }, []);
  }

  legendOptions() {
    const { trends } = this.state;

    return Object.keys(trends).reduce((acc, key) => {
      acc.push({
        key,
        color: trendOptions[key].color,
        label: trendOptions[key].label,
        active: trends[key],
      });

      return acc;
    }, []);
  }

  chartColumns() {
    const columns = [
      {
        type: 'date',
        label: 'Date',
      },
    ];

    this.enabledColumns().forEach((key) => {
      columns.push({
        type: 'number',
        label: trendOptions[key].label,
      });
    });

    return columns;
  }

  enabledSeries() {
    return this.enabledColumns().reduce((acc, key, index) => {
      const { color, type, isStacked } = trendOptions[key];
      acc[index] = {
        color,
        type,
        isStacked,
        visibleInLegend: false,
      };

      return acc;
    }, {});
  }

  handleLegendClick(key) {
    const { trends } = this.state;

    this.setState({
      trends: {
        ...trends,
        [key]: !trends[key],
      },
    });
  }

  render() {
    const { expectationsData } = this.props;
    const { values, trends } = this.state;

    const balance = Object.values(values).reduce((t, value) => (
      t.add(
        value.getAmount
          ? value
          : Money(value || { cents: 0, currencyIso: 'EUR' })
      )
    ), new Money({ cents: 0, currencyIso: 'EUR' }));
    const rows = computeData(expectationsData, balance, trends);
    const columns = this.chartColumns();
    const { canvasWidth } = this.state;
    const series = this.enabledSeries();
    const legendOptions = this.legendOptions();

    return (
      <div ref={this.wrapperRef}>
        <Chart
          chartType="ComboChart"
          width={canvasWidth}
          height="600px"
          chartArea={{
            left: '10',
            right: '10',
            width: canvasWidth,
            height: '100',
          }}
          rows={rows}
          columns={columns}
          options={{
            legend: false,
            hAxis: {
              titleTextStyle: { color: '#333' },
            },
            vAxis: {
              style: 'currency',
              format: '\u20AC#',
            },
            backgroundColor: '#f8f9fa',
            seriesType: 'bars',
            series,
            chartArea: {
              width: '80%',
              height: '80%',
            },
          }}
        />

        <CustomLegend
          items={legendOptions}
          onClick={(key) => { this.handleLegendClick(key); }}
          themes={['centered']}
          className="mt-3"
        />
      </div>
    );
  }
}

export default LiquidityForecastChart;
