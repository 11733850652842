import React from 'react';
import PropTypes from 'prop-types';

import { invertColor } from '../../../utils/colors';
import Icon from '../../Icon';
import { draftAllocationShape } from '../proptypes';
// import { formatIsoDateTime } from '../../../utils/datetime';

import DatetimeLabel from '../../DatetimeRangeLabel';

const GroupedDraftAllocation = ({ slot, draftAllocations, color }) => {
  // const {
  //   duration,
  //   start_at,
  //   end_at,
  //   date,
  //   moment,
  // } = draftAllocation;
  const {
    start_at,
    end_at,
    date,
  } = slot;

  const { description } = moment;
  const start_on = start_at.split('T')[0];
  const end_on = end_at.split('T')[0];
  const style = {
    paddingBottom: `${20 + (30 * draftAllocations.length)}%`,
  };
  let secondaryColor = '#000000';

  if (color) {
    style.backgroundColor = color;
    secondaryColor = invertColor(color, true);
    style.color = secondaryColor;
  }

  const slotTitle = 'Slot';
  const title = description || 'Draft allocation';

  return (
    <div className="GroupedDraftAllocation" style={style}>
      <div className="GroupedDraftAllocation__content">
        <h6 className="text-truncate" title={slotTitle}>
          <Icon
            name="cube"
            family="fas"
          />
          &nbsp;
          <strong>{ slotTitle }:</strong>
          &nbsp;
          <span className="GroupedDraftAllocation__datetime small">
            <DatetimeLabel
              start_on={start_on}
              start_at={start_at}
              end_on={end_on}
              end_at={end_at}
              separator=" "
              compact
            />
          </span>
        </h6>

        <hr
          className="my-2"
          style={{ borderColor: secondaryColor }}
        />

        {
          draftAllocations.map(({ start_on, start_at, end_on, end_at, moment }) => (
            <div
              className="GroupedDraftAllocation__allocation badge badge-light p-2 mb-2"
            >
              <h6 className="text-truncate" title={slotTitle}>
                <Icon
                  name="calendar-check"
                  family="far"
                />
                &nbsp;
                <strong>
                  { moment.description || 'Draft allocation' }
                </strong>
              </h6>

              <hr className="mt-0 mb-2" />

              <div className="GroupedDraftAllocation__datetime">
                <DatetimeLabel
                  start_on={start_on}
                  start_at={start_at}
                  end_on={end_on}
                  end_at={end_at}
                  separator=" "
                  compact
                />
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

GroupedDraftAllocation.propTypes = {
  // draftAllocation: PropTypes.shape(draftAllocationShape).isRequired,
  color: PropTypes.string,
};

GroupedDraftAllocation.defatulProps = {
  color: null,
};

export default GroupedDraftAllocation;
