import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';

import Icon from '../../Icon';
import AddExpectationModal from './AddExpectationModal';

const AddExpectationButton = ({
  onExpectationSave,
  countries,
  currencies,
  accounts,
  view,
}) => {
  const [showModal, setShowModal] = useState(false);

  const hideModal = () => { setShowModal(false); };
  const handleAddExpectationClick = () => { setShowModal(true); };

  return (
    <>
      <AddExpectationModal
        view={view}
        show={showModal}
        onHide={hideModal}
        countries={countries}
        currencies={currencies}
        accounts={accounts}
        onExpectationSave={onExpectationSave}
      />

      <Button
        className="d-block w-100"
        size="sm"
        variant="light"
        onClick={handleAddExpectationClick}
      >
        <Icon name="plus" fw />
        &nbsp;
        Add compensation
      </Button>
    </>
  );
};

export default AddExpectationButton;
