import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ticketShape } from '../utils';
import DataTable from './TicketsDataTable';
import { eventShape } from '../utils';

const TicketsPage = ({ event, tickets }) => {
  return (
    <div className={classnames('tickets-page')}>
      <DataTable
        event={event}
        tickets={tickets}
        showActions
      />
    </div>
  );
};

TicketsPage.propTypes = {
  tickets: PropTypes.arrayOf(ticketShape).isRequired,
  event: eventShape.isRequired,
};

TicketsPage.defaultProps = {
};

export default TicketsPage;
