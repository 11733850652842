import React from 'react';
import classnames from 'classnames';
import { format } from 'date-fns';

import styles from './WeekViewCalendar.module.scss';

const MonthHeader = ({ month, dateFormat }) => (
  <div className={classnames(styles.header, styles.weekDaysRow, 'flex-middle')}>
    <div className={classnames(styles.col, styles.colStart)}>
      {/* <div className="icon" onClick={() => changeMonthHandle("prev")}>
        prev month
      </div> */}
    </div>

    <div className={classnames(styles.col, styles.colCenter)}>
      <span>{format(month, dateFormat)}</span>
    </div>

    <div className={classnames(styles.col, styles.colEnd)}>
      {/* <div className="icon" onClick={() => changeMonthHandle("next")}>next month</div> */}
    </div>
  </div>
);

export default MonthHeader;
