import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';
//
import { colorizedLabel } from '../../utils/money';
import EditModal from '../OperationsPage/EditModal';
import TrackParentModal from '../OperationsPage/TrackParentModal';
import RowWrapper from '../ListingTable/Row';
import LinkedOperation from './LinkedOperation';
import CategoryColumns from './CategoryColumns';
import EditButton from './EditButton';
import TrackParentButton from './TrackParentButton';
import DownloadButton from './DownloadButton';
import {
  camelizeOperationProps,
  generateUrl,
} from './utils';

const Row = (props) => {
  const [showTrackParentModal, setShowTrackParentModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const {
    item,
    items,
    categories,
    creditNotes,
    onDelete,
    onUpdate,
    onCategoryLabelChange,
    onCategoryLabelChanged,
  } = props;
  const camelizedProps = camelizeOperationProps(item);
  const {
    id,
    operationHeadline,
    categoryLabels,
    counterpart,
    creditNoteId,
    amount,
    operationDate,
    operationReference,
    operationNotes,
    operationCountry,
    operationDocumentUrl,
    typology,
    operationType,
    humanOperationType,
  } = camelizedProps;

  const relatedCreditNote = items.find((op) => (op.id === creditNoteId));

  const handleParentModalChange = ({ parentOperationId }) => {
    setShowTrackParentModal(false);

    onUpdate({
      operation: {
        ...item,
        credit_note_id: parentOperationId,
      },
    });
  };

  const handleConversion = ({ operationId }) => {
    onDelete({ operationId });
    setShowEditModal(false);
  };

  return (
    <RowWrapper {...props}>
      <td className="h5">
        N°
        <strong>{operationReference || '?'}</strong>
        &nbsp;of&nbsp;
        {operationDate}
        <br />
        <small className="pt-2 d-block">
          { operationHeadline }
        </small>

        <LinkedOperation operation={relatedCreditNote} />
      </td>

      <td className="h5 text-right">
        <strong>{colorizedLabel(amount)}</strong>
        <br />
        <span className={classnames('badge', 'badge-light')}>
          {humanOperationType}
        </span>
      </td>

      <td className="h5">
        <a href={generateUrl(id)}>
          {counterpart}
        </a>
      </td>

      <CategoryColumns
        id={id}
        operation={item}
        categories={categories}
        categoryLabels={categoryLabels}
        operationHeadline={operationHeadline}
        onCategoryLabelChange={onCategoryLabelChange}
        onCategoryLabelChanged={onCategoryLabelChanged}
      />

      <td className="h5 text-center">
        <TrackParentModal
          show={showTrackParentModal}
          onHide={() => { setShowTrackParentModal(false); }}
          onChange={(args) => { handleParentModalChange(args); }}
          creditNotes={creditNotes}
          operation={item}
        />

        <EditModal
          show={showEditModal}
          onHide={() => { setShowEditModal(false); }}
          onConversion={(args) => { handleConversion(args); }}
          operationId={id}
          operationReference={operationReference}
          operationHeadline={operationHeadline}
          amount={amount}
          operationDate={operationDate}
          operationCountry={operationCountry}
          operationNotes={operationNotes}
          typology={typology}
          humanOperationType={humanOperationType}
          counterpart={counterpart}
          operation={item}
        />

        <div className="btn-group">
          <TrackParentButton
            onClick={() => { setShowTrackParentModal(true); }}
            operationType={operationType}
          />

          <EditButton
            onClick={() => { setShowEditModal(true); }}
          />

          <DownloadButton
            url={operationDocumentUrl}
          />
        </div>
      </td>
    </RowWrapper>
  );
};

const operationShape = PropTypes.shape({});
const categoryShape = PropTypes.shape({});

Row.propTypes = {
  item: operationShape.isRequired,
  items: PropTypes.arrayOf(operationShape).isRequired,
  creditNotes: PropTypes.arrayOf(operationShape).isRequired,
  categories: PropTypes.arrayOf(categoryShape).isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCategoryLabelChange: PropTypes.func.isRequired,
  onCategoryLabelChanged: PropTypes.func.isRequired,
}

const shouldMemo = (prev, next) => {
  const result = prev.label === next.label
    && prev.credit_note_id === next.credit_note_id
    && prev.selected === next.selected
    && _.isEqual(prev.category_labels_names, next.category_labels_names)

  return result;
};

export default memo(Row, shouldMemo);
