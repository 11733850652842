import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import _ from 'lodash';

import {
  buildLabel,
  totalRepartition,
  isValidRepartition,
  categoryShape,
  decimal,
} from '../utils';
import UUID from '../../../../../utils/uuid';
import { unclassifiedLabel } from '../../../../OperationsPage/commons';
import Icon from '../../../../Icon';
import RepartitionsChart from './RepartitionsChart';
import EntityFields from './EntityFields';

// Build a new entity from an existing label
const buildEntity = (label, attributes = {}) => ({
  ...buildLabel(label),
  entityId: UUID.generate(),
  repartition: label.repartition || 0,
  ...attributes,
});

const Form = ({
  entities, labels, onAdd, onChange, onRemove, validRepartitions, category,
}) => {
  const { category_id } = category;

  // Labels are lazily loaded on the first modal opening
  if (labels.length === 0) {
    return (<div>Loading...</div>);
  }

  const selectedLabelIds = entities.map(({ label_id }) => (label_id));
  const enhancedLabels = [
    ...labels.map(buildLabel),
    // buildLabel(unclassifiedLabel),
  ];
  const availableLabels = enhancedLabels.filter((el) => (
    !_.includes(selectedLabelIds, el.label_id)
  ));
  const isAddButtonDisabled = availableLabels.length === 0;
  const handleAddEntity = () => {
    const newEntity = buildEntity({
      ...availableLabels[0],
    }, { repartition: 0, category_id });

    onAdd(newEntity);
  };

  return (
    <div>
      {
        entities.map((entity, idx) => (
          <EntityFields
            key={entity.entityId}
            entity={entity}
            labels={availableLabels}
            onChange={onChange}
            onRemove={onRemove}
            validRepartitions={validRepartitions}
            style={{ position: 'relative', zIndex: (entities.length - idx) }}
          />
        ))
      }

      <Button variant="info" onClick={handleAddEntity} disabled={isAddButtonDisabled}>
        <Icon name="plus" fw />
        Add label
      </Button>
    </div>
  );
};

Form.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  labels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onAdd: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  validRepartitions: PropTypes.bool.isRequired,
  category: categoryShape.isRequired,
};

const AdvancedCategorizationModal = ({
  show,
  onHide,
  category,
  labels,
  subtitle,
  values,
  onSubmit,
}) => {
  const { category_id } = category;

  // State
  const initialState = values.length === 0
    ? [buildEntity(unclassifiedLabel, { category_id, repartition: 1 })]
    : values.map((v) => (buildEntity(v, { category_id })));
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [entities, setEntities] = React.useState(initialState);

  // Handlers
  const handleEntityChange = (updatedEntity) => {
    // const oldEntity = entities.find((e) => (e.entityId === updatedEntity.entityId))
    // const delta = (oldEntity.repartition - updatedEntity.repartition) / (entities.length - 1);
    const newEntities = entities.map((entity) => (
      updatedEntity.entityId === entity.entityId
        ? updatedEntity
        : entity
    ));

    setEntities(newEntities);

    if (!isDirty) { setIsDirty(true); }
  };

  const handleAddEntity = (entity) => {
    if (!isDirty) { setIsDirty(true); }

    setEntities([...entities, entity]);
  };

  const handleRemoveEntity = (entityId) => {
    if (!isDirty) { setIsDirty(true); }

    const newEntities = entities.filter((e) => (e.entityId !== entityId));

    setEntities(newEntities);
  };

  const total = totalRepartition(entities);
  const validRepartitions = total <= 100.0;
  // toFixed is used to avoid floating point errors
  const anyInvalidRepartition = entities.find(({ repartition }) => (
    !isValidRepartition(decimal(repartition * 100.0))
  ));
  const isFormValid = validRepartitions && !anyInvalidRepartition;

  // rendering
  const canSubmit = !isLoading && isDirty && isFormValid;
  const cannotSubmit = !canSubmit;
  const handleSubmit = () => {
    setIsLoading(true);
    onSubmit(entities);
  };

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Split classification</strong>
          &nbsp;
          <small>{subtitle}</small>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ zIndex: 200 }}>
        <Row className="my-0">
          <Col md={7}>
            {
              total > 100 && (
                <h5 className="text-danger mb-3">
                  <strong>Attention: the total sum of the breakdowns is greater than 100</strong>
                </h5>
              )
            }
            <Form
              entities={entities}
              labels={labels}
              onChange={handleEntityChange}
              onRemove={handleRemoveEntity}
              onAdd={handleAddEntity}
              validRepartitions={validRepartitions}
              category={category}
            />
          </Col>

          <Col md={5}>
            <div className="px-5">
              <RepartitionsChart entities={entities} />
            </div>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}  style={{ zIndex: 300 }}>Close</Button>
        <Button variant="success" disabled={cannotSubmit} onClick={handleSubmit}  style={{ zIndex: 300 }}>
          <Icon name="check" fw />
          &nbsp;
          { isLoading ? 'Loading...' : 'Ok' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AdvancedCategorizationModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  category: categoryShape.isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({})),
  labels: PropTypes.arrayOf(PropTypes.shape({})),
  onSubmit: PropTypes.func,
  subtitle: PropTypes.string,
};

AdvancedCategorizationModal.defaultProps = {
  show: false,
  values: [],
  labels: [],
  onSubmit: () => {},
  subtitle: '',
};

export default AdvancedCategorizationModal;
