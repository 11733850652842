import Money, { formatMoney } from '../../utils/money';

export const unclassifiedId = 'unclassified';

export const unclassifiedLabel = {
  _id: unclassifiedId,
  id: unclassifiedId,
  key: unclassifiedId,
  label_id: unclassifiedId,
  color: '#cccccc',
  name: 'Unspecified',
  label: 'Unspecified',
  active: true,
};

export const makeUnclassifiedLabel = ({ repartition = 1, category_id }) => ({
  ...unclassifiedLabel,
  repartition,
  category_id,
});

export const unclassifiedColor = unclassifiedLabel.color;

export const unclassifiedName = unclassifiedLabel.name;

export const findOperationCategoryLabels = (categoryId, labels) => {
  const repartedLabels = (labels || []).filter(({ category_id }) => (
    category_id === categoryId
  ));

  const totalRepartition = repartedLabels.reduce((acc, l) => (acc + l.repartition), 0);

  if (totalRepartition < 1) {
    const unclassifiedRepartition = 1 - totalRepartition;

    repartedLabels.push(makeUnclassifiedLabel({
      category_id: categoryId,
      repartition: unclassifiedRepartition,
    }));
  }

  return repartedLabels;
};

export const operationHeadline = (operation) => {
  const { operation_headline, operation_reference, amount } = operation;
  const money = Money(amount);

  return operation_headline && (operation_reference || '').lenght > 0
    ? operation_headline
    : `Nº ${operation_reference || '?'} of ${formatMoney(money)}`;
};

export default {
  operationHeadline,
};
