import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import { showPath } from '../../../routes/trainings/planned_events';

import Icon from '../../Icon';
import Row from '../../ListingTable/Row';

const HeadlineCell = ({ detailsPath, headline }) => (
  <td className="h5">
    <a href={detailsPath}>
      { headline }
    </a>
  </td>
);

const DateCell = ({ startsOn }) => (
  <td className="h5">
    <Icon name="calendar" family="far" fw />
    &nbsp;
    {moment(startsOn).format('D/M/Y')}
  </td>
);

const TrainersCell = ({ trainersName }) => {
  if (!trainersName) {
    return (
      <td className="h6">
        -
      </td>
    );
  }

  return (
    <td className="h6">
      <Icon name="user-alt" fw />
      &nbsp;
      { trainersName }
    </td>
  );
};

const VenueCell = ({ venue }) => {
  if (!venue) {
    return (
      <td className="h6">
        <Icon name="globe-africa" fw />
        &nbsp;
        Online
      </td>
    );
  }

  return (
    <td className="h6">
      <Icon name="map-marker" fw />
      &nbsp;
      { venue }
    </td>
  );
};

const BADGE_THEMES = {
  draft: 'badge-secondary',
  unconfirmed: 'badge-warning',
  confirmed: 'badge-success',
  sold_out: 'badge-success',
  canceled: 'badge-dark',
  running: 'badge-info',
  completed: 'badge-success',
  past: 'badge-light',
};

const badgeTheme = (status) => (
  BADGE_THEMES[status] || 'badge-secondary'
);

const StatusCell = ({ status }) => (
  <td className="h5">
    <span
      className={
        classnames('badge', badgeTheme(status))
      }
    >
      {_.capitalize(status)}
    </span>
  </td>
);

const TicketsProgressBar = ({ ticketsSold, capacity }) => {
  const ticketProgress = ticketsSold
    ? (ticketsSold / capacity) * 100.0
    : 0;

  const label = ticketProgress >= 100 ? 'SOLD OUT' : '';

  return (
    <div className="progress progress-mini">
      <div
        style={{ width: `${ticketProgress}%` }}
        className={
          classnames('progress-bar', {
            'bg-success': ticketProgress >= 100,
            'bg-warning': ticketProgress < 50,
          })
        }
      >
        { label }
      </div>
    </div>
  );
};

const TicketsCell = ({ ticketsSold, capacity }) => (
  <td className="h6">
    <div className="small">
      Tickets Sold:
      &nbsp;
      <strong>{ticketsSold} of {capacity}</strong>
    </div>

    <TicketsProgressBar ticketsSold={ticketsSold} capacity={capacity} />
  </td>
);

// // TODO: Use ACL
// const CertificationButton = ({ status }) => {
//   if (status === 'unconfirmed') { return ''; }
//
//   return (
//     <Button size="sm" variant="light" title="Certificates">
//       <Icon name="stamp" fw />
//     </Button>
//   );
// };
//
// const CancelButton = ({ item, onClick }) => {
//   const { canCancel } = item.acl;
//
//   if (!canCancel) { return ''; }
//
//   return (
//     <Button
//       size="sm"
//       variant="danger"
//       title="Cancel event"
//       onClick={onClick}
//     >
//       <Icon name="times" fw />
//     </Button>
//   );
// };
//
// const ConfirmButton = ({ item, onClick }) => {
//   const { canConfirm } = item.acl;
//
//   if (!canConfirm) { return ''; }
//
//   return (
//     <Button
//       size="sm"
//       variant="success"
//       title="Confirm event"
//       onClick={onClick}
//     >
//       <Icon name="check" fw />
//     </Button>
//   );
// };

const PublicEventRow = (props) => {
  // const [showCancelModal, setShowCancelModal] = useState(false);
  // const hideCancelModal = () => { setShowCancelModal(false); };
  // const [showConfirmModal, setShowConfirmModal] = useState(false);
  // const hideConfirmModal = () => { setShowConfirmModal(false); };
  const { item } = props;
  const {
    id,
    headline,
    trainers_name,
    venue,
    status,
    starts_on,
    tickets_sold,
    initial_capacity,
    capacity, // This is the actuale sold tickets
  } = item;

  // const handleCancelButtonClick = () => { setShowCancelModal(true); };
  // const handleConfirmButtonClick = () => { setShowConfirmModal(true); };
  // const handleConfirmation = () => {
  //   onUpdate({
  //     plannedEvent: {
  //       ...item,
  //       status: 'confirmed',
  //       _status: 'confirmed',
  //     },
  //   });
  // };

  return (
    <Row {...props}>
      <DateCell
        startsOn={starts_on}
      />

      <StatusCell
        status={status}
      />

      <HeadlineCell
        headline={headline}
        detailsPath={showPath(id)}
      />

      <TrainersCell
        trainersName={trainers_name}
      />

      <VenueCell
        venue={venue}
      />

      <TicketsCell
        capacity={initial_capacity}
        ticketsSold={tickets_sold}
      />
    </Row>
  );
};

PublicEventRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    headline: PropTypes.string,
    trainers_name: PropTypes.string,
    venue: PropTypes.string,
    status: PropTypes.string,
    starts_on: PropTypes.string,
    tickets_sold: PropTypes.number,
    capacity: PropTypes.number,
    initial_capacity: PropTypes.number,
    acl: PropTypes.shape({
      canConfirm: PropTypes.bool,
      canCancel: PropTypes.bool,
    }),
  }).isRequired,
  // onUpdate: PropTypes.func,
  // onDelete: PropTypes.func,
};

PublicEventRow.defaultProps = {
  // onUpdate: () => {},
  // onDelete: () => {},
};

export default PublicEventRow;
