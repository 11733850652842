import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { format, isSameDay } from 'date-fns';

import DayCellContent from './DayCellContent';
import Icon from '../Icon';

import styles from './WeekViewCalendar.module.scss';

const DayCell = ({
  onFocus,
  onBlur,
  day,
  dateFormat,
  selected,
  isLoading,
  events,
  labels,
  onEventFocus,
  viewMode,
}) => {
  const formattedDate = format(day, dateFormat);
  const midFormattedDate = format(day, 'dd MMM');
  const longFormattedDate = format(day, 'cccc dd MMMM yyyy');
  const dayName = format(day, 'EEE').toLowerCase();
  const elementClassNames = classnames(
    styles.col,
    styles.cell,
    styles[`cell--${viewMode}`],
    styles[`cell--${dayName}`],
    styles.dayCell,
    {
      [styles.today]: isSameDay(day, new Date()),
      [styles.selected]: selected,
    },
  );
  const isCompact = viewMode === 'compact';
  const hasEvents = events.length > 0;

  const containerClassNames = classnames(
    styles.DayCellContainer,
    styles[dayName],
    {
      [styles.today]: isSameDay(day, new Date()),
      [styles.selected]: selected,
    },
  );

  const handleClick = () => {
    onFocus(day);
  };

  const handleKeyPress = (event) => {
    // ESC
    if (event.keyCode === 27) {
      event.preventDefault();
      event.stopPropagation();
      onBlur();
    }

    // ENTER
    if (event.keyCode === 13 && !selected) {
      event.preventDefault();
      event.stopPropagation();
      onFocus(day);
    }
  };

  const handleCloseClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onBlur();
  };

  return (
    <div
      className={elementClassNames}
      key={day}
    >
      <div
        className={containerClassNames}
        tabIndex={0}
        onKeyUp={handleKeyPress}
        onClick={handleClick}
        role="treegrid"
      >
        <span className={classnames(styles.longCellNumber, 'text-nowrap')}>
          {longFormattedDate}
        </span>

        <span
          className={classnames(
            styles.midCellNumber,
            { [styles.hidden]: selected || (isCompact && hasEvents)  }
          )}
        >
          {midFormattedDate}
        </span>

        <span
          className={classnames(styles.cellCloseAction)}
          onClick={handleCloseClick}
          onKeyPress={handleCloseClick}
          role="button"
          tabIndex={0}
        >
          <Icon name="times" fw />
        </span>

        <span className={classnames(styles.cellBg)}>
          {formattedDate}
        </span>

        <DayCellContent
          day={day}
          events={events}
          labels={labels}
          isLoading={isLoading}
          onEventFocus={onEventFocus}
          selected={selected}
          viewMode={viewMode}
        />
      </div>
    </div>
  );
};

DayCell.propTypes = {
  day: PropTypes.instanceOf(Date).isRequired,
  dateFormat: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  selected: PropTypes.bool,
  isLoading: PropTypes.bool,
  events: PropTypes.arrayOf(PropTypes.shape({})),
  onEventFocus: PropTypes.func,
};

DayCell.defaultProps = {
  onFocus: () => {},
  onBlur: () => {},
  selected: false,
  isLoading: false,
  events: [],
  onEventFocus: () => {},
};

export default DayCell;
