import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './BulkToolbarContainer.module.scss';

const DefaultToolbar = ({ selectedItems, totalItems }) => (
  <div className={classnames('row')}>
    <div className="col-sm-12">
      Selected {selectedItems.length} items of {totalItems}
    </div>
  </div>
)

const ToolbarWrapper = ({ BulkToolbar, selectedItems, ...props }) => {
  const Toolbar = BulkToolbar || DefaultToolbar;

  return (
    <Toolbar
      {...props}
      selectedItems={selectedItems}
    />
  );
};

const BulkToolbarContainer = ({
  className,
  selectable,
  totalItems,
  selectedItems,
  components,
  ...props
}) => {
  if (!selectable) { return null; }

  const { BulkToolbar } = components;
  const itemsSelected = selectedItems.length > 0;
  const classNames = classnames(
    'listing-table-bulk-toolbar',
    className,
    {
      hide: !itemsSelected,
      [styles.BulkToolbar]: itemsSelected,
    },
  );

  return (
    <div className={classNames}>
      <ToolbarWrapper
        {...props}
        selectedItems={selectedItems}
        totalItems={totalItems}
        BulkToolbar={BulkToolbar}
      />
    </div>
  );
};

BulkToolbarContainer.propTypes = {
  className: PropTypes.string,
  // children: PropTypes.oneOfType([
  //   PropTypes.arrayOf(PropTypes.node),
  //   PropTypes.node,
  //   null,
  // ]),
  selectable: PropTypes.bool,
  toolbarName: PropTypes.string,
};

BulkToolbarContainer.defaultProps = {
  className: '',
  selectable: false,
  // children: null,
  toolbarName: null,
};

export default BulkToolbarContainer;
