// import $ from 'jquery'

const selector = 'input[data-behaviour="datepicker"]';
const format = 'DD/MM/YYYY';

export const init = (item) => {
  const $item = $(item);
  const defaultDate = $item.data('default-date')

  destroy(item)

  $item.datetimepicker({
    format,
    defaultDate,
  });
}

export const initAll = () => {
  $(selector).each((idx, item) => {
    init(item)
  });
}

export const destroy = (item) => {
  const $item = $(item);

  $item.datetimepicker('destroy')
}

export const destroyAll = () => {
  $(selector).each((idx, item) => {
    destroy(item);
  });
}
