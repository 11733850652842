import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import Icon from '../../Icon';
import Context from './Context';

const SyncCalendarsButton = ({ className, caption }) => {
  const {
    isSynchronizingCalendars,
    syncAccountCalendars,
  } = useContext(Context);
  const onSync = () => { syncAccountCalendars(); };

  return (
    <Button
      variant="warning"
      className={className}
      disabled={isSynchronizingCalendars}
      onClick={() => (onSync())}
    >
      <Icon name="sync-alt" spin={isSynchronizingCalendars} fw />
      &nbsp;
      {caption}
    </Button>
  );
};

SyncCalendarsButton.propTypes = {
  className: PropTypes.string,
  caption: PropTypes.string,
};

SyncCalendarsButton.defaultProps = {
  className: '',
  caption: 'Sync calendars',
};

export default SyncCalendarsButton;
