import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import EventBus from '../../../packs/event_bus';
import { trainerPlacedTickets } from '../../../api/trainers/trainers';
import sleep from '../../../utils/sleep';
import handleError from '../../../utils/error-handler';
import { trainerShape } from '../proptypes';

const decorateTicket = (ticket, attributes = {}) => ({
  ...ticket,
  ...attributes,
});
const decorateTickets = (tickets, attributes = {}) => (
  tickets.map((ca) => (decorateTicket(ca, attributes)))
);

const WithTrainerTickets = (WrappedComponent) => {
  class TrainerTicketsHOC extends React.Component {
    constructor(props) {
      super(props);

      this.debouncedLoadTrainerTickets = debounce(
        this.loadTrainerTickets,
        500,
        { leading: true },
      );

      this.state = {
        tickets: [],
        loading: true,
      };
    }

    componentDidMount() {
      /// const events = [
      ///   'Trainings::PublicEdition::TrainerAssigned',
      ///   'Trainings::PublicEdition::TrainerUnassigned',
      ///   'Trainings::PublicEdition::TrainersSet',
      /// ];

      /// this.ticketsEventsSubscriptionId = EventBus.subscribe(
      ///   events,
      ///   this.handleTrainerTicketsEventsSubscription,
      ///   this,
      /// );

      this.loadTrainerTickets();
    }

    componentWillUnmount() {
      /// EventBus.unsubscribe(this.ticketsEventsSubscriptionId);
    }

    /// handleTrainerTicketsEventsSubscription() {
    ///   this.debouncedLoadTrainerTickets();
    /// }

    setTrainerTickets(plainTickets, attributes = {}) {
      const tickets = decorateTickets(
        plainTickets,
        attributes,
      );

      this.setState({
        loading: false,
        tickets,
      });
    }

    loadTrainerTickets(wait = 0) {
      const { trainer } = this.props;
      const { id } = trainer;

      return sleep(wait).then(() => {
        trainerPlacedTickets({ trainerId: id })
          .then((data) => { this.setTrainerTickets(data); })
          .catch(handleError);
      });
    }

    render() {
      const { trainer } = this.props;
      const { tickets, loading } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          loading={loading}
          trainer={trainer}
          tickets={tickets}
        />
      );
    }
  }

  TrainerTicketsHOC.propTypes = {
    trainer: trainerShape.isRequired,
  };

  return TrainerTicketsHOC;
};

export default WithTrainerTickets;
