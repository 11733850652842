import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Icon from './Icon';
import ReactiveValue from './accounts/ReactiveValue';
import Row from './ListingTable/Row';
import { showPath, editPath, deletePath } from '../routes/treasury/accounts';

const AccountRow = ({ item }) => {
  const {
    id,
    color,
    accountName,
    accountType,
    signAdjustedCurrentBalance,
    lastCheckedOn,
    icon,
  } = item;

  const humanLastCheckedOn = moment(lastCheckedOn).format('MMMM D, Y');

  return (
    <Row className="AccountRow" id={id}>
      <td>
        <Icon
          name={icon}
          title={accountType}
          size="2x"
          color={color}
          fw
        />
      </td>
      <td className="AccountRow__color-cell">
        <div
          className="AccountRow__color"
          style={{ backgroundColor: color }}
        />
      </td>

      <td className="h5">
        <a href={showPath(id)}><strong>{accountName}</strong></a>
      </td>

      <td className="h5" title={`Last checked on ${humanLastCheckedOn}`}>
        <Icon name="calendar" fw />
        &nbsp;
        {humanLastCheckedOn}
      </td>

      <td className="h5 text-right">
        <ReactiveValue
          accountId={id}
          initialValue={signAdjustedCurrentBalance}
        />
      </td>
      <td className="table-actions">
        <div className="btn-group">
          <a
            href={showPath(id)}
            className="btn btn-success btn-small btn-outline"
          >
            <Icon name="search-plus" fw />
            View
          </a>
          <a
            href={editPath(id)}
            className="btn btn-primary btn-small btn-outline"
          >
            <Icon name="pencil-alt" fw />
            Edit
          </a>
          <a
            href={deletePath(id)}
            className="btn btn-warning btn-small btn-outline"
            data-confirm="Are you sure?"
            data-method="delete"
          >
            <Icon name="trash" fw />
            &nbsp;
            Delete
          </a>
        </div>
      </td>
    </Row>
  );
};

AccountRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    accountName: PropTypes.string.isRequired,
    accountType: PropTypes.string.isRequired,
  }).isRequired,
};

export default AccountRow;
