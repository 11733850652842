import React from 'react';
import Button from 'react-bootstrap/Button';
import Icon from './Icon';
import Money from '../utils/money';
import EventBus from '../packs/event_bus';

const generateUrl = (id = null, action = null) => {
   let url = '/financial_analysis/business_lines/';
   if (id) url = url + id + '/';
   if (action) url = url + action;
   return url;
};

class BusinessLineRow extends React.Component {
  componentDidMount() {
    this.eventSubscriptionId = EventBus.subscribe(
      'Billing::Classification::DocumentClassified',
      this.handleClassifiedDocument,
      this,
    );
  }

  componentWillUnmount() {
    EventBus.unsubscribe(this.eventSubscriptionId);
  }

  handleClassifiedDocument(event) {
    // const { id } = this.props;

    // if (event.document_id === id) {
    //   sleep(500).then(() => {
    //     billingDocument(id)
    //       .then((response) => (response.json()))
    //       .then((doc) => {
    //         this.setState({
    //           state: doc.state,
    //           headline: doc.headline,
    //           customer: doc.customer,
    //           totalAmount: doc.total_amount,
    //           netAmount: doc.net_amount,
    //           issuedOn: doc.issued_on,
    //           dueDate: doc.due_date,
    //           progressiveNumber: doc.progressive_number,
    //           businessLine: doc.business_line,
    //           businessLineId: doc.business_line_id,
    //           documentUrl: doc.document_url,
    //         });
    //       });
    //   });
    // }
  }

  renderDownloadButton() {
    // const { documentUrl } = this.state;
    const documentUrl = '#';

    if (!documentUrl) { return ''; }

    return (
      <Button href={documentUrl} rel="noopener noreferrer" target="_blank" className="btn btn-sm btn-info">
        <Icon name="download" />
      </Button>
    );
  }

  render() {
    const {
      name,
      revenues,
      expenses,
      profitability,
      state,
    } = this.props;

    const id = this.props._id;

    return (
      <tr key={id}>
        <td className="h5 col-2">
          <a href={generateUrl(id)}><strong>{name}</strong></a>
        </td>

        <td className="h5 col-2 text-right green">
          {Money(revenues).toFormat()}
        </td>

        <td className="h5 col-2 text-right red">
          {Money(expenses).toFormat()}
        </td>

        <td className="h5 col-2 text-right">
          {Money(profitability).toFormat()}
        </td>

        <td className="h5 col-2 text-center">
          <span className="badge badge-secondary">{state}</span>
        </td>
      </tr>
    )
  }
}

export default BusinessLineRow;
