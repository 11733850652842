import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';

import Input from '../../RHF/Input';
import SelectInput from '../../RHF/SelectInput';
import TimeDurationInput from '../../RHF/TimeDurationInput';
import { priorityMarks, offsetOptions, findOffsetOption } from '../utils';

const OffsetStepForm = ({
  formId,
  onSubmit,
  defaultValues,
  className,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const enhancedOnSubmit = ({ offset_type, ...params }) => {
    onSubmit({
      ...params,
      step_type: 'offset',
      offset_type: findOffsetOption(offset_type.value),
    });
  };

  const customSubmitHandler = (event) => {
    // Avoid submit event propagation in the parent form component
    event.stopPropagation();
    handleSubmit(enhancedOnSubmit)(event);
  };

  return (
    <div className={classnames('StepTypeForm', className)}>
      <Form onSubmit={customSubmitHandler} id={formId}>
        <div className="row">
          <div className="col-8">
            <Input
              label="Description"
              placeholder="Lunch..."
              name="description"
              errors={errors}
              control={control}
              size="lg"
              required
            />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <SelectInput
              name="offset_type"
              label="Offset type"
              errors={errors}
              control={control}
              options={offsetOptions}
              size="lg"
              required
            />
          </div>

          <div className="col-6">
            <TimeDurationInput
              label="Duration"
              placeholder="120 minutes"
              name="duration"
              errors={errors}
              control={control}
              size="lg"
              hint="i.e. 2h 30m"
              customValidation={({ value }) => (value > 0)}
              required
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

OffsetStepForm.propTypes = {
  formId: PropTypes.string,
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.shape({}),
  className: PropTypes.string,
};

OffsetStepForm.defaultProps = {
  formId: 'offset-step-form',
  onSubmit: () => {},
  defaultValues: { priority: 50, duration: 60 },
  className: null,
};

export default OffsetStepForm;
