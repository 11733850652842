import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import moment from 'moment';
import $ from 'jquery';
import _ from 'lodash';

import Money, { moneyToChartValue } from '../../utils/money';
import EventBus from '../../packs/event_bus';
import { get } from '../../api/base';
import sleep from '../../utils/sleep';

const findCategoryLabelId = (categoryId, labels) => {
  const label = (labels || []).find(({ category_id }) => (
    category_id === categoryId
  ));

  return label && label.label_id
    ? label.label_id
    : 'missing';
};

const computeData = (operations, labels, category, { logic }) => {
  const { category_id } = category;
  const rows = [];
  const year = operations[0]
    ? moment(operations[0].movement_date).year()
    : moment().year();

  // Returns an object with labelId => row index
  const labelsMapping = labels.reduce((hash, label, index) => {
    hash[label.label_id] = index + 2;

    return hash;
  }, {
    date: 0,
    missing: 1, // Unclassified operations
  });

  // For each month
  Array(12).fill(null).forEach((_val, index) => {
    const month = index + 1;
    const date = moment(`${year}-${month}-01`, 'YYYY-MM-DD').format('MMMM');

    // Create a empty array with a the amounts for each label
    rows[index] = Array(labels.length + 2).fill(Money());
    rows[index][0] = date; // the first value is the current month
  });

  operations.forEach((datum) => {
    const labelId = findCategoryLabelId(category_id, datum.category_labels);
    const rawAmount = Object.assign({}, datum.amount);

    if (logic === 'negative') {
      rawAmount.cents *= -1;
    }

    const amount = Money(rawAmount);
    const month = moment(datum.operation_date).month();
    const index = labelsMapping[labelId]; // || 1;

    if (index) {
      rows[month][index] = rows[month][index].add(amount);
    }
  });

  return rows.map((row) => (
    row.map((value, index) => (
      index === 0 ? value : moneyToChartValue(value)
    ))
  ));
};

const reactiveEvents = [
  'FinancialAnalysis::Classification::PendingOperationClassificationRegistered',
  'FinancialAnalysis::Classification::OperationCategorized',
];

class MonthlyCategoryOperationsBarChartCompensated extends React.Component {
  constructor(props) {
    super(props);

    const { operations, category } = props;
    this.state = { operations, category };
  }

  componentDidMount() {
    const { reactive } = this.props
    $(document).on('shown.bs.tab', this.handleTabShown.bind(this));

    if (!reactive) { return; }

    const { operations } = this.props;

    this.eventSubscriptionId = EventBus.subscribe(
      reactiveEvents,
      _.debounce(this.handleEventSubscription, 500, { leading: true }),
      this,
    );

    if (!operations || operations.length === 0) {
      this.reloadOperations();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { category } = nextProps;

    this.setState({ category });
  }

  componentWillUnmount() {
    $(document).off('shown.bs.tab', this.handleTabShown);

    if (this.eventSubscriptionId) {
      EventBus.unsubscribe(this.eventSubscriptionId);
      this.eventSubscriptionId = undefined;
    }
  }

  getOperations() {
    const { reactive } = this.props;

    return reactive
      ? this.state.operations
      : this.props.operations;
  }

  getCategory() {
    const { category } = this.state;

    return category;
  }

  getLabels() {
    const category = this.getCategory();

    return category.labels || [];
  }

  reloadOperations(wait = 0) {
    const { workingYear, endpoint } = this.props;

    return sleep(wait).then(() => {
      get(endpoint)
        .then((response) => (response.json()))
        .then(({ data }) => {
          this.setState({ operations: data });
        });
    });
  }

  reloadCategory(wait = 0) {
    const category = this.getCategory();
    const { category_id } = category;
    const endpoint = `/financial_analysis/classification/categories/${category_id}.json`;

    return sleep(wait).then(() => {
      get(endpoint)
        .then((response) => (response.json()))
        .then((data) => {
          this.setState({ category: data });
        });
    });
  }

  handleTabShown(event) {
    const $a = $(event.target);
    const category = this.getCategory();
    const operations = this.getOperations();
    const { category_id } = category;

    if (category_id === $a.data('category-id')) {
      // Force rerender
      sleep(10).then(() => {
        this.setState({ operations: [...operations] });
      });
    }
  }

  handleEventSubscription(event) {
    const { category_label_id, category_id } = event;
    const category = this.getCategory();

    if (category.category_id !== category_id) { return; }

    if (this.findLabelId(category_label_id)) {
      this.reloadOperations(200);
    } else {
      this.reloadCategory(50).then(() => {
        this.reloadOperations(200)
      });
    }
  }

  findLabelId(labelId) {
    const labels = this.getLabels();

    return labels.find((label) => (label.label_id === labelId))
  }

  render() {
    const { logic } = this.props;
    const category = this.getCategory();
    const { label } = category;
    const labels = this.getLabels();
    const operations = this.getOperations();
    const rows = computeData(operations, labels || [], category, { logic });
    const columns = [{}, { type: 'number', label: 'Unspecified' }]
      .concat(labels.map((l) => ({ type: 'number', label: l.name })));
    const series = labels.reduce((hash, label, index) => {
      hash[index + 1] = { color: label.color }; // IN

      return hash;
    }, {
      0: { color: '#dddddd' },
    });

    return (
      <div>
        <Chart
          loader={<div>Loading Chart...</div>}
          chartType="ColumnChart"
          width="100%"
          height="450px"
          rows={rows}
          columns={columns}
          options={{
            title: `${label} categorization`,
            isStacked: true,
            legend: {
              position: 'bottom',
            },
            hAxis: {
              title: 'Time',
              titleTextStyle: { color: '#333' },
            },
            vAxis: {
              style: 'currency',
              format: '\u20AC#',
            },
            series,
            backgroundColor: 'transparent',
            chartArea: {
              width: '90%',
            },
            trendlines: {
              0: { type: 'linear', lineWidth: 5, opacity: 0.3 },
            },
          }}
        />
      </div>
    );
  }
}

MonthlyCategoryOperationsBarChartCompensated.propTypes = {
  logic: PropTypes.string,
  operations: PropTypes.arrayOf(PropTypes.object),
  reactive: PropTypes.bool,
  workingYear: PropTypes.number,
};

MonthlyCategoryOperationsBarChartCompensated.defaultProps = {
  logic: 'positive',
  operations: [],
  reactive: true,
};

export default MonthlyCategoryOperationsBarChartCompensated;
