import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import Button from 'react-bootstrap/Button';

import Icon from '../Icon';
import { eventLabels } from './utils';
import DayCellEvent from './DayCellEvent';
import styles from './WeekViewCalendar.module.scss';

const eventLabelsLength = (event, labels) => {
  if (!event) { return 0; }

  return eventLabels({ event, labels }).length;
};

const EventsListView = ({ events, labels, onEventFocus, selected }) => {
  // const previewEventsSize = events.length === 4 ? 4 : 3;

  const previewEventsSize = 4;
  // const totalLabelsLength = eventLabelsLength(events[0], labels) + eventLabelsLength(events[1], labels);

  // if (events.length > 2 && totalLabelsLength <= 4) {
  //   previewEventsSize = 3;
  // }

  const previewEvents = events.slice(0, previewEventsSize);
  const cellEvents = selected ? events : previewEvents;

  // if (selected) {
    return (
      <Scrollbars>
        {
          events.map((event, index) => (
            <DayCellEvent
              key={event.id}
              event={event}
              labels={labels}
              onClick={onEventFocus}
              highlight={false}
            />
          ))
        }

        {
          events.length > 0 && !selected && (
            <div className={classnames('py-2 text-center')}>
              <Button variant="light" size="sm" className="w-75">
                <Icon name="search" fw />
                &nbsp;
                View
              </Button>
            </div>
          )
        }
      </Scrollbars>
    );
  // }

  // const showMoreCTA = !selected && events.length > previewEventsSize;
  const showMoreCTA = !selected && events.length > 2;
  // const moreEventsSize = events.length - previewEventsSize;
  // const moreLabel = moreEventsSize === 1
  //   ? 'One more event...'
  //   : `${moreEventsSize} more events...`

  const moreLabel = 'More events'

  return (
    <div>
      {
        cellEvents.map((event, index) => (
          <DayCellEvent
            key={event.id}
            event={event}
            labels={labels}
            onClick={onEventFocus}
            highlight={false}
          />
        ))
      }
      {
        showMoreCTA && (
          <div
            className={classnames(
              styles.DayCellShowMore,
              'py-2 text-center',
            )}
            role="button"
            aria-pressed="false"
            tabIndex={0}
          >
            <Button variant="light" size="sm" className="px-3">
              {moreLabel}
            </Button>
          </div>
        )
      }
    </div>
  );
};

EventsListView.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onEventFocus: PropTypes.func,
  selected: PropTypes.bool,
};

EventsListView.defaultProps = {
  onEventFocus: () => {},
  selected: false,
};

export default EventsListView;
