import React from 'react';

import { Provider } from './Context';
import Calendar from './Calendar';

const CalendarEventsPage = (props) => (
  <Provider {...props}>
    <div className="CalendarEventsPage">
      <Calendar />
    </div>
  </Provider>
);

export default CalendarEventsPage;
