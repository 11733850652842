import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import classnames from 'classnames';

import { extractError } from './utils';

const Input = ({
  label,
  type,
  errors,
  name,
  min,
  max,
  hint,
  control,
  required,
  defaultValue,
  customValidation,
  ...props
}) => {
  const error = extractError(errors, name);
  const errorMessage = error
    ? error.message || 'Invalid field'
    : null;
  const hasError = !! errorMessage;

  return (
    <Form.Group controlId={`form-grid-${name}`}>
      {
        label && (
          <Form.Label className={classnames({ 'text-danger': !!hasError })}>
            {label}
            &nbsp;
            {required && (<abbr title="required">*</abbr>)}
          </Form.Label>
        )
      }
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={{
          required,
          min,
          max,
          validate: (value) => (
            customValidation({
              name,
              value,
              formValues: control._formValues,
            })
          ),
        }}
        render={({ field }) => (
          <Form.Control
            {...props}
            {...field}
            type={type}
            isInvalid={!!errorMessage}
            onChange={(e) => { field.onChange(e.target.value); }}
          />
        )}
      />
      { hint && (
        <small
          className={
            classnames(
              'form-text', {
                'text-muted': !hasError,
                'text-danger': !!hasError,
              },
            )
          }
        >
          {hint}
        </small>
      )}
      {errorMessage && (
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

Input.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  hint: PropTypes.string,
  defaultValue: PropTypes.string,
  errors: PropTypes.shape({}),
  control: PropTypes.shape({}).isRequired,
  required: PropTypes.bool,
  customValidation: PropTypes.func,
};

Input.defaultProps = {
  label: 'Input',
  type: 'text',
  errors: {},
  required: false,
  defaultValue: '',
  customValidation: () => (true),
  hint: null,
};

export default Input;
