import { compareAsc } from 'date-fns';
import _mergeRanges from 'merge-ranges';
// import { ScheduleType } from '../types';

export const mergeRanges = (event) => (
  _mergeRanges(
    [...event].map((d) => d.map((c) => new Date(c))),
  )
);

export const mergeEvents = (
  event1,
  event2,
) => {
  if (event2 === null) {
    return event1;
  }

  return mergeRanges([...event1, ...event2]).sort((range1, range2) => (
    compareAsc(range1[0], range2[0])
  ));
};

export default mergeEvents;
