import React from 'react';
import PropTypes from 'prop-types';
// import classnames from 'classnames';
// import Button from 'react-bootstrap/Button';
import Icon from '../../Icon';
import {
  showPath,
  editPath,
  deletePath,
} from '../../../routes/financial_analysis/classification/categories';

import Label from './InlineCategorization/LabelBadge';

// const labelStyles = (color) => ({
//   textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
//   color: 'white',
//   backgroundColor: color,
// });

// const Label = ({ name, color }) => (
//   <span className="badge badge-secondary mr-1 px-2" style={labelStyles(color)}>
//     <Icon name="tag" />
//     { ` ${name}` }
//   </span>
// );

const CategoryRow = (props) => {
  const {
    categoryId,
    revenue,
    expense,
    notes,
    label,
    labels,
  } = props;

  const id = categoryId;

  return (
    <tr key={id} className="">
      <td className="text-right">
        <h4 className="text-nowrap pl-5">{ label }</h4>
        {
          revenue && (
            <span className="badge badge-success ml-2">
              <Icon name="sign-in-alt" fw />
              &nbsp;
              Revenue
            </span>
          )
        }
        {
          expense && (
            <span className="badge badge-danger ml-2">
              <Icon name="sign-out-alt" fw />
              &nbsp;
              Expense
            </span>
          )
        }

        <p className="mt-2">{ notes }</p>
      </td>

      <td>
        <Label key={0} name="Unspecified" color="#bbbbc9" />

        {
          labels.map(({ labelId, name, color }) => (
            <Label key={labelId} name={name} color={color} />
          ))
        }
      </td>

      <td className="table-actions">
        <div className="btn-group">
          <a
            href={showPath(id)}
            className="btn btn-success btn-sm btn-outline"
          >
            <Icon name="search-plus" fw />
            View
          </a>
          <a
            href={editPath(id)}
            className="btn btn-primary btn-sm btn-outline"
          >
            <Icon name="pencil-alt" fw />
            Edit
          </a>
          <a
            href={deletePath(id)}
            className="btn btn-warning btn-sm btn-outline"
            data-confirm="Are you sure?"
            data-method="delete"
          >
            <Icon name="trash" fw />
            Delete
          </a>
        </div>
      </td>
    </tr>
  );
};

CategoryRow.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.object),
};

CategoryRow.defaultProps = {
  labels: [],
};

export default CategoryRow;
