import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import UnconfirmedMissingCertification from './UnconfirmedMissingCertification';
import ConfirmedMissingCertification from './ConfirmedMissingCertification';
import CertificationSettings from '../certifications/CertificationSettings';
import CertifiedAttendeesPage from '../certifications/CertifiedAttendeesPage';
import { eventShape } from '../utils';

const CertificationPage = ({ event, certification, certificatesHost }) => {
  const { status } = event;

  if (!certification && status === 'unconfirmed') {
    return (
      <UnconfirmedMissingCertification
        event={event}
      />
    );
  }

  if (!certification) {
    return (
      <ConfirmedMissingCertification
        event={event}
      />
    );
  }

  return (
    <div className={classnames('certification-page')}>
      <CertificationSettings
        certification={certification}
        certificatesHost={certificatesHost}
      />

      <hr />

      <CertifiedAttendeesPage
        certification={certification}
        certificatesHost={certificatesHost}
      />
    </div>
  );
};

CertificationPage.propTypes = {
  event: eventShape.isRequired,

  certification: PropTypes.shape({
    id: PropTypes.string,
  }),

  certificatesHost: PropTypes.string,
};

CertificationPage.defaultProps = {
  certification: null,
  certificatesHost: null,
};

export default CertificationPage;
