import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import CircularTimePicker from '../inputs/CircularTimePicker';

const extractError = (errors, name) => {
  const chunks = name.split(/[[\]]/).filter((chunk) => (chunk.trim().length > 0));

  return chunks.reduce((acc, chunk) => {
    if (!acc) { return null; }

    return acc[chunk] || null;
  }, errors);
};

const CirculaTimespanPickerInput = ({
  label,
  errors,
  name,
  control,
  required,
  defaultValue,
  ...props
}) => {
  const error = extractError(errors, name);
  const errorMessage = error
    ? error.message || 'Required field'
    : null;

  const handleChange = ({ segments, field }) => {
    field.onChange(segments);
  };

  return (
    <Form.Group controlId={`form-grid-${name}`}>
      {
        label && (
          <Form.Label>
            {label}
            &nbsp;
            {required && (<abbr title="required">*</abbr>)}
          </Form.Label>
        )
      }
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={{ required }}
        render={({ field }) => (
          <CircularTimePicker
            {...props}
            {...field}
            isInvalid={!!errorMessage}
            onClick={(segments) => { handleChange({ segments, field }); }}
          />
        )}
      />
      {errorMessage && (
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

CirculaTimespanPickerInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  errors: PropTypes.shape({}),
  control: PropTypes.shape({}).isRequired,
  required: PropTypes.bool,
};

CirculaTimespanPickerInput.defaultProps = {
  label: 'Timespan Input',
  errors: {},
  required: false,
  defaultValue: '',
};

export default CirculaTimespanPickerInput;
