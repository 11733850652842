import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import classnames from 'classnames';
import uuidv4 from 'uuid/v4';
import Button from 'react-bootstrap/Button';

import Icon from '../../../Icon';
import NestedLabelFields from './NestedLabelFields';

const buildEmptyLabel = () => ({
  name: '',
  color: null,
  labelId: uuidv4(),
});

const NestedLabels = ({ initialLabels, errors, control }) => {
  const parsedLabels = initialLabels && initialLabels.length > 0
    ? initialLabels.map(({ name, color, labelId }) => ({ name, color, labelId }))
    : [buildEmptyLabel()];

  const [currentLabels, setCurrentLabels] = useState(parsedLabels);
  const handleAdd = () => {
    setCurrentLabels((stateLabels) => ([
      ...stateLabels,
      buildEmptyLabel(),
    ]));
  };

  const handleRemove = (labelId) => {
    const newLabels = currentLabels.filter((label) => (label.labelId !== labelId));
    setCurrentLabels(newLabels);
  };

  return (
    <div className="NestedLabels">
      <h4>
        <Icon name="tags" fw />
        { ' Labels' }
      </h4>

      <div>
        {
          currentLabels.map(({ labelId, color, name }) => (
            <NestedLabelFields
              key={labelId}
              errors={errors}
              control={control}
              labelId={labelId}
              color={color}
              name={name}
              onRemove={handleRemove}
            />
          ))
        }
      </div>

      <Button variant="success" onClick={handleAdd}>
        <Icon name="plus" fw />
        &nbsp;
        Add label
      </Button>
    </div>
  );
};

NestedLabels.propTypes = {
  initialLabels: PropTypes.arrayOf(PropTypes.shape({
    labelId: PropTypes.string,
    color: PropTypes.string,
    name: PropTypes.string,
  })),
};

NestedLabels.defaultProps = {
  initialLabels: [],
};

export default NestedLabels;
