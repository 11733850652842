import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const iconFamily = (provider) => {
  switch (provider) {
    case 'google':
      return 'fab';

    default:
      console.error('Unhandled provider icon');
      return 'question';
  }
};

const iconName = (provider) => {
  switch (provider) {
    case 'google':
      return 'google';

    default:
      console.error('Unhandled provider icon');
      return 'question';
  }
};

const ProviderIcon = ({ provider, ...iconProps }) => (
  <Icon
    name={iconName(provider)}
    family={iconFamily(provider)}
    {...iconProps}
    fw
  />
);

ProviderIcon.propTypes = {
  provider: PropTypes.string.isRequired,
};

export default ProviderIcon;
