import React from 'react';

import { createRecipe } from '../../../api/smart_planner/recipes';
import handleError from '../../../utils/error-handler';
import { visit } from '../../../utils/router';
import { success } from '../../../utils/notifier';
import Form from './Form';
import Icon from '../../Icon';

const New = () => {
  const handleCreate = (params) => {
    createRecipe(params)
      .then((response) => response.json())
      .then(({ redirect_to, message }) => {
        visit(redirect_to).then(() => { success({ message }); });
      })
      .catch(handleError);
  };

  return (
    <div className="card">
      <div className="card-header bg-info">
        <h1 className="text-center">
          <Icon name="shapes" />
          &nbsp;
          New recipe
        </h1>
      </div>

      <div className="card-body">
        <div className="col-12 col-lg-10 mx-auto mt-4 mb-4">
          <Form onSubmit={handleCreate} />
        </div>
      </div>
    </div>
  );
};

export default New;
