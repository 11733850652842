import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import moment from 'moment';
import Money from '../../utils/money';

const chartHeight = 600;
const chartControlsHeight = 140;
const serializeRubyMoney = (rubyMoney) => {
  const money = Money(rubyMoney);

  return {
    v: money.toUnit(),
    f: money.toFormat(),
  };
};
const normalizeRows = (series) => (
  series.map((dateValues) => (
    dateValues.map((value, index) => (
      // The first value is the Date
      index === 0
        ? moment(value).toDate()
        : serializeRubyMoney(value)
    ))
  ))
);
const buildColumns = (legend) => {
  const columns = [
    { type: 'date', label: 'Date' },
    { type: 'number', label: 'Total' },
  ];

  Object.keys(legend).forEach((key) => {
    const item = legend[key];

    columns[item.position] = {
      type: 'number',
      label: item.name,
    };
  });


  return columns;
};
const buildSeriesOptions = (legend) => (
  Object.keys(legend).reduce((accumulator, key) => {
    const item = legend[key];

    accumulator[item.position - 1] = {
      color: item.color,
    };

    return accumulator;
  }, {})
);

class LiquiditySummaryChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();

    const { legend, series, sinceDaysAgo } = props;
    const fromDate = moment().subtract(sinceDaysAgo, 'day');

    this.state = {
      rows: normalizeRows(series),
      columns: buildColumns(legend),
      series: buildSeriesOptions(legend),
      fromDate,
    };
  }

  render() {
    const { rows, columns, series, fromDate } = this.state;
    const start = fromDate.toDate();
    const end = (rows[rows.length - 1] || [])[0];
    const wrapperStyle = {
      overflow: 'visible',
      marginBottom: chartControlsHeight,
    };

    return (
      <div className="LiquiditySummaryChart" style={wrapperStyle}>
        <Chart
          ref={this.chartRef}
          chartType="SteppedAreaChart"
          width="100%"
          height={chartHeight}
          rows={rows}
          columns={columns}
          loader={<div style={{ textAlign: 'center', padding: 100 }}>Loading liquidity chart...</div>}
          options={{
            title: 'Organization liquidity over time',
            backgroundColor: 'transparent',
            vAxis: {
              title: 'Liquidity',
              minValue: 0,
            },
            isStacked: false,
            series,
            chartArea: { height: '80%', width: '70%' },
          }}
          chartPackages={['corechart', 'controls']}
          controls={[
            {
              controlType: 'ChartRangeFilter',
              options: {
                filterColumnIndex: 0,
                ui: {
                  chartOptions: {
                    backgroundColor: 'transparent',
                    chartArea: { width: '70%', height: '50%' },
                    hAxis: { baselineColor: 'none' },
                  },
                },
              },
              controlPosition: 'bottom',
              controlWrapperParams: {
                state: {
                  range: { start, end },
                },
              },
            },
          ]}
        />
      </div>
    );
  }
}

LiquiditySummaryChart.propTypes = {
  sinceDaysAgo: PropTypes.number,
  legend: PropTypes.object.isRequired,
  series: PropTypes.array.isRequired,
};

LiquiditySummaryChart.defaultProps = {
  sinceDaysAgo: 365,
};

export default LiquiditySummaryChart;
