import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  // format,
  isSameMonth,
} from 'date-fns';

import Header from './Header';
import WeekHeader from './WeekHeader';
import WeekDays from './WeekDays';
// import Footer from './Footer';

import styles from './WeekViewCalendar.module.scss';

const AlternatedHeader = ({ index, weeksData, weekStartedOn }) => {
  const isMonthChanged = index > 0
    && !isSameMonth(weekStartedOn, weeksData[index - 1].weekStartedOn);

  if (index === 0 || isMonthChanged) {
    return (
      <Header
        weekStartedOn={weekStartedOn}
      />
    );
  }

  return null;
};

const WeekViewCalendar = ({
  onEventFocus,
  onDayFocus,
  weeksData,
  sortEvents,
  filterEvent,
  viewMode,
  labels,
}) => {
  // const today = new Date();
  const [selectedDate, setSelectedDate] = useState(null);
  const onDateClickHandle = (day) => {
    // const dayStr = format(day, 'ccc dd MMM yy');
    setSelectedDate(day);
    onDayFocus(day); // Not sure about this api
  };

  const isDateSelected = !!selectedDate;
  const handleBackdropClick = () => {
    setSelectedDate(null);
  };

  return (
    <div className={classnames(styles.calendar, 'WeekViewCalendar')}>
      <div
        className={classnames(styles.backdrop, { [styles.activeBackdrop]: isDateSelected })}
        onClick={handleBackdropClick}
        onKeyPress={handleBackdropClick}
        role="none"
      />

      <div className={classnames(styles.calendarGrid)}>
        {
          weeksData.map(({
            id,
            weekStartedOn,
            isLoading,
            events,
          }, index) => (
            <React.Fragment key={id}>
              <AlternatedHeader
                index={index}
                weeksData={weeksData}
                weekStartedOn={weekStartedOn}
              />

              <WeekDays
                weekStartedOn={weekStartedOn}
                selectedDate={selectedDate}
                events={events}
                isLoading={isLoading}
                onDateChange={(day) => { onDateClickHandle(day); }}
                onEventFocus={onEventFocus}
                sortEvents={sortEvents}
                filterEvent={filterEvent}
                viewMode={viewMode}
                labels={labels}
              />
            </React.Fragment>
          ))
        }

        <WeekHeader
          weekStartedOn={weeksData[0].weekStartedOn}
        />
      </div>
    </div>
  );
};

WeekViewCalendar.propTypes = {
  weeksData: PropTypes.arrayOf(PropTypes.shape({
    weekStartedOn: PropTypes.instanceOf(Date),
  })).isRequired,
  onDayFocus: PropTypes.func,
  onEventFocus: PropTypes.func,
  sortEvents: PropTypes.func,
  filterEvent: PropTypes.func,
  viewMode: PropTypes.string,
};

WeekViewCalendar.defaultProps = {
  onDayFocus: () => {},
  onEventFocus: () => {},
  sortEvents: (events) => (events),
  filterEvent: () => (true),
  viewMode: 'compact'
};

export default WeekViewCalendar;
