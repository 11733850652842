import { get } from '../base';

const basePath = () => (
  [
    '/smart_planner/availabilities',
  ].join('/')
);

const date2Iso = (date) => {
  if (typeof date === 'string') { return date; }
  if (!date) { return ''; }

  return date.toISOString();
};

export const userAvailableSlots = ({ userId, priority = 0 }) => (
  get(`${basePath()}/slots.json?user_id=${userId}&priority=${priority}`)
);

// NOTE: duration must be provided with minutes unit
export const firstAvailableSlot = (duration, {
  priority = 0,
  from,
  to,
}) => {
  const qString = [
    `duration=${duration}`,
    `&priority=${priority}`,
    `&from=${date2Iso(from)}`,
    `&to=${date2Iso(to)}`,
  ].join('&');

  return get(`${basePath()}/first-available-slot.json?${qString}`);
};

export default {
  userAvailableSlots,
  firstAvailableSlot,
};
