import React from "react"
import moment from 'moment'
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-basic';
const PlotlyComponent = createPlotlyComponent(Plotly);

class TicketsSoldChart extends React.Component {
  state = {
    data: [],
  }

  componentWillMount() {
    this.loadData()
  }

  loadData() {
    fetch(this.props.dataUrl, {
      credentials: 'same-origin'
    })
      .then(response => response.ok ? response.json() : [])
      .then(json => this.setState({
        data: this.parseData(json)
      }))
  }

  unpack(rows, key) {
    return rows.map((row) => {
      return row[key];
    });
  }

  parseData(data) {
    return data.map((item) => {
      return {
        date: moment(item.date).toDate(),
        sold_tickets: item.sold_tickets,
        cancelled_tickets: -item.cancelled_tickets,
        base_price: item.base_price['fractional'] / 100
      }
    })
  }

  render() {
    if (this.state.data.length == 0)
      return <div>Loading...</div>

    let traceSoldTickets = {
      name: 'Sold Tickets',
      type: "bar",
      xaxis: 'x',
      yaxis: 'y',
      x: this.unpack(this.state.data, 'date'),
      y: this.unpack(this.state.data, 'sold_tickets'),
      line: {
        width: 0.5
      },
    }

    let traceCancelledTickets = {
      name: 'Cancelled Tickets',
      type: "bar",
      xaxis: 'x',
      yaxis: 'y',
      x: this.unpack(this.state.data, 'date'),
      y: this.unpack(this.state.data, 'cancelled_tickets'),
      line: {
        width: 0.5
      },
    }

    let traceIncome = {
      name: 'Income',
      type: "scatter",
      xaxis: 'x',
      yaxis: 'y2',
      x: this.unpack(this.state.data, 'date'),
      y: this.unpack(this.state.data, 'base_price'),
      line: {
        width: 0.5
      }
    }

    let layout = {
      title: 'Tickets Sold by day',
      autosize: true,
      xaxis: {
        title: 'time',
        type: 'date',
        tickmode: 'auto',
        'rangeslider': {
          visible: !!this.props.showRangeSlider,
          autorange: true
        },
        range: (this.props.scopeYear ? [moment().startOf('year').format(), moment().format()] : []),
        rangeselector: {
          buttons: [
            {
              step: 'all',
              label: 'reset'
            },
            {
              step: 'year',
              stepmode: 'todate',
              count: 1,
              label: 'This year'
            }
          ]
        }
      },
      yaxis: {
        fixedrange: true,
        side: "left"
      },

      yaxis2: {
        side: 'right',
        overlaying: 'y',
        tickprefix: '€',
        tickformat: ',.2r'
      },

      legend: {
        orientation: 'h'
      },

      dragmode: 'zoom'
    };

    let config = {
      showLink: false,
      displayModeBar: true
    };

    return (
      <PlotlyComponent data={[traceSoldTickets, traceCancelledTickets, traceIncome]} layout={layout} config={config} />
    );
  }
}

export default TicketsSoldChart
