import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';

import LabelSelector from './LabelSelector';
import Icon from '../../Icon';
import { calendarEventShape } from '../proptypes';
import styles from './LabelsPicker.module.scss';

const AddLabelModalLabels = ({
  onShowNewLabelForm,
  onToggle,
  onEdit,
  onDelete,
  className,
  labels,
  event,
  user,
  show,
}) => {
  const { label_ids } = event;
  const systemLabels = labels.filter(({ system }) => (system === true));
  const userLabels = labels.filter(({ system }) => (system === false));

  return (
    <div
      className={classnames(
        styles.AddLabelModalLabels, className,
        { 'd-none': !show }
      )}
    >
      <div className={styles.AddLabelModal__body}>
        <Scrollbars>
          {
            systemLabels.length > 0 && (
              <h6>System labels</h6>
            )
          }
          <>
            {
              systemLabels.map((label) => (
                <LabelSelector
                  key={label.id}
                  label={label}
                  onClick={onToggle}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  selected={_.includes(label_ids, label.id)}
                  user={user}
                />
              ))
            }
          </>
          {
            userLabels.length > 0 && (
              <h6>Your labels</h6>
            )
          }
          <>
            {
              userLabels.map((label) => (
                <LabelSelector
                  key={label.id}
                  label={label}
                  onClick={onToggle}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  selected={_.includes(label_ids, label.id)}
                  user={user}
                />
              ))
            }
          </>
        </Scrollbars>
      </div>

      <div className={styles.AddLabelModal__footer}>
        <div className={classnames('text-right')}>
          <Button onClick={onShowNewLabelForm}>
            <Icon name="plus" fw />
            &nbsp;
            New label
          </Button>
        </div>
      </div>
    </div>
  );
};

AddLabelModalLabels.propTypes = {
};

AddLabelModalLabels.defaultProps = {
};

export default AddLabelModalLabels;
