import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';

import Input from '../../RHF/Input';
import ColorInput from '../../RHF/ColorInput';
import { randomColor } from '../../../utils/colors';
import Icon from '../../Icon';
import { calendarEventShape } from '../proptypes';
import { deleteLabel } from '../../../api/smart_planner/labels';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';

import FormFields from './FormFields';
import styles from './LabelsPicker.module.scss';

const AddLabelModalConfirmDelete = ({
  onBack,
  onUpdate,
  onDelete,
  className,
  label,
  show,
  formId,
}) => {
  if (!show) { return null; }

  const [isLoading, setIsLoading] = useState(false);
  const { id, name, color } = label;

  const handleDelete = () => {
    setIsLoading(true);

    deleteLabel(id)
      .then((response) => (response.json()))
      .then(({ message }) => {
        success({ message });
        setIsLoading(false);
        onBack();
        onDelete(label);
      })
      .catch(handleError);
  };

  return (
    <div
      className={classnames(
        styles.AddLabelModalForm, className,
        { 'd-none': !show },
      )}
    >
      <div className={styles.AddLabelModal__body}>
        <h4 className="text-danger pt-2">
          Are you sure you want to delete the label "<strong>{name}</strong>"?
        </h4>
      </div>

      <div className={styles.AddLabelModal__footer}>
        <Button
          variant="danger"
          className="float-right"
          disabled={isLoading}
          onClick={handleDelete}
        >
          {
            isLoading
              ? (<Icon name="spinner" spin fw />)
              : (<Icon name="trash-alt" fw />)
          }
          &nbsp;
          Delete
        </Button>

        <Button
          variant="secondary"
          onClick={() => { onBack(); }}
          disabled={isLoading}
        >
          <Icon name="angle-left" fw />
          &nbsp;
          Back
        </Button>
      </div>
    </div>
  );
};

AddLabelModalConfirmDelete.propTypes = {
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  label: PropTypes.shape({}).isRequired,
  formId: PropTypes.string,
};

AddLabelModalConfirmDelete.defaultProps = {
  formId: 'edit-label-form',
  onUpdate: () => {},
  onDelete: () => {},
};

export default AddLabelModalConfirmDelete;
