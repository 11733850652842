import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import Money, { moneyToChartValue } from '../../../utils/money';

const YearlyCategoryBarChart = ({
  category, labels, title, dataSet, chartArea, height,
}) => {
  const sortedLabels = labels.reduce((accumulator, label, index) => {
    const { label_id, name, color } = label;
    accumulator[label_id] = {
      ...label,
      index: index + 1,
    };

    return accumulator;
  }, {});

  const emptyData = {};
  const toYear = (new Date()).getFullYear();
  const fromYear = toYear - 10;
  const zero = Money({ cents: 0 });
  const chartZero = moneyToChartValue(zero);

  for (let year = fromYear; year <= toYear; year += 1) {
    emptyData[year] = new Array(labels.length + 1);
    emptyData[year].fill(chartZero);
    emptyData[year][0] = year.toString();
  }

  const rawData = labels.reduce((accumulator, label) => {
    const { label_id } = label;
    const values = category[dataSet];
    const index = sortedLabels[label_id].index;

    Object.keys(values).forEach((year) => {
      const amount = Money({ cents: (values[year] || {})[label_id] || 0 });
      accumulator[year][index] = moneyToChartValue(amount);
    });

    return accumulator;
  }, emptyData);

  const header = ['Year'].concat(labels.map(({ name }) => (name)));
  const data = [header].concat(Object.values(rawData));
  const isStacked = true;
  const colors = labels.map(({ color }) => (color));

  return (
    <div className="YearlyCategoryBarChart">
      <Chart
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        data={data}
        height={height}
        options={{
          legend: 'none',
          isStacked,
          vAxis: {
            style: 'currency',
            format: '\u20AC#',
          },
          title,
          colors,
          chartArea,
        }}
      />
    </div>
  );
};

YearlyCategoryBarChart.propTypes = {
  title: PropTypes.string,
  dataSet: PropTypes.string,
  height: PropTypes.string,
  chartArea: PropTypes.object,
};

YearlyCategoryBarChart.defaultProps = {
  title: null,
  dataSet: 'yearly_profits',
  height: '450px',
  chartArea: {
    width: '70%',
    height: '70%',
  }
};

export default YearlyCategoryBarChart;
