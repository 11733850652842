import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import Icon from '../../../../Icon';

const RepartitionsChart = ({
  entities, height, scale, tooltip, pieHole,
}) => {
  const data = entities.map(({ repartition, name }) => ([
    name, repartition * 100,
  ]));
  const sum = data.reduce((acc, current) => (
    acc + current[1]
  ), 0);

  if (sum < 100) {
    data.push(['Unspecified', 100 - sum]);
  }
  const header = ['Label', 'Repartition'];
  const slices = entities.reduce((hash, { color }, index) => ({
    ...hash,
    [index]: { color },
  }), {});

  const wrapperStyles = {
    height,
    overflow: 'visible',
  };

  const tooltipOption = tooltip
    ? true
    : { trigger: 'none' };

  return (
    <div className="RepartitionsChart" style={wrapperStyles}>
      <div style={{ transform: `scale(${scale})` }}>
        <Chart
          loader={<Icon name="spinner" fw spin />}
          chartType="PieChart"
          width="100%"
          height={`${height}px`}
          data={[header, ...data]}
          options={{
            slices,
            pieHole,
            legend: 'none',
            backgroundColor: 'transparent',
            is3D: false,
            tooltip: tooltipOption,
            chartArea: {
              width: '100%',
            },
          }}
        />
      </div>
    </div>
  );
};

RepartitionsChart.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.object).isRequired,
  height: PropTypes.number,
  scale: PropTypes.number,
  pieHole: PropTypes.number,
  tooltip: PropTypes.bool,
};

RepartitionsChart.defaultProps = {
  height: 300,
  scale: 1.4,
  tooltip: true,
  pieHole: 0.4,
};

export default RepartitionsChart;
