import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { isPresent } from '../../utils/common';
import Icon from '../Icon';
import { colorizedLabel } from '../../utils/money';
import handleError from '../../utils/error-handler';
import { success } from '../../utils/notifier';
import {
  trackExpenseOperation, trackRevenueOperation,
} from '../../api/operations';
import SyncButton from '../billing/SyncButton';

const ICONS = {
  expense: 'sign-out-alt',
  revenue: 'sign-in-alt',
};

const EditModal = ({
  show,
  onHide,
  onConversion,

  operation,
  typology,
  humanOperationType,
  operationId,
  amount,
  operationHeadline,
  operationCountry,
  operationNotes,
  operationDate,
  counterpart,
  operationReference,
}) => {
  const targetTypology = typology === 'expense' ? 'revenue' : 'expense';
  const variant = targetTypology === 'expense' ? 'danger' : 'success';
  const iconName = ICONS[targetTypology];
  const FormattedAmount = colorizedLabel(amount);
  const trackApi = targetTypology === 'revenue'
    ? trackRevenueOperation
    : trackExpenseOperation;
  const sanitizedNotes = operationNotes
    ? operationNotes.replace(/(<([^>]+)>)/gi, '')
    : '-';

  const handleConversion = () => {
    trackApi({ operationId })
      .then((response) => response.json())
      .then((data) => {
        success({ message: data.message });
        onConversion({ operationId });
      })
      .catch(handleError);
  };

  const {
    parent_operation_id,
    credit_note_id,
    refers_to,
    operation_external_reference,
  } = operation;

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Details</strong>
          &nbsp;
          <small>{ operationHeadline }</small>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Alert variant={variant}>
          <h5>
            Convert the
            &nbsp;
            <em>{humanOperationType}</em>
            &nbsp;
            into a
            &nbsp;
            <strong>
              &quot;
              {targetTypology}
              &quot;
            </strong>
            &nbsp;
            operation.
          </h5>

          <hr />

          <div className="text-right">
            <Button variant={variant} onClick={handleConversion}>
              <Icon name={iconName} fw />
              Convert
            </Button>
          </div>
        </Alert>

        <dl>
          <dt>ID:</dt>
          <dd>{operationId}</dd>

          <dt>Typology:</dt>
          <dd>{typology.toUpperCase()}</dd>

          <dt>Document type:</dt>
          <dd>{humanOperationType}</dd>

          <dt>Headline:</dt>
          <dd>
            {operationHeadline}
          </dd>

          <dt>Reference number:</dt>
          <dd>{operationReference || '-'}</dd>

          <dt>Date:</dt>
          <dd>{operationDate}</dd>

          <dt>Counterpart:</dt>
          <dd>{counterpart}</dd>

          <dt>Country:</dt>
          <dd>{operationCountry}</dd>

          <dt>Amount:</dt>
          <dd>
            <strong>{FormattedAmount}</strong>
          </dd>

          <dt>Notes:</dt>
          <dd>{sanitizedNotes}</dd>

          <dt>Credit note:</dt>
          <dd>{credit_note_id || '-'}</dd>

          <dt>Parent:</dt>
          <dd>{parent_operation_id || '-'}</dd>

          <dt>Fatture in Cloud reference:</dt>
          <dd>{operation_external_reference || '-'}</dd>
        </dl>
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        {
          isPresent(operation_external_reference) && (
            <SyncButton
              id={refers_to}
              humanDocumentType={humanOperationType}
              progressiveNumber={operationReference}
              headline={operationHeadline}
              issuedOn={operationDate}
              size="md"
              showConfirmationModal={false}
              onSync={() => { onHide(); }}
              showButtonText
            />
          )
        }

        <Button variant="secondary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

EditModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  operationId: PropTypes.string.isRequired,
  operation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    refers_to: PropTypes.string.isRequired,
  }).isRequired,
  typology: PropTypes.string.isRequired,
  onConversion: PropTypes.func,
  operationNotes: PropTypes.string,
  operationDate: PropTypes.string.isRequired,
};

EditModal.defaultProps = {
  show: false,
  onConversion: () => {},
  operationNotes: '',
};

export default EditModal;
