import {
  get,
  post,
  patch,
  httpDelete,
} from '../base';

const basePath = () => ('/smart_planner/recipes');

const serializeStep = ({ offset_type, duration, ...stepParams }) => ({
  ...stepParams,
  minutes_duration: duration,
  offset_type,
});

export const fetchRecipes = () => (
  get(`${basePath()}.json`)
);

export const deleteRecipe = (recipeId) => (
  httpDelete(`${basePath()}/${recipeId}.json`)
);

export const createRecipe = ({
  name,
  description,
  color,
  steps,
}) => (
  post(`${basePath()}.json`, {
    recipes: {
      name,
      color,
      description,
      steps: steps.map(serializeStep),
    },
  })
);

export const updateRecipe = ({
  recipeId,
  name,
  description,
  color,
  steps,
}) => (
  patch(`${basePath()}/${recipeId}.json`, {
    recipes: {
      name,
      color,
      description,
      steps: steps.map(serializeStep),
    },
  })
);

export default {
  fetchRecipes,
  deleteRecipe,
  createRecipe,
  updateRecipe,
};
