import Money from '../../utils/money';

export const colorize = (money = null) => {
  if (!money) { return ''; }

  let color = '';
  if (Money(money).isPositive()) {
    color = 'green';
  } else {
    color = 'red';
  }

  return color;
};

export default {
  colorize,
};
