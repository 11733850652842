import React from "react";
import Money from '../utils/money.js';
import PriceValue from './expectations/PriceValue';

class ExpectationsArchiveRow extends React.Component {
    generate_url(id = null, action = null) {
      let url = this.props.direction === 'in'
        ? '/financial_analysis/income_expectations/'
        : '/financial_analysis/expense_expectations/';
      if (id) url = url + id + '/';
      if (action) url = url + action;
      return url
    }

    // TODO -> Make this a generic one.
    static colorize(money = null) {
        let color = '';
        if (Money(money).isPositive()) {
            color ='green';
        } else {
            color ='red';
        }
        return color
    }

    colorize_deadline(deadline) {
        let color = '';
        if (Date.parse(deadline) < Date.now())
            color = 'badge badge-warning';
        else
            color = 'badge badge-light';
        return color;
    }

    icon_for(expectation_type) {
        let icon_string = 'fa fa-lg';
        if (expectation_type === 'single-shot') icon_string += ' fa-arrow-down';
        if (expectation_type === 'recurring') icon_string += ' fa-repeat';
        return icon_string;
    }

    render() {
        const id = this.props._id;
        const headline = this.props.headline;
        const expected_amount = this.props.sign_adjusted_amount;
        const expected_on_date = this.props.expected_on_date;
        const expectation_type = this.props.expectation_type;
        const { item, state } = this.props;

        return (
            <tr key={id} className="row">
                <td className="text-right col-lg-3">
                  <PriceValue expectation={item} />
                </td>
                <td className="col-lg-1 text-center">
                    <i className={this.icon_for(expectation_type)}></i>
                </td>
                <td className="col-lg-3">
                  <div className="text-truncate" style={{ maxWidth: 150 }}>
                    <a href={this.generate_url(id)}>{headline}</a>
                  </div>
                </td>
                <td className="col-lg-2"><h5><span className = {this.colorize_deadline(expected_on_date)}>{expected_on_date}</span></h5></td>
                <td className="col-lg-3 text-center">

                    <a href={this.generate_url(id, 'register_complete')}
                       className="btn btn-ghost-success btn-sm"
                       data-toggle="tooltip"
                       data-placement="top"
                       title="Mark as fulfilled"
                       data-original-title="Mark as fulfilled"><i className="fa fa-check"></i></a>

                    <a href={this.generate_url(id, 'edit')}
                       className="btn btn-ghost-info btn-sm"
                       data-toggle="tooltip"
                       data-placement="top"
                       title="Edit expectation"
                       data-original-title="Edit expectation"><i className="fa fa-edit"></i></a>

                    <a href={this.generate_url(id, 'delete')}
                       className="btn btn-ghost-dark btn-sm"
                       data-toggle="tooltip"
                       data-placement="top"
                       data-confirm="Are you sure?"
                       title="Delete expectation"
                       data-original-title="Delete expectation"><i className="fa fa-trash"></i></a>
                </td>
            </tr>

        )
    }
}

export default ExpectationsArchiveRow
