import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import PriorityUpdateModal from './PriorityUpdateModal';
import { calendarEventShape } from '../proptypes';
// import { visit } from '../../../utils/router';
import Icon from '../../Icon';
import { success } from '../../../utils/notifier';

const PriorityInplaceInput = ({
  event,
  className,
  onClick,
  onChange,
  onHide,
}) => {
  const [showModal, setShowModal] = useState(false);
  const hideModal = () => {
    onHide();
    setShowModal(false);
  };
  const { priority } = event;
  const handlePriorityUpdate = (eventId, attrs) => {
    onChange(eventId, attrs);
  };
  const handleClick = (e) => {
    e.preventDefault();
    setShowModal(true);
    onClick();
  };

  return (
    <div className={classnames('PriorityInplaceInput', className)}>
      <PriorityUpdateModal
        show={showModal}
        event={event}
        onHide={hideModal}
        onUpdate={handlePriorityUpdate}
      />

      <a href="#priority" onClick={handleClick}>
        <span className="editable">{priority || 'N/A'}</span>
        &nbsp;
        <Icon name="pencil-alt" fw />
      </a>
    </div>
  );
};

PriorityInplaceInput.propTypes = {
  event: calendarEventShape.isRequired,
  onClick: PropTypes.func,
  onHide: PropTypes.func,
};

PriorityInplaceInput.defaultProps = {
  onClick: () => {},
  onHide: () => {},
};

export default PriorityInplaceInput;
