import { get, httpDelete } from '../base';

const basePath = '/smart_planner/accounts';

export const fetchUserAccounts = () => (
  get(`${basePath}.json`)
);

export const deleteUserAccount = (accountId) => (
  httpDelete(`${basePath}/${accountId}.json`)
);

export default {
  fetchUserAccounts,
  deleteUserAccount,
};
