import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

// import Icon from '../../Icon';
import { calendarEventShape } from '../proptypes';

const handleApiLoaded = (map, maps, location) => {
  const markers = [];
  const infowindows = [];

  markers.push(new maps.Marker({
    position: {
      lat: location.lat,
      lng: location.lng,
    },
    map,
  }));

  const content = `
    <div class="p-3">
      <div>
        Location:
      </div>

      <h6 class="mt-2">
        <i class="fa-1x fas fa-map-marker-alt fa-1x"></i>
        ${location.address}
      </h6>
    </div>
  `;

  infowindows.push(new maps.InfoWindow({ content }));

  markers.forEach((marker, i) => {
    marker.addListener('click', () => {
      infowindows[i].open(map, marker);
    });
  });
};

const CalendarEventLocationMap = ({ event, zoomLevel, className }) => {
  const { location, location_latitude, location_longitude } = event;

  const marker = {
    address: location,
    lat: location_latitude,
    lng: location_longitude,
  };

  return (
    <div className={className}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: '' }}
        defaultCenter={marker}
        defaultZoom={zoomLevel}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, marker)}
      />
    </div>
  );
};

CalendarEventLocationMap.propTypes = {
  event: calendarEventShape,
  zoomLevel: PropTypes.number,
  className: PropTypes.string,
};

CalendarEventLocationMap.defaultProps = {
  event: {},
  zoomLevel: 15,
  className: '',
};

export default CalendarEventLocationMap;
