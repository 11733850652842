import React from 'react';
import PropTypes from 'prop-types';

import ListingTable from '../ListingTable';

import Spinner from '../Spinner';
import WithExpenses from './WithExpenses';
import Chart from '../MonthlyBillingBarChart';

const ExpensesPage = (({
  loading,
  expenses,
  // onUpdate,
  // onDelete,
}) => {
  if (loading) {
    return (
      <div>
        <Spinner
          description="Loading expense documents..."
        />
      </div>
    );
  }

  return (
    <div className="mt-3 card">
      <div className="card-body pt-5">
        <Chart
          billingData={expenses}
        />

        <hr className="my-5" />

        <ListingTable
          billingData={expenses}
          items={expenses}
          hideSearch={false}
          responsive={false}
          rowComponent="BillingDocumentRow"
          filterKeys={['headline', 'customer', 'progressive_number']}
          headers={['Headline', 'Customer', 'Document type', 'Amount', 'Status', 'Actions']}
        />
      </div>
    </div>
  );
});

ExpensesPage.propTypes = {
  loading: PropTypes.bool,
  // onUpdate: PropTypes.func.isRequired,
  // onDelete: PropTypes.func.isRequired,
};

ExpensesPage.defaultProps = {
  loading: false,
};

export default WithExpenses(ExpensesPage);
