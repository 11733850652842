// import $ from 'jquery';
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Creatable from 'react-select/creatable';

import Icon from '../Icon';

const getSelectComponent = (type) => {
  switch (type) {
    case 'creatable':
      return Creatable;
    default:
      return Select;
  }
};

// Used mostly into the SimpleForm rb wrapper
class SelectInput extends React.Component {
  constructor(props) {
    super(props);

    const { initialValue } = props;
    const options = this.computedOptions();
    const selectedOption = typeof initialValue === 'string'
      ? options.find((o) => (o.value === initialValue))
      : initialValue;

    this.state = { selectedOption };

    if (props.behavior) {
      this.behavior = require(`../../behaviors/${props.behavior}`);
      this.behavior.initialize(props, this.state);
    }
  }

  componentWillUnmount() {
    if (this.behavior) {
      this.behavior.destroy(this.props, this.state);
    }
  }

  handleChange(selectedOption) {
    const { onChange } = this.props;
    const newState = {
      ...this.state,
      selectedOption,
    };

    this.setState(newState);

    onChange(selectedOption);

    if (this.behavior && this.behavior.onChange) {
      this.behavior.onChange(this.props, newState);
    }
  }

  computedOptions() {
    const {
      options, labelKey, valueKey, iconKey,
    } = this.props;

    return options.map((obj) => {
      const label = obj[iconKey]
        ? (<span><Icon name={obj[iconKey]} fw /> {obj[labelKey]}</span>)
        : obj[labelKey];
      const value = obj[valueKey] || obj.value;

      return {
        ...obj,
        label,
        value,
        orig: obj,
      };
    });
  }

  renderInput() {
    const { selectedOption } = this.state;
    const {
      name, valueKey, labelKey, multi, inputProps, type,
    } = this.props;

    const options = this.computedOptions();
    const SelectComponent = getSelectComponent(type);

    return (
      <SelectComponent
        {...inputProps}
        name={name}
        multi={multi}
        valueKey={valueKey}
        labelKey={labelKey}
        value={selectedOption}
        options={options}
        className="Select-lg Select"
        onChange={(e) => { this.handleChange(e); }}
      />
    );
  }

  renderVerticalInput() {
    const { label } = this.props;

    return (
      <div className="form-group select-input">
        {
          label.length > 0 ? <label>{label}</label> : null
        }
        {this.renderInput()}
      </div>
    );
  }

  render() {
    const { layout } = this.props;

    return (
      layout
        ? this.renderVerticalInput()
        : this.renderInput()
    );
  }
}

SelectInput.propTypes = {
  initialValue: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.shape({}),
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  iconKey: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  multi: PropTypes.bool,
  behavior: PropTypes.string,
  type: PropTypes.oneOf(['default', 'creatable']),
  layout: PropTypes.oneOf(['vertical', 'horizontal', false]),
  onChange: PropTypes.func,
  inputProps: PropTypes.shape({}),
};

SelectInput.defaultProps = {
  initialValue: null,
  name: 'select',
  valueKey: 'value',
  labelKey: 'label',
  iconKey: 'icon',
  options: [],
  multi: false,
  behavior: null,
  layout: 'vertical', // vertical | horizontal | false
  label: '',
  onChange: () => {},
  inputProps: {},
  type: 'default',
};

export default SelectInput;
