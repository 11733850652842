import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  addDays,
  isSameDay,
  getWeek,
  parseISO,
  endOfDay,
  areIntervalsOverlapping,
} from 'date-fns';

import DayCell from './DayCell';
import WeekDetails from './WeekDetails';
import styles from './WeekViewCalendar.module.scss';

const filterDayEvent = (day, event) => {
  const eventStartAt = parseISO(event.start_at || event.start_on);
  const eventEndAt = parseISO(event.end_at || event.end_on);
  const dayStartAt = day;
  const dayEndAt = endOfDay(day);

  return areIntervalsOverlapping(
    { start: eventStartAt, end: eventEndAt },
    { start: dayStartAt, end: dayEndAt },
  );
};

const WeekDays = ({
  weekStartedOn,
  selectedDate,
  onDateChange,
  onWeekDetailsClick,
  onEventFocus,
  isLoading,
  events,
  labels,
  sortEvents,
  filterEvent,
  viewMode,
}) => {
  const weekNumber = getWeek(weekStartedOn);
  const dateFormat = 'd';
  const days = [];
  let day = weekStartedOn;

  const handleDayCellFocus = (date) => { onDateChange(date); };
  const handleDayCellBlur = () => { onDateChange(null); };
  const filterDayEvents = (currentDay, allEvents) => (
    allEvents.filter((event) => (
      filterDayEvent(currentDay, event) && filterEvent(event)
    ))
  );

  for (let i = 0; i < 7; i += 1) {
    const dailyEvents = sortEvents(filterDayEvents(day, events));
    const currentCell = (
      <DayCell
        key={day}
        day={day}
        dateFormat={dateFormat}
        selected={isSameDay(day, selectedDate)}
        isLoading={isLoading}
        events={dailyEvents}
        labels={labels}
        onFocus={handleDayCellFocus}
        onBlur={handleDayCellBlur}
        onEventFocus={onEventFocus}
        viewMode={viewMode}
      />
    );

    days.push(currentCell);
    day = addDays(day, 1);
  }

  return (
    <div className={classnames(styles.body)}>
      <div className={classnames(styles.row, styles[viewMode])} key={day}>
        {days}

        <WeekDetails
          weekNumber={weekNumber}
          selected={false}
          onClick={onWeekDetailsClick}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

WeekDays.propTypes = {
  sortEvents: PropTypes.func,
  weekStartedOn: PropTypes.instanceOf(Date).isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  onDateChange: PropTypes.func,
  onEventFocus: PropTypes.func,
  onWeekDetailsClick: PropTypes.func,
  isLoading: PropTypes.bool,
  events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filterEvent: PropTypes.func,
  viewMode: PropTypes.string,
};

WeekDays.defaultProps = {
  sortEvents: (events) => (events),
  selectedDate: null,
  onDateChange: () => {},
  onEventFocus: () => {},
  onWeekDetailsClick: () => {},
  isLoading: false,
  filterEvent: true,
  viewMode: 'compact',
};

export default WeekDays;
