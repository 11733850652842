import React from 'react';
import Icon from '../Icon';

const LinkedOperation = ({ operation, prefix = 'Operation' }) => {
  if (!operation) { return ''; }

  return (
    <small className="d-block mt-1">
      <strong>
        <Icon name="file-invoice" fw />
        &nbsp;
        {operation.label}
      </strong>
    </small>
  );
};

export default LinkedOperation;
