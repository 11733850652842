import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import classnames from 'classnames';

import Icon from '../../Icon';
import styles from './styles.module.scss';

const AddButton = ({ className, onClick, position }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  useEffect(() => {
    const handleEsc = (event) => {
       if (event.key === 'Escape') {
         setShow(false);
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const handleAddButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setShow(!show);
  };

  const handleStepButtonClick = (event, step_type) => {
    event.preventDefault();
    event.stopPropagation();

    setShow(false);
    onClick({ position, step_type });
  };

  return (
    <>
      <Button
        className={classnames(styles.AddButton, className)}
        variant="secondary"
        onClick={handleAddButtonClick}
        ref={target}
      >
        <Icon name="plus" fw />
      </Button>

      <Overlay
        placement="right"
        target={target.current}
        show={show}
      >
        <Popover id="popover-basic">
          <Popover.Title as="h3">Choose the next opportunity step</Popover.Title>
          <Popover.Content>
            <Button className="btn-block" variant="primary" onClick={ (e) => { handleStepButtonClick(e, 'moment'); } }>
              <Icon name="calendar" fw />
              &nbsp;
              Moment
            </Button>

            <Button className="btn-block" variant="secondary" onClick={ (e) => { handleStepButtonClick(e, 'offset'); } }>
              <Icon name="arrows-alt-v" fw />
              &nbsp;
              Offset
            </Button>
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
};

AddButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  position: PropTypes.number.isRequired,
};

AddButton.defaultProps = {
  className: null,
  onClick: () => {},
};

export default AddButton;
