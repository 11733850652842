import { get, patch, fetchPaginatedResources } from '../base';

export const archiveExpense = (id) => (
  patch(`/billing/expenses/${id}/archive.json`)
);

export const syncExpense = (id) => (
  patch(`/billing/expenses/${id}/sync.json`)
);

export const paginatedExpenses = ({ page }) => (
  get(`/billing/expenses.json?page=${page || 1}`)
);

export const expenses = async () => (
  fetchPaginatedResources({ fetchApi: paginatedExpenses })
);

export default {
  archiveExpense,
  paginatedExpenses,
  expenses,
};
