import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';

import Icon from '../../Icon';
import { recipeShape } from '../proptypes';
import { findOffsetOption, deserializeSteps } from '../utils';
import StepsList from '../Footprint/StepsList';
import SearchRecipeOpportunitiesPage from '../SearchOpportunitiesPage/SearchRecipeOpportunitiesPage';

const TabTitle = ({ title, icon, iconFamily = 'fas' }) => (
  <span>
    <Icon name={icon} family={iconFamily} fw />
    &nbsp;
    {title}
  </span>
);

const Show = ({ recipe, users, currentUserId }) => {
  const [key, setKey] = useState('info');
  const decoratedRecipe = recipe;
  decoratedRecipe.steps = decoratedRecipe.steps.map(({ offset_type, ...step }) => ({
    ...step,
    offset_type, //: findOffsetOption(offset_type),
  }));

  const { name, description, color, human_footprint } = recipe;
  const deserializedSteps = deserializeSteps(decoratedRecipe.steps);

  return (
    <div className="RecipeShow">
      <Tabs
        activeKey={key}
        id="recipe-show-tabs"
        onSelect={setKey}
      >
        <Tab
          eventKey="info"
          title={<TabTitle title="Details" icon="info" />}
        >
          <h5>Recipe details:</h5>
          <hr/>
          <div className="row">
            <div className="col-md-8">
              <dl className="row">
                <dt className="col-lg-3 text-right">
                  Name:
                </dt>
                <dd className="col-lg-8">
                  { name }
                </dd>

                <dt className="col-lg-3 text-right">
                  Description:
                </dt>
                <dd className="col-lg-8">
                  { description || '-' }
                </dd>

                <dt className="col-lg-3 text-right">
                  Color
                </dt>
                <dd className="col-lg-8">
                  <div
                    className="ColorMarker"
                    style={{ backgroundColor: color }}
                  ></div>
                </dd>

                <dt className="col-lg-3 text-right">
                  Footprint:
                </dt>
                <dd className="col-lg-8">
                  { human_footprint }
                </dd>
              </dl>
            </div>
          </div>

          <div className="mb-3">
            <h5>Recipe steps:</h5>
            <hr/>
            <StepsList
              steps={deserializedSteps}
              layout="horizontal"
              readOnly
            />
          </div>

          <h5>First available date:</h5>
          <hr/>
          <SearchRecipeOpportunitiesPage
            recipe={recipe}
            users={users}
            currentUserId={currentUserId}
            hideRecipeInput
            hideDesigner
            preload
          />
        </Tab>
      </Tabs>
    </div>
  );
};

Show.propTypes = {
  recipe: recipeShape.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentUserId: PropTypes.string.isRequired,
};

export default Show;
