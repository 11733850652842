export const extractError = (errors, name) => {
  const chunks = name.split(/[[\]]/).filter(
    (chunk) => (chunk.trim().length > 0),
  );

  return chunks.reduce((acc, chunk) => {
    if (!acc) { return null; }

    return acc[chunk] || null;
  }, errors);
};

export default {
  extractError,
};
