import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';

import { formatRubyMoney as fm } from '../../../utils/money';
import TicketsChart from '../../TicketsSoldChart';
import DataTable from './TicketsDataTable';
import { eventShape, ticketShape } from '../utils';
import Icon from '../../Icon';
import { ordersPath, ticketsPath, attendeesPath } from '../../../routes/trainings/planned_events';

const SalesPage = ({ event, tickets }) => {
  const { id } = event;
  const chartDataUrl = `/trainings/dashboard/tickets_sold_stats.json?public_training_edition_id=${id}`;

  return (
    <div className={classnames('sales-page')}>
      <TicketsChart
        dataUrl={chartDataUrl}
        showRangeSlider={false}
      />

      <hr />

      <div className="row">
        <div className="col-md-4">
          <h3>Financial Data</h3>

          <table className="table table-sm">
            <tbody>
              <tr>
                <td className="no-borders">
                  Gross income:
                </td>
                <td className="no-borders text-right">
                  {fm(event.tickets_gross_income)}
                </td>
              </tr>
              <tr>
                <td className="no-borders">
                  Taxes:
                </td>
                <td className="no-borders text-right">
                  {fm(event.tickets_tax)}
                </td>
              </tr>
              <tr>
                <td className="no-borders">
                  Service fees:
                </td>
                <td className="no-borders text-right">
                  {fm(event.ticketing_service_fee)}
                </td>
              </tr>
              <tr>
                <td className="no-borders">
                  Tickets payment fee
                </td>
                <td className="no-borders text-right">
                  {fm(event.ticketing_payment_fee)}
                </td>
              </tr>

              <tr>
                <td className="border-top">
                  <h4>Net income:</h4>
                </td>
                <td className="border-top text-right">
                  <h4>
                    {fm(event.tickets_net_income)}
                  </h4>
                </td>
              </tr>
              <tr className="wip">
                <td className="no-borders">
                  Trainer fee
                </td>
                <td className="no-borders text-right">
                  ??
                </td>
              </tr>
              <tr className="wip">
                <td className="no-borders">
                  Other expenses
                </td>
                <td className="no-borders text-right">
                  ??
                </td>
              </tr>
              <tr className="wip border-top">
                <td>
                  <h4>We're making</h4>
                </td>
                <td className="text-right">
                  <h4>??</h4>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="col-md-8">
          <h3>
            <Button
              href={attendeesPath(id, { format: 'xlsx' })}
              className="float-right ml-3"
              size="sm"
              variant="dark"
              target="_blank"
            >
              <Icon name="user-graduate" family="fas" fw />
              &nbsp;
              Export attendees
            </Button>
            &nbsp;
            <Button
              href={ordersPath(id, { format: 'xlsx' })}
              className="float-right ml-3"
              size="sm"
              variant="primary"
              target="_blank"
            >
              <Icon name="file-excel" family="far" fw />
              &nbsp;
              Export orders
            </Button>
            &nbsp;
            <Button
              href={ticketsPath(id, { format: 'xlsx' })}
              className="float-right ml-3 d-none"
              size="sm"
              variant="secondary"
              target="_blank"
            >
              <Icon name="ticket-alt" family="fas" fw />
              &nbsp;
              Export tickets
            </Button>

            Ticket sales
          </h3>

          <DataTable
            event={event}
            tickets={tickets}
            hideSearch
          />
        </div>
      </div>

      <hr />
    </div>
  );
};

SalesPage.propTypes = {
  event: eventShape.isRequired,
  tickets: PropTypes.arrayOf(ticketShape),
};

SalesPage.defaultProps = {
  tickets: [],
};

export default SalesPage;
