import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import { isFuture } from "date-fns";

import ExpectedTicketSalesInplaceInput from './ExpectedTicketSalesInplaceInput';
import ExpectedAverageTicketPriceInplaceInput from './ExpectedAverageTicketPriceInplaceInput';
import ExpectedCostPerAttendeeInplaceInput from './ExpectedCostPerAttendeeInplaceInput';
import ExpectedTrainerMinimumFeeInplaceInput from './ExpectedTrainerMinimumFeeInplaceInput';
import ExpectedTrainerRevenuePercentageInplaceInput from './ExpectedTrainerRevenuePercentageInplaceInput';

import ExpectedExpensesElement from './ExpectedExpensesElement';
import RegisteredCostsElement from './RegisteredCostsElement';

import ForecastCharts from './ForecastCharts';
import TicketClassesList from '../TicketClassesList';
import ListingTable from '../../../ListingTable/Infinite';
import Row from '../../../ExpectationsCalendarRow';
import { eventShape } from '../../utils';
import Spinner from '../../../Spinner';
import Icon from '../../../Icon';
import ConfirmButton from '../../../ConfirmModalButton';
import handleError from '../../../../utils/error-handler';
import { success } from '../../../../utils/notifier';
import Money, { formatRubyMoney } from '../../../../utils/money';
import { 
  enableSalesForecasts, 
  disableSalesForecasts, 
} from '../../../../api/forecast/public_training_sales';

const endpoint = (id) => (`/forecast/planned_event_expectations/${id}/calendar.json`);

const EnableButton = ({ onConfirm, canConfirm }) => (
  <>
    <ConfirmButton
      block
      size="lg"
      variant="success"
      onConfirm={onConfirm}
      buttonCaption="Enable training forecast"
      title="Enable training sales forecasting"
      description="Do you want to generate sales expectations for the current training course?"
      disabled={!canConfirm}
    />

    <p className={classnames('text-center', { 'd-none': canConfirm })}>
      <em>
        Fill in all required fields *
      </em>
    </p>
  </>
);

const ForecastPage = ({ 
  event, 
  forecastExpectations, 
  tickets, 
  loading, 
  countries,
  accounts,
  currencies,
  salesForecast,
}) => {
  if (loading) {
    return (
      <div>
        <Spinner
          description="Loading public training forecast settings..."
        />
      </div>
    );
  }

  const [isLoading, setIsLoading] = useState(false);
  const publicTrainingId = event.id;
  const {
    expected_average_gross_price,
    expected_average_net_price,
    expected_average_vat_percent,
    expected_ticket_sales,
    expected_expenses,
    registered_costs,
    initial_capacity,
    ticket_classes,
    ends_on,
    tickets_sold,
  } = event;

  const hasAverageNetPrice = expected_average_net_price && expected_average_net_price.cents > 0;
  const canConfirm = hasAverageNetPrice && !!expected_ticket_sales;

  const hasForecast = event['enabled_forecast?'];
  const expectedExpenses = expected_expenses || [];
  const registeredCosts = registered_costs || [];

  const handleEnableForecast = () => {
    if (isLoading) { return; }

    setIsLoading(true);

    enableSalesForecasts({ publicTrainingId })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
      })
      .catch(handleError);
  };

  const handleDisableForecast = () => {
    if (isLoading) { return; }

    setIsLoading(true);

    disableSalesForecasts({ publicTrainingId })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
      })
      .catch(handleError);
  };

  const handleInputChange = ({ key, value }) => {
    // console.log('change', { key, value });
  }

  const headers = ['Ticket name', 'Date', 'Price'];
  const sortableColumns = {};
  const endsOn = new Date(ends_on);
  const isFutureEvent = isFuture(endsOn);
  const isPastEvent = !isFutureEvent;
  const isSoldOut = tickets_sold >= initial_capacity;
  const showConfirmButton = !hasForecast && isFutureEvent && !isSoldOut;

  return (
    <div className={classnames('forecast-page')}>
      <div className="row">
        <div className="col-md-4">
          <h3 className="mb-3">
            Expected sales configurations
          </h3>

          <dl>
            <dt>
              <Icon name="chair" fw />
              &nbsp;
              Max capacity
            </dt>
            <dd className="">
              { `${initial_capacity} seats` }
            </dd>

            <dt>
              <Icon name="dollar-sign" fw />
              &nbsp;
              Number of sold tickets
            </dt>
            <dd className="">
              {tickets.length} sold tickets
            </dd>

            <dt>
              <Icon name="users" fw />
              &nbsp;
              Total number of saleable tickets expected
              &nbsp;
              <abbr title="Required">*</abbr>
            </dt>
            <dd className="">
              <ExpectedTicketSalesInplaceInput
                event={event}
                className="d-inline-block"
                onChange={handleInputChange}
              />
            </dd>

            <dt>
              <Icon name="layer-group" fw />
              &nbsp;
              Ticket classes
            </dt>
            <dd className="">
              {
                ticket_classes.length === 0 && (
                  <p>No configured ticket classes</p>
                )
              }
              {
                ticket_classes.length > 0 && (
                  <TicketClassesList ticketClasses={ticket_classes} />
                )
              }
            </dd>

            <dt>
              <Icon name="ticket-alt" fw />
              &nbsp;
              Average ticket price
              &nbsp;
              <abbr title="Required">*</abbr>
            </dt>
            <dd className="">
              <ExpectedAverageTicketPriceInplaceInput
                event={event}
                className="d-inline-block"
              />
            </dd>

            <dt>
              <Icon name="money-bill-alt" fw />
              &nbsp;
              Cost per attendee
            </dt>
            <dd className="">
              <ExpectedCostPerAttendeeInplaceInput
                event={event}
                className="d-inline-block"
              />
            </dd>

            <dt>
              <Icon name="chalkboard-teacher" fw />
              &nbsp;
              Trainer minimum fee
            </dt>
            <dd className="">
              <ExpectedTrainerMinimumFeeInplaceInput
                event={event}
                className="d-inline-block"
              />
            </dd>

            <dt>
              <Icon name="percent" fw />
              &nbsp;
              Trainer revenue percentage 
            </dt>
            <dd className="">
              <ExpectedTrainerRevenuePercentageInplaceInput
                event={event}
                className="d-inline-block"
              />
            </dd>

            <ExpectedExpensesElement 
              event={event}
              countries={countries}
              accounts={accounts}
              currencies={currencies}
              expectedExpenses={expectedExpenses}
            />

            <RegisteredCostsElement 
              event={event}
              countries={countries}
              accounts={accounts}
              currencies={currencies}
              registeredCosts={registeredCosts}
            />
          </dl>

          <div className="my-5">
            {
              showConfirmButton && (
                <EnableButton 
                  onConfirm={handleEnableForecast}
                  canConfirm={canConfirm}
                />
              )
            }

            {
              hasForecast && (
                <ConfirmButton
                  block
                  size="lg"
                  variant="danger"
                  onConfirm={handleDisableForecast}
                  buttonCaption="Disable training forecast"
                  title="Disable training sales forecasting"
                  description="Do you want to delete the generated sales expectations for the current training course?"
                />
              )
            }
          </div>
        </div>

        <div className="col-md-8">
          {
            hasForecast && (
              <ForecastCharts salesForecast={salesForecast} />
            )
          }
          {
            !hasForecast && isPastEvent && (
              <div className="bg-light p-5 text-center">
                <h5>
                  Disabled training forecast
                </h5>
              </div>
            )
          }
          {
            !hasForecast && isSoldOut && (
              <div className="bg-light p-5 text-center">
                <h5>
                  The training is SOLD OUT!
                </h5>
              </div>
            )
          }
            {
              showConfirmButton && (
                <div className="bg-light p-5 text-center">
                  <Icon name="poll" fw size="3x" className="mb-3" />

                  <EnableButton 
                    onConfirm={handleEnableForecast}
                    canConfirm={canConfirm}
                  />
                </div>
              )
            }
        </div>
      </div>

      {
        hasForecast && (
          <ListingTable
            tableClassName="mt-5"
            initialEndpoint={endpoint(publicTrainingId)}
            components={{ Row }}
            filterKeys={['headline', 'notes', 'counterpart']}
            headers={['Ticket sales expectations']}
            events={[
              'Forecast::Expectations::Events::PublicTrainingTicketSoldExpectationSet',
              'Forecast::Expectations::Events::ExpectationDeleted',
              'Forecast::Expectations::Events::ExpectationDeletedFromReconciliation',
              'Forecast::Expectations::Events::ExpectationFulfilled',
            ]}
            hideSearch
          />
        )
      }
    </div>
  );
};

ForecastPage.propTypes = {
  event: eventShape.isRequired,
  loading: PropTypes.bool,
};

ForecastPage.defaultProps = {
  loading: false,
};

export default ForecastPage;

