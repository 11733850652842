import React from "react"
import EventBus from '../packs/event_bus';
import { getCsrfBody } from '../utils/csrf'

class GenerateCertificatesBox extends React.Component {

  state = {
    isRunning: false,
    currentlyProcessed: 0,
    events: []
  };

  componentWillUnmount() {
    EventBus.unsubscribe(this.eventSubscriptionId)
  }

  onReceivedCertificateGenerated(event) {
    const { public_training_edition_id } = event
    if (public_training_edition_id != this.props.publicEditionId)
      return

    let processed = this.state.currentlyProcessed + 1
    let isRunning = processed != this.props.attendeesCount
    if (!isRunning) { processed = 0; }

    this.setState({
      events: [...this.state.events, event],
      currentlyProcessed: processed,
      isRunning: isRunning
    })
  }

  componentDidMount() {
    this.eventSubscriptionId = EventBus.subscribe("Trainings::Certificates::CertificateGenerated", this.onReceivedCertificateGenerated, this)
  }

  startGenerate = (event) => {
    this.setState({ ...this.state, events: [], isRunning: true })
    const body = getCsrfBody();

    fetch(`/trainings/planned_events/${this.props.publicEditionId}/certificates/generate_all`, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
  }

  computeProgress() {
    return Math.round((this.state.currentlyProcessed ? (this.state.currentlyProcessed / this.props.attendeesCount) : 0) * 100)
  }

  findAttendee(participationId) {
    return this.props.attendees.find(a => a.participation_id === participationId)
  }

  renderAttendee(event) {
    const { certificate_id, pdf_uri, participation_id, verification_code } = event
    const attendee = this.findAttendee(participation_id);

    return <tr key={certificate_id}>
      <td>{attendee.first_name} {attendee.last_name}<br />{attendee.email}</td>
      <td className="text-center">
        <object data={`${pdf_uri}#view=FitH&toolbar=0&statusbar=0&navpanes=0`} type="application/pdf" style={{ width: '300px', height: '213px' }}>
          <embed src={`${pdf_uri}#view=FitH&toolbar=0&statusbar=0&navpanes=0`} type="application/pdf" />
        </object>
        <div>
          <a href={pdf_uri} target="_new">Open</a>
        </div>
      </td>
    </tr>
  }

  renderAttendeesList() {
    let instance = this
    if (this.state.events.length > 0) {
      return <table className="table mt-4">
        <thead><tr><th>Attendee</th><th>Certificate</th></tr></thead>
        <tbody>
          {instance.state.events.map(function (attendee) {
            return instance.renderAttendee(attendee)
          })}
        </tbody>
      </table>
    }
  }

  renderProgress() {
    if (this.state.isRunning) {
      return <div className="mt-4">
        <div className="progress progress-striped active">
          <div style={{ width: `${this.computeProgress()}%` }} className="progress-bar"></div>
        </div>
        <small>Generation in progress <strong>{this.computeProgress()}%</strong>.</small>
      </div>
    }
  }

  render() {
    return <div className="text-center">
      <button className="btn btn-lg btn-primary" disabled={this.state.isRunning} onClick={this.startGenerate}>Generate Certificates</button>
      {this.renderProgress()}
      {this.renderAttendeesList()}
    </div>
  }
}

export default GenerateCertificatesBox
