import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import Icon from '../../Icon';
import TextInput from '../../RHF/TextInput';
import SelectInput from '../../RHF/SelectInput';
import CertificationPreview from './CertificationPreview';

import { updateCertification } from '../../../api/trainings/certifications';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';

const localeOptions = [
  { value: 'en', label: 'English' },
  { value: 'it', label: 'Italian' },
];

const buildTemplateOption = (template) => ({
  value: template,
  label: template,
});

const CertificationSettings = (({ certification }) => {
  const {
    id,
    headline,
    description,
    venue,
    template,
    locale,
    course_dates,
  } = certification;

  const { handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      headline: headline || '',
      description: description || '',
      venue: venue || '',
      course_dates: course_dates || '',
      template: buildTemplateOption(template),
      locale: localeOptions.find((opt) => (opt.value === locale)),
    },
  });

  const onSubmit = (params) => {
    const attributes = {
      ...params,
      template: params.template.value,
      locale: params.locale.value,
    };

    updateCertification({ certificationId: id, attributes })
      .then((response) => response.json())
      .then((data) => {
        success({ message: data.message });
      })
      .catch(handleError);
  };

  // TODO: load available templates through API
  const templates = [
    'DefaultTemplate',
    'PrivateCourseTemplate',
    'EventStormingTemplate',
    'PrivateEventStormingTemplate',
    'EventStormingRemoveModellingTemplate',
  ];

  const templateOptions = templates.map(buildTemplateOption);

  return (
    <div className="row">
      <div className="col-md-6">
        <h4>Update the certificate layout</h4>

        <hr />

        <Alert variant="warning">
          You should regenerate all PDFs after any change.
        </Alert>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            name="headline"
            label="Certification headline"
            placeholder="e.g. EventStorming Workshop"
            errors={errors}
            control={control}
            required
          />

          <TextInput
            name="description"
            label="Description"
            placeholder="e.g. Remote edition"
            errors={errors}
            control={control}
          />

          <div className="row">
            <div className="col-md-6">
              <TextInput
                name="venue"
                label="Venue"
                errors={errors}
                control={control}
                placeholder="e.g. Online"
                required
              />
            </div>

            <div className="col-md-6">
              <TextInput
                name="course_dates"
                label="Course dates"
                errors={errors}
                control={control}
                placeholder="e.g. September 14-17, 2021"
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <SelectInput
                name="template"
                label="Template"
                errors={errors}
                control={control}
                options={templateOptions}
              />
            </div>

            <div className="col-md-6">
              <SelectInput
                name="locale"
                label="Language"
                errors={errors}
                control={control}
                options={localeOptions}
              />
            </div>
          </div>

          <hr />

          <Button variant="primary" type="submit" size="lg">
            <Icon name="edit" family="far" fw />
            &nbsp;
            Update certificate
          </Button>
        </Form>
      </div>

      <div className="col-md-6">
        <CertificationPreview
          certification={certification}
        />
      </div>
    </div>
  );
});

CertificationSettings.propTypes = {
  certification: PropTypes.shape({
    id: PropTypes.string,
    headline: PropTypes.string,
    description: PropTypes.string,
    venue: PropTypes.string,
    locale: PropTypes.string,
    template: PropTypes.string,
    course_dates: PropTypes.string,
  }).isRequired,
};

CertificationSettings.defaultProps = {
};

export default CertificationSettings;
