import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import Footprint from '../Footprint';
import Icon from '../../Icon';

const DrawModal = ({
  show,
  title,
  onHide,
  onConfirm,
  initialSteps,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [steps, setSteps] = useState(initialSteps);
  const handleConfirm = () => {
    if (isLoading) { return; }
    setIsLoading(true);

    onHide();
    onConfirm(steps);
    setIsLoading(false);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong className="">
            {title}
          </strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Footprint
          height={500}
          onChange={setSteps}
          initialSteps={initialSteps}
        />
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
        <Button variant="success" onClick={handleConfirm} disabled={isLoading}>
          <Icon name="check" fw />
          &nbsp;
          { isLoading ? 'Loading...' : 'Save' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DrawModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  initialSteps: PropTypes.arrayOf(PropTypes.shape({})),
};

DrawModal.defaultProps = {
  show: false,
  onConfirm: () => {},
  initialSteps: [],
  title: 'Draw opportunity'
};

export default DrawModal;
