import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { DateTime } from 'luxon';

import Icon from '../../Icon';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';
import { convertIntoTraining } from '../../../api/trainings/planned_events';
import { eventShape } from '../utils';

const ConvertIntoTrainingModal = ({
  show,
  onHide,
  onConfirm,
  event,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const plannedEventId = event.id;
  const handleConfirm = () => {
    if (isLoading) { return; }
    setIsLoading(true);

    convertIntoTraining({ plannedEventId })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        success({ message: data.message });
        onHide();
        onConfirm({ plannedEventId, response: data });
      })
      .catch(handleError);
  };

  const { headline, starts_on } = event;
  const startsOn = DateTime.fromISO(starts_on);
  const humanDate = startsOn.toFormat('dd LLL yyyy');

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>
            Convert the public training into a Training course
            &nbsp;
            <Icon name="ticket-alt" fw />
          </strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h4 className="mb-3">
          Do you really want to convert the public training
          &nbsp;
          <strong>
            &quot;
            {headline}
            &quot;
          </strong>
          &nbsp;
          on
          &nbsp;
          {humanDate}
          &nbsp;
          into a
          &nbsp;
          <strong>Training course</strong>
          ?
        </h4>
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={handleConfirm} disabled={isLoading}>
          <Icon name="ticket-alt" fw />
          &nbsp;
          { isLoading ? 'Loading...' : 'Convert into Training course' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConvertIntoTrainingModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  event: eventShape.isRequired,
  onConfirm: PropTypes.func,
};

ConvertIntoTrainingModal.defaultProps = {
  show: false,
  onConfirm: () => {},
};

export default ConvertIntoTrainingModal;
