const createPageMapCoordsToContainer = (container) => (
  (event) => {
    let clientX;
    let clientY;
    let pageX;
    let pageY;

    if ('changedTouches' in event) {
      ({ clientX, clientY, pageX, pageY } = event.changedTouches[0]);
    } else {
      ({ clientX, clientY, pageX, pageY } = event);
    }
    const { top, left } = container.getBoundingClientRect();

    return {
      clientX,
      clientY,
      pageX,
      pageY,
      top,
      left,
      x: clientX - left,
      y: clientY - top,
    };
  }
);

export default createPageMapCoordsToContainer;
