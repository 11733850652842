import React from "react"
import PropTypes from "prop-types"
import Select from 'react-select';

// import 'react-select/dist/react-select.css';

class AutocompleteInputTrainers extends React.Component {
  state = {
    selectedOption: this.props.selectedIds,
    trainers: []
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  }

  componentDidMount() {
    fetch(`/trainers/trainers.json`, {credentials: 'same-origin'})
      .then(response => response.json())
      .then(json => this.setState({
        trainers: json.map((t) => ({ value: t._id, label: t.full_name }))
      }))
  }

  render () {
    const { trainers } = this.state;

    return <Select
        name={`${this.props.formInputName}[]`}
        multi={true}
        value={this.state.selectedOption}
        onChange={this.handleChange}
        options={trainers}
      />
  }
}

export default AutocompleteInputTrainers
