import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DateTime } from 'luxon';

import ConfirmationDateUpdateModal from './ConfirmationDateUpdateModal';
import { eventShape } from '../utils';
import { visit } from '../../../utils/router';
import Icon from '../../Icon';
import { success } from '../../../utils/notifier';

const ConfirmationDateInplaceInput = ({ event, className }) => {
  const [showModal, setShowModal] = useState(false);
  const hideModal = () => { setShowModal(false); };
  const { confirmation_date, details_path } = event;
  const initialDate = confirmation_date ? DateTime.fromISO(confirmation_date) : null;
  const humanDate = initialDate ? initialDate.toFormat('dd LLL yyyy') : 'Missing date';
  const handleClick = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleConfirmationDateUpdate = ({ response }) => {
    visit(details_path).then(() => { success({ message: response.message }); });
  };

  return (
    <div className={classnames('ConfirmationDateInplaceInput', className)}>
      <ConfirmationDateUpdateModal
        show={showModal}
        event={event}
        onHide={hideModal}
        onUpdate={handleConfirmationDateUpdate}
      />

      <a href="#confirmation-date" onClick={handleClick}>
        <span className="editable">{humanDate}</span>
        &nbsp;
        <Icon name="pencil-alt" fw />
      </a>
    </div>
  );
};

ConfirmationDateInplaceInput.propTypes = {
  event: eventShape.isRequired,
};

ConfirmationDateInplaceInput.defaultProps = {};

export default ConfirmationDateInplaceInput;
