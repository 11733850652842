import React from 'react';
import PropTypes from 'prop-types';

import { invertColor } from '../../../utils/colors';
import Icon from '../../Icon';
// import { formatIsoDateTime } from '../../../utils/datetime';

import DatetimeLabel from '../../DatetimeRangeLabel';

const SlotPlaceholder = ({ slot, color, title }) => {
  const {
    duration,
    start_at,
    end_at,
    date,
  } = slot;
  const start_on = start_at.split('T')[0];
  const end_on = end_at.split('T')[0];
  const style = {};
  let secondaryColor = '#000000';

  if (color) {
    style.backgroundColor = color;
    secondaryColor = invertColor(color, true);
    style.color = secondaryColor;
  }

  return (
    <div className="SlotPlaceholder" style={style}>
      <div className="SlotPlaceholder__content">
        <h6 className="text-truncate" title={title}>
          <Icon
            name="calendar-check"
            family="far"
          />
          &nbsp;
          <strong>
            { title || 'Slot' }
          </strong>
        </h6>

        <hr className="my-2" style={{ borderColor: secondaryColor }} />

        <div className="SlotPlaceholder__datetime small">
          <DatetimeLabel
            start_on={start_on}
            start_at={start_at}
            end_on={end_on}
            end_at={end_at}
            separator=""
          />
        </div>
      </div>
    </div>
  );
};

SlotPlaceholder.propTypes = {
  slot: PropTypes.shape({}).isRequired,
};

export default SlotPlaceholder;
