import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DateTime } from 'luxon';

import ExpectedTrainerRevenuePercentageUpdateModal from './ExpectedTrainerRevenuePercentageUpdateModal';
import { eventShape } from '../../utils';
import { reload } from '../../../../utils/router';
import { formatRubyMoney } from '../../../../utils/money';
import Icon from '../../../Icon';
import { success } from '../../../../utils/notifier';

const ExpectedTrainerRevenuePercentageInplaceInput = ({ event, className }) => {
  const [showModal, setShowModal] = useState(false);
  const hideModal = () => { setShowModal(false); };
  const { expected_trainer_revenue_percentage, details_path } = event;
  const handleExpectedTrainerRevenuePercentageUpdate = ({ response }) => {
    reload().then(() => { success({ message: response.message }); });
  };
  const handleClick = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <div className={classnames('ExpectedTrainerRevenuePercentageInplaceInput', className)}>
      <ExpectedTrainerRevenuePercentageUpdateModal
        show={showModal}
        event={event}
        onHide={hideModal}
        onUpdate={handleExpectedTrainerRevenuePercentageUpdate}
      />

      <a href="#trainer-revenue-percentage" onClick={handleClick}>
        <span className="editable">{expected_trainer_revenue_percentage || 0.0}%</span>
        &nbsp;
        <Icon name="pencil-alt" fw />
      </a>
    </div>
  );
};

ExpectedTrainerRevenuePercentageInplaceInput.propTypes = {
  event: eventShape.isRequired,
};

ExpectedTrainerRevenuePercentageInplaceInput.defaultProps = {};

export default ExpectedTrainerRevenuePercentageInplaceInput;
