import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import moment from 'moment';

import SelectInput from '../inputs/SelectInput';
import BaseInput from '../inputs/BaseInput';
import DateInput from '../inputs/DateInput';
import Money from '../../utils/money';
import handleError from '../../utils/error-handler';
import { extendRecurringExpectation } from '../../api/recurringExpectations';
import { visit } from '../../utils/router';

const formatDate = (date) => {
  if (!date) { return ''; }

  return moment(date).format('D/M/Y');
};

const defaultTillDate = () => (null);
const defaultMaxInstances = () => (12);
const terminationOptions = [
  { value: 'TILL_DATE', label: 'Till date', icon: 'far fa-calendar-check' },
  { value: 'MAX_INSTANCES', label: 'Max instances', icon: 'fas fa-sort-numeric-down' },
];

const ExtendRecurringExpectationModal = ({
  expectationId,
  headline,
  show,
  direction,
  expectedAmount,
  onHide,
}) => {
  // State primitives
  const [isLoading, setIsLoading] = React.useState(false);
  const [termination, setTermination] = React.useState(null);
  const [tillDate, setTillDate] = React.useState(defaultTillDate());
  const [maxInstances, setMaxInstances] = React.useState(defaultMaxInstances());

  // Validations
  const isTillDateTermination = () => (termination === 'TILL_DATE');
  const validTillDateForm = () => (isTillDateTermination() && tillDate);
  const isMaxInstancesTermination = () => (termination === 'MAX_INSTANCES');
  const validMaxInstancesForm = () => (
    isMaxInstancesTermination() && maxInstances && maxInstances > 0
  );
  const isSubmitEnabled = () => (
    !isLoading && termination
      && (!isTillDateTermination() || validTillDateForm())
      && (!isMaxInstancesTermination() || validMaxInstancesForm())
  );
  const isSubmitDisabled = () => (!isSubmitEnabled());

  // Human labels
  const expectationsLabel = direction === 'out'
    ? 'expenses'
    : 'incomes';
  const humanAmount = Money(expectedAmount).toFormat();

  // Handle state changes
  const handleTerminationUpdate = (value) => {
    setTermination(value ? value.value : null);
  };
  const handleTillDateChange = (date) => {
    setTillDate(date);
  };
  const handleMaxInstancesChange = (e) => {
    e.preventDefault();

    const { value } = e.target;
    if (value.length === 0) {
      setMaxInstances(maxInstances);
      return;
    }

    const intValue = parseInt(value, 10);
    setMaxInstances(Number.isNaN(intValue) ? maxInstances : intValue);
  };
  const expendApiPayload = () => {
    switch (termination) {
      case 'TILL_DATE':
        return {
          termination,
          till_date: formatDate(tillDate),
        };
      case 'MAX_INSTANCES':
        return {
          termination,
          max_instances: maxInstances,
        };
      default:
        return {};
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    extendRecurringExpectation({ expectationId, params: expendApiPayload() })
      .then((response) => { visit(response.redirect_to); })
      .catch(handleError);
  };

  const renderTerminationFields = () => {
    switch (termination) {
      case 'TILL_DATE':
        return (
          <DateInput
            label="Till date"
            value={formatDate(tillDate)}
            onChange={(e) => { handleTillDateChange(e); }}
          />
        );
      case 'MAX_INSTANCES':
        return (
          <BaseInput
            type="number"
            label="Max instances"
            value={maxInstances}
            onChange={(e) => { handleMaxInstancesChange(e); }}
            min="1"
            max="1000"
          />
        );
      default:
        return '';
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {`Extend recurring ${expectationsLabel}`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h6>
          Extend {expectationsLabel} of <strong>{humanAmount}</strong> for <em>{headline}</em>.
        </h6>

        <br />

        <div className="row">
          <div className="col">
            <SelectInput
              label="Termination"
              onChange={(value) => { handleTerminationUpdate(value); }}
              options={terminationOptions}
            />
          </div>

          <div className="col">
            {renderTerminationFields()}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="success" disabled={isSubmitDisabled()} onClick={(e) => { handleSubmit(e); }}>
          {
            isLoading
              ? 'Loading...'
              : `Extend ${expectationsLabel}`
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ExtendRecurringExpectationModal.propTypes = {
  expectationId: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
};

ExtendRecurringExpectationModal.defaultProps = {
  show: false,
};

export default ExtendRecurringExpectationModal;
