import React from "react"
import ReactSummernote from 'react-summernote';
import EventBus from '../packs/event_bus';

import 'react-summernote/dist/react-summernote.css';

class EmailTemplateEditor extends React.Component {

  state = {
    body: this.props.body
  }

  handleChange = (value) => {
    this.setState({ body: value });
  }

  componentDidMount() {
    this.eventSubscriptionId = EventBus.subscribe("UI::EmailTemplateSelected", this.onEmailTemplateSelected, this)
  	this.setState({body: this.props.body})
  }

  onEmailTemplateSelected = (event) => {
    this.setState({body: event.body})
  }


  render () {
    return <div>
	    	<ReactSummernote
            id="email_template_editor"
	        value={this.state.body}
	        options={{
	          height: 380,
	          dialogsInBody: true,
	          toolbar: [
	            ['style', ['style']],
	            ['font', ['bold', 'underline', 'clear']],
	            ['para', ['ul', 'ol', 'paragraph']],
	            ['table', ['table']],
	            ['insert', ['link']],
	            ['view', ['fullscreen', 'codeview']]
	          ]
	        }}
	        onChange={this.handleChange}
	      />
	      <input type="hidden" name={this.props.formInputName} value={this.state.body} />
	     </div>
  }
}

export default EmailTemplateEditor
