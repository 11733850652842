import { get, patch } from '../base';

export const billingDocument = (id) => (
  get(`/billing/billing_documents/${id}.json`)
);

export const syncBillingDocument = (id) => (
  patch(`/billing/billing_documents/${id}/sync.json`)
);

export default {
  billingDocument,
  syncBillingDocument,
};
