import React from 'react';
// import PropTypes from 'prop-types';
// import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import Icon from '../../../Icon';
import Input from '../../../RHF/Input';
import ColorInput from '../../../RHF/ColorInput';

const inputName = ({ name, index }) => (
  `labels[${index}][${name}]`
);

const handleNameValidation = ({ value, formValues }) => {
  const labels = Object.values(formValues.labels);
  const sameLabels = labels.filter((label) => (
    label.name.toLowerCase() === value.toLowerCase()
  ));

  if (sameLabels.length <= 1) { return true; }

  return 'Must be unique';
};

const NestedLabelFields = ({
  labelId,
  name,
  color,
  onRemove,
  errors,
  control,
  index,
}) => {
  const handleRemove = () => { onRemove(); };

  return (
    <fieldset>
      <div className="d-none">
        <Input
          name={inputName({ name: 'labelId', index })}
          type="text"
          label="Label ID"
          errors={errors}
          control={control}
          disabled
        />
      </div>

      <div className="row">
        <div className="col-md-7">
          <Input
            name={inputName({ name: 'name', index })}
            label={false}
            placeholder="The child label name"
            errors={errors}
            control={control}
            customValidation={handleNameValidation}
            size="lg"
            required
          />
        </div>

        <div className="col-md-3">
          <ColorInput
            name={inputName({ name: 'color', index })}
            errors={errors}
            control={control}
            label={false}
          />
        </div>

        <div className="col-md-2 text-right">
          <Button variant="danger" style={{ marginTop: 4 }} onClick={handleRemove}>
            <Icon name="trash" fw />
          </Button>
        </div>
      </div>
    </fieldset>
  );
};

NestedLabelFields.propTypes = {
};

NestedLabelFields.defaultProps = {
};

export default NestedLabelFields;
