import React from 'react';
import { Chart } from 'react-google-charts';
import moment from 'moment';
import Money, { moneyToChartValue } from '../../utils/money';

const computeData = (billingData) => {
  const rows = [];
  const year = moment(billingData[0].movement_date).year() || moment().year();

  Array(12).fill(null).forEach((_val, index) => {
    const month = index + 1;
    const date = moment(`${year}-${month}-01`, 'YYYY-MM-DD').format('MMMM')

    rows[index] = [
      date,    // Month
      Money(), // Revenues
      Money(), // Cost
    ];
  });


  billingData.forEach((datum) => {
    const inAmount = Money(datum.in_amount);
    const outAmount = Money(datum.out_amount);
    const month = moment(datum.operation_date).month();
    rows[month][1] = rows[month][1].add(inAmount);
    rows[month][2] = rows[month][2].add(outAmount);
  });

  return rows.map((row) => (
    [
      row[0],
      moneyToChartValue(row[1]),
      moneyToChartValue(row[2]),
    ]
  ));
}

class MonthlyOperationsClassificationBarChart extends React.Component {
  render() {
    const { billingData } = this.props;
    const rows = computeData(billingData);
    const columns = [
      {
      },
      {
        type: 'number',
        label: 'Revenues',
      },
      {
        type: 'number',
        label: 'Cost',
      },
    ];

    return (
      <Chart
        loader={<div>Loading Chart...</div>}
        chartType="ColumnChart"
        width="100%"
        height="450px"
        rows={rows}
        columns={columns}
        options={{
          legend: {
            position: 'bottom',
          },
          hAxis: {
            title: 'Time',
            titleTextStyle: { color: '#333' },
          },
          vAxis: {
            style: 'currency',
            format: '\u20AC#',
          },
          series: {
            0: { color: 'darkgreen', isStacked: false },
            1: { color: 'firebrick', isStacked: false },
          },
          backgroundColor: 'transparent',
          chartArea: {
            width: '90%',
          },
        }}
      />
    );
  }
}

export default MonthlyOperationsClassificationBarChart;
