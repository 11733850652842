import React from 'react';
import PropTypes from 'prop-types';

import DataTable from './DataTable';
import WithPlannedEvents from './WithPlannedEvents';
import Spinner from '../../Spinner';

const PlannedEventsPage = (({
  plannedEvents,
  loading,
  onUpdate,
  onDelete,
  venueParam,
}) => {
  if (loading) {
    return (
      <div>
        <Spinner
          description="Loading planned events ..."
        />
      </div>
    );
  }

  return (
    <div>
      <DataTable
        plannedEvents={plannedEvents}
        onUpdate={onUpdate}
        onDelete={onDelete}
        initialVenueFilter={venueParam || 'all'}
      />
    </div>
  );
});

PlannedEventsPage.propTypes = {
  plannedEvents: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    headline: PropTypes.string,
    starts_on: PropTypes.string,
  })).isRequired,
  loading: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  venueParam: PropTypes.string, // all | online | onsite
};

PlannedEventsPage.defaultProps = {
  loading: false,
  venueParam: 'all',
};

export default WithPlannedEvents(PlannedEventsPage);
