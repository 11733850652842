import React from "react"
import PropTypes from "prop-types"
import Moment from 'moment'

class PrivateClassRow extends React.Component {

  generate_url(id = null, action = null) {
    let url = '/trainings/private_classes/'
    if (id) url = url + id + '/';
    if (action) url = url + action;

    return url
  }

  // TODO: --> Move this logic to a .css ?
  statusBadgeClass(status) {
    switch (status) {
    case 'draft':
      return 'badge-secondary';
    case 'unconfirmed':
      return 'badge-warning';
    case 'confirmed':
      return 'badge-success';
    case 'sold_out':
      return 'badge-success';
    case 'canceled':
      return 'badge-dark';
    case 'running':
      return 'badge-info';
    case 'completed':
      return 'badge-success';
    case 'past':
      return 'badge-light';
    default:
      return 'badge-secondary';
    }
  }

  render() {
    const id = this.props._id;
    const headline = this.props.headline;
    const starts_at = Moment(this.props.starts_at);
    const city = this.props.city;
    const customer = this.props.customer;
    // TODO
    const tickets_sold = this.props.tickets_sold || 0;
    const initial_capacity = this.props.initial_capacity || 0;
    const status = this.props._status;
    const ticketProgress = (tickets_sold ? (tickets_sold / initial_capacity) : 0) * 100;

    return (
      <tr key={id}>
        <td>
          <h4>
            <span className={`badge ${this.statusBadgeClass(status)}`}>{status}</span>
          </h4>
        </td>
        <td>
          <a href={this.generate_url(id)}><big>{headline}</big></a>
          <div><small>{this.props.trainers_name}</small></div>
        </td>

        <td className="trainer">
          <div>{starts_at.format('LL')}</div>
          <strong>{customer} ({city || 'N/A'})</strong>
        </td>
        <td className="project-completion" style={{ width: '10%' }}>
          <small>Tickets Sold: {tickets_sold}/{initial_capacity}</small>
          <div className="progress progress-mini">
            <div style={{ width: `${ticketProgress}%` }} className="progress-bar"></div>
          </div>
        </td>

        <td className="table-actions" style={{ width: '29%' }}>
          <div className="btn-group">
            <a href={this.generate_url(id, 'edit')} className="btn btn-primary"><i className="fa fa-pencil"></i> Edit </a>
            <a href="#" className="btn btn-light"><i className="fa fa-area-chart"></i> Manage </a>
            <a href="/trainings/event_sheet" className="btn btn-light"><i className="fa fa-file-text-o"></i> Sheet </a>
          </div>
        </td>
      </tr>
    )
  }
}

export default PrivateClassRow
