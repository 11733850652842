import moment from 'moment';

export const interval = 30;

export const boundaryHour = 8;

export const weekDays = [
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
  'sun',
];

export const serializeMoment = (momentObj) => ({
  hour: momentObj.hour(),
  minute: momentObj.minute(),
  second: 0,
});

// export const deserializeWorkingTime = (wt, { currentInterval, currentBoundaryHour }) => {
//   const key = ((wt.from - currentBoundaryHour) * 60) + currentInterval;
//
//   return {
//     [key]: [
//       moment({ hour: 5, minute: 10 }),
//       moment({ hour: 5, minute: 10 }),
//     ],
//   };
// };

export const workingTimeToSegments = (workingTime, { currentInterval, currentBoundaryHour }) => {
  const { from, to } = workingTime;
  const minFrom = from.hour * 60 + from.minute;
  const minTo = to.hour * 60 + to.minute;
  let currentMin = minFrom;
  let currentFrom = moment(from);

  const data = {};
  let key;

  while (currentMin < minTo) {
    key = currentMin - (currentBoundaryHour * 60) + 30;
    data[key] = [
      moment(currentFrom),
      moment(currentFrom).add(30, 'minutes'),
    ];

    currentFrom = moment(currentFrom).add(30, 'minutes');
    currentMin += currentInterval;
  }

  return data;
};

export const workingTimesToSegments = (workingTimes, { currentInterval, currentBoundaryHour }) => (
  (workingTimes || []).reduce((data, wt) => (
    {
      ...data,
      ...workingTimeToSegments(wt, { currentInterval, currentBoundaryHour }),
    }
  ), {})
);

export const serializeSegments = (segments, currentInterval) => {
  const slots = [];

  let lastKey = null;
  let currentSlot = {};

  Object.entries(segments).forEach(([key, value]) => {
    const intKey = parseInt(key, 10);
    const isSameSlot = lastKey && value && lastKey + currentInterval === intKey;

    if (value && !currentSlot.from) {
      currentSlot.from = serializeMoment(value[0]);
      currentSlot.to = serializeMoment(value[1]);
      lastKey = intKey;
      return;
    }

    if (value && isSameSlot) {
      currentSlot.to = serializeMoment(value[1]);
      lastKey = intKey;
      return;
    }

    if (value && !isSameSlot && currentSlot.from && currentSlot.to) {
      slots.push({ ...currentSlot });
      currentSlot = {};
      currentSlot.from = serializeMoment(value[0]);
      currentSlot.to = serializeMoment(value[1]);
      lastKey = intKey;
      return;
    }

    if (!value && currentSlot.from && currentSlot.to) {
      slots.push({ ...currentSlot });
      currentSlot = {};
      lastKey = intKey;
      // return;
    }
  });

  if (currentSlot.from && currentSlot.to) {
    slots.push({ ...currentSlot });
    currentSlot = {};
  }

  return slots;
};

export const wtToLabel = ({ hour, minute }) => (
  [
    hour.toString().padStart(2, '0'),
    minute.toString().padStart(2, '0'),
  ].join(':')
);

export default {
  interval,
  boundaryHour,
  weekDays,
  wtToLabel,
  serializeMoment,
  serializeSegments,
};
