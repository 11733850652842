import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import Dinero from 'dinero.js';
import moment from 'moment';
// import $ from 'jquery';
import Money from '../utils/money';
import Cable from '../channels/FinancialAnalysis';
import { accountsTransactions } from '../api/bankingAccounts';
// import DateInput from './inputs/DateInput';
// import { DATE_FORMAT } from '../utils/locale';
// import sleep from '../utils/sleep';

const moneyToChartValue = (money) => {
  const newMoney = money || Dinero({ amount: 0, currency: 'EUR' });
  return { v: newMoney.toUnit(), f: newMoney.toFormat() };
};

const computeData = (bankingAccounts, accountOrderIndex) => {
  const dataMappedRows = {};

  // Map all transactions by dates
  //
  // Flat all transactions
  // Sort by dates
  //
  bankingAccounts.forEach((account) => {
    const { account_name, transactions } = account;
    const columnIndex = accountOrderIndex.indexOf(account_name);

    (transactions || []).forEach((transaction) => {
      const amount = transaction.resulting_balance;
      const date = new Date(transaction.date);
      const money = Money(amount);
      const { operation_amount, observed_amount, resulting_balance } = transaction;

      let row = dataMappedRows[date];
      if (!row) {
        // row = Array(accountOrderIndex.length).fill(Dinero({ amount: 0, currency: 'EUR' }))
        row = Array(accountOrderIndex.length).fill(null);
        row.unshift(date); // date
      }

      // row[columnIndex + 1] = row[columnIndex + 1].add(money)
      row[columnIndex + 1] = money;
      dataMappedRows[date] = row;
    });
  });

  const sortedValues = Object.values(dataMappedRows).sort((a, b) => (
    (a[0] < b[0]) ? -1 : 1
  ));

  const rows = [];

  // compute total and converts Dinero to moneyToChartValue
  sortedValues.forEach((row, rowIndex) => {
    const [date, ...amounts] = row;
    let newTotal = Dinero({ amount: 0, currency: 'EUR' });
    const newRow = [];

    amounts.forEach((amount, amountIndex) => {
      if (amount !== null) {
        newRow.push(moneyToChartValue(amount));
        newTotal = newTotal.add(amount);
      } else if (rowIndex > 0) {
        // the first value of sortedValues is the date
        const previousValue = rows[rowIndex - 1][amountIndex + 2];

        newRow.push(previousValue);
        const lastValue = newRow[newRow.length - 1].v || 0;
        const lastMoneyValue = Dinero({ amount: parseInt(lastValue * 100, 10), currency: 'EUR' });
        newTotal = newTotal.add(lastMoneyValue);
      } else {
        newRow.push(moneyToChartValue(Dinero({ amount: 0, currency: 'EUR' })));
      }
    });

    newRow.unshift(moneyToChartValue(newTotal)); // total
    newRow.unshift(date); // date
    rows.push(newRow);
  });

  return rows;
  // rows = [['2010-12-31', 34000, -1350, 6900, 0, 0],
  // ['2011-12-31', 15000.50, -2300, 8500, 0, 0],
  // ['2012-01-31', 5600, -3400, 6000, 0, 0],
  // ['2012-12-31', 18500, -4400, 7500, 0, 0],
  // ['2015-12-31', 20000, -2500, 4000, 0, 0],
  // ['2018-09-05', 14000, -4532, 7600, 0, 0]]
};


// const getLegendWidth = () => (
//   Number($('svg:first > :nth-child(3) > rect').attr('width')) || 0
// );

const chartHeight = 600;
const chartControlsHeight = 140;

class LegacyLiquiditySummaryChart extends React.Component {
  constructor(props) {
    super(props);
    const { bankingAccounts, sinceDaysAgo } = props;
    const fromDate = moment().subtract(sinceDaysAgo, 'day');

    this.chartRef = React.createRef();

    this.state = {
      bankingAccounts,
      fromDate,
      legendWidth: 0,
    };
  }

  componentDidMount() {
    this.cableSubscriptionId = Cable.subscribe(
      'account_mirrors_view:updated',
      this.handleAccountUpdate,
      this,
    );

    // window.addEventListener('resize', () => { this.handleResize(); });
    // // the chart is slow... :-/
    // [500, 600, 700, 800, 900, 1000, 2000, 3000].forEach((timeout) => {
    //   sleep(timeout).then(() => {
    //     this.handleResize();
    //   });
    // })
  }

  componentWillUnmount() {
    Cable.unsubscribe(this.cableSubscriptionId);
    // window.removeEventListener('resize', () => { this.handleResize(); });
  }

  // handleResize() {
  //   const legendWidth = getLegendWidth();
  //   this.setState({ legendWidth });
  // }

  handleAccountUpdate() {
    accountsTransactions()
      .then((response) => response.json())
      .then((response) => {
        this.setState({ bankingAccounts: response.banking_accounts });
      });
  }

  handleDateChange(value) {
    const fromDate = moment(value);
    this.setState({ fromDate });
  }

  // renderDatepicker() {
  //   const { fromDate, legendWidth } = this.state;
  //   const datepickerStyle = {
  //     right: legendWidth + 30,
  //   };

  //   return (
  //     <div className="LegacyLiquiditySummaryChart__datepicker" style={datepickerStyle}>
  //       <DateInput
  //         defaultValue={fromDate.format(DATE_FORMAT)}
  //         onChange={(values) => { this.handleDateChange(values[0]); }}
  //       />
  //     </div>
  //   );
  // }

  render() {
    const { bankingAccounts, fromDate } = this.state;
    const columns = [
      { type: 'date', label: 'Date' },
      { type: 'number', label: 'Total' },
    ];
    const accountOrderIndex = [];
    const series = {
      0: { color: '#3eff29' }, // Total
    };

    bankingAccounts.forEach(({ account_name, color }, index) => {
      series[index + 1] = {};
      if (color) series[index + 1].color = color;

      columns.push({ type: 'number', label: account_name });
      accountOrderIndex.push(account_name);
    });

    const rows = computeData(bankingAccounts, accountOrderIndex);
    // Ensure at least one datum per month
    const enrichedRows = rows.reduce((accumulator, row) => {
      if (accumulator.length === 0) {
        accumulator.push(row);
        return accumulator;
      }

      const lastRow = accumulator[accumulator.length - 1];
      const lastDate = moment(lastRow[0]);
      let gapDate = lastDate.add(1, 'month').startOf('month');
      let gapRow = [...lastRow];

      while (moment(row[0]).isAfter(gapDate)) {
        gapRow = [...gapRow];
        gapRow[0] = gapDate.toDate();
        accumulator.push(gapRow);

        gapDate = gapDate.add(1, 'month');
      }

      accumulator.push(row);
      return accumulator;
    }, []);
    const start = fromDate.toDate();
    const end = (enrichedRows[enrichedRows.length - 1] || [])[0];
    // const totalRows = rows.map((row) => ([row[0], row[1]]));
    const wrapperStyle = {
      overflow: 'visible',
      marginBottom: chartControlsHeight,
    };

    return (
      <div className="LiquiditySummaryChart" style={wrapperStyle}>
        <Chart
          ref={this.chartRef}
          chartType="SteppedAreaChart"
          width="100%"
          height={chartHeight}
          rows={enrichedRows}
          columns={columns}
          loader={<div style={{ textAlign: 'center', padding: 100 }}>Loading liquidity chart...</div>}
          options={{
            title: 'Organization liquidity over time',
            backgroundColor: 'transparent',
            // hAxis: {
            //   title: 'Time',
            //   titleTextStyle: { color: '#333' },
            //   continuous: true,
            // },
            vAxis: {
              title: 'Liquidity',
              minValue: 0,
            },
            isStacked: false,
            series,
            chartArea: { height: '80%', width: '70%' },
          }}
          chartPackages={['corechart', 'controls']}
          controls={[
            {
              controlType: 'ChartRangeFilter',
              options: {
                filterColumnIndex: 0,
                ui: {
                  chartOptions: {
                    backgroundColor: 'transparent',
                    chartArea: { width: '70%', height: '50%' },
                    hAxis: { baselineColor: 'none' },
                  },
                },
              },
              controlPosition: 'bottom',
              controlWrapperParams: {
                state: {
                  range: { start, end },
                },
              },
            },
          ]}
        />
      </div>
    );
  }
}

LegacyLiquiditySummaryChart.propTypes = {
  sinceDaysAgo: PropTypes.number,
};

LegacyLiquiditySummaryChart.defaultProps = {
  sinceDaysAgo: 365,
};

export default LegacyLiquiditySummaryChart;
