import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import Icon from '../Icon';
import { success } from '../../utils/notifier';
import handleError from '../../utils/error-handler';
import { syncBillingDocument } from '../../api/billing/billing_documents';
import ConfirmModal from '../ConfirmModal';

const SyncButton = ({
  id,
  humanDocumentType,
  progressiveNumber,
  headline,
  issuedOn,
  size,
  showButtonText,
  onClick,
  onSync,
  showConfirmationModal,
}) => {
  const [showModal, setShowModal] = useState(false);
  const handleSync = () => {
    syncBillingDocument(id)
      .then((response) => response.json())
      .then((data) => {
        success({ message: data.message });
        onSync();
      })
      .catch(handleError);
  };

  const caption = (
    <div>
      N°
      <strong>{progressiveNumber}</strong>
      &nbsp;of&nbsp;
      {issuedOn}
      <br />
      <small>{headline}</small>
    </div>
  );

  const syncDescription = (
    <div>
      <h5>
        Do you really want to sync the
        &nbsp;
        <em>{humanDocumentType}</em>
        &nbsp;
        from
        &nbsp;
        <strong>Fatture In Cloud</strong>
        ?
      </h5>

      <div className="alert alert-secondary mt-3">
        {caption}
      </div>
    </div>
  );

  const handleClick = () => {
    onClick();

    if (showConfirmationModal) {
      setShowModal(true);
      return;
    }

    handleSync();
  };

  return (
    <>
      <Button
        variant="warning"
        size={size}
        title="Synchronize from Fatture in Cloud"
        onClick={() => { handleClick(); }}
      >
        <Icon name="sync" fw />
        {
          showButtonText && (
            <span>
              &nbsp;
              Sync
              &nbsp;
              {humanDocumentType}
            </span>
          )
        }
      </Button>

      <ConfirmModal
        variant="warning"
        title={`Sync the selected ${humanDocumentType}`}
        description={syncDescription}
        show={showModal}
        onHide={() => { setShowModal(false); }}
        onConfirm={() => { handleSync(); }}
      />
    </>
  );
};

SyncButton.propTypes = {
  id: PropTypes.string.isRequired,
  humanDocumentType: PropTypes.string.isRequired,
  progressiveNumber: PropTypes.string,
  headline: PropTypes.string.isRequired,
  issuedOn: PropTypes.string.isRequired,
  size: PropTypes.string,
  showButtonText: PropTypes.bool,
  onClick: PropTypes.func,
  onSync: PropTypes.func,
  showConfirmationModal: PropTypes.bool,
};

SyncButton.defaultProps = {
  progressiveNumber: 'N/A',
  size: 'sm',
  showButtonText: false,
  showConfirmationModal: true,
  onClick: () => {},
  onSync: () => {},
};

export default SyncButton;
