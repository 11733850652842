import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import Icon from '../../Icon';
import handleError from '../../../utils/error-handler';
import { success } from '../../../utils/notifier';
import { enableCertification } from '../../../api/trainings/planned_events';
import { eventShape } from '../utils';

const EnableCertificationModal = ({
  show,
  onHide,
  onConfirm,
  event,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const plannedEventId = event.id;
  const handleClick = (e) => {
    e.preventDefault();

    if (isLoading) { return; }
    setIsLoading(true);

    enableCertification({ plannedEventId })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        success({ message: data.message });
        onHide();
        onConfirm({ plannedEventId, response: data });
      })
      .catch(handleError);
  };

  const {
    headline,
    // starts_on
  } = event;
  // const startsOn = DateTime.fromISO(starts_on);
  // const humanDate = startsOn.toFormat('dd LLL yyyy');

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Enable public training certification</strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h4 className="mb-3">
          Do you really want to enable the certification for the event
          &nbsp;
          <strong>
            &quot;
            {headline}
            &quot;
          </strong>
          ?
        </h4>
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }}>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="success" onClick={handleClick} disabled={isLoading}>
          <Icon name="check" fw />
          { isLoading ? 'Loading...' : 'Enable' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

EnableCertificationModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  event: eventShape.isRequired,
  onConfirm: PropTypes.func,
};

EnableCertificationModal.defaultProps = {
  show: false,
  onConfirm: () => {},
};

export default EnableCertificationModal;
